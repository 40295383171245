import { build } from './build-info';
import { IAppEnvironment } from '@domain/environment';

export const environment: IAppEnvironment = {
    build,
    stage: 'production',
    production: true,
    sandbox: false,
    clientId: 'StudioJS',
    appInsights: {
        instrumentationKey: '805f97b4-921e-4d2c-ad99-1382c5cc0261',
        samplingPercentage: 33,
        enabled: true
    },
    newrelic: {
        enabled: true,
        accountId: '4122654',
        trustKey: '4122654',
        agentId: '538530075',
        licenseKey: 'NRJS-bd28b6acdc31b77b97c',
        applicationId: '538530075'
    },
    signalR: {
        enabled: true,
        url: 'https://api.bannerflow.com/studio/hub'
    },
    intercomId: 'vc7jxmzv',
    gtmId: 'GTM-W5CTW5Z',
    origins: {
        acg: 'https://api.bannerflow.net/acg',
        analyticsFrontendUrl: 'https://analytics.bannerflow.com',
        b2: 'https://app.bannerflow.com',
        bannerflowLibrary: 'https://api.bannerflow.com/bannerflow-library',
        bannerlingo: 'https://api.bannerflow.com/bls',
        bfc: 'https://home.bannerflow.com',
        brandManager: 'https://api.bannerflow.com/brand-manager',
        campaignManager: 'https://cm.bannerflow.com',
        campaignService: 'https://api.bannerflow.com/cs',
        commentService: 'https://api.bannerflow.com/comment-service',
        cps: 'https://api.bannerflow.com/preview',
        feedStorage: 'https://c.bannerflow.net/sfeeds',
        fontManager: 'https://fontmanager.bannerflow.com',
        fontManagerApi: 'https://api.bannerflow.com/font-manager',
        fontService: 'https://c.bannerflow.net/fs',
        fontStorage: 'https://fonts.bannerflow.net',
        genAiImage: 'https://api.bannerflow.com/gen-ai-image-service',
        idp: 'https://idp.bannerflow.com',
        imageOptimizer: 'https://c.bannerflow.net/io',
        listService: 'https://api.bannerflow.com/list-service',
        sapi: 'https://api.bannerflow.com/studio/api',
        designApi: 'https://api.bannerflow.com/design-api/api',
        socialCampaignManager: 'https://scm.bannerflow.com',
        socialCampaignService: 'https://scm.bannerflow.com',
        studioBlobStorage: 'https://c.bannerflow.net',
        videoStorage: 'https://c.bannerflow.net'
    },
    commentServiceCreativeToken: '3b15c66f-e3f8-4f12-b2af-3d5a27222dfb',
    commentServiceCreativesetToken: '4d215810-4945-4ce2-8df0-06d3b13c06fe'
};
