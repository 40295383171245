import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BrandManagerBrandDto } from '@domain/creativeset/brand';
import { catchError, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BrandDataService {
    constructor(private http: HttpClient) {}

    getBrand(brandId: string): Observable<BrandManagerBrandDto> {
        return this.http
            .get<BrandManagerBrandDto>(
                `${environment.origins.brandManager}/brand/${brandId}?includeAllLocalizations=true`
            )
            .pipe(
                catchError(err => {
                    if (err.error === 'Invalid brandId') {
                        err.status = 404;
                    }
                    throw err;
                })
            );
    }
}
