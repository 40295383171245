import { IFontStyle } from '@domain/font';
import { IFontFamilyStyle } from '@domain/font-families';
import { replaceOrigin, getUrlParameters } from './url';

export function isElementDescendantOfElement(
    parentElement: HTMLElement | Element | EventTarget | null,
    childElement: HTMLElement | EventTarget | null
): boolean {
    if (!parentElement) {
        return false;
    }

    if (parentElement === childElement) {
        return true;
    }

    if (!childElement || !('parentNode' in childElement)) {
        return false;
    }
    let node = childElement.parentNode;
    while (node) {
        if (node === parentElement) {
            return true;
        }
        node = node.parentNode;
    }

    return false;
}

interface IUrlOption {
    scheme?: 'http:' | 'https:';
    host?: string;
    port?: number;
    pathPrefix?: string;
    path?: string;
}

export function formatUrl(option: IUrlOption): string {
    const scheme = option.scheme || 'http:';
    const host = option.host || 'localhost';
    const port = option.port;
    const pathPrefix = option.pathPrefix || '';
    const path = option.path || '';

    return `${scheme}//${host}${port ? `:${port}` : ''}${pathPrefix}${path}`;
}

export function isElementDescendantOfElementWithClass(
    element: HTMLElement | Element | EventTarget | null,
    className: string
): boolean {
    if (!element) {
        return false;
    }
    if ('classList' in element && element.classList.contains(className)) {
        return true;
    }
    if (!('parentNode' in element)) {
        return false;
    }
    let node = element.parentNode;
    while (node) {
        if ((node as HTMLElement)?.classList?.contains(className)) {
            return true;
        }
        node = node.parentNode;
    }
    return false;
}

export function isChildOfSelector(element: HTMLElement, parentSelector: string): boolean {
    let node = element.parentNode as HTMLElement;
    while (node) {
        if (typeof node.matches !== 'undefined' && node.matches(parentSelector)) {
            return true;
        }
        node = node.parentNode as HTMLElement;
    }
    return false;
}

export function isFocused(el: HTMLElement): boolean {
    return el === document.activeElement;
}

const fontStyleMap = new Map<string, FontFace>();
const fontStyleURLMap = new Map<string, string>(); // Load font again if the URL are different (i.e.: diff characters in feed)
export async function injectFontFace(
    fontStyle: IFontStyle | IFontFamilyStyle,
    fontServiceOrigin?: string,
    trimZWNJ = false
): Promise<FontFace> {
    const fontURL = getFontURL(fontStyle, fontServiceOrigin);

    let fontFace = fontStyleMap.get(fontStyle.id);
    const preloadedFontURL = fontStyleURLMap.get(fontStyle.id);
    if (fontFace && preloadedFontURL === fontURL) {
        return await fontFace.load();
    }

    if (fontURL) {
        // Skip U+200C (Zero Width Non Joiner), it should always use fallback
        const unicodeRange = trimZWNJ ? 'U+0-200B, U+200E-10FFFF' : undefined;
        fontFace = new FontFace(`f-${fontStyle.id}`, `url(${fontURL})`, {
            unicodeRange
        });
    } else {
        throw new Error('Unknown font style.');
    }
    fontStyleMap.set(fontStyle.id, fontFace);
    fontStyleURLMap.set(fontStyle.id, fontURL);
    return await fontFace.load();
}

export function injectFontStyle(
    document: Document,
    fontStyle: IFontStyle | IFontFamilyStyle,
    fontServiceOrigin?: string
): void {
    const fontURL = getFontURL(fontStyle, fontServiceOrigin);
    if (!fontURL) {
        throw new Error('Failed to get URL of font style');
    }

    const fontFamily = `f-${fontStyle.id}`;
    const fontType = getFontExtension(fontURL);
    const fontStyleSheet = document.createElement('style');
    const fontPreloadLink = document.createElement('link');
    fontPreloadLink.rel = 'preload';
    fontPreloadLink.href = fontURL;
    fontPreloadLink.as = 'font';
    fontPreloadLink.type = `font/${fontType}`;
    fontPreloadLink.crossOrigin = '';

    fontStyleSheet.textContent = `
                        @font-face {
                          font-family: '${fontFamily}';
                          src: url(${fontURL}) format('${fontType}');
                        }
                      `;
    document.head.appendChild(fontPreloadLink);
    document.head.appendChild(fontStyleSheet);
}

export function getFontExtension(url: string): string | undefined {
    return getUrlParameters(url).u?.split('.').pop();
}

export function getFontURL(
    fontStyle: IFontStyle | IFontFamilyStyle,
    fontServiceOrigin?: string
): string {
    let url = (fontStyle as IFontStyle).src || (fontStyle as IFontFamilyStyle).fontUrl;

    if (
        (typeof process === 'undefined' ||
            (window.bfstudio?.environment.stage !== 'test' && process.env.STUDIO_JS)) &&
        window.bfstudio
    ) {
        url = replaceOrigin(url, process.env.FONTS_AZURE_STORAGE_CDN_ORIGIN!);
    }
    if (
        (typeof process === 'undefined' || window.bfstudio?.environment.stage !== 'test') &&
        fontServiceOrigin
    ) {
        // notice, url can be base64 and still work
        url = replaceOrigin(url, fontServiceOrigin);
    }
    return url;
}

/**
 * Zero width joints are used for joining spans that are separated. In Arabic, Hindi and Emoji text, glyphs that are in separate
 * spans are rendered as separate instead of continous joined text. The glyphs changes a bit when they are separated vs joined.
 * In order to support Character Styles, we have to include zero-width joint character u+200D and zero-width-no-joint u+200C
 * character, to signal that a text is joined.
 */
export function getStringByExcludingZeroWidthJoints(node: Node): string {
    return node.textContent!.replace(/\u200C/g, '').replace(/\u200D/g, '');
}

export function getXmlElement(element: Element, tag: string): Element {
    const child = element.querySelector(tag);
    if (!child) {
        throw new Error(`No ${tag} found in the ${element.tagName}`);
    }

    return child;
}

export function getXmlElements(element: Element, tag: string): NodeListOf<Element> {
    const children = element.querySelectorAll(tag);
    if (!children) {
        throw new Error(`No ${tag} found in the ${element.tagName}`);
    }

    return children;
}

export function getXmlAttribute(element: Element, attribute: string): string {
    const value = element.getAttribute(attribute);
    if (!value) {
        throw new Error(`No ${attribute} attribute found in the ${element.tagName}`);
    }

    return value;
}
