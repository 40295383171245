import { ICampaignStatus, PublishStatus } from '@domain/campaign';
import { ICreative } from '@domain/creativeset/creative';

export function isCreativePublishable(creative: ICreative, campaigns: ICampaignStatus[]): boolean {
    const filteredCampaigns = getFilteredCampaigns(creative, campaigns);

    if (!isCreativeConnectedToCampaigns(creative, filteredCampaigns)) {
        return false;
    }

    if (areAllFilteredCampaignsInBadStatus(filteredCampaigns)) {
        return false;
    }

    if (hasFailedCampaign(filteredCampaigns, creative.id)) {
        return true;
    }

    if (hasChecksumMismatch(filteredCampaigns, creative)) {
        return true;
    }

    return false;
}

function getFilteredCampaigns(creative: ICreative, campaigns: ICampaignStatus[]): ICampaignStatus[] {
    return campaigns.filter(
        ({ id, creatives }) =>
            (creative.connectedCampaigns ?? []).includes(id) && // creative -> campaign
            creatives.some(({ creativeId }) => creativeId === creative.id) // campaign -> creative
    );
}

function isCreativeConnectedToCampaigns(
    creative: ICreative,
    filteredCampaigns: ICampaignStatus[]
): boolean {
    return creative.connectedCampaigns.length > 0 && filteredCampaigns.length > 0;
}

function areAllFilteredCampaignsInBadStatus(filteredCampaigns: ICampaignStatus[]): boolean {
    return filteredCampaigns.every(
        ({ status }) => status === PublishStatus.NotPublished || status === PublishStatus.Publishing
    );
}

function hasFailedCampaign(filteredCampaigns: ICampaignStatus[], creativeId: string): boolean {
    return filteredCampaigns.some(
        ({ creatives }) => creatives.find(({ creativeId: id }) => id === creativeId)?.failed
    );
}

function hasChecksumMismatch(filteredCampaigns: ICampaignStatus[], creative: ICreative): boolean {
    for (const campaign of filteredCampaigns) {
        const creativeInCampaign = campaign.creatives.find(
            ({ creativeId }) => creativeId === creative.id
        );
        if (
            creative.checksum &&
            creativeInCampaign?.checksum &&
            creative.checksum !== creativeInCampaign.checksum
        ) {
            return true;
        }
    }
    return false;
}

export function isCreativeInCampaign(creative: ICreative, campaigns: ICampaignStatus[]): boolean {
    if (!creative.connectedCampaigns?.length) {
        return false;
    }
    for (const campaignId of creative.connectedCampaigns) {
        const campaign = campaigns.find(({ id }) => id === campaignId);
        if (!campaign) {
            continue;
        }
        const creativeInCampaign = campaign.creatives.find(
            ({ creativeId }) => creativeId === creative.id
        );
        if (!creativeInCampaign) {
            continue;
        }
        return true;
    }
    return false;
}

export function filterCampaignsConnectedTo(
    creatives: ICreative[],
    campaigns: ICampaignStatus[]
): ICampaignStatus[] {
    const connectedCampaigns: ICampaignStatus[] = [];
    for (const campaign of campaigns) {
        const campaignConnected = campaign.creatives.some(({ creativeId }) =>
            creatives.some(
                ({ id, connectedCampaigns }) =>
                    id === creativeId && connectedCampaigns.includes(campaign.id)
            )
        );
        if (!campaignConnected) {
            continue;
        }
        connectedCampaigns.push(campaign);
    }
    return connectedCampaigns;
}
