import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MediaLibraryEffects } from './media-library.effects';
import * as fromMediaLibrary from './media-library.reducer';
import { MediaLibraryService } from './media-library.service';

@NgModule({
    imports: [
        StoreModule.forFeature(fromMediaLibrary.MEDIA_LIBRARY_FEATURE_KEY, fromMediaLibrary.reducer),
        EffectsModule.forFeature([MediaLibraryEffects])
    ],
    providers: [MediaLibraryService]
})
export class MediaLibraryStoreModule {}
