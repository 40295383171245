import { Injectable } from '@angular/core';
import { Logger } from '@bannerflow/sentinel-logger';
import { isImageNode } from '@creative/nodes';
import { IDesign, IElementProperty, ImageLibraryAsset } from '@domain/creativeset';
import { IImageElementDataNode } from '@domain/nodes';
import { uuidv4 } from '@studio/utils/id';
import { isSVGFile } from '@studio/utils/media';
import { Subject, Subscription, take } from 'rxjs';
import { CreativesetDataService } from '../../../shared/creativeset/creativeset.data.service';
import {
    AssetUploadService,
    IAssetUploadAfterProgressState,
    IAssetUploadLoadedState,
    IAssetUploadState
} from '../../design-view/services';

export type AssetUploadProgress = { current: number; total: number };

@Injectable()
export class CreativeConverterUploadService {
    uploadSubscription: Subscription; // this needs to be handled from the component
    private _assetUploadComplete$ = new Subject<IDesign>();
    assetUploadComplete$ = this._assetUploadComplete$.asObservable().pipe(take(1));
    private _assetsUploadProgress$ = new Subject<AssetUploadProgress>();
    assetsUploadProgress$ = this._assetsUploadProgress$.asObservable();

    private imagesUploaded = 0;
    private imagesToUpload = 0;
    private imagesFailed: string[] = [];
    private assetNameToNodeId = new Map<string, string>();
    private logger = new Logger('CreativeConverterUploadService');
    private design: IDesign;

    constructor(
        private creativesetDataService: CreativesetDataService,
        private assetUploadService: AssetUploadService
    ) {
        this.uploadSubscription = this.assetUploadService.uploadProgress$.subscribe(
            this.onAssetUploadStateChange
        );
    }

    hasAssetsToUpload(design: IDesign): boolean {
        for (const node of design.document.nodeIterator_m()) {
            if (isImageNode(node)) {
                if (node.imageAsset) {
                    return true;
                }
            }
        }
        return false;
    }

    async uploadCreativeAssets(design: IDesign): Promise<string | void> {
        if (this.imagesUploaded !== 0) {
            this.logger.warn(
                'This instance already uploaded assets. It seems that the state was persisted. Did you forget to unsubscribe?'
            );
        }

        this.design = design;
        const files = await this.getImageFiles(design);

        this.imagesToUpload = files.length;
        return this.uploadAssets(files);
    }

    private async getImageFiles(design: IDesign): Promise<File[]> {
        const nodeImageFiles: File[] = [];
        for (const node of design.document.nodeIterator_m()) {
            if (!isImageNode(node) || !node.imageAsset) {
                continue;
            }
            try {
                // need to convert base64 --> blob --> file
                const base64File = await fetch(node.imageAsset.url);
                const blob = await base64File.blob();
                const imageExtension = isSVGFile(blob) ? 'svg' : 'png';
                const uniqueFileName = `${uuidv4()}.${imageExtension}`;
                this.assetNameToNodeId.set(uniqueFileName, node.id);
                const file = new File([blob], uniqueFileName, { type: blob.type });
                nodeImageFiles.push(file);
            } catch (e) {
                this.logger.error(e);
            }
        }
        return nodeImageFiles;
    }

    getFailedImages(): string[] {
        return this.imagesFailed;
    }

    imageUploadFailed(): boolean {
        return this.imagesFailed.length > 0;
    }

    private uploadAssets(files: File[]): Promise<string | void> {
        this.logger.verbose(`Uploading assets`);
        return this.assetUploadService.uploadAssets({ files });
    }

    private onAssetUploadStateChange = (uploadState: IAssetUploadState): void => {
        switch (uploadState.status) {
            case 'LOAD_ASSET_FILE_STARTED':
                this.logger.verbose(`Asset upload LOAD_ASSET_FILE_STARTED`);
                this.uploadStarted(uploadState);
                break;
            case 'AFTER_PROGRESS': {
                this.logger.verbose(`Asset[${uploadState.asset.name}] upload AFTER PROGRESS`);
                this.imageUploaded();

                const assetName = uploadState.newAsset.name;
                const nodeId = this.assetNameToNodeId.get(assetName);
                if (!nodeId) {
                    this.logger.error(`Could not find node id for asset ${assetName}`);
                    return;
                }
                const node = this.design.document.findNodeById_m(nodeId);
                if (node && isImageNode(node)) {
                    this.updateImageNodeAsset(node, uploadState);
                }
                break;
            }
            case 'COMPLETE':
                this.logger.verbose(`Asset upload COMPLETE`);
                // if we are done processing all assets
                if (this.imagesLeftToUpload() === 0) {
                    this._assetUploadComplete$.next(this.design);
                }
                break;
            case 'FAIL': {
                this.logger.warn(`Asset upload FAIL`);
                const assetName = uploadState.file.name;
                const nodeId = this.assetNameToNodeId.get(assetName);
                if (!nodeId) {
                    this.logger.error(`Could not find node id for asset ${assetName}`);
                    return;
                }
                this.imageFailed(nodeId);
                break;
            }
            default:
                break;
        }
    };

    private uploadStarted(uploadState: IAssetUploadLoadedState): void {
        this.logger.verbose(`Asset[${uploadState.file.name}] upload started`);

        const { asset } = uploadState;
        if ('original' in asset) {
            asset.original.url = uploadState.url;
            asset.original.height = uploadState.size.height;
            asset.original.width = uploadState.size.width;
        }

        uploadState.asset.name = uploadState.file.name;
    }

    private updateImageNodeAsset(
        node: IImageElementDataNode,
        uploadState: IAssetUploadAfterProgressState
    ): void {
        const nodeAsset = node.imageAsset;
        if (!nodeAsset) {
            throw new Error('No asset found!');
        }
        nodeAsset.id = uploadState.newAsset.id;
        nodeAsset.url = uploadState.newAsset.url;
        nodeAsset.__loading = false;

        if ('fileSize' in nodeAsset) {
            nodeAsset.fileSize = uploadState.newAsset.fileSize;
        }

        this.updateElementProperties(node.id, uploadState.element.properties);
        this.creativesetDataService.creativeset.images.push(uploadState.newAsset as ImageLibraryAsset);
    }

    private updateElementProperties(elementId: string, newElementProperties: IElementProperty[]): void {
        const designElement = this.design.elements.find(element => element.id === elementId);
        if (designElement) {
            designElement.properties = newElementProperties;
        } else {
            this.logger.warn(`Could not find element with id: ${elementId}`);
        }
    }

    private imageUploaded(): void {
        this.imagesUploaded++;
        this.uploadProgress();
    }

    private imageFailed(nodeId: string): void {
        this.imagesFailed.push(nodeId);
        this.uploadProgress();
    }

    private uploadProgress(): void {
        this._assetsUploadProgress$.next({
            current: this.imagesUploaded + this.imagesFailed.length,
            total: this.imagesToUpload
        });
    }
    private imagesLeftToUpload(): number {
        return this.imagesToUpload - this.imagesUploaded - this.imagesFailed.length;
    }
}
