// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const BANNERFLOW_INTERNAL_ACCOUNT_SLUGS = [
    'bannerflow',
    'bannerflow-marketing-agency',
    'jakob',
    'lacice-showcase-account',
    'eric',
    'fredrik-testing',
    'bannerflow-trial-account',
    'jansson-shoes',
    'fredrik-test-account',
    'design-store',
    'kreab',
    'enterprise-demo',
    'jespertest',
    'fatest',
    'bannerflow-enterprise',
    'minan-test',
    'jared-lekkas',
    'sanna-test-2',
    'kpis-dont-touch',
    'oscars-agency',
    'gustaf-group',
    'niteco',
    'recruitment-test',
    'the-agency',
    'mediamenu',
    'greensock-internal-testing',
    'aexus',
    'yrgo',
    'happy-hugo',
    'bannerflow-marketing',
    'jonas-agency',
    'peter-test-3',
    'bannerflow-jan-test',
    'bannerflow-studio',
    'studio-onsite-test',
    'randa-test',
    'randa-testing',
    'bannerflow-anton',
    'customer-business-report',
    'ip-only',
    'showcase',
    'bjornes-magasin',
    'sizmek',
    'studio-demo',
    'bannerflow-paid-marketing',
    'sannas-account-121',
    'stylepit',
    'speed-dating-demo',
    'bannerflow-training',
    'marie-test-account',
    'bannerflow-joakim',
    'delta-projects-test',
    'testingtesting',
    'sentor2',
    'bannerflow-robin',
    'bannerflow-website-mock-brand',
    'drmugg',
    'sentor',
    'brand-x',
    'platform161',
    'bannerflow-testing',
    'fredrik-test-321',
    'bannerflow-anton-testtest',
    'bannerflow-travel',
    'bannerflow-casino',
    'bannerflow-showcase',
    'bannerflow-campaign-manager-test-sessions',
    'bancom',
    'bannerflow-sales',
    'bannerflow-production-suite',
    'joakim-jamte-production-test',
    'petter-knutsson-production-test',
    'christopher-ahlen-production-test',
    'burris',
    'ibrahim-production-test',
    'henrik-ralvert-production-test',
    'mickef-production-test',
    'fredrik-production-suite',
    'sameh-production-test',
    'tingan',
    'can-bakis-production-test',
    'felix-freye-prodcution-test',
    'toliy-production-test',
    'blomqvist-production-test',
    'artem-production-test',
    'serhii-production-test',
    'danielv-production-test',
    'sanna-prod-package-test-2',
    'mathias-production-test',
    'bjorn-production-test',
    'therese-production',
    'johannes-prod-test',
    'production-suite',
    'cervin-test',
    'cme2e',
    'new-cm-on',
    'campaign-manager-demo',
    'cm-test',
    'live-ad-test-do-not-use',
    'states-release',
    'henriks-super-konto',
    'viktor-testing-stuff',
    'andreas-hahn',
    'pontus-jonsson',
    'mirco',
    'actions',
    'danielv-cmp',
    'migration-cm-test',
    'hakim-lazrak',
    'unisport',
    'sameh-account',
    'therese-social',
    'test-agency222',
    'test-trial-22',
    'tttt',
    'ad-weight-tracking',
    'henriks-no-analyticsdemo',
    'omnirender',
    'sales-demo',
    'level-up',
    'social-test',
    'test-cm-toggle',
    'anton-prodspec',
    'andreas-testar',
    'oskar-prodspec',
    'lars-test',
    'lars-test-agency',
    'nichol-prodspec',
    'evelinn-productspec',
    'henrik-testnewtoggles',
    'stefan-test',
    'hakim',
    'serhiips',
    'stephen-test',
    'studio-performance-testing',
    'maxiprod',
    'pentest-a',
    'pentest-b',
    'henrik-production-suite',
    'linus-test',
    'hhah',
    'tobias-demo',
    'hannatest',
    'dooh-showcase',
    'andreas-hahn-ps',
    'roberto-ps',
    'joanna-cinkusz',
    'social-testing-sessions',
    'bannerflow-marketing-2022',
    'mgr',
    'michal-staszewski',
    'co-demo-account',
    'studio-test-account',
    'mateusz-dominiak',
    'max-demo',
    'facebook-review-2022',
    'nadiia-productspec',
    'david-prodspec',
    'johanna-prodspec',
    'wojtek-test',
    'rawi-prodspec',
    'joanna-social-test-prod',
    'bannerflow-onboarding',
    'joakims-playground',
    'custom-widgets',
    'bannerflow-internal',
    'david2-prodspec',
    'nicks-demos',
    'bannerflow-rec',
    'linus',
    'on-demand',
    'dashyai',
    'tomek-gizinski',
    'hannaintegrationtest',
    'bfgoes-espana2023',
    'amanda-prodspec',
    'cachebusters',
    'mateuszkajsztura',
    'jennies-ab',
    'christian-jolinger',
    'vandad-monadian',
    'kajas-demo',
    'cecilia-ps',
    'test-roberto',
    'new-account-test',
    'new-ent-123',
    'new-account',
    'new-account-hot-fix',
    'clv-testing',
    'demo',
    'recruitment-demo',
    'gabor-czigleczky'
] as const;
