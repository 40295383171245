import { Injectable, OnDestroy } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
    addKeyframeWithState,
    createAnimation,
    getAnimationsOfType,
    getInAnimationDuration,
    getKeyframesAtTime,
    getKeyframesOfAnimationAtTime,
    isTimeAt,
    MIN_KEYFRAME_DISTANCE,
    sortAnimations,
    toRelativeTime
} from '@creative/animation.utils';
import {
    calculateStateFromAnimationAtTime,
    DEFAULT_EMPTY_STATE,
    getStateById
} from '@creative/rendering';
import { IAnimation, IAnimationKeyframe } from '@domain/animation';
import { OneOfElementPropertyKeys, statePropertyToUnitMap } from '@domain/property';
import { IState } from '@domain/state';
import { TransformMode } from '@domain/workspace';
import { merge, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { GizmoColor } from '../../../shared/components/canvas-drawer/gizmo.colors';
import { GainsightEvent, GainsightService } from '../../../shared/services/gainsight.service';
import { PropertiesService } from '../properties-panel/properties.service';
import { ElementSelectionService } from '../services';
import { EditorEventService } from '../services/editor-event/editor-event.service';
import { EditorStateService } from '../services/editor-state.service';
import { HistoryService } from '../services/history.service';
import { WorkspaceTransformService } from '../workspace/workspace-transform.service';
import { AnimationService, KeyframeService } from './timeline-element';

const KEYFRAME_TIME_TOLERANCE = MIN_KEYFRAME_DISTANCE;

@Injectable()
export class AnimationRecorderService implements OnDestroy {
    isRecording = false;
    private _recording$ = new Subject<boolean>();
    recording$ = this._recording$.asObservable();
    private animation?: IAnimation;
    private newElementAnimationMap = new Map<string /** element id */, IAnimation>();
    private unsubscribe$ = new Subject<void>();

    private get time(): number {
        return this.editorStateService.renderer.time_m;
    }

    constructor(
        private editorStateService: EditorStateService,
        private propertiesService: PropertiesService,
        private editorEvent: EditorEventService,
        private keyframeService: KeyframeService,
        private animationService: AnimationService,
        private transformService: WorkspaceTransformService,
        private historyService: HistoryService,
        private gainsightService: GainsightService,
        private elementSelectionService: ElementSelectionService
    ) {
        this.propertiesService.propertyChanging$
            .pipe(
                filter(() => this.isRecording),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(({ property, value }) => this.updateProperty(property, value));

        merge(
            this.elementSelectionService.change$,
            this.historyService.snapshotApply$,
            this.animationService.seek$,
            this.animationService.pause$,
            this.transformService.mode$.pipe(
                filter(
                    mode =>
                        this.isRecording &&
                        (mode === TransformMode.Resize ||
                            mode === TransformMode.Rotate ||
                            mode === TransformMode.Move)
                )
            )
        )
            .pipe(
                // Note: workspace not initially available
                filter(
                    () =>
                        this.isRecording &&
                        !this.transformService.workspace?.designView.animator?.isPlaying
                ),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => this.updateKeyframeSelection(this.time));

        this.editorEvent.creative.change$.pipe(takeUntilDestroyed()).subscribe(() => {
            if (!this.editorStateService.document.elements.length) {
                this.stopRecording();
            }
        });
    }

    private getCalculatedStateAtCurrentTime(): IState | undefined {
        if (this.isRecording) {
            const element = this.elementSelectionService.currentSelection.element;
            const time = this.getValidKeyframeTime();
            this.updateAnimationSelection();
            if (element && typeof time === 'number') {
                if (this.animation) {
                    return {
                        ...DEFAULT_EMPTY_STATE,
                        ...calculateStateFromAnimationAtTime(
                            element,
                            this.animation,
                            this.editorStateService.size,
                            element.time + time
                        )
                    };
                }
                return { ...DEFAULT_EMPTY_STATE };
            }
        }
    }

    startRecording(): void {
        if (this.isRecording) {
            return;
        }
        GizmoColor.inRecordMode = true;
        const selection = this.elementSelectionService.currentSelection;
        if (selection && selection.length > 1) {
            const index = selection.length - 1;
            this.elementSelectionService.setSelection(selection.asSortedArray()[index]);
        }
        this._recording$.next(true);
        this.isRecording = true;
        this.updateKeyframeSelection(this.time);
        this.gainsightService.sendCustomEvent(GainsightEvent.StartRecordAnimation);
    }

    stopRecording(): void {
        if (!this.isRecording) {
            return;
        }
        GizmoColor.inRecordMode = false;
        this._recording$.next(false);
        this.isRecording = false;
        this.newElementAnimationMap.clear();
        this.animation = undefined;
        // TODO: Clear "empty" animations

        // Time relative to element
        const time = this.getValidKeyframeTime();
        let keyframeAtTime: IAnimationKeyframe | undefined;
        const element = this.elementSelectionService.currentSelection.element;
        if (typeof time === 'number' && element) {
            keyframeAtTime = getKeyframesAtTime(
                element.time + time,
                element,
                this.animation,
                'keyframe'
            )[0];
        }
        if (!keyframeAtTime) {
            this.propertiesService.selectedStateChange$.next(undefined);
        }
        this.gainsightService.sendCustomEvent(GainsightEvent.StopRecordAnimation);
    }

    private updateProperty(property: OneOfElementPropertyKeys, value: any): void {
        const time = this.getValidKeyframeTime();

        if (typeof time !== 'number') {
            return;
        }

        const element = this.elementSelectionService.currentSelection.element;

        if (!element) {
            throw new Error('No selection element when updating properties');
        }

        this.updateAnimationSelection();

        let animationOrKeyframeCreated = false;

        if (!this.animation) {
            this.historyService.createSnapshotCandidate();
            animationOrKeyframeCreated = this.getOrCreateAnimation();
        }

        // this.animation should theoretically always exist after createAnimation
        if (!this.animation) {
            throw new Error('Could not record keyframe, no animation available.');
        }

        if (property in statePropertyToUnitMap) {
            // Create empty keyframe so no properties other than the modified one is affected.
            const { state, keyframe, created } = this.getOrCreateKeyframe(property, value);

            if (this.propertiesService.stateData?.id !== state.id) {
                // When no state is selected value will the element value and not a relative value
                if (!this.propertiesService.inStateView && typeof value === 'number') {
                    value = value - element[property];
                }

                // Can cause jumps unless we set the prop value here
                state[property] = value;
                this.propertiesService.selectedStateChange$.next(state);
                this.keyframeService.set(keyframe);
            }
            if (created || animationOrKeyframeCreated) {
                this.editorEvent.elements.change(element, { animations: element.animations });
                this.historyService.saveLastSnapshotCandidate();
            }
        }
    }

    /**
     * Create animation if it doesn't already exist, else set it to existing.
     * Return true if animation or keyframe is created
     */
    private getOrCreateAnimation(): boolean {
        const element = this.elementSelectionService.currentSelection.element;

        // Time relative to element
        const time = this.getValidKeyframeTime();

        if (typeof time !== 'number') {
            return false;
        }

        if (!element) {
            throw new Error('No selection element found when getting or creating animation');
        }

        let animationCreated = false;
        const { animations } = element;

        // Only allow one custom keyframe animation
        this.updateAnimationSelection();

        if (!this.animation) {
            this.historyService.createSnapshotCandidate();
            this.animation = createAnimation(element);
            element.animations.push(this.animation);
            element.animations.sort(sortAnimations);
            this.newElementAnimationMap.set(element.id, this.animation);
            animationCreated = true;
        }

        // Add default state keyframes in the beginning and end of element (minus transitions)
        if (animationCreated && this.animation.keyframes.length < 2) {
            const inDuration = getInAnimationDuration(animations);

            // The keyframe is NOT added at 0 => Add a default keyframe in the beginning
            if (time > MIN_KEYFRAME_DISTANCE) {
                const inTime = time > MIN_KEYFRAME_DISTANCE + inDuration ? inDuration : 0;
                addKeyframeWithState(element, this.animation, { time: inTime });
            }
            // The keyframe is NOT added at the end => Add a keyframe in the end
            // if (time < duration - MIN_KEYFRAME_DISTANCE) {
            // const outTime = time < duration - outDuration - MIN_KEYFRAME_DISTANCE ? duration - outDuration : duration;
            //     addKeyframeWithState(element, this.animation, { time: duration });
            // }
        }

        return animationCreated;
    }

    /**
     * Return the keyframe if it exist at playhead time.
     * If not create a new one.
     */
    private getOrCreateKeyframe(
        property: OneOfElementPropertyKeys,
        value: any
    ): { state: IState; keyframe: IAnimationKeyframe; created: boolean } {
        const time = this.getValidKeyframeTime();
        const element = this.elementSelectionService.currentSelection.element;
        if (element && this.animation && typeof time === 'number') {
            const keyframesAtTime = getKeyframesOfAnimationAtTime(
                element.time + time,
                element,
                this.animation,
                KEYFRAME_TIME_TOLERANCE
            );

            // Has existing keyframes at time
            if (keyframesAtTime.length) {
                const keyframe = keyframesAtTime[0];
                return {
                    state: getStateById(element, keyframe.stateId)!,
                    keyframe,
                    created: false
                };
            }
            // Create new keyframe
            else {
                const currentState = this.getCalculatedStateAtCurrentTime();
                const keyframeAndState = addKeyframeWithState(element, this.animation, {
                    ...currentState,
                    [property]: value,
                    time
                });
                if (keyframeAndState) {
                    return { ...keyframeAndState, created: true };
                }
            }
        }
        throw new Error('Can´t get or create keyframe at this time');
    }

    private updateKeyframeSelection(time: number): void {
        if (this.isRecording) {
            const keyframes: IAnimationKeyframe[] = [];
            const elements = this.elementSelectionService.currentSelection.elements;

            elements.forEach(element => {
                const animations = getAnimationsOfType(element, 'keyframe');
                keyframes.push(...getKeyframesAtTime(time, element, animations, 'keyframe', 0.001));
            });
            this.keyframeService.set(...keyframes);

            if (keyframes.length === 0) {
                // Make properties service calculate a state
                this.propertiesService.selectedStateChange$.next(
                    this.getCalculatedStateAtCurrentTime()
                );
            }
        }
    }

    /**
     * Sets animations to undefined if owner element has been deselected
     */
    private updateAnimationSelection(): void {
        const element = this.elementSelectionService.currentSelection.element;
        if (!element || !element.animations.some(animation => animation.id === this.animation?.id)) {
            this.animation = undefined;
            if (element) {
                this.newElementAnimationMap.delete(element.id);
            }
        }

        if (element) {
            // Only allow one custom keyframe animation
            const animation = getAnimationsOfType(element, 'keyframe')[0];
            if (animation) {
                this.newElementAnimationMap.set(element.id, animation);
            }

            if (this.newElementAnimationMap.has(element.id)) {
                this.animation = this.newElementAnimationMap.get(element.id)!;
            }
        }
    }

    /**
     * Returns a time relative to element.time
     */
    private getValidKeyframeTime(): number | undefined {
        const element = this.elementSelectionService.currentSelection.element;

        if (element) {
            const time = this.time;

            if (isTimeAt(time, element)) {
                return toRelativeTime(element, time);
            }
        }

        return;
    }

    ngOnDestroy(): void {
        GizmoColor.inRecordMode = false;
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
