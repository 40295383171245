import { IPSDConversionError, LayerItem, PSDErrorType } from '@domain/psd';

export class PSDConversionError extends Error implements IPSDConversionError {
    // these are errors that we can ignore and still import the creative
    // (e.g. if the original font is not found, we can still import the creative with a fallback font)
    private _lowSeverityErrors = [PSDErrorType.OriginalFontNotFound];
    private _type: PSDErrorType;
    private _isCritical: boolean;

    get type(): PSDErrorType {
        return this._type;
    }

    get isCritical(): boolean {
        return this._isCritical;
    }

    constructor(message: string, type: PSDErrorType = PSDErrorType.CreativeConversion) {
        super(message);
        this.name = 'PSDConversionError';
        this._type = type;
        this._isCritical = !this._lowSeverityErrors.includes(type);
    }
}

export function hasCriticalError(layer: LayerItem): boolean {
    return Boolean(layer?.error?.isCritical);
}
