import { Params } from '@angular/router';
import { ApprovalStatus } from '@domain/creativeset/creative';

interface IMVFilters {
    sizes: string[];
    statuses: ApprovalStatus[];
}

export function getFiltersFromQueryParams(queryParams: Params): IMVFilters {
    // get filters from current query parameters. e.g.: &size=132,32&status=in%20progress
    const sizesParam = queryParams['size'] || '';
    const statusesParam = queryParams['status'] || '';
    const sizes = sizesParam.split(',').filter((size: string) => size.trim() !== '');
    const statuses = statusesParam.split(',').filter((status: ApprovalStatus) => status.trim() !== '');

    return {
        sizes,
        statuses
    };
}
