import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

import { WebStorageStateStore, UserManager, User as OidcUser } from 'oidc-client';
import { Logger } from '@bannerflow/sentinel-logger';
import { UserService } from './state/user.service';

const SILENT_REQUEST_TIMEOUT = 15000;

@Injectable({ providedIn: 'root' })
export class OidcUserService {
    userSession: UserManager | undefined;
    inShowcaseMode = location.pathname.startsWith('/share/');

    private user: OidcUser | undefined;
    private silentRenewErrors = 0;
    private logger = new Logger('UserService');

    constructor(private userService: UserService) {
        this.userSession =
            environment.stage !== 'test'
                ? new UserManager({
                      authority: environment.origins.idp,
                      client_id: environment.clientId,
                      post_logout_redirect_uri: location.origin,
                      redirect_uri: `${location.origin}/assets/auth/callback.html`,
                      response_type: 'id_token token',
                      scope: 'openid studio fontmanager bannerflowlibrary campaignservice commentservice videoconverter listservice bannerlingoservice',
                      silent_redirect_uri: `${location.origin}/assets/auth/silent.html`,
                      automaticSilentRenew: true,
                      silentRequestTimeout: SILENT_REQUEST_TIMEOUT,
                      userStore: new WebStorageStateStore({ store: window.localStorage })
                  })
                : undefined;

        if (this.userSession) {
            // clear stale state
            this.userSession.clearStaleState();

            localStorage.setItem('oidc-redirect-path', window.location.pathname);

            // Setup event to listen to silent refreshes
            this.userSession.events.addUserLoaded(async () => {
                const user = await this.userSession?.getUser();
                if (user) {
                    this.setUser(user);
                }
            });

            this.userSession.events.addUserUnloaded(() => {
                this.logger.warn('User session has been terminated');
                this.signinRedirect();
            });

            this.userSession.events.addAccessTokenExpiring(() => {
                if (!this.inShowcaseMode) {
                    void this.silentRenew();
                }
            });

            this.userSession.events.addUserSignedOut(() => {
                this.user = undefined;

                // When a user is logged out let it be remained logged out, when accessing a shared link.
                if (!this.inShowcaseMode) {
                    void this.signinRedirect();
                }
            });

            this.userSession.events.addSilentRenewError(e => {
                this.logger.debug(e);
                this.handleSilentError();
            });

            if (this.user) {
                this.setUser(this.user);
            }
        }
    }

    async getUser(): Promise<OidcUser | undefined> {
        const allowAnonymousUser = this.inShowcaseMode;
        if (!this.userSession) {
            return;
        }

        if (this.user) {
            if (this.user.expired) {
                await this.renewToken();
            }
        } else if (this.userSession) {
            const user = await this.userSession.getUser();
            if (user) {
                this.setUser(user);
            }
            if ((!user || user.expired) && allowAnonymousUser) {
                return;
            }
        }

        if (allowAnonymousUser && !this.user) {
            return;
        }

        if (!this.user || this.user.expired) {
            await this.signinRedirect({
                state: window.location.href
            });
        }

        return this.user;
    }

    public setUser(oidcUser: OidcUser): void {
        if (oidcUser && !oidcUser.expired) {
            this.user = oidcUser;
            this.userService.setOIDCUser(oidcUser);
        }
    }

    async renewToken(): Promise<void> {
        this.user = await this.silentRenew();
    }

    public handleSilentError(): void {
        this.silentRenewErrors++;

        // If we fail to silently renew the session 15 times, force login
        if (this.silentRenewErrors > 15) {
            this.logger.warn('Failed to renew token. Redirecting to login!');
            this.login();
        }

        if (this.userSession) {
            setTimeout(() => {
                this.userSession?.signinSilent().catch(() => {
                    this.handleSilentError();
                });
            }, SILENT_REQUEST_TIMEOUT);
        }
    }

    login(): void {
        if (!this.userSession) {
            return;
        }
        this.signinRedirect();
    }

    private async silentRenew(): Promise<OidcUser> {
        if (!this.userSession) {
            return Promise.resolve(undefined as any);
        }
        try {
            this.logger.warn('Silently renewing token');
            return await this.userSession.signinSilent();
        } catch (ex) {
            this.logger.warn(ex);
            // Try onemore time :) to handle 'Framed window timeout' issue https://github.com/IdentityModel/oidc-client-js/issues/644.
            return this.userSession.signinSilent();
        }
    }

    signinRedirect = async (redirectUrl?: { state: string }): Promise<void> => {
        localStorage.setItem('oidc-redirect-path', window.location.pathname);
        this.logger.warn('You are not logged in.');
        await this.userSession?.signinRedirect(redirectUrl);
    };
}
