import { IAd } from '@domain/ad/ad';
import { AdDisplayType, IAdData } from '@domain/ad/ad-data';
import { IAdDataCreative } from '@domain/ad/ad-data-creative';
import { AdEvents, AdEventsType, AdEventType } from '@domain/ad/ad-events';
import { IBannerflowWindow, IPublisherWindow } from '@domain/ad/bannerflow-window';
import { TCData } from '@domain/ad/tcf';
import { ITracking } from '@domain/ad/tracking-data';
import { IUrlParameterMap } from '@domain/ad/url-parameters';
import { IViewability } from '@domain/ad/viewability';
import { IFeedDataKeyValueItem } from '@domain/feed';
import { isDataJSUrl } from '@studio/utils/ad/ad.utils';
import { getAndroidMajorVersion, isIE } from '@studio/utils/ad/browser';
import { accessTopWindow, insertAfter, isIframe } from '@studio/utils/ad/dom';
import { EventUtils } from '@studio/utils/ad/events';
import { AsyncSubject } from '@studio/utils/async-subject';
import { EventEmitter } from '@studio/utils/event-emitter';
import { _performanceMark } from '@studio/utils/performance';
import {
    concatUrl,
    getDomain,
    getUrlParameters,
    getUrlsInString,
    hasParameter,
    isBannerflow,
    parameterToBool,
    removeQueryParams
} from '@studio/utils/url';
import { validateFeedData } from '@studio/utils/validation';
import { AdScriptLoader } from './ad-script-loader';
import { CreativeLoader } from './creative-loader';
import { PreloadImage } from './preload-image';
import { initTCF_m } from './tracking/tcf';
import { Tracking } from './tracking/tracking';
import { Viewability } from './viewability';

const SUCCESS_ATTRIBUTE = 'data-rendered';
const HTML_EXPORT_REGEX = /ad\.js/;

export class Ad extends EventEmitter<AdEventsType> implements IAd {
    /**
     * Reference to the ad script tag added to the DOM.
     */
    adTag: HTMLScriptElement;

    /**
     * Data to be injected by Ad generator service
     */
    readonly data: IAdData;

    /**
     * UrlParameters on the script tag.
     */
    readonly parameters: IUrlParameterMap;

    /**
     * Creative to be displayed. Note that the selected may
     * vary when using scheduling custom scripts etc.
     */
    selectedCreative: IAdDataCreative;

    /**
     * Preload image loader.
     * Will be undefined if preload is skipped.
     */
    preloadImage_m?: PreloadImage;

    /**
     * Div in which creative and image should be rendered inside
     */
    container: HTMLDivElement;

    /**
     * Creative loader. Will load and show animated creative.
     */
    creativeLoader?: CreativeLoader;

    /**
     * Handles communication with our tracker and third party networks.
     */
    tracking: ITracking;

    /**
     * Responsive
     */
    responsive: boolean;

    /**
     * Custom script loaded
     */
    adScripts: AdScriptLoader;

    /**
     * Custom dynamic content, mainly used for DCO
     */
    overriddenFeedData?: IFeedDataKeyValueItem[];

    /**
     * Viewability
     */
    viewability: IViewability;

    /**
     * Util for better event support.
     */
    events = new EventUtils();

    /**
     * Subject for TCData.
     */
    tcDataSubject = new AsyncSubject<TCData | undefined>(undefined);

    /**
     * If creative or animated ad is the inteded for this ad.
     * Can be set in ad data or by UrlParameter on the ad tag.
     */
    displayType_m: AdDisplayType;

    private _overridePreload = false;
    private _destroyed = false;
    private isHeadInjection: boolean; // if the ad tag was injected into the <head>

    private _window = window as unknown as IBannerflowWindow;
    private _mutationObserver?: MutationObserver;

    constructor(data: IAdData, adTag?: HTMLScriptElement, render = true) {
        super();
        _performanceMark('ad:creation');

        // Tracker needs to be first to be enabled to report errors
        this.tracking = new Tracking(this);

        // Listen on errors
        if (render) {
            this.on(AdEvents.Error, this._onError);
            this.events.on(window, 'error', this._onNativeError);
            this.events.on(window, 'unhandledrejection', this._onNativeError);
        }

        this.data = data;
        adTag = adTag || this._getAdTag() || undefined;

        if (adTag) {
            this.adTag = adTag;
            this.parameters = getUrlParameters(this.adTag ? this.adTag.src : '');

            // Sometimes body does not exist so wait until it's created
            if (render) {
                this._domReady(this._initiate);
                initTCF_m(this, this._window);
            } else {
                this._initiate(render);
            }
        } else {
            const msg = 'Could not locate ad tag';
            this.emit(AdEvents.Error, { event: msg, destroyAd: true });
            throw new Error(msg);
        }
    }

    /**
     * Make sure to destroy everything when removing ad from view.
     * Should be called automatically when the add is removed in the DOM.
     * Could also be called to destroy the add manually,
     */
    destroy(): void {
        console.debug('[Ad] destroy');
        const w = window as unknown as IBannerflowWindow;
        const ads = w._bannerflow?.ads;
        this._destroyed = true;

        this.emit(AdEvents.Destroy);

        this.clearEvents();
        if (this.tracking) {
            this.tracking.destroy();
        }
        if (this.container) {
            this.container.remove();
        }
        if (this.adTag) {
            this.adTag.remove();
        }
        this._mutationObserver?.disconnect();
        this.creativeLoader?.destroy();
        this.preloadImage_m?.destroy();
        this.viewability.destroy();
        this.events.clear();

        if (ads) {
            const index = ads.indexOf(this);
            if (index > -1) {
                ads.splice(index, 1);
            }
        }
    }

    isHtml5Export(tag?: HTMLScriptElement): boolean {
        // HTML5 export references ad.js and exclude previews which has bannerflow domain in it.
        const src = (tag || this.adTag).src;
        return HTML_EXPORT_REGEX.test(src) && !isBannerflow(src);
    }

    /**
     * If creative should be auto play or not.
     */
    shouldAutoplay(): boolean {
        if (hasParameter(this.parameters, 'autoplay')) {
            return !!parameterToBool(this.parameters.autoplay);
        }
        return true;
    }

    /**
     * If TCF check is disabled with params, enabled by default
     */
    isTCFCheckDisabled(): boolean {
        if (hasParameter(this.parameters, 'tcfcheck')) {
            // tcfcheck can be on or off from params
            // tcfcheck is on => isDisabled is false
            return !parameterToBool(this.parameters.tcfcheck);
        }
        return false;
    }

    /**
     * Override emit to also emit on adTag
     */
    emit(event: AdEventType, arg?: AdEventsType[AdEventType]): this {
        super.emit(event, arg);

        console.debug('[AdEvent]', event);
        if (this.events && this.adTag) {
            this.events.dispatch(this.adTag, event, arg);
        }
        return this;
    }

    /**
     * If creative should fade in or not.
     */
    shouldFadeIn(): boolean {
        if (hasParameter(this.parameters, 'fadein')) {
            return !!parameterToBool(this.parameters.fadein);
        }
        return true;
    }

    /**
     * Inititate Ad
     */
    private _initiate = (render = true): void => {
        _performanceMark('ad:init:start');

        this.adScripts = new AdScriptLoader(this);
        this.overriddenFeedData = this._getOverrideFeedData();
        this._overridePreload = this.adScripts.overridePreload();
        this.responsive = this._isResponsive();
        this.selectedCreative = this._selectCreative(this.data);
        this.displayType_m = this._getDisplayType();

        if (window.bfstudio || !render) {
            return;
        }

        this.container = this._createCreativeContainer();
        this.viewability = new Viewability(this);
        if (CreativeLoader.isSupported()) {
            this.creativeLoader = new CreativeLoader(this);
        }
        this._detectContainerRemoval();

        /** Don't continue with rendering if the ad is already destroyed */
        if (this._destroyed) {
            return;
        }

        if (this._usePreloadImage(this.selectedCreative)) {
            this._showPreload();
            if (this.displayType_m === 'animated') {
                this._politeLoadCreative();
            }
        } else {
            this.loadCreative();
        }

        // Everything initiated and ready (not loaded yet though)
        this.emit(AdEvents.Init);
        _performanceMark('ad:init:end');
    };

    /**
     * Fire event when DOM is ready for adding the creative.
     * Needed when script is rendered in empty html
     * and we have to wait for body to be created
     * @param callback
     */
    private _domReady(callback: () => void): void {
        if (document.body) {
            callback();
        } else {
            const onReadyChange = (): void => {
                if (document.readyState === 'interactive' || document.readyState === 'complete') {
                    this.events.off(document, 'readystatechange', onReadyChange);
                    callback();
                }
            };
            this.events.on(document, 'readystatechange', onReadyChange);
        }
    }

    private _loadCreativeOnWindowLoad(w: Window = window): void {
        this.events.windowLoad(w, this.loadCreative);
    }

    loadCreative = (): void => {
        _performanceMark('ad:load:start');
        console.debug('[Ad] loading creative');

        this.creativeLoader?.load_m(
            // Success
            () => {
                _performanceMark('ad:load:end');
                console.debug('[Ad] loading creative success');

                this.emit(AdEvents.CreativeLoad);
                this.events.dispatch(document, AdEvents.CreativeLoad);

                // Remove preload image (only on success)
                if (this.preloadImage_m) {
                    this.preloadImage_m.remove();
                }
                // If an image have not been display, this is the first visible step
                else {
                    this._onRender();
                }
            },
            // Error
            () => {
                console.debug('[Ad] loading creative failed');

                // For image generator etc to detect a failure
                this.adTag?.setAttribute(SUCCESS_ATTRIBUTE, 'false');

                // Show preload image instead
                this._showPreload();
            }
        );
    };

    private _showPreload(): void {
        console.debug('[Ad] showing preload');

        if (!this.preloadImage_m) {
            this.preloadImage_m = new PreloadImage(this);
            this.preloadImage_m.add(this.container, () => {
                this.emit(AdEvents.Preload);
                this._onRender();
            });
        }
    }

    private _politeLoadCreative(): void {
        // Preload handled by custom scripts
        if (this._overridePreload) {
            // Page has already been loaded
            if (this.adScripts.pageReady) {
                this.loadCreative();
            } else {
                this.once(AdEvents.PageReady, this.loadCreative);
            }
        }
        // Inside iframe
        else if (isIframe()) {
            const topWindow = accessTopWindow() as IPublisherWindow;

            // Top window is accessible
            if (topWindow) {
                this._loadCreativeOnWindowLoad(topWindow);
            }
            // Just use timeout for cases we can't detect (IAB says so)
            else {
                setTimeout(() => {
                    this.loadCreative();
                }, 1000);
            }
        }
        // On main page
        else {
            this._loadCreativeOnWindowLoad();
        }
    }

    /**
     * IE doesn't support document.currentScript
     * so get last scripttag with src containing id.
     */
    private _getAdTag(): HTMLScriptElement | undefined {
        const scripts = document.getElementsByTagName('script');
        const d = this.data;

        // Loop through all script tags and se if adTag is already loaded
        for (let i = scripts.length - 1; i >= 0; i--) {
            const script = scripts[i];
            const src = script.src || '';
            const creativeId = this.data.creatives[0] ? d.creatives[0].id : undefined;
            if (
                src.indexOf(d.id) !== -1 ||
                src.indexOf(d.adTagId) !== -1 ||
                (creativeId && src.indexOf(creativeId) !== -1)
            ) {
                return script;
            }
        }

        /**
         * Above loop will fail to find anything in exported ads and
         * document.currentScript which is used generally doesn't work
         * properly in IE
         **/
        if (isIE) {
            for (let i = scripts.length - 1; i >= 0; i--) {
                const script = scripts[i];
                /** The ad script for exported ads is called ad.js */
                if (this.isHtml5Export(script)) {
                    return script;
                }
            }
        }
    }

    /**
     * Check if user have specified any container to render creative inside,.
     */
    private _getContainer(): HTMLElement | undefined {
        const parent = this.adTag.parentNode;
        const body = document.body;
        const selector = this.parameters.container;
        let container = this.adScripts.overrideContainer();

        // Putting tag in header should render creative in body if it is empty,
        // OR if ad is in an iframe & window has same dimensions as creative - COBE-297
        if (parent && parent.nodeName === 'HEAD') {
            if (!body.innerHTML) {
                container = body;
            } else if (isIframe()) {
                const containerWidth = window.innerWidth;
                const containerHeight = window.innerHeight;
                if (
                    containerWidth === this.selectedCreative.size.width &&
                    containerHeight === this.selectedCreative.size.height
                ) {
                    console.debug(
                        '[Ad] <head> injection, creative is same size as iframe, rendering in body'
                    );
                    this.isHeadInjection = true;
                    container = body;
                }
            }
        }

        // Try to find container based on selector
        if (selector) {
            container = (document.querySelector(selector) as HTMLElement) || container;

            // If no class or id sign is included, assume it's an id passed.
            if (selector.indexOf('#') !== 0 && selector.indexOf('.') !== 0) {
                container = (document.querySelector(`#${selector}`) as HTMLElement) || container;
            }
        }

        return container;
    }

    /**
     * Create the div in which image and creative should be rendered in.
     */
    private _createCreativeContainer(): HTMLDivElement {
        _performanceMark('ad:container:start');

        const container = this._getContainer();
        const selector = this.parameters.container;
        // Crash if container is specified but not found
        if (selector && !container) {
            const msg = `No container element could be found. Please make sure element with selector "${selector}" exists on page`;
            this.emit(AdEvents.Error, { event: msg, destroyAd: true });
            throw new Error(msg);
        }

        const width = this.selectedCreative.size.width;
        const height = this.selectedCreative.size.height;

        const div = document.createElement('div');
        const style = div.style;
        style.display = 'inline-block';
        style.width = `${width}px`;
        style.height = `${height}px`;
        style.position = 'relative';
        style.margin = style.padding = '0';

        if (this.responsive) {
            const aspectRatio = height / width;

            if (this.parameters['responsive-mode'] === 'contain') {
                const containerWidth = this.adTag.offsetWidth;
                const containerHeight = this.adTag.offsetHeight;
                if (containerWidth / containerHeight < aspectRatio) {
                    style.width = '100%';
                    style.height = 'auto';
                    style.paddingTop = `${aspectRatio * 100}%`; // Width responsive
                } else {
                    style.width = 'auto';
                    style.height = '100%';
                    style.paddingRight = '100%'; // Height responsive
                }
            } else {
                style.height = '0';
                style.width = '100%';
                style.paddingTop = `${aspectRatio * 100}%`;
            }
        }

        // User have specified another container than next to script tag
        if (container) {
            // if it was an iframe head injection, add it as first child of the body
            if (this.isHeadInjection) {
                container.insertBefore(div, container.childNodes[0]);
            } else {
                container.appendChild(div);
            }
        } else {
            insertAfter(this.adTag, div);
        }

        _performanceMark('ad:container:end');
        return div;
    }

    /**
     * Select which creative to render
     * @param ad
     */
    private _selectCreative(ad: IAdData): IAdDataCreative {
        // NOTE: When changing this also change getAdTag method
        return this.adScripts.selectCreative() || ad.creatives[0];
    }

    /**
     * Rules for detecting when a preload image should be shown
     * @param creative
     */
    private _usePreloadImage(creative: IAdDataCreative): boolean {
        const preloadParam = this.parameters.preload;
        const image = creative.image;

        // Animated content not support, load image.
        if (!CreativeLoader.isSupported()) {
            return true;
        }

        //  Preloading turned off in URL
        if (parameterToBool(preloadParam) === false) {
            return false;
        }

        // Preload turned off in data
        if (this.data.showPreloadImage === false) {
            return false;
        }

        // No image -> Dont try to load image
        if (!image?.url) {
            return false;
        }

        // Force preload image
        if (parameterToBool(preloadParam) === true || this._overridePreload) {
            return true;
        }

        const imageSize = image.fileSize || 0;
        const animatedSize = creative.animated.fileSize || 0;

        // Use preload with unknown weights
        if (!imageSize || !animatedSize) {
            return document.readyState !== 'complete';
        }
        // Page has already been loaded
        else if (document.readyState === 'complete') {
            // Animated must be 100kb and image 1/3 of the animated size to preload
            return animatedSize > 100 && imageSize < animatedSize / 3;
        }
        // Page still loading
        else {
            // Animated must be 100kb and image 1/2 of the animated size to preload
            return animatedSize > 100 && imageSize < animatedSize / 2;
        }
    }

    /**
     * If creative should be displayed as image or animated html
     */
    private _getDisplayType(): AdDisplayType {
        const androidVersion = getAndroidMajorVersion(navigator.userAgent);
        const hasVideo = this.selectedCreative.animated.files.some(file => file.url.includes('video'));

        if ((androidVersion === '9' || androidVersion === '10') && hasVideo) {
            return 'image';
        }
        if (!CreativeLoader.isSupported()) {
            return 'image';
        }
        if (hasParameter(this.parameters, 'display')) {
            return this.parameters.display as AdDisplayType;
        }
        if (this.data.displayType !== undefined) {
            return this.data.displayType;
        }

        return 'animated';
    }

    /**
     * If creative should be rendered responsive or not.
     */
    private _isResponsive(): boolean {
        if (hasParameter(this.parameters, 'responsive')) {
            return !!parameterToBool(this.parameters.responsive);
        }
        return !!this.data.responsive;
    }

    private _inImageGenerator(): boolean {
        return this.parameters.env === 'image-generator';
    }

    private _onRender(): void {
        _performanceMark('ad:render');

        this.tracking.trackImpression_m();
        this.emit(AdEvents.Render);
        if (this._inImageGenerator() && this.creativeLoader?.creative?.hasWidgetElements) {
            setTimeout(() => {
                if (this.adTag) {
                    this._notifySuccess();
                }
            }, 1000);
        } else {
            this._notifySuccess();
        }
    }

    /**
     * Detect if this ad is removed from page to clean up memory.
     */
    private _detectContainerRemoval(): void {
        const onMutation = (): void => {
            if (!document.body.contains(this.container)) {
                this.destroy();
            }
        };

        if (this.container.parentNode && window['MutationObserver']) {
            const observer = new MutationObserver(() => {
                onMutation();
            });

            // Watch changes to childList of body so we can detect when any of our parent are removed
            observer.observe(document.body, { childList: true });

            this._mutationObserver = observer;
        }

        // Do an initial check as the container may already be removed
        onMutation();
    }

    /**
     * Report thrown, emitted errors and rejected promises to tracker.
     */
    private _onError = ({ event, destroyAd }: AdEventsType['error']): void => {
        // Emitted errors
        if (!event || typeof event === 'string') {
            this.tracking.trackError_m(event);
            if (destroyAd === true) {
                this.destroy();
            }
        }
        // PromiseRejections
        else if ('reason' in event && event.reason) {
            const reason = event.reason;
            const file = reason.stack && getUrlsInString(reason.stack)[0];
            if (file && this._isAdFile(file)) {
                this.tracking.trackError_m(`${reason.message} in: ${file}`);
            }
        }
        // ErrorEvents
        else if ('error' in event && event.error) {
            const error = event.error;
            const file = event.filename;
            if (file && this._isAdFile(file)) {
                this.tracking.trackError_m(`${error.message} in: ${file}`);
            }
        }
    };

    private _onNativeError = (
        event: ErrorEvent | PromiseRejectionEvent | string,
        destroyAd?: boolean
    ): void => {
        this._onError({ event, destroyAd });
    };

    private _isAdFile(url: string): boolean {
        url = removeQueryParams(url);
        let scripts = ['animated-creative.', removeQueryParams(this.adTag.src)];

        if (this.creativeLoader && this.creativeLoader.creative) {
            scripts = scripts.concat(this.creativeLoader.getFiles_m().map(r => r.url));
        }

        // NOSONAR
        for (let i = 0; i < scripts.length; i++) {
            if (url.indexOf(scripts[i]) > -1) {
                return true;
            }
        }
        return false;
    }

    isDataJSVersion(): boolean {
        const files = this.selectedCreative.animated.files;
        if (files.length === 0) {
            throw new Error('Selected creative has no files');
        }
        return files.some(file => isDataJSUrl(file.url));
    }

    getOrigin(): string | undefined {
        const adTagSrc = this.adTag.src;
        const adTagOrigin = getDomain(adTagSrc, false);
        let adOrigin = this.data.origin;
        if (adOrigin) {
            // Origin can be absolute path:ed
            if (/^\//.test(adOrigin) && adTagOrigin) {
                adOrigin = concatUrl(adTagOrigin, adOrigin);
            }
            return adOrigin;
        }
        // No script.src is set (inline script)
        if (!adTagSrc) {
            const origin = this.selectedCreative?.animated.defaultOrigin;
            if (origin) {
                return origin;
            }
        }
        // All assets in file:// page must be relative. Thus, origin should be empty.
        if (/^file:\/\//.test(adTagSrc) || /ad\.js/.test(adTagSrc)) {
            return '';
        }
        return adTagOrigin || '';
    }

    getParentPath(): string | undefined {
        const adTagSrc = this.adTag.src;
        if (!/ad\.js/.test(adTagSrc)) {
            return undefined;
        }
        const splits = adTagSrc.split('/');
        return splits.slice(0, splits.length - 1).join('/');
    }

    private _getOverrideFeedData(): undefined | IFeedDataKeyValueItem[] {
        const win = this._window;

        if (win._bannerflow) {
            const overrideFeedData = win._bannerflow.overriddenFeedData;
            if (win._bannerflow.overriddenFeedData?.length) {
                validateFeedData(win._bannerflow.overriddenFeedData);
            }
            return overrideFeedData;
        }
    }

    private _notifySuccess(): void {
        _performanceMark('ad:success');
        this.adTag.setAttribute(SUCCESS_ATTRIBUTE, 'true');
    }
}
