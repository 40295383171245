import { IVersionProperty } from '@domain/creativeset/version';
import { IFeed, IFeedStep } from '@domain/feed';
import Ajv from 'ajv/dist/jtd';
import {
    ensureEnumOrDefault,
    ensureNumberOrDefault,
    ensureStringOrDefault
} from '../serialization.utils';
import { feedSchema } from './version-schemas';

const ajv = new Ajv();

export function serializeVersionedFeed({ value }: IVersionProperty<IFeed>): string {
    const validate = ajv.compile(feedSchema);
    const serialize = ajv.compileSerializer(feedSchema);

    if (validate(value)) {
        return serialize(value);
    }
    // If invalid, fix it
    const validFeed: IFeed = {
        id: ensureStringOrDefault(value['id']),
        path: ensureStringOrDefault(value['path']),
        fallback: ensureStringOrDefault(value['fallback']),
        type: ensureEnumOrDefault<IFeed['type']>(value['type'], ['text', 'number', 'image', 'video']),
        step: {
            size: ensureNumberOrDefault(value['step']?.['size']),
            start: ensureNumberOrDefault(value['step']?.['start']),
            occurrence: ensureEnumOrDefault<IFeedStep['occurrence']>(value['step']?.['occurrence'], [
                'loop',
                'none'
            ])
        }
    };
    return serialize(validFeed);
}
