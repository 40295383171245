<ui-header
    class="header"
    [full]="true"
    [showLogo]="false">
    <studio-topbar>
        <ng-container left>
            <topbar-context-menu [target]="translationMenu.dropdown"></topbar-context-menu>
            <translation-menu #translationMenu></translation-menu>
            <div class="creativeset-name">
                <span
                    [uiTooltip]="creativesetName"
                    truncateSpan
                    [spanText]="'Edit Translations / ' + creativesetName">
                </span>
            </div>
        </ng-container>
        <ng-container center>
            <size-picker></size-picker>
            <version-picker
                class="versions"
                [allowManageVersions]="true"
                [showAllVersions]="true">
            </version-picker>
        </ng-container>
        <ng-container right>
            @if ((isEmployee$ | async) === true && (inShowcaseMode$ | async) === false) {
                <feature-toggle
                    uiTooltip="Features in development. Use at your own risk!"
                    uiTooltipPosition="bottom" />
                <toggle-environment />
            }

            <current-users></current-users>
            <comments-overview
                [creativesetId]="creativesetId"
                [creativesetName]="creativesetName">
            </comments-overview>
            <div class="btns">
                <div
                    class="btn btn-layout"
                    uiTooltip="Change layout preferences"
                    uiTooltipPosition="left"
                    (click)="changeLayoutDirection()">
                    <ui-svg-icon icon="swap"></ui-svg-icon>
                </div>

                @if (inShowcaseMode$ | async) {
                    <mark-done-button></mark-done-button>
                }

                <help-menu />

                <div
                    class="btn btn-close"
                    (click)="navigateToMV()">
                    <ui-svg-icon icon="close"></ui-svg-icon>
                </div>
            </div>
        </ng-container>
    </studio-topbar>
</ui-header>
