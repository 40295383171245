import { Injectable, inject } from '@angular/core';
import { SentinelService } from '@bannerflow/sentinel';
import { Logger } from '@bannerflow/sentinel-logger';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { User as OidcUser } from 'oidc-client';
import { from, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import * as BrandActions from '../../brand/state/brand.actions';
import { ErrorsRedirectionService } from '../../services/errors-redirection.service';
import { OidcUserService } from '../oidc-user.service';
import { UserDataService } from '../user.data.service';
import * as UserActions from './user.actions';
import { UserService } from './user.service';

@Injectable()
export class UserEffects {
    private logger = new Logger('UserService');

    loadUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(UserActions.loadUser, BrandActions.loadBrandSuccess),
            map(action => {
                if (action.type === BrandActions.loadBrandSuccess.type) {
                    return action.brand.accountSlug;
                } else {
                    return action.accountSlug;
                }
            }),
            switchMap(accountSlug =>
                this.userDataService.getUser(accountSlug).pipe(
                    withLatestFrom(this.oidcUserService.getUser()),
                    map(([user, oidcUser]) => {
                        this.sentinelService.setUser(oidcUser?.profile.sub || accountSlug, accountSlug);
                        this.logger.debug(user.permissions);
                        return UserActions.loadUserSuccess({ user });
                    }),
                    catchError(error => of(UserActions.loadUserFailure({ error })))
                )
            )
        );
    });

    loadUserFailure$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(UserActions.loadUserFailure),
                tap(({ error }) => this.errorsRedirectionService.handleErrorRedirection(error))
            );
        },
        { dispatch: false }
    );

    loadOIDCUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(UserActions.loadOIDCUser, BrandActions.loadBrandSuccess),
            concatLatestFrom(() => this.userService.oidcUser$),
            filter(([_, oidcUser]) => !oidcUser),
            switchMap(() =>
                from(this.oidcUserService.getUser()).pipe(
                    map(oidcUser =>
                        UserActions.loadOIDCUserSuccess({ oidcUser: oidcUser as OidcUser })
                    ),
                    catchError(error => of(UserActions.loadOIDCUserFailure({ error })))
                )
            )
        );
    });

    renewToken$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(UserActions.renewToken),
                tap(() => {
                    this.oidcUserService.renewToken();
                })
            );
        },
        { dispatch: false }
    );

    login$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(UserActions.login),
                tap(() => {
                    this.oidcUserService.login();
                })
            );
        },
        { dispatch: false }
    );

    private sentinelService = inject(SentinelService);

    constructor(
        private actions$: Actions,
        private userDataService: UserDataService,
        private oidcUserService: OidcUserService,
        private userService: UserService,
        private errorsRedirectionService: ErrorsRedirectionService
    ) {}
}
