import { createAction, props } from '@ngrx/store';
import { IUser } from '@domain/user';
import { User as OidcUser } from 'oidc-client';

export const loadUser = createAction('[User] Load User', props<{ accountSlug: string }>());

export const loadUserSuccess = createAction('[User] Load User Success', props<{ user: IUser }>());

export const loadUserFailure = createAction('[User] Load User Failure', props<{ error: any }>());

export const loadOIDCUser = createAction('[User] Load OIDC User');

export const loadOIDCUserSuccess = createAction(
    '[User] Load OIDC User Success',
    props<{ oidcUser: OidcUser }>()
);

export const loadOIDCUserFailure = createAction(
    '[User] Load OIDC User Failure',
    props<{ error: any }>()
);

export const renewToken = createAction('[User] Renew Token');

export const login = createAction('[User] Login');

export const setOIDCUser = createAction('[User] Set OIDC user ', props<{ oidcUser: OidcUser }>());
