import { Injectable, inject } from '@angular/core';
import { IElement } from '@domain/creativeset';
import { IVersionProperty } from '@domain/creativeset/version';
import { IBfFeed } from '@domain/feed';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
    DirtyCharacterStylingChanges,
    DirtyCharacterStylingElements,
    DirtyProperties,
    DirtyVersionPropertiesChanges,
    GroupedElements
} from '@studio/domain/components/translation-page';
import { EMPTY, Observable } from 'rxjs';
import * as TranslationPageActions from './translation-page.actions';
import { TranslationPageState } from './translation-page.reducer';
import * as TranslationPageSelectors from './translation-page.selectors';

@Injectable({
    providedIn: 'root'
})
export class TranslationPageService {
    private store = inject(Store);
    private actions$ = inject(Actions);

    intialized$ = this.store.select(TranslationPageSelectors.getInitialized);
    loaded$ = this.store.select(TranslationPageSelectors.getLoaded);
    error$ = this.store.select(TranslationPageSelectors.getError);

    elements$ = this.store.select(TranslationPageSelectors.getElements);
    selectedElement$ = this.store.select(TranslationPageSelectors.getSelectedElement);

    isShowingAll$ = this.store.select(TranslationPageSelectors.getIsShowingAll);

    groups$ = this.store.select(TranslationPageSelectors.getGroups);
    selectedGroup$ = this.store.select(TranslationPageSelectors.getSelectedGroup);

    selectedElements$ = this.store.select(TranslationPageSelectors.getSelectedElements);

    canSelectPrevious$ = this.store.select(TranslationPageSelectors.getCanSelectPrevious);
    canSelectNext$ = this.store.select(TranslationPageSelectors.getCanSelectNext);

    getTranslatableElementsAmount$ = this.store.select(
        TranslationPageSelectors.getTranslatableElementsAmount
    );

    hasTranslatableElements$ = this.store.select(TranslationPageSelectors.getHasTranslatableElements);

    dirtyProperties$ = this.store.select(TranslationPageSelectors.getDirtyProperties);

    dirtyCharacterStyling$ = this.store.select(TranslationPageSelectors.getDirtyCharacterStyling);

    hasDirtyProperties$ = this.store.select(TranslationPageSelectors.getHasDirtyProperties);

    resetInputs$ = this.actions$.pipe(ofType(TranslationPageActions.cancelTranslations));
    hasDirtyVersionProperties$ = this.store.select(TranslationPageSelectors.getHasDirtyProperties);

    feedSources$ = this.store.select(TranslationPageSelectors.getFeedSources);

    usedColors$ = this.store.select(TranslationPageSelectors.getUsedColors);

    selectedText$ = this.store.select(TranslationPageSelectors.getSelectedText);

    private dirtyPropertiesForVersionsSelectors: { [versionId: string]: Observable<DirtyProperties> } =
        {};

    private dirtyCharacterStylingForCreativeSelectors: {
        [creativeId: string]: Observable<DirtyCharacterStylingElements | undefined>;
    } = {};

    selectDirtyVersionsFor(versionId: string): Observable<DirtyProperties> {
        this.dirtyPropertiesForVersionsSelectors[versionId] ??= this.store.select(
            TranslationPageSelectors.getDirtyPropertiesForVersion(versionId)
        );
        return this.dirtyPropertiesForVersionsSelectors[versionId];
    }

    selectDirtyCharacterStylingForCreative(
        creativeId: string | undefined
    ): Observable<DirtyCharacterStylingElements | undefined> {
        if (!creativeId) {
            return EMPTY;
        }
        this.dirtyCharacterStylingForCreativeSelectors[creativeId] ??= this.store.select(
            TranslationPageSelectors.getDirtyCharacterStylingForCreative(creativeId)
        );
        return this.dirtyCharacterStylingForCreativeSelectors[creativeId];
    }

    init(): void {
        this.store.dispatch(TranslationPageActions.loadTranslationPage());
    }

    exit(): void {
        this.store.dispatch(TranslationPageActions.exitTranslationPage());
    }

    selectElement(element: IElement): void {
        this.store.dispatch(TranslationPageActions.selectElement({ elementId: element.id }));
    }

    selectGroup(group: GroupedElements): void {
        this.store.dispatch(TranslationPageActions.selectGroup({ groupId: group.id }));
    }

    selectNext(): void {
        this.store.dispatch(TranslationPageActions.selectNext());
    }

    selectPrevious(): void {
        this.store.dispatch(TranslationPageActions.selectPrevious());
    }

    modifyDirtyVersionProperties(changes: DirtyVersionPropertiesChanges): void {
        this.store.dispatch(TranslationPageActions.modifyDirtyVersionProperties({ changes }));
    }

    modifyDirtyCharacterStyles(changes: DirtyCharacterStylingChanges): void {
        this.store.dispatch(TranslationPageActions.modifyDirtyCharacterStyles({ changes }));
    }

    upsertDirtyVersionProperty(versionId: string, versionProperty: IVersionProperty): void {
        this.store.dispatch(
            TranslationPageActions.upsertDirtyVersionProperty({
                versionId,
                versionProperty
            })
        );
    }

    removeDirtyVersionProperty(versionId: string, versionPropertyId: string): void {
        this.store.dispatch(
            TranslationPageActions.removeDirtyVersionProperty({
                versionId,
                versionPropertyId
            })
        );
    }

    cancelTranslations(): void {
        this.store.dispatch(TranslationPageActions.cancelTranslations());
    }

    saveDirtyVersions(): void {
        this.store.dispatch(TranslationPageActions.saveDirtyVersions());
    }

    showAll(): void {
        this.store.dispatch(TranslationPageActions.showAll());
    }

    hideAll(): void {
        this.store.dispatch(TranslationPageActions.hideAll());
    }

    setFeeds(feeds: IBfFeed[]): void {
        this.store.dispatch(TranslationPageActions.initBfFeeds({ feeds }));
    }

    setSelectedText(selectedText: TranslationPageState['selectedText']): void {
        this.store.dispatch(TranslationPageActions.selectText({ selectedText }));
    }
    undo(): void {
        this.store.dispatch(TranslationPageActions.undo());
    }

    redo(): void {
        this.store.dispatch(TranslationPageActions.redo());
    }
}
