import { IActionListener } from './action';
import { IColor } from './color';
import { IVideoRenderer } from './creative/elements/video/video-renderer.header';
import { IElementSelection } from './creative/nodes/selection';
import { IGifExport, IPreloadImage } from './creativeset/creative/creative';
import { IBounds, IPosition, ISize } from './dimension';
import { IImageElementAsset, IVideoElementAsset } from './element-asset';
import { ElementKind, IElementV2, ITextKindElement } from './elements';
import { IFeed } from './feed';
import { IFontStyle } from './font';
import { IImageSettings } from './image';
import { Masking, NotMaskable } from './mask';
import { IRichText } from './rich-text/rich-text.header';
import { ICreativeSocialGuide } from './social';
import { AppearenceStyles, IBorder, IFilterMap, IRadius, IShadow } from './style';
import { ICharacterStylesMap, IText, ITextProperties } from './text';
import { IVideoSettings } from './video';
import { IWidgetElementDataNode, IWidgetViewElement } from './widget';
import { IGuideline } from './workspace';

export interface INodeId {
    id: string;
    cidIndex?: number;
    parentId?: string;
}

export interface IId {
    id: string;
}

export interface IBaseDataNode extends INodeId {
    name: string;
    locked: boolean;
    hidden: boolean;
    __rootNode?: ICreativeDataNode;
    __parentNode?: IGroupElementDataNode;
}

interface IBaseViewNode<Kind extends NodeKind = NodeKind> extends INodeId {
    /**
     * The DOM-element of the element
     */
    __rootElement?: HTMLDivElement;
    __data: Extract<OneOfElementDataNodes, { kind: Kind }>;
}

export interface INewBaseNode extends IBounds {
    parentId?: string;
}

export interface IAncestor {
    _setFlatViewElementsList: (nodes: OneOfDataNodes[]) => void;
}

export interface ICreativeDataNode extends IRootDataNode, INodeWithChildren, IAncestor {
    id: string;
    kind: CreativeKind.Creative;
    fill: IColor;
    width: number;
    height: number;
    guidelines: IGuideline[];
    startTime?: number;
    stopTime?: number;
    loops: number;
    preloadImage: IPreloadImage;
    gifExport: IGifExport;
    socialGuide?: ICreativeSocialGuide;
    duration: number;

    getStopTime_m(): number;
    getFirstPreloadImageFrame(): number;
    /** @@remove STUDIO:START */
    preserveEmptyChildren(preserve: boolean): void;
    clearEmptyChildren(): void;
    /** @@remove STUDIO:END */
}

export interface ISVGBackground {
    foreignObject: SVGElement;
    render_m: <Kind extends NodeKind>(
        element: ISVGBackgroundNode<Kind>,
        overrides?: AppearenceStyles
    ) => Promise<void> | undefined;
    setResponsive: () => void;
    removeMasking_m: () => void;
    id: string;
}

export interface IGroupViewElement extends INodeWithKind<ElementKind.Group>, IBaseViewNode, IChildren {}

export interface IRootDataNode extends INodeWithChildren<CreativeKind.Creative> {}

export interface IChildren {
    nodes: OneOfViewNodes[];
}

export interface IDataNodeChildren {
    nodes: OneOfDataNodes[];
}

export enum NodeName {
    Rectangle = 'Rectangle',
    Ellipse = 'Ellipse',
    Text = 'Text',
    Button = 'Button',
    Image = 'Image',
    Widget = 'Widget',
    Video = 'Video',
    Group = 'Group'
}

export const enum CreativeKind {
    Creative = 'creative'
}

export type NodeKind = ElementKind | CreativeKind;

export type OneOfViewNodes =
    | IEllipseViewElement
    | IRectangleViewElement
    | IButtonViewElement
    | ITextViewElement
    | IImageViewElement
    | IWidgetViewElement
    | IVideoViewElement;

export type OneOfSelectableElements = OneOfElementDataNodes | IElementSelection;

// Is this union type needed?
export type OneOfGroupViewNodes = IGroupViewElement | ICreativeViewNode;

export type OneOfGroupDataNodes = IGroupElementDataNode | IRootDataNode;

export type OneOfTextViewElements = IButtonViewElement | ITextViewElement;

export type OneOfTextDataNodes = IButtonElementDataNode | ITextElementDataNode;

export interface ICreativeViewNode
    extends IChildren,
        ISVGBackgroundNode<CreativeKind.Creative>,
        ISize {}

export interface INodeWithKind<Kind extends NodeKind> {
    readonly kind: Kind;
}

export interface ISVGBackgroundNode<Kind extends NodeKind = NodeKind>
    extends ISize,
        INodeWithKind<Kind> {
    fill?: IColor;
    filters?: IFilterMap;
    border?: IBorder;
    shadows?: IShadow[];
    radius?: IRadius;
    rotationZ?: number;
    __rootElement?: HTMLDivElement;
    __svgBackground?: ISVGBackground;
}

/**
 * The view element. I.E computed state of an element at a certain point in time
 * We should probably not explicitly set a nodekind here as that should be taken care of by the caller
 * it is however easier to do it this way since I can't be bothered to change all the places it exists
 */
export interface IElementViewNode<Kind extends NodeKind = NodeKind>
    extends INodeId,
        IPosition,
        ISize,
        ISVGBackgroundNode<Kind>,
        IBaseViewNode<Kind> {
    name: string;
    ratio?: number;
    originX?: number;
    originY?: number;
    rotationX?: number;
    rotationY?: number;
    rotationZ?: number;
    mirrorX?: boolean;
    mirrorY?: boolean;
    scaleX: number;
    scaleY: number;
    opacity: number;
    time: number;
    duration: number;
    perspective?: number;
    filters: IFilterMap;
    feed?: IFeed;
    elementCid?: string;

    /**
     * Safari overlaps elements if they do not have their own stacking context.
     * Only way to create this seems to be to create a parent div with transform: 'translate(0)'.
     */
    __safari3dElement?: HTMLDivElement;

    /**
     * The DOM-element of the element
     */
    __rootElement?: HTMLDivElement;
    __actionListeners?: IActionListener[];
}

export type OneOfElementDataNodes =
    | IRectangleElementDataNode
    | IEllipseElementDataNode
    | ITextElementDataNode
    | IButtonElementDataNode
    | IImageElementDataNode
    | IWidgetElementDataNode
    | IVideoElementDataNode;

type ExtractFieldsOfType<Obj, Type> = {
    [K in keyof Obj]: Obj[K] extends Type ? K : never;
}[keyof Obj];

export type OmitSuperflous<Node extends OneOfDataNodes | IElementDataNode> = Omit<
    Node,
    | 'id'
    | 'kind'
    | 'parentId'
    | '__rootNode'
    | '__fontStyleId'
    | '__deletedStyleHashMap'
    | '__dirtyContent'
    | '__parentNode'
    | '__styleHashMap'
    | '__widget'
    | 'cidIndex'
    | ExtractFieldsOfType<Node, Function | undefined>
    | ExtractFieldsOfType<Node, Element>
>;

export type AllDataNodes = OmitSuperflous<IRectangleElementDataNode> &
    OmitSuperflous<IEllipseElementDataNode> &
    OmitSuperflous<ITextElementDataNode> &
    OmitSuperflous<IButtonElementDataNode> &
    OmitSuperflous<IImageElementDataNode> &
    OmitSuperflous<IWidgetElementDataNode> &
    OmitSuperflous<IVideoElementDataNode> &
    OmitSuperflous<IGroupElementDataNode>;

export type OneOfDataNodeValues = AllDataNodes[Exclude<keyof AllDataNodes, 'masking'>] | Masking;

export type NumberAndStringNodeProperties = ExtractFieldsOfType<AllDataNodes, string | number>;

export type OneOfDataNodes = OneOfElementDataNodes | IGroupElementDataNode;

export type NodeKindWithChildren = CreativeKind.Creative | ElementKind.Group;

export interface INodeWithChildren<Kind extends NodeKindWithChildren = NodeKindWithChildren>
    extends INodeWithKind<Kind>,
        IDataNodeChildren,
        INodeId {
    get elements(): OneOfElementDataNodes[];
    setNodes_m(nodes: OneOfDataNodes[]): void;
    addNode_m(node: OneOfDataNodes, atIndex?: number): void;
    moveNode_m?(node: OneOfDataNodes, toIndex?: number): void;
    removeNodeById_m(nodeId: string): void;
    findNodeById_m(nodeId: string, includeGroups?: boolean): OneOfDataNodes | undefined;
    nodeIterator_m(
        includeGroups?: boolean,
        group?: INodeWithChildren
    ): IterableIterator<OneOfElementDataNodes | OneOfDataNodes>;
}

export interface IGroupElementDataNode
    extends INodeWithChildren<ElementKind.Group>,
        IPrimitiveDataNode<ElementKind.Group> {
    /** Calculated based on the child nodes of the group */
    get time(): number;
    /** Calculated based on the child nodes of the group */
    get duration(): number;
    /** @@remove STUDIO:START */
    copy(keepId: boolean): IGroupElementDataNode;
    /** @@remove STUDIO:END */
}

export interface ITimelineNode {
    node: OneOfDataNodes;
    isGroup: boolean;
    collapsed: boolean;
    inHiddenNodeTree: boolean;
}

export interface IPrimitiveDataNode<Kind extends NodeKind = NodeKind> extends INodeWithKind<Kind> {
    id: string;
    name: string;
    locked: boolean;
    hidden: boolean;
    __rootNode?: ICreativeDataNode;
    __parentNode?: IGroupElementDataNode;
}

/**
 * Data node. I.E what's actually saved (all states on timeline)
 */
export interface IElementDataNode<Kind extends ElementKind = ElementKind>
    extends Omit<IElementV2<Kind>, 'parentNodeId'>,
        IPrimitiveDataNode<Kind> {}

export interface ITextDataNode<Kind extends ElementKind = ElementKind>
    extends IElementDataNode<Kind>,
        Omit<ITextKindElement<Kind>, 'content' | 'characterStyles' | 'textColor' | 'font'> {
    content: IText;
    characterStyles: ICharacterStylesMap;
    textColor: IColor;
    font?: IFontStyle;
    __styleHashMap: Map</* styleHash */ string, /* styleId */ string>;

    // We have to store the deleted style's ids to refer to them if we happen to create new style
    // with exact same styles.
    __deletedStyleHashMap: Map</* styleHash */ string, /* styleId */ string>;
    __fontStyleId?: string;
    __rootElement?: HTMLDivElement;
    __backgroundElement?: SVGSVGElement;
    __centerElement?: HTMLDivElement;
    __textElement?: HTMLDivElement;

    // We store the dirty content to enable global undo. Pls. check docs char-styles.md.
    __dirtyContent?: IText;
}

export interface IRectangleElementDataNode extends IElementDataNode<ElementKind.Rectangle> {}
export interface IEllipseElementDataNode extends IElementDataNode<ElementKind.Ellipse> {}
export interface ITextElementDataNode extends ITextDataNode<ElementKind.Text> {}
export interface IButtonElementDataNode extends ITextDataNode<ElementKind.Button> {}

export interface IImageElementDataNode extends IElementDataNode<ElementKind.Image> {
    imageAsset?: IImageElementAsset;
    feed?: IFeed;
    imageSettings: IImageSettings;
}

export interface IVideoElementDataNode extends NotMaskable<IElementDataNode<ElementKind.Video>> {
    videoSettings: IVideoSettings;
    videoAsset?: IVideoElementAsset;
    feed?: IFeed;
    checksum?: string;
}

export type IMediaElementDataNode = IVideoElementDataNode | IImageElementDataNode;

interface ITextViewNode<Kind extends NodeKind = NodeKind>
    extends ITextProperties,
        IElementViewNode<Kind> {
    __rootElement?: HTMLDivElement;
    __centerElement?: HTMLDivElement;
    __textElement?: HTMLDivElement;
    __richTextRenderer?: IRichText;
}

export interface ITextViewElement extends ITextViewNode<ElementKind.Text> {}

export interface IButtonViewElement extends ITextViewNode<ElementKind.Button> {}

export interface IEllipseViewElement extends IElementViewNode<ElementKind.Ellipse> {}

export interface IRectangleViewElement extends IElementViewNode<ElementKind.Rectangle> {}

export interface IImageViewElement extends IElementViewNode<ElementKind.Image> {
    imageUrl?: string;

    // Should view element have this property?
    feed?: IFeed;
}

export interface IVideoViewElement extends IElementViewNode<ElementKind.Video> {
    __videoRenderer: IVideoRenderer;
}
