import { IAction, IActionOperation } from '@domain/action';
import { IAnimation, IAnimationKeyframe } from '@domain/animation';
import { IColor } from '@domain/color';
import { IBoundingBox, ISize } from '@domain/dimension';
import { IElementDataNode, ITextElementDataNode, OneOfElementDataNodes } from '@domain/nodes';
import {
    OneOfElementPropertyKeys,
    propertyToUnitMap,
    serializableStateProperties,
    textPropertyToUnitMap
} from '@domain/property';
import { IState, OneOfStatePropertyKeys, StateProperties } from '@domain/state';
import {
    FILTER_MAP,
    IBorder,
    IFilterMap,
    IPadding,
    IRadius,
    IShadow,
    ITextShadow
} from '@domain/style';
import { animatableProperties, AnimatableProperty, AnimatablePropertyValue } from '@domain/transition';
import { getBoundsOfScaledRectangle } from '@studio/utils/geom';
import { isUUID, uuidv4 } from '@studio/utils/id';
import { deepEqual, lerp, omit, toDegrees } from '@studio/utils/utils';
import { isReservedAction } from '../actions/actions.utils';
import {
    getAllKeyframes,
    getNextTime,
    getPreviousTime,
    isTimeAt,
    toRelativeTime
} from '../animation.utils';
import { Color } from '../color';
import { mixColor, parseColor } from '../color.utils';
import { getTimingFunctionFromKeyframe, getTimingFunctionValueBetween } from '../timing-functions';
import { formulaToValue, isFormulaValue } from './formula.utils';

const AdditiveProperties = ['x', 'y', 'rotationX', 'rotationY', 'rotationZ'];

/**
 * Element properties that need to be scaled in order to scale a creative to any particular size
 */
export const scalableProperties = [
    'x',
    'y',
    'width',
    'height',
    'scaleX',
    'scaleY',
    'fitPositionX',
    'fitPositionY',
    'radius',
    'border',
    'fontSize',
    'padding',
    'shadows',
    'textShadows'
];

export const propertyMaxValues = {
    opacity: 1
};

export const propertyMinValues = {
    radius: 0,
    opacity: 0,
    scaleX: 0,
    scaleY: 0
};

export const DEFAULT_EMPTY_STATE: Readonly<IState> = Object.freeze({
    ratio: 1
});

export function createEmptyState(name?: string): IState {
    const state: IState = {
        ...DEFAULT_EMPTY_STATE,
        id: uuidv4()
    };

    if (name !== undefined) {
        state.name = name;
    }

    return state;
}

export function isEmptyState(state: IState): boolean {
    return getPropertyKeysInStates(state).length === 0;
}

/**
 * Merges all states with current element values
 * @param timeStates
 * @param element
 */
export function mergeStates(
    timeStates: ITimeState[],
    element: IElementDataNode,
    lerpAdditive?: boolean
): IState {
    const merged: IState = {};

    // Last states will overwrite previous states so reverse the array to prio actions and animation order...
    timeStates.forEach(timeState => {
        const state = timeState.state;
        const ratio = timeState.rate !== undefined ? timeState.rate : 1;

        if (ratio === 0) {
            return;
        }

        for (const property in state) {
            if (animatableProperties.some(p => p === property)) {
                let currentValue = merged[property];
                const unit = propertyToUnitMap[property];
                const value = state[property];
                const currentOrElementValue =
                    currentValue !== undefined ? currentValue : element[property];

                if (value !== undefined) {
                    if (unit === 'number') {
                        // Different default values depending on if it's an additive property or a state property
                        if (currentValue === undefined) {
                            currentValue = isAdditiveProperty(property) ? 0 : element[property];
                        }
                        merged[property] = mergeNumbers(
                            property as AnimatableProperty,
                            currentValue,
                            value,
                            ratio,
                            lerpAdditive
                        );
                    } else {
                        merged[property] = mergeProperty(
                            property as AnimatableProperty,
                            currentOrElementValue,
                            value,
                            ratio
                        );
                    }
                }
            } else {
                merged[property] = state[property];
            }
        }
    });
    return merged;
}

export function mergeProperty<T extends AnimatablePropertyValue>(
    property: AnimatableProperty,
    from: T | undefined,
    to: T | undefined,
    ratio: number
): AnimatablePropertyValue | undefined {
    const unit = propertyToUnitMap[property];

    switch (unit) {
        case 'number':
            // Different default values depending on if it's an additive property or a state property
            // if (currentValue === undefined) {
            //     // currentValue = isAdditiveProperty(property) ? 0 : element[property];
            // }
            return mergeNumbers(property, from as number, to as number, ratio);
        case 'Radius':
            return mergeRadius(from as IRadius, to as IRadius, ratio);
        case 'Color':
            return mergeColors(from as IColor, to as IColor, ratio);
        case 'Shadow[]':
        case 'TextShadow[]':
            return mergeShadows(from as IShadow[], to as IShadow[], ratio);
        case 'FilterMap':
            return mergeFilters(ratio, from as IFilterMap, to as IFilterMap);
        case 'Border':
            return mergeBorders(from as IBorder, to as IBorder, ratio);
        default:
            // For non numerical values, only set value when rate is 1
            if (ratio === 1) {
                return to;
            }
            break;
    }
}

/**
 * Merge two shadows with eachother. Amount control where they should meet.
 * @param fromShadows
 * @param toShadows
 * @param amount
 */
function mergeShadows(
    fromShadows: IShadow[],
    toShadows: IShadow[],
    amount: number
): ITextShadow[] | IShadow[] {
    const shadows: IShadow[] | ITextShadow[] = [];

    // Total amount of shadows
    const length = Math.max(fromShadows?.length || 0, toShadows?.length || 0);

    for (let i = 0; i < length; i++) {
        let from = fromShadows && (fromShadows[i] as IShadow | undefined);
        let to = toShadows && (toShadows[i] as IShadow | undefined);

        const fromColor = from?.color;
        const toColor = to?.color;

        if (!from) {
            from = { ...to! };
        }
        if (!to) {
            to = { ...from };
        }

        shadows[i] = {
            blur: Math.max(0, lerp(from.blur, to.blur, amount)),
            offsetX: lerp(from.offsetX, to.offsetX, amount),
            offsetY: lerp(from.offsetY, to.offsetY, amount),
            color: mergeColors(fromColor, toColor, amount)
        };

        if (from.spread !== undefined) {
            (shadows[i] as IShadow).spread = Math.max(0, lerp(from.spread, to.spread, amount));
        }
    }

    return shadows;
}

function mergeFilters(amount: number, from: IFilterMap = {}, to: IFilterMap = {}): IFilterMap {
    const filterKeySet = new Set([...Object.keys(from), ...Object.keys(to)]) as Set<keyof IFilterMap>;

    return [...filterKeySet]
        .filter(key => from[key] !== undefined || to[key] !== undefined)
        .reduce((memo, key) => {
            const filter = FILTER_MAP[key];
            return {
                ...memo,
                [key]: {
                    value: lerp(
                        from[key]?.value ?? (filter?.default || 0),
                        to[key]?.value ?? (filter?.default || 0),
                        amount
                    )
                }
            };
        }, {});
}

function mergeBorders(
    from: IBorder | undefined,
    to: IBorder | undefined,
    amount: number
): IBorder | undefined {
    if (from || to) {
        from = from || { ...to!, thickness: 0 };
        to = to || { ...from, thickness: 0 };

        if (to && from) {
            return {
                thickness: Math.max(0, lerp(from.thickness, to.thickness, amount)),
                style: amount === 1 ? to.style : from.style, // Swap style when 100% in
                color: mixColor(from.color, { color: to.color, amount })
            };
        }
    }
}

function mergeColors(from: IColor | undefined, to: IColor | undefined, amount: number): IColor {
    if (!to) {
        to = parseColor(from!);
        to.alpha = 0;
    }
    if (!from) {
        from = parseColor(to);
        from.alpha = 0;
    }
    const tint = { color: to, amount };
    return mixColor(from, tint);
}

function mergeRadius(
    from: IRadius | undefined,
    to: IRadius | undefined,
    amount: number
): IRadius | undefined {
    if (to && from) {
        return {
            type: to.type,
            topLeft: mergeNumbers('radius', from.topLeft, to.topLeft, amount),
            topRight: mergeNumbers('radius', from.topRight, to.topRight, amount),
            bottomLeft: mergeNumbers('radius', from.bottomLeft, to.bottomLeft, amount),
            bottomRight: mergeNumbers('radius', from.bottomRight, to.bottomRight, amount)
        };
    }
}

function mergeNumbers(
    property: AnimatableProperty,
    from: number | undefined,
    to: number | undefined,
    ratio: number,
    lerpAdditive = false
): number {
    if (typeof to !== 'number') {
        if (typeof from === 'number') {
            return from;
        }
        throw new Error('Can only merge numeric values');
    }
    // Additive properties like x, y, rotations
    if (isAdditiveProperty(property) && !lerpAdditive) {
        if (typeof from !== 'number') {
            from = 0;
        }
        return limitNumberProperty(property, from + to * ratio);
    }
    // Average properties like scale etc
    else {
        if (typeof from !== 'number') {
            from = 1;
        }
        return limitNumberProperty(property, lerp(from, to, ratio));
    }
}

function limitNumberProperty(property: AnimatableProperty, value: number): number {
    const min = propertyMinValues[property];
    const max = propertyMaxValues[property];
    if (typeof min === 'number') {
        value = Math.max(min, value);
    }
    if (typeof max === 'number') {
        value = Math.min(max, value);
    }
    return value;
}

/**
 * Resolve values so they can be rendered.
 * Typically turn any formulas into numbers
 * @param element
 * @param animation
 * @param canvasSize
 * @param property
 * @param value
 */
export function resolveStateFormulaValue(
    element: OneOfElementDataNodes,
    animation: IAnimation,
    canvasSize: ISize,
    property: AnimatableProperty | string,
    value: StateProperties
): StateProperties {
    const unit = propertyToUnitMap[property];

    if (unit === 'number') {
        if ((typeof value === 'number' && !isNaN(value)) || value === undefined) {
            return value;
        }

        if ((typeof value === 'string' || typeof value === 'number') && isFormulaValue(value)) {
            return formulaToValue(value, {
                element,
                propertyName: property,
                settings: animation.settings,
                canvasSize
            });
        }

        if (typeof value === 'string' && !isNaN(+value)) {
            return +value;
        }

        throw new Error('Unexpected number value.');
    }

    return value;
}

export function getResolvedStatePropertyValue(
    value: StateProperties,
    property: AnimatableProperty,
    element?: OneOfElementDataNodes
): StateProperties {
    switch (property) {
        case 'rotationZ':
        case 'rotationX':
        case 'rotationY':
            return toDegrees((value as number) || 0) as StateProperties;
        case 'opacity':
        case 'radius':
            return value ?? element?.[property];
        default:
            const unit = propertyToUnitMap[property];
            if (unit === 'number') {
                return Math.round(value as number) || 0;
            }
            return value;
    }
}

export function getValueFromKeyframe(
    property: AnimatableProperty,
    keyframe: IAnimationKeyframe,
    animation: IAnimation,
    element: OneOfElementDataNodes,
    canvasSize: ISize
): StateProperties {
    const state = getStateById(element, keyframe.stateId);
    const value = state?.[property];
    return resolveStateFormulaValue(element, animation, canvasSize, property, value);
}

export function getViewElementProperty(
    property: OneOfElementPropertyKeys,
    element: IElementDataNode,
    mergedState: IState = {},
    scale = 1
    // Needs major refactoring to remove this any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
    const unit = propertyToUnitMap[property];
    const value = element[property];
    const stateValue = mergedState[property];
    const stateValueOrValue = stateValue !== undefined ? stateValue : value;

    if (stateValueOrValue !== undefined) {
        if (unit === 'number') {
            const numericValue = getNumericPropertyValue(
                property,
                value,
                stateValue,
                stateValueOrValue
            );

            if (isScalableProperty(property)) {
                return scaleNumericValue(numericValue, scale);
            }
            return numericValue;
        } else if (unit === 'Color') {
            return new Color(stateValueOrValue);
        } else if (unit === 'Border') {
            return getViewElementBorder(stateValueOrValue, scale);
        } else if (unit === 'Shadow[]' || unit === 'TextShadow[]') {
            return stateValueOrValue.map((shadow: ITextShadow | IShadow<IColor>) =>
                getShadowPropertyValue(unit, shadow, scale)
            );
        } else if (unit === 'ImageAsset') {
            return { ...stateValueOrValue };
        } else if (unit === 'ImageSettings') {
            return { ...stateValueOrValue };
        } else if (unit === 'Padding') {
            return getViewElementPadding(stateValueOrValue, scale);
        } else if (unit === 'Radius') {
            return {
                ...stateValueOrValue,
                topLeft: stateValueOrValue.topLeft * scale,
                topRight: stateValueOrValue.topRight * scale,
                bottomLeft: stateValueOrValue.bottomLeft * scale,
                bottomRight: stateValueOrValue.bottomRight * scale
            };
        }
    }
    return stateValueOrValue;
}

function isScalableProperty(property: OneOfElementPropertyKeys): boolean {
    return scalableProperties.includes(property);
}

function getNumericPropertyValue(
    property: OneOfElementPropertyKeys,
    value: number,
    stateValue: number,
    stateValueOrValue: number
): number {
    if (isAdditiveProperty(property)) {
        return value + (stateValue || 0);
    } else {
        return stateValueOrValue;
    }
}

function scaleNumericValue(value: number, scale: number): number {
    return value * scale;
}

export function getShadowPropertyValue(
    unit: string,
    shadow: IShadow | ITextShadow,
    scale: number
): IShadow | ITextShadow {
    const newShadow = { ...shadow };
    if (unit === 'Shadow[]') {
        newShadow.offsetX *= scale;
        newShadow.offsetY *= scale;
        (newShadow as IShadow).spread *= scale;
        newShadow.blur *= scale;
    } else if (unit === 'TextShadow[]') {
        newShadow.offsetX *= scale;
        newShadow.offsetY *= scale;
        newShadow.blur *= scale;
    }
    return newShadow;
}

function getViewElementBorder(stateValueOrValue: IBorder, scale: number): IBorder {
    return {
        thickness: stateValueOrValue.thickness * scale,
        color: stateValueOrValue.color,
        style: stateValueOrValue.style
    };
}

function getViewElementPadding(stateValueOrValue: IPadding, scale: number): IPadding {
    return {
        bottom: stateValueOrValue.bottom * scale,
        top: stateValueOrValue.top * scale,
        right: stateValueOrValue.right * scale,
        left: stateValueOrValue.left * scale
    };
}
/**
 * Merge an animation to a "relative" state. Pass extra states to show states not added by timeline
 */
export function animationsToState(
    element: OneOfElementDataNodes,
    canvasSize: ISize,
    time = 0,
    states: ITimeState[] = []
): IState {
    // Merge states from actions
    states = mergeActionStates(element, states);

    if (element.animations) {
        element.animations
            .filter(animation => !animation.hidden)
            .forEach(animation => {
                const animationStates = animationToStates(element, animation, canvasSize, time).filter(
                    state => !states.some(s => state.state === s.state)
                ); // Make sure we don't render states twice

                states.unshift(...animationStates);
            });
    }

    const merged = mergeStates(states, element);

    return merged;
}

export function mergeActionStates(element: OneOfElementDataNodes, states: ITimeState[]): ITimeState[] {
    if (states.length > 1) {
        const maxRate = Math.max(...states.map(s => s.rate), 0);

        // No need to spend cpu on when completely "faded" out
        if (maxRate === 0) {
            return [];
        }

        // States scaled so highest rate is 1
        const normalizedStates = states.map(s => {
            // Later all values will be scaled by maxRate so compensate for that
            const rate = s.rate / maxRate;
            return {
                rate,
                state: s.state
            };
        });
        const state = mergeStates(normalizedStates, element, true);
        return [{ rate: maxRate, state }];
    }

    // Return shallow clone to not overwrite original
    return states.slice(0);
}

export function getStateById(element: OneOfElementDataNodes, id?: string): IState | undefined {
    if (element.states?.length && id) {
        return element.states.find(state => state.id === id);
    }
}

export function cloneState(state: IState): IState {
    const clone = {};
    for (const property in state) {
        const value = state[property];
        const type = typeof value;

        if (type === 'string' || type === 'number' || type === 'object') {
            clone[property] = value;
        }
    }
    return clone;
}

/**
 * Calulate a state of an animation at a certain point in time.
 * Note: Don't use this to render element
 */
export function calculateStateFromAnimationAtTime(
    element: OneOfElementDataNodes,
    animation: IAnimation,
    canvasSize: ISize,
    time = 0
): IState {
    const states = animationToStates(element, animation, canvasSize, time);
    return omit(mergeStates(states, element), 'id');
}

/**
 * Go through all properties in a state and resolve any formulas found
 * @param element
 * @param animation
 * @param canvasSize
 * @param state
 * @returns
 */
function resolveStateFormulas(
    element: OneOfElementDataNodes,
    animation: IAnimation,
    canvasSize: ISize,
    state: IState
): IState<number> {
    const resolvedState: IState<number> = {};
    // Resolve any formulas
    for (const key in state) {
        resolvedState[key] = resolveStateFormulaValue(element, animation, canvasSize, key, state[key]);
    }
    return resolvedState;
}

/**
 * Get all states from an animation with a "rate"
 * which is how much from 0 to 1 that this state should be "visible"
 */
export function animationToStates(
    element: OneOfElementDataNodes,
    animation: IAnimation,
    canvasSize: ISize,
    absoluteTime = 0
): ITimeState[] {
    const states: ITimeState[] = [];
    const { keyframes } = animation;
    if (!keyframes?.length || !isTimeAt(absoluteTime, element)) {
        return states;
    }

    const time = toRelativeTime(element, absoluteTime);
    const tolerance = 0.000001;

    const prev = getPreviousTime(keyframes, time, tolerance);
    const next = getNextTime(keyframes, time, tolerance);
    const prevState = prev?.stateId && {
        ...resolveStateFormulas(element, animation, canvasSize, getStateById(element, prev.stateId)!)
    };
    const nextState = next?.stateId && {
        ...resolveStateFormulas(element, animation, canvasSize, getStateById(element, next.stateId)!)
    };

    // Should only happen if no keyframes is defined
    if (!prev && !next) {
        throw new Error('Animation has no keyframes');
    }
    // When within the duration of a keyframe,
    else if (prev === next || !next || (prev && isTimeAt(time, prev))) {
        // Ignore double default state case
        if (prevState) {
            states.push({ state: prevState, rate: 1 });
        }
    }
    // In between two states => apply easing
    else if (prev && next) {
        const timingFunction = getTimingFunctionFromKeyframe(animation, next);
        const rate = getTimingFunctionValueBetween(time, prev, next, timingFunction);

        // Inverted rate when animating to the default state.
        // Between states the previous value should always be at 100%
        if (prevState) {
            // Both states defined
            if (nextState) {
                const props = getPropertyKeysInStates(prevState, nextState);

                // Make sure both states have the same values
                props.forEach(p => {
                    const defaultValue = isAdditiveProperty(p) ? 0 : element[p];

                    if (prevState[p] === undefined && defaultValue !== undefined) {
                        prevState[p] = defaultValue;
                    }
                    if (nextState[p] === undefined) {
                        nextState[p] =
                            defaultValue !== undefined
                                ? defaultValue
                                : mergeProperty(p, prevState[p], defaultValue, 1);
                    }
                });

                const additive = getAdditiveStateProperties(prevState);
                const nonAdditive = getNonAdditiveStateProperties(prevState);
                if (nonAdditive) {
                    states.push({ state: nonAdditive, rate: 1 });
                }
                if (additive) {
                    states.push({ state: additive, rate: 1 - rate });
                }
            } else {
                states.push({ state: prevState, rate: 1 - rate });
            }
        }
        if (nextState) {
            states.push({ state: nextState, rate });
        }
    }
    return states;
}

export function setNewPropertyIds(
    originalElement: OneOfElementDataNodes,
    newElement: OneOfElementDataNodes
): Map<string, string> {
    const idChangeMap = new Map<string, string>();
    function isActionOperation(value: unknown): value is IActionOperation {
        return !!value && typeof value === 'object' && 'target' in value && 'method' in value;
    }

    function setNewIdOnItem(
        item: IAction | IActionOperation | IState | IAnimation | IAnimationKeyframe
    ): void {
        const newId = uuidv4();
        let oldId: string | undefined;

        if (isActionOperation(item)) {
            oldId = item.value;
            item.value = newId;
        } else {
            oldId = item.id;
            item.id = newId;
        }

        idChangeMap.set(oldId!, newId);
    }
    const stateIdsMap = {};
    for (const state of newElement.states) {
        const oldId = state?.id;
        if (oldId) {
            setNewIdOnItem(state);
            stateIdsMap[oldId] = state.id;
        }
    }
    for (const animation of newElement.animations) {
        setNewIdOnItem(animation);
        for (const keyframe of animation.keyframes) {
            setNewIdOnItem(keyframe);
            /**
             * Migrated IDs (e.g keyframe-0) may be the same
             * on mulitple elements hence we need to set the stateId
             * reference here right away. The ID patching is otherwise
             * done in designView.patchPropertyIds.
             * This can probably be removed once all design elements
             * has been migrated.
             */
            const keyframeStateId = keyframe?.stateId;
            if (keyframeStateId && stateIdsMap[keyframeStateId] && !isUUID(keyframeStateId)) {
                keyframe.stateId = stateIdsMap[keyframeStateId];
            }
        }
    }

    for (const action of newElement.actions) {
        setNewIdOnItem(action);
    }

    /**
     * Validate that all ids are unique
     */

    const idSet = new Set<string>();

    const properties: OneOfElementPropertyKeys[] = ['animations', 'actions', 'states'];

    const recursiveFindIds = (object: unknown): void => {
        if (typeof object !== 'object' || !object) {
            return;
        }

        if ('id' in object && typeof object.id === 'string') {
            if (idSet.has(object.id)) {
                throw new Error('Duplicate IDs found.');
            }

            if (object.id) {
                idSet.add(object.id);
            }
        }

        if (Array.isArray(object)) {
            for (const prop of object) {
                recursiveFindIds(prop);
            }
        } else {
            for (const prop in object) {
                recursiveFindIds(object[prop]);
            }
        }
    };

    for (const key of Object.keys(originalElement) as OneOfElementPropertyKeys[]) {
        if (!properties.includes(key)) {
            continue;
        }
        recursiveFindIds(originalElement[key]);
        recursiveFindIds(newElement[key]);
    }

    return idChangeMap;
}

export function isStateProperty(property: string): property is OneOfStatePropertyKeys {
    return !!property && serializableStateProperties.indexOf(property as OneOfStatePropertyKeys) > -1;
}

export function isTextState(
    state: IState | OneOfElementDataNodes,
    property: OneOfElementPropertyKeys
): state is IState & ITextElementDataNode {
    return property in textPropertyToUnitMap;
}

export function isReservedActionState(element: OneOfElementDataNodes, state: IState): boolean {
    return element.actions.some(
        action => isReservedAction(action) && action.operations.some(op => op.value === state.id)
    );
}

export function isAnimationState(element: OneOfElementDataNodes, state: IState): boolean {
    if (!state.id) {
        return false;
    }
    return getAllKeyframes(element).some(keyframe => keyframe.stateId === state.id);
}

/**
 * Check if all bounds provided have the same size & position
 * @param bounds
 */
export function isEqualStates(...states: (IState | undefined)[]): boolean {
    if (!states || states.length < 2) {
        throw new Error('2 or more states must be passed to compare them');
    }

    // Id is irrelevant
    states = states.map(s => s && omit(s, 'id'));

    const first = states[0];
    const toCompare = states.slice(1);

    if (toCompare.some(s => !deepEqual(first, s))) {
        return false;
    }
    return true;
}

export function getReservedStates(element: OneOfElementDataNodes): IState[] {
    return element.states.filter(state => isReservedActionState(element, state));
}

/**
 * Custom states that are states that are not related to
 * reserved States (actions) and not related to any keyframe animation
 */
export function getCustomStates(element: OneOfElementDataNodes): IState[] {
    return element.states.filter(
        state => !isAnimationState(element, state) && !isReservedActionState(element, state)
    );
}

export function getBoundingBoxOfElementWithState(
    element: IBoundingBox | OneOfElementDataNodes,
    state: IState = {},
    includeScale?: boolean
): IBoundingBox {
    const scaleX = 'scaleX' in element && typeof element.scaleX === 'number' ? element.scaleX : 1;
    const scaleY = 'scaleY' in element && typeof element.scaleY === 'number' ? element.scaleY : 1;
    const originX = 'originX' in element && typeof element.originX === 'number' ? element.originX : 0.5;
    const originY = 'originY' in element && typeof element.originY === 'number' ? element.originY : 0.5;

    const box = {
        x: element.x + (typeof state.x === 'number' ? state.x : 0),
        y: element.y + (typeof state.y === 'number' ? state.y : 0),
        scaleX: scaleX * (typeof state.scaleX === 'number' ? state.scaleX : 1),
        scaleY: scaleY * (typeof state.scaleY === 'number' ? state.scaleY : 1),
        originX: typeof state.originX === 'number' ? state.originX : originX,
        originY: typeof state.originY === 'number' ? state.originY : originY,
        // Note state shouldn't have width and height ATM
        width: element.width,
        height: element.height,
        rotationZ:
            (element.rotationZ || 0) + (typeof state.rotationZ === 'number' ? state.rotationZ : 0)
    };

    if (includeScale) {
        return getBoundsOfScaledRectangle(box);
    }

    return box;
}

export function getPropertyKeysInStates(...states: (IState | undefined)[]): AnimatableProperty[] {
    const keys: AnimatableProperty[] = [];
    states.forEach(state => {
        for (const k in state) {
            const key = k as AnimatableProperty;
            if (
                state[key] !== undefined &&
                keys.indexOf(key) === -1 &&
                animatableProperties.indexOf(key) > -1
            ) {
                keys.push(key);
            }
        }
    });
    return keys;
}

export function isAdditiveProperty(property: string): boolean {
    return AdditiveProperties.indexOf(property) > -1;
}

export function getAdditiveStateProperties(state: IState): IState | undefined {
    const additive: IState = {};

    for (const key in state) {
        if (isAdditiveProperty(key)) {
            additive[key] = state[key];
        }
    }
    return !isEmptyState(additive) ? additive : undefined;
}

export function getNonAdditiveStateProperties(state: IState): IState | undefined {
    const nonAdditive: IState = {};

    for (const key in state) {
        if (!isAdditiveProperty(key)) {
            nonAdditive[key] = state[key];
        }
    }
    return Object.keys(nonAdditive).length ? nonAdditive : undefined;
}

export interface ITimeState {
    /**
     * How much from 0-1 this states is active.
     * Note that when elastic easings are in use this number may be a bit over 1.
     */
    rate: number;

    /**
     *
     */
    state: IState;
}
