export * from './di.decorators';
import { ParameterMap, getOwnMetadata } from './di.decorators';

interface IConstructorMetadataStore<T> {
    parameterMap: ParameterMap<T>;
    parentParameterIndex?: number;
    containerParameterIndex?: number;
    target: Function | any;
}

interface IParentParameterResolvement {
    parentParameterIndex?: number;
    target?: any;
}

interface IRecursiveItemResolvment<T extends MetadataType> {
    item: T extends MetadataType.Metadata
        ? IConstructorMetadataStore<any>
        : T extends MetadataType.Factory
          ? (...args: any[]) => any
          : object;
    container: Container<any>;
}
interface IChildWithParent {
    target: any;
    parentParameterIndex: number;
}

const enum MetadataType {
    Metadata,
    Factory,
    Object,
    ParentInjection,
    ContainerRef
}

export class Container<T> {
    private _constructorMetadataMap = new Map<T | Function, IConstructorMetadataStore<T>>();
    private _objectMap = new Map<T | Function, any>();
    private _instanceMap = new Map<T, any>();
    private _factoryMap = new Map<T | Function, (...args: any[]) => any>();

    constructor(public parent?: Container<T>) {}

    register_m(token: T, target: unknown): void {
        const parameterMap = getOwnMetadata('i', target) as ParameterMap<T>;
        const parentParameterIndex = getOwnMetadata('p', target) as number;
        const containerParameterIndex = getOwnMetadata('c', target) as number;
        if (target instanceof Function) {
            const constructorMetadata = {
                target,
                parameterMap,
                parentParameterIndex,
                containerParameterIndex
            };
            this._constructorMetadataMap.set(token, constructorMetadata);
        } else {
            this._objectMap.set(token, target);
        }
    }

    unregister_m(token: T): void {
        this._constructorMetadataMap.delete(token);
        this._objectMap.delete(token);
        this._factoryMap.delete(token);
    }

    maybeResolve<R>(token: T): R | undefined {
        const { target } = this._recursivelyResolve(token, true);
        return target;
    }

    clear(): void {
        this._instanceMap.clear();
    }

    resolve<R>(token: T, isOptional: boolean = false): R {
        const { target } = this._recursivelyResolve(token, isOptional);
        if (!target) {
            throw new Error(`Could not resolve token '${token}'.`);
        }
        return target;
    }

    registerFactory_m<R>(token: T, context: (container: Container<T>) => R): void {
        this._factoryMap.set(token, context);
    }

    private _recursivelyResolve(token: T, isOptional: boolean): IParentParameterResolvement {
        const metadata = this._recursivelyGetMetadata(token, MetadataType.Metadata);
        const args: any[] = [];
        if (metadata) {
            const item = metadata.item;
            if (typeof item.target === 'function') {
                const cachedInstance = metadata.container._instanceMap.get(token);
                if (cachedInstance) {
                    return { target: cachedInstance, parentParameterIndex: undefined };
                }
                const childrenWithParent: IChildWithParent[] = [];
                if (item.parameterMap) {
                    for (const [index, parameter] of item.parameterMap.entries()) {
                        const { target, parentParameterIndex } = this._recursivelyResolve(
                            parameter.token,
                            parameter.optional || isOptional
                        );
                        if (!target) {
                            if (!isOptional && !parameter.optional) {
                                throw new Error(`Could not resolve symbol '${parameter.token}'.`);
                            }
                            continue;
                        }
                        args[index] = target;
                        if (parentParameterIndex !== undefined) {
                            childrenWithParent.push({
                                target,
                                parentParameterIndex
                            });
                        }
                    }
                }
                if (item.containerParameterIndex !== undefined) {
                    args[item.containerParameterIndex] = this;
                }
                const instance = new item.target(...args);

                for (const child of childrenWithParent) {
                    let enumKeyIndex = 0;
                    for (const key in child.target) {
                        if (enumKeyIndex === child.parentParameterIndex) {
                            child.target[key] = instance;
                        }
                        enumKeyIndex++;
                    }
                }
                metadata.container._instanceMap.set(token, instance);
                return { target: instance, parentParameterIndex: item.parentParameterIndex };
            }
        }
        const object = this._recursivelyGetMetadata(token, MetadataType.Object);
        if (object) {
            return { target: object.item };
        }
        const factory = this._recursivelyGetMetadata(token, MetadataType.Factory);
        if (factory) {
            return { target: factory.item(factory.container) };
        }
        return { target: undefined };
    }

    private _recursivelyGetMetadata<M extends MetadataType>(
        token: T,
        type: M
    ): IRecursiveItemResolvment<M> | undefined {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        let container: Container<T> | undefined = this;
        while (container) {
            let item: any;
            switch (type) {
                case MetadataType.Metadata:
                    item = container._constructorMetadataMap.get(token);
                    break;
                case MetadataType.Object:
                    item = container._objectMap.get(token);
                    break;
                case MetadataType.Factory:
                    item = container._factoryMap.get(token);
                    break;
            }
            if (item !== undefined) {
                return { item, container };
            }
            container = container.parent;
        }
        return undefined;
    }
}
