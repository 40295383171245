import { createReducer, on } from '@ngrx/store';
import { ApprovalStatus } from '@domain/creativeset/creative';
import * as FiltersActions from './filters.actions';

export const FILTERS_FEATURE_KEY = 'filters';

export interface FiltersPartialState {
    readonly [FILTERS_FEATURE_KEY]: FiltersState;
}

export interface FiltersState {
    allSizes: string[]; // contains all size ids, this should be moved to a separate state
    sizes: string[];
    statuses: ApprovalStatus[];
    versions: string[]; // MV supports multiple version selection, DV only should always have one
    previousVersions: string[]; // Versions IDs selected in MV before going to DV
}

export const initialState: FiltersState = {
    allSizes: [],
    sizes: [],
    statuses: [],
    versions: [],
    previousVersions: []
};

export const reducer = createReducer(
    initialState,

    on(
        FiltersActions.loadFiltersSuccess,
        (state, { sizes, statuses }): FiltersState => ({
            ...state,
            sizes,
            statuses
        })
    ),

    on(
        FiltersActions.init,
        FiltersActions.setAllSizes,
        (state, { sizeIds }): FiltersState => ({
            ...state,
            allSizes: Array.from(new Set(sizeIds))
        })
    ),

    // Sizes
    on(
        FiltersActions.addSizesToFilter,
        (state, { sizeIds }): FiltersState => ({
            ...state,
            sizes: Array.from(new Set([...state.sizes, ...sizeIds]))
        })
    ),
    on(
        FiltersActions.removeSizesFromFilter,
        (state, { sizeIds }): FiltersState => ({
            ...state,
            sizes: state.sizes.filter(sizeId => !sizeIds.includes(sizeId))
        })
    ),
    on(
        FiltersActions.setSizesFilter,
        (state, { sizeIds }): FiltersState => ({
            ...state,
            sizes: Array.from(new Set(sizeIds))
        })
    ),
    on(
        FiltersActions.clearAllSizesFilter,
        (state): FiltersState => ({
            ...state,
            sizes: []
        })
    ),
    on(
        FiltersActions.showAllSizesFilter,
        (state): FiltersState => ({
            ...state,
            sizes: [...state.allSizes]
        })
    ),

    // Statuses
    on(
        FiltersActions.addStatusesToFilter,
        (state, { statuses }): FiltersState => ({
            ...state,
            statuses: Array.from(new Set([...state.statuses, ...statuses]))
        })
    ),
    on(
        FiltersActions.removeStatusesFromFilter,
        (state, { statuses }): FiltersState => ({
            ...state,
            statuses: state.statuses.filter(activeStatus => !statuses.includes(activeStatus))
        })
    ),
    on(
        FiltersActions.setStatusesFilter,
        (state, { statuses }): FiltersState => ({
            ...state,
            statuses: Array.from(new Set(statuses))
        })
    ),
    on(
        FiltersActions.clearAllStatusesFilter,
        (state): FiltersState => ({
            ...state,
            statuses: []
        })
    ),

    // Versions
    on(
        FiltersActions.setVersionsFilter,
        (state, { versionIds, updateOldSelection }): FiltersState => ({
            ...state,
            versions: versionIds,
            previousVersions: updateOldSelection ? state.versions : state.previousVersions
        })
    ),
    on(
        FiltersActions.addVersionsToFilter,
        (state, { versionIds }): FiltersState => ({
            ...state,
            versions: [...state.versions, ...versionIds]
        })
    ),
    on(
        FiltersActions.removeVersionsFromFilter,
        (state, { versionIds }): FiltersState => ({
            ...state,
            versions: state.versions.filter(id => !versionIds.includes(id))
        })
    ),
    on(
        FiltersActions.clearVersionsFilter,
        (state): FiltersState => ({
            ...state,
            versions: []
        })
    ),

    on(FiltersActions.selectPreviousVersionSelection, (state): FiltersState => {
        if (!state.previousVersions.length) {
            return state;
        }
        return {
            ...state,
            versions: state.previousVersions
        };
    }),
    on(
        FiltersActions.updatePreviousVersionSelection,
        (state): FiltersState => ({
            ...state,
            previousVersions: state.versions
        })
    )
);
