import { Injectable } from '@angular/core';
import { T } from '@creative/creative.container';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Container } from '@studio/utils/di';
import { of } from 'rxjs';
import { catchError, combineLatestWith, map, switchMap, tap } from 'rxjs/operators';
import { BrandService } from '../../brand/state/brand.service';
import { FontFamiliesDataService } from '../font-families.data.service';
import * as FontFamiliesActions from './font-families.actions';

@Injectable()
export class FontFamiliesEffects {
    loadFontFamilies$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FontFamiliesActions.loadFontFamiliesOfBrand),
            combineLatestWith(this.brandService.brandId$),
            switchMap(([_, brandId]) =>
                this.fontFamiliesDataService.getFontFamiliesByBrandId(brandId).pipe(
                    map(fontFamilies =>
                        FontFamiliesActions.loadFontFamiliesOfBrandSuccess({ fontFamilies })
                    ),
                    catchError(error =>
                        of(FontFamiliesActions.loadFontFamiliesOfBrandFailure({ error }))
                    )
                )
            )
        );
    });

    registerFontFamiles$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(
                    FontFamiliesActions.loadFontFamiliesOfBrandSuccess,
                    FontFamiliesActions.setFontFamiliesOfCreativeSet
                ),
                tap(({ fontFamilies }) => {
                    this.container?.register_m(T.FONT_FAMILIES, fontFamilies);
                })
            );
        },
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private fontFamiliesDataService: FontFamiliesDataService,
        private brandService: BrandService,
        private container: Container<T>
    ) {}
}
