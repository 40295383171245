import { CdkVirtualScrollViewport, ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    QueryList,
    TemplateRef,
    ViewChild,
    ViewChildren,
    inject
} from '@angular/core';
import { UIDialogService, UIModule } from '@bannerflow/ui';
import { concatLatestFrom } from '@ngrx/operators';
import { ICreative } from '@domain/creativeset/creative/creative';
import { IVersion } from '@domain/creativeset/version';
import { Breakpoint } from '@studio/utils/breakpoints';
import { sortCreatives } from '@studio/utils/design.utils';
import { pushSorted, sortByFormatSize } from '@studio/utils/utils';
import { BehaviorSubject, Subject, merge } from 'rxjs';
import {
    combineLatestWith,
    debounceTime,
    delay,
    filter,
    map,
    skip,
    take,
    takeUntil,
    throttleTime,
    withLatestFrom
} from 'rxjs/operators';
import { ExportDropdownComponent } from '../../pages/manage-view/export-creative/export-dropdown/export-dropdown.component';
import { FullScreenDialogComponent } from '../../pages/manage-view/full-screen-dialog/full-screen-dialog.component';
import { EditCreativeService } from '../../pages/manage-view/services/edit-creative.service';
import {
    GroupedSizeCreatives,
    TileSelectService
} from '../../pages/manage-view/services/tile-select.service';
import { CreativesService } from '../creatives/state/creatives.service';
import { CreativesetDataService } from '../creativeset/creativeset.data.service';
import { MediaDirective, ObserveVisibilityDirective } from '../directives';
import { FiltersService } from '../filters/filters.service';
import { FilterCreativesPipe, FilterGroupedSizeCreativesPipe } from '../pipes';
import { EnvironmentService } from '../services/environment.service';
import { CollapsibleItem, VirtualScrollService } from '../services/virtual-scroll.service';
import { VersionsService } from '../versions/state/versions.service';
import { CreativeListGroupHeaderComponent } from './creative-list-groupheader/creative-list-groupheader.component';
import { CreativeListItemComponent } from './creative-list-item/creative-list-item.component';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        UIModule,
        ScrollingModule,
        CreativeListGroupHeaderComponent,
        FilterGroupedSizeCreativesPipe,
        MediaDirective,
        FilterCreativesPipe,
        CreativeListItemComponent,
        ObserveVisibilityDirective,
        ExportDropdownComponent
    ],
    selector: 'creative-list',
    templateUrl: './creative-list.component.html',
    styleUrls: ['./creative-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreativeListComponent implements OnDestroy, AfterViewInit {
    @Input() creativeItemHeaderTemplate: TemplateRef<{
        $implicit: ICreative | undefined;
        creativeIndex: number;
        isCreativeGroup: boolean;
    }>;
    @Input() creativeItemFooterTemplate: TemplateRef<{
        $implicit: ICreative | undefined;
        index: number;
        scale: number;
    }>;

    @Output() scroll = new EventEmitter<void>();

    @ViewChildren('creativeListItem') creativeListItemComponent: QueryList<CreativeListItemComponent>;
    @ViewChildren('creativeListGroup') creativeListItemsGroup: QueryList<ElementRef>;

    @ViewChild('cdkVirtualScrollViewport') virtualScrollViewport: CdkVirtualScrollViewport;
    @ViewChild('creativeListGroupItem', { static: false }) creativeListGroupItem: ElementRef;

    private initialItemSize = 2000;
    itemSize = this.initialItemSize;
    bufferPx = this.initialItemSize;
    private lastCollapsibleItemsState: CollapsibleItem[] = [];
    private filterGroupedSizeCreativesPipe: FilterGroupedSizeCreativesPipe;

    sortedGroupCreatives$ = new BehaviorSubject<GroupedSizeCreatives[]>([]);
    sortedCreatives$ = new BehaviorSubject<ICreative[]>([]);

    Breakpoint = Breakpoint;
    isMobileShowcase = false;

    private unsubscribe$ = new Subject<void>();

    private creativesService = inject(CreativesService);

    constructor(
        private creativesetDataService: CreativesetDataService,
        private editCreativeService: EditCreativeService,
        private tileSelectService: TileSelectService,
        private filtersService: FiltersService,
        private versionsService: VersionsService,
        private virtualScrollService: VirtualScrollService,
        private environmentService: EnvironmentService,
        private uiDialogService: UIDialogService
    ) {
        this.isMobileShowcase = this.environmentService.isMobileShowcase;
        this.filterGroupedSizeCreativesPipe = new FilterGroupedSizeCreativesPipe(this.filtersService);

        merge(
            // TODO: Remove this debounceTime when we move the CreativeSet data to NgRx. Currently, we need to wait for the creatives to be there before grouping when creating a new version
            this.versionsService.selectedVersions$.pipe(debounceTime(30)),
            this.versionsService.versions$,
            this.editCreativeService.updateView$,
            this.filtersService.filtersState$,
            this.creativesetDataService.creativeset$
        )
            .pipe(
                takeUntil(this.unsubscribe$),
                concatLatestFrom(() => [
                    this.versionsService.sortedSelectedVersions$,
                    this.creativesService.filteredCreatives$
                ]),
                filter(([_, selectedVersions]) => !!selectedVersions?.length)
            )
            .subscribe(([_, selectedVersions, filteredCreatives]) => {
                this.groupCreatives(selectedVersions, filteredCreatives);
            });

        this.tileSelectService.creativeListScrolled$
            .pipe(
                withLatestFrom(this.environmentService.isMobile$),
                filter(([_, isMobile]) => isMobile),
                map(([event]) => (event.target as HTMLElement)?.scrollTop ?? 0),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(scrollTop => {
                this.selectSizeByScrollPosition(scrollTop);
            });
    }

    ngAfterViewInit(): void {
        this.filtersService.isShowingAllVersions$
            .pipe(delay(100), takeUntil(this.unsubscribe$))
            .subscribe(() => {
                if (this.creativeListGroupItem) {
                    this.initVirtualScrollOnFirstLoad();
                }

                this.virtualScrollViewport
                    ?.elementScrolled()
                    .pipe(throttleTime(50), takeUntil(this.unsubscribe$))
                    .subscribe(() => {
                        this.scroll.emit();
                        this.lastCollapsibleItemsState =
                            this.virtualScrollService.getState()?.lastCollapsibleItemsState;
                        this.updateVirtualScrollState();
                    });
            });

        const lastPosition = this.virtualScrollService.getState()?.lastScrollPosition;

        if (lastPosition) {
            setTimeout(() => {
                this.virtualScrollViewport?.scrollToOffset(
                    this.virtualScrollService.getNewScrollPositionFromTheTop()
                );
            }, 10);
        }

        this.virtualScrollService.selectedItem$
            .pipe(
                filter(selectedItem => selectedItem.forceScroll),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(selectedItem => {
                this.virtualScrollViewport?.scrollToIndex(selectedItem.index, 'smooth');
            });

        this.virtualScrollViewport?.scrolledIndexChange
            .pipe(
                combineLatestWith(this.virtualScrollService.isSmoothScrolling$),
                filter(([_, isSmoothScrolling]) => !isSmoothScrolling),
                skip(1),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(([index, _]) => {
                this.virtualScrollService.updateSelectedItemByIndex(index);
            });
    }

    onCollapse(group: GroupedSizeCreatives[], isCollapsed: boolean): void {
        const sortedGroupCreatives = group.map(item => ({ collapsed: item.collapsed, id: item.id }));
        const totalOffsetHeight =
            this.virtualScrollService.calculateNewTotalOffsetHeight(sortedGroupCreatives);
        this.itemSize = Math.round(totalOffsetHeight / sortedGroupCreatives.length);
        this.bufferPx = this.virtualScrollService.adjustBufferSizes(isCollapsed);
        this.lastCollapsibleItemsState = sortedGroupCreatives;
        this.updateVirtualScrollState();
    }

    private updateVirtualScrollState(): void {
        const lastScrollPosition = this.virtualScrollService.getState()?.lastScrollPosition;
        const newScrollPosition = this.virtualScrollViewport?.measureScrollOffset();

        this.virtualScrollService.setState({
            lastCollapsibleItemsState: this.lastCollapsibleItemsState,
            lastScrollPosition: newScrollPosition ? newScrollPosition : lastScrollPosition,
            bufferPx: this.bufferPx,
            itemSize: this.itemSize,
            initialItemSize: this.initialItemSize
        });
    }

    private initVirtualScrollOnFirstLoad(): void {
        const { offsetHeight } = this.creativeListGroupItem.nativeElement;
        this.initialItemSize = offsetHeight;
        this.itemSize = offsetHeight;
        this.bufferPx = offsetHeight;

        this.filterGroupedSizeCreativesPipe
            .transform(this.sortedGroupCreatives$)
            .pipe(
                filter(item => item.length !== 0),
                map(item => item.map(group => ({ collapsed: group.collapsed, id: group.id }))),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(group => {
                this.lastCollapsibleItemsState = group;
                if (this.virtualScrollViewport) {
                    this.updateVirtualScrollState();
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    trackByCreative(_index: number, creative: ICreative): string {
        return creative.id + creative.design?.id; // a creative design can change without the creative id changing
    }

    selectSizeByScrollPosition(scrollTop: number): void {
        let currentID = '';
        this.creativeListItemsGroup.forEach(element => {
            const sectionTop = element.nativeElement.offsetTop;
            if (scrollTop + 200 >= sectionTop) {
                currentID = element.nativeElement.getAttribute('id') || '';
            }
        });

        this.tileSelectService.setCurrentlyViewedSize(currentID);
    }

    private groupCreatives(selectedVersions: IVersion[], creatives: ICreative[]): void {
        const singleVersionSelected = selectedVersions.length === 1;
        const allOrMultipleVersionsSelected =
            selectedVersions.length === 0 || selectedVersions.length > 1;

        const groupedCreatives = allOrMultipleVersionsSelected
            ? this.getSortedGroupedCreatives(creatives, selectedVersions)
            : [];
        this.sortedGroupCreatives$.next(groupedCreatives);

        this.tileSelectService.sortedGroupCreatives = groupedCreatives;

        const sortedCreatives = singleVersionSelected
            ? this.getSortedCreatives(creatives, selectedVersions[0])
            : [];
        this.sortedCreatives$.next(sortedCreatives);
        this.tileSelectService.sortedCreatives = sortedCreatives;
    }

    private getSortedCreatives(
        creatives: ICreative[] | undefined,
        selectedVersion: IVersion
    ): ICreative[] {
        if (!creatives) {
            return [];
        }

        const creativesArr = creatives.map(c => c).sort(sortCreatives);
        return creativesArr.filter(creative => creative.version.id === selectedVersion.id);
    }

    private getSortedGroupedCreatives(
        creatives: ICreative[],
        versions: IVersion[]
    ): GroupedSizeCreatives[] {
        let filteredCreatives = creatives;

        if (versions.length) {
            filteredCreatives = creatives.filter(creative =>
                versions.find(v => v.id === creative.version.id)
            );
        }

        let creativeGroups: GroupedSizeCreatives[] = [];

        for (const creative of filteredCreatives) {
            const group = creativeGroups.find(grp => grp.size.id === creative.size.id);

            if (group) {
                pushSorted(group.creatives, creative, (a, b) => {
                    const versionIndexA = versions.findIndex(v => v.id === a.version.id);
                    const versionIndexB = versions.findIndex(v => v.id === b.version.id);
                    return versionIndexA - versionIndexB;
                });
            } else {
                creativeGroups.push({
                    size: creative.size,
                    creatives: [creative],
                    id: creative.size.id,
                    collapsed: false
                });
            }
        }

        creativeGroups = sortByFormatSize(creativeGroups, 'size');

        return creativeGroups;
    }

    openFullScreenDialog(
        creative: ICreative,
        sortedGroup?: GroupedSizeCreatives[],
        creatives?: ICreative[]
    ): void {
        if (this.isMobileShowcase) {
            const allCreatives = this.getCreatives(sortedGroup, creatives);
            const dialog = this.uiDialogService.openComponent(FullScreenDialogComponent, {
                width: '100%',
                height: '100%',
                panelClass: 'full-screen',
                data: {
                    creative,
                    creatives: allCreatives
                }
            });

            dialog
                .afterClose()
                .pipe(take(1))
                .subscribe(() => {
                    for (const item of allCreatives) {
                        this.editCreativeService.setCreativeVisiblityStatus(item, { visible: true });
                    }
                });
        }
    }

    private getCreatives(sortedGroup?: GroupedSizeCreatives[], creatives?: ICreative[]): ICreative[] {
        let allCreatives: ICreative[] = [];
        if (sortedGroup) {
            sortedGroup.forEach(groupCreatives => {
                allCreatives.push(...groupCreatives.creatives);
            });
        } else if (creatives) {
            allCreatives = creatives;
        }
        return allCreatives;
    }
}
