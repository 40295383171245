import { Component, computed, effect, inject, input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AIStudioService } from '@app/shared/ai-studio/ai-studio.service';
import { GenAIService } from '@app/shared/ai-studio/state/gen-ai.service';
import { Logger } from '@bannerflow/sentinel-logger';
import { FeededReference, IFeed } from '@domain/feed';
import { ImageSizeMode } from '@domain/image';
import { IImageElementDataNode } from '@domain/nodes';
import { isImageNode } from '@creative/nodes';
import { EventLoggerService, ImagePropertyChangeEvent } from '@studio/monitoring/events';
import { createElementProperty } from '@studio/utils/element.utils';
import { uuidv4 } from '@studio/utils/id';
import { hasFeededImageReference, hasImageReference } from '@studio/utils/media';
import { isSVGFile } from '@studio/utils/url';
import { AssetPickerService } from '../../asset-picker/asset-picker.service';
import { DesignViewComponent } from '../../design-view.component';
import { ElementChangeType } from '../../services/editor-event';
import { EditorSaveStateService } from '../../services/editor-save-state.service';
import { EditorStateService } from '../../services/editor-state.service';
import { ElementReplaceService } from '../../services/element-replace.service';
import { MutatorService } from '../../services/mutator.service';
import { AssetPropertyContext } from '../asset-property/asset-property';
import { IAISupported } from '@studio/domain/components/ai-studio.types';

@Component({
    selector: 'image-properties',
    templateUrl: 'image-properties.component.html',
    styleUrls: ['./image-properties.component.scss']
})
export class ImagePropertiesComponent {
    editor = input.required<DesignViewComponent>();
    images = input<IImageElementDataNode[]>();

    private aiStudioService = inject(AIStudioService);
    private assetPickerService = inject(AssetPickerService);
    private editorSaveStateService = inject(EditorSaveStateService);
    private editorStateService = inject(EditorStateService);
    private elementReplaceService = inject(ElementReplaceService);
    private eventLoggerService = inject(EventLoggerService);
    private genAIService = inject(GenAIService);
    private mutatorService = inject(MutatorService);

    AssetPropertyContext = AssetPropertyContext;
    isSizeModeEnabled = false;
    isImageOptimizationEnabled = false;
    sizeMode: ImageSizeMode | 'mixed';
    aiStudioState = computed(() => this.computeAIStudioState());

    private imageElements: IImageElementDataNode[] = [];
    private logger = new Logger('ImagePropertiesComponent');

    constructor() {
        this.assetPickerService.assetSelected$.pipe(takeUntilDestroyed()).subscribe(({ asset }) => {
            this.checkImageSettings([asset.url]);
        });

        effect(() => {
            const images = this.images();
            if (!images) {
                return;
            }

            this.imageElements = images;
            const imageUrls = this.imageElements.map(element => element.imageAsset?.url ?? '');
            this.checkImageSettings(imageUrls);
            this.refreshSizeMode();
        });
    }

    openInAIStudio(): void {
        const element = this.imageElements[0];
        if (!element) {
            return;
        }
        const { imageAsset, id, name } = element;
        this.genAIService.openElementInAIStudio(id, name, imageAsset);
    }

    isOneImage(elements: IImageElementDataNode[]): boolean {
        const element = elements[0];
        if (!element || !isImageNode(element) || !element.imageAsset || elements.length > 1) {
            return false;
        }

        return true;
    }

    updateImageSizeMode(sizeMode: ImageSizeMode): void {
        for (const element of this.imageElements) {
            this.eventLoggerService.log(
                new ImagePropertyChangeEvent('sizeMode', element.imageSettings.sizeMode, sizeMode)
            );
            this.mutatorService.setImageSettings(element, { sizeMode });
        }
        this.editor().workspace.gizmoDrawer.draw();
    }

    updateHighDPI(dpi: boolean): void {
        for (const element of this.imageElements) {
            this.eventLoggerService.log(
                new ImagePropertyChangeEvent('highDpi', element.imageSettings.highDpi, dpi)
            );
            this.mutatorService.setImageSettings(element, { highDpi: dpi });
        }
    }

    updateQuality(quality: number | undefined): void {
        for (const element of this.imageElements) {
            this.eventLoggerService.log(
                new ImagePropertyChangeEvent('quality', element.imageSettings.quality, quality)
            );
            this.mutatorService.setImageSettings(element, { quality }, ElementChangeType.Burst);
        }
    }

    onFeedSettingsChanged(event: { feed: IFeed; replaceAll: boolean }): void {
        this.replaceFeededImage(event.feed, event.replaceAll);

        if (event.replaceAll) {
            this.editorSaveStateService.save({
                saveAll: true,
                saveAndExit: false
            });
        }

        this.isImageOptimizationEnabled = true;
        this.isSizeModeEnabled = true;
    }

    private replaceFeededImage(feed: IFeed, replaceInAllDesigns: boolean): void {
        for (const element of this.imageElements) {
            this.eventLoggerService.log(
                new ImagePropertyChangeEvent('feed', element.feed, feed),
                this.logger
            );

            this.mutatorService.setElementPropertyValue(element, 'feed', feed);

            if (replaceInAllDesigns) {
                this.elementReplaceService.replaceFeededMediaInAllDesigns(element, feed);
            } else {
                this.elementReplaceService.replaceFeededMediaInDesign(
                    element,
                    feed,
                    this.editorStateService.designFork
                );
            }

            const editorElement = this.editorStateService.designFork.elements.find(
                ({ id }) => id === element.id
            );
            if (editorElement) {
                const imageReference = editorElement.properties.find(hasImageReference);
                const feededImageReference = editorElement.properties.find(hasFeededImageReference);
                const feedId = `${feed.id}.${feed.path}`;

                if (feededImageReference) {
                    feededImageReference.value = feedId;
                } else {
                    editorElement.properties.push(
                        createElementProperty({
                            clientId: uuidv4(),
                            name: FeededReference.Image,
                            unit: 'id',
                            value: feedId
                        })
                    );
                }

                if (imageReference) {
                    editorElement.properties = editorElement.properties.filter(
                        property => !hasImageReference(property)
                    );
                }
            }

            element.parentId = undefined;
        }
    }

    private checkImageSettings(imageUrls: string[]): void {
        const hasSVG = imageUrls.some(isSVGFile);
        this.isImageOptimizationEnabled = !hasSVG;
        this.isSizeModeEnabled = !hasSVG;
    }

    private refreshSizeMode(): void {
        if (!this.imageElements?.length) {
            return;
        }
        let sizeMode: ImageSizeMode | 'mixed' = this.imageElements[0].imageSettings.sizeMode;
        for (const image of this.imageElements) {
            if (sizeMode !== image.imageSettings.sizeMode) {
                sizeMode = 'mixed';
            }
        }
        this.sizeMode = sizeMode;
    }

    private computeAIStudioState(): IAISupported {
        const images = this.images();
        if (!images?.[0]?.imageAsset) {
            return { supported: false };
        }
        return this.aiStudioService.isSupported(images[0].imageAsset);
    }
}
