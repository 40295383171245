import { Color } from '@creative/color';
import { fromGrayscale, fromRGBA } from '@creative/color.utils';
import { explodeSpans, mergeSpans } from '@creative/elements/rich-text/rich-text.span.utils';
import {
    createRichTextFromString,
    getHashFromStyle,
    isContentSpan
} from '@creative/elements/rich-text/text-nodes';
import { IFontStyle } from '@domain/font';
import { IFontFamily, IFontFamilyStyle } from '@domain/font-families';
import {
    IElementDataNode,
    IGroupElementDataNode,
    IImageElementDataNode,
    IRectangleElementDataNode,
    ITextElementDataNode
} from '@domain/nodes';
import {
    PSDElement,
    PSDGroupElement,
    PSDLayerElement,
    PSDStyleRun,
    PSDTextElement,
    PSDVectorElement,
    TextFontCaps
} from '@domain/psd';
import {
    ICharacterProperties,
    ICharacterStylesMap,
    IContentSpan,
    OneOfEditableSpans
} from '@domain/text';
import { uuidv4 } from '@studio/utils/id';
import { getMostSimilarItem } from '@studio/utils/string';
import { omitUndefined } from '@studio/utils/utils';
import { Color as PSDColor } from 'ag-psd/dist-es';
import { isGrayscalePSDColor, isRGBAPSDColor, isRGBPSDColor } from './psd/psd-reader';
import { ElementKind } from '@domain/elements';

export function getImageDataFromPsdElementData(
    element: PSDLayerElement
): Partial<IImageElementDataNode> {
    return {
        ...getBaseDataFromPsdElement(element),
        kind: ElementKind.Image,
        imageAsset: {
            id: `psd-image-${element.id}`, // this ID will be patched after uploading the asset
            url: element.data.url,
            width: element.size.width,
            height: element.size.height
        }
    };
}

export function getRectangleDataFromPsdElement(
    element: PSDVectorElement
): Partial<IRectangleElementDataNode> {
    const psdColor = element.data.color;
    let fill: Color | undefined;
    if (psdColor && isRGBAPSDColor(psdColor)) {
        fill = fromRGBA(psdColor.r, psdColor.g, psdColor.b, psdColor.a);
    }
    return {
        ...getBaseDataFromPsdElement(element),
        kind: ElementKind.Rectangle,
        fill
    };
}

export function getColorFromPSDColor(psdColor: PSDColor): Color | undefined {
    if (isRGBPSDColor(psdColor)) {
        return fromRGBA(psdColor.r, psdColor.g, psdColor.b);
    }

    if (isRGBAPSDColor(psdColor)) {
        return fromRGBA(psdColor.r, psdColor.g, psdColor.b, psdColor.a);
    }

    if (isGrayscalePSDColor(psdColor)) {
        return fromGrayscale(psdColor.k);
    }
}

export function getGroupDataFromPsdElement(element: PSDGroupElement): Partial<IGroupElementDataNode> {
    return {
        name: element.name
    };
}

export function getBaseDataFromPsdElement(element: PSDElement): Partial<IElementDataNode> {
    return {
        id: element.id,
        x: element.position.x,
        y: element.position.y,
        width: element.size.width,
        height: element.size.height,
        name: element.name,
        opacity: element.opacity,
        hidden: element.hidden
    };
}

export function getTextDataFromPsdElement(
    element: PSDTextElement,
    rootNodeId: string
): Partial<ITextElementDataNode> {
    const fontSize = element.data.fontSize;
    const textColor = getTextColorFromPSDElement(element);
    const content = createRichTextFromString(element.data.text);
    let characterStyles: ICharacterStylesMap | undefined;
    if (content?.spans && element.data?.styleRuns) {
        const styles = getStylesFromPSDRuns(
            content.spans,
            element.data.styleRuns,
            fontSize,
            rootNodeId
        );
        content.spans = styles.spans;
        characterStyles = styles.characterStyles;
    }

    return omitUndefined<Partial<ITextElementDataNode>>({
        ...getBaseDataFromPsdElement(element),
        kind: ElementKind.Text,
        characterStyles,
        content,
        fontSize,
        horizontalAlignment: element.data.justification,
        textColor,
        strikethrough: element.data.strikethrough,
        underline: element.data.underline,
        uppercase: element.data.uppercase
    });
}

export function getPossibleFontStyle(
    fontFamilyName: string | undefined,
    fontFamilies: IFontFamily[]
): IFontStyle | undefined {
    if (!fontFamilyName) {
        return;
    }

    const fontName = fontFamilyName.toLowerCase();
    const strippedFontName = stripFontName(fontName);
    let possibleFontStyle = fontFamilies[0]?.fontStyles[0]; // default to first family & style in case we could not find one
    // try to find exact family first, then search for similar font
    let fontFamily = fontFamilies.find(({ name }) => name.toLowerCase() === strippedFontName);
    if (!fontFamily) {
        fontFamily = getMostSimilarItem(strippedFontName, 'name', fontFamilies);
    }
    if (fontFamily) {
        possibleFontStyle = getPossibleFontStyleFromFamily(fontFamily, fontName);
    }

    return mapFontFamilyStyleToFontStyle(possibleFontStyle);
}

function getPossibleFontStyleFromFamily(fontFamily: IFontFamily, fontName: string): IFontFamilyStyle {
    const nonDeletedStyles = fontFamily.fontStyles.filter(style => !style.deletedAt);
    const fontStyleName = stripFontStyleName(fontName);
    // try to find exact style first
    let fontFamilyStyle = nonDeletedStyles.find(({ name }) => name.toLowerCase() === fontStyleName);
    if (!fontFamilyStyle) {
        fontFamilyStyle = getMostSimilarItem(fontStyleName, 'name', nonDeletedStyles);
    }
    return fontFamilyStyle ?? fontFamily.fontStyles[0]; // default to first style if we could not find one
}

function mapFontFamilyStyleToFontStyle(fontFamilyFontStyle?: IFontFamilyStyle): IFontStyle | undefined {
    if (!fontFamilyFontStyle) {
        return;
    }
    return {
        id: fontFamilyFontStyle.id,
        src: fontFamilyFontStyle.fontUrl,
        weight: fontFamilyFontStyle.weight,
        style: fontFamilyFontStyle.italic ? 'italic' : 'normal',
        fontFamilyId: fontFamilyFontStyle.fontFamilyId!
    };
}

const FONT_STYLE_KEYWORDS = [
    'semi',
    'normal',
    'condensed',
    'thin',
    'italic',
    'light',
    'bold',
    'regular',
    'black',
    'shine',
    'upper'
];

function stripFontName(fontName: string): string {
    // in cases of 'Helvetica-Italic' or 'Arial-Italic'
    // we want to strip the '-Italic' part to prevent fuzzy matches on '-Italic'
    let strippedName = fontName.toLowerCase();
    for (const keyword of FONT_STYLE_KEYWORDS) {
        // strip keyword combinations like SemiBold as well
        strippedName = strippedName.replaceAll(keyword, '').trim();
    }
    // strip the '-' character as well if it is at the end now
    if (strippedName.endsWith('-')) {
        strippedName = strippedName.substring(0, strippedName.length - 1);
    }
    return strippedName;
}

function stripFontStyleName(styleName: string): string {
    // trying to extract e.g.: 'Helvetic-Regular' --> 'Regular'
    const split = styleName.split('-');
    if (split.length === 1) {
        return styleName;
    }
    return split[1];
}

function getTextColorFromPSDElement(element: PSDTextElement): Color {
    const elementColor = element.data.color;

    if (elementColor) {
        const color = getColorFromPSDColor(elementColor);
        if (color) {
            return color;
        }
    }
    return new Color();
}

function getStyleIdFromSpan(
    currSpan: IContentSpan,
    characterStyles: ICharacterStylesMap,
    characterStyleHashes: Map<string, string>
): string {
    const styleHash = getHashFromStyle(currSpan.style);
    let styleId = characterStyleHashes.get(styleHash);
    if (!styleId) {
        // only create a new style if we don't have one yet, identified by its style hash
        styleId = uuidv4();
        characterStyleHashes.set(styleHash, styleId);
        characterStyles.set(styleId, currSpan.style);
    }
    return styleId;
}

export function getStylesFromPSDRuns(
    spans: OneOfEditableSpans[],
    styleRuns: PSDStyleRun[],
    elementFontSize: number | undefined,
    rootNodeId: string
): {
    spans: OneOfEditableSpans[];
    characterStyles: ICharacterStylesMap;
} {
    const characterStyles: ICharacterStylesMap = new Map<string, Partial<ICharacterProperties>>();
    const characterStyleHashes = new Map<string, string>();
    const newSpans: OneOfEditableSpans[] = explodeSpans(spans);

    let currentStylePos = 0;
    for (const styleRun of styleRuns) {
        const { length, style } = styleRun;
        const { fontSize, fillColor, leading, fontCaps, underline, strikethrough } = style;
        const hasStyle =
            !!fontSize || !!fillColor || !!leading || !!fontCaps || !!underline || !!strikethrough;

        if (!hasStyle) {
            // skip this style if we don't support it
            currentStylePos += length;
            continue;
        }

        for (let _i = 0; _i < length; _i++) {
            const currSpan = newSpans[currentStylePos];
            if (!currSpan) {
                continue;
            }

            if (isContentSpan(currSpan)) {
                if (fontSize && elementFontSize) {
                    currSpan.style.fontSize = fontSize / elementFontSize; // our font size is relative to the base font size
                }
                if (fillColor) {
                    const color = getColorFromPSDColor(fillColor);
                    if (color) {
                        currSpan.style.textColor = color;
                    }
                }
                if (leading && fontSize) {
                    currSpan.style.lineHeight = +(fontSize / leading).toFixed(2); // rudimentary lineHeight detection
                }
                if (underline) {
                    currSpan.style.underline = true;
                }
                if (strikethrough) {
                    currSpan.style.strikethrough = true;
                }
                if (fontCaps === TextFontCaps.AllCaps) {
                    currSpan.style.uppercase = true;
                }

                const styleId = getStyleIdFromSpan(currSpan, characterStyles, characterStyleHashes);
                currSpan.styleId = styleId;
                currSpan.styleIds = { ...currSpan.styleIds, [rootNodeId]: styleId };
            }

            currentStylePos++;
        }
    }

    const mergedSpans = mergeSpans(newSpans);
    return { spans: mergedSpans, characterStyles };
}
