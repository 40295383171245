import { Pipe, PipeTransform } from '@angular/core';
import { concatLatestFrom } from '@ngrx/operators';
import { ICreative } from '@domain/creativeset/creative';
import { map, Observable } from 'rxjs';
import { filterCreatives } from '../../pages/manage-view/utils/filter-creatives.utils';
import { FiltersService } from '../filters/filters.service';

@Pipe({
    standalone: true,
    name: 'filterCreatives'
})
export class FilterCreativesPipe implements PipeTransform {
    constructor(private filtersService: FiltersService) {}

    transform(value: Observable<ICreative[]>): Observable<ICreative[]> {
        return value.pipe(
            concatLatestFrom(() => [this.filtersService.sizes$, this.filtersService.statuses$]),
            map(([inputArray, sizes, statuses]) => filterCreatives(inputArray, sizes, statuses))
        );
    }
}
