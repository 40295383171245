import {
    ICreativesetShowcase,
    ICreativesetShowcaseCreateData,
    ICreativesetShowcasesResponse,
    IShowcaseCreativesetResponse
} from '@domain/showcase';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const creativesetShowcaseActions = createActionGroup({
    source: 'Showcase',
    events: {
        'Load Showcase Creativeset ': props<{ key: string }>(),
        'Load Showcase Creativeset Success': props<IShowcaseCreativesetResponse>(),
        'Load Showcase Creativeset Failure': props<{ error: any }>(),

        'Load Creativeset Showcases': props<{ creativesetId: string }>(),
        'Load Creativeset Showcases Success': props<{ response: ICreativesetShowcasesResponse }>(),
        'Load Creativeset Showcases Failure': props<{ error: any }>(),

        'Create Creativeset Showcase': props<{ showcase: ICreativesetShowcaseCreateData }>(),
        'Create Creativeset Showcase Success': props<{ showcase: ICreativesetShowcase }>(),
        'Create Creativeset Showcase Failure': props<{ error: any }>(),

        'Update Creativeset Showcase': props<{
            changes: Partial<ICreativesetShowcase>;
            showcaseKey: string;
        }>(),
        'Update Creativeset Showcase Success': props<{ showcase: ICreativesetShowcase }>(),
        'Update Creativeset Showcase Failure': props<{ error: any }>(),

        'Delete Creativeset Showcase': props<{ showcaseKey: string }>(),
        'Delete Creativeset Showcase Success': props<{ showcaseKey: string }>(),
        'Delete Creativeset Showcase Failure': props<{ error: any }>(),

        'Send Creativeset Showcase Link': props<{ showcase: ICreativesetShowcase }>(),

        'Reset Created Showcase': emptyProps(),

        'Complete Translation': props<{ creativesetId: string; showcaseKey: string }>(),
        'Complete Translation Success': emptyProps(),
        'Complete Translation Failure': props<{ error: any }>()
    }
});
