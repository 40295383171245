import { parseColor, toRGBA } from '@creative/color.utils';
import {
    roundDimension,
    roundPosition,
    serializeNumberOrFormula
} from '@creative/serialization/serialization.utils';
import {
    CharacterStyleDto,
    GroupNodeDto,
    ImageElementDto,
    VideoElementDto
} from '@domain/api/generated/sapi';
import { IColor } from '@domain/color';
import { ElementKind } from '@domain/elements';
import {
    IButtonElementDataNode,
    ITextElementDataNode,
    OneOfDataNodes,
    OneOfElementDataNodes,
    OneOfTextDataNodes
} from '@domain/nodes';
import {
    OneOfCustomPropertyDto,
    OneOfElementsDto,
    OneOfNodesDto,
    OneOfStatesDto,
    TextLikeElementDto
} from '@domain/serialization';
import { clamp, decimal } from '@studio/utils/utils';
import { validateKeyframeToStatesMapping } from '../animation.utils';
import {
    isGroupDataNode,
    isImageNode,
    isMaskingSupported,
    isMaskingSupportedDto,
    isVideoNode
} from '../nodes/helpers';
import { convertActionToDto, deserializeAction } from './action-serializer';
import { convertAnimationToDto, deserializeAnimation } from './animation-serializer';
import {
    convertCharacterStyleToDto,
    deserializeCharacterStyles
} from './character-properties-serializer';
import { deserializeImageSettings } from './image-settings-serializer';
import {
    convertBorderToDto,
    convertColorToDto,
    convertFiltersToDto,
    convertPaddingToDto,
    convertRadiusToDto,
    convertShadowsToDto,
    convertTextShadowsToDtos,
    convertVideoSettingsToDto,
    deserializeBorder,
    deserializeFilter,
    deserializeRadius,
    deserializeShadows,
    deserializeTextShadows,
    deserializeVideoSettings
} from './property-serializer';
import { convertStateToDto, deserializeState } from './state-serializer';

export function convertNodeToDto(
    element: OneOfDataNodes,
    elements?: OneOfElementDataNodes[]
): OneOfNodesDto {
    const nodeDto = {} as OneOfNodesDto;
    nodeDto.id = element.id;
    nodeDto.parentNodeId = element.__parentNode?.id;
    nodeDto.locked = element.locked || false;
    nodeDto.hidden = element.hidden || false;

    if (isGroupDataNode(element)) {
        return {
            ...nodeDto,
            __groupKind: true
        } satisfies GroupNodeDto;
    }

    const elementDto = nodeDto as OneOfElementsDto;
    elementDto.parentId = element.parentId;

    return convertToElementDto(elementDto, element, elements);
}

function convertToElementDto(
    elementDto: OneOfElementsDto,
    element: OneOfElementDataNodes,
    elements?: OneOfElementDataNodes[]
): OneOfElementsDto {
    validateKeyframeToStatesMapping(element);

    convertSharedPropertiesToDto(element, elementDto, elements);

    const states = new Set();
    for (const state of elementDto.states) {
        if (states.has(state.id)) {
            throw new Error('Multiple states with the same id should not exist.');
        } else {
            states.add(state.id);
        }
    }

    switch (element.kind) {
        case ElementKind.Rectangle:
            elementDto = {
                ...elementDto,
                __rectangleKind: true
            };
            break;
        case ElementKind.Ellipse:
            elementDto = {
                ...elementDto,
                __ellipseKind: true
            };
            break;
        case ElementKind.Text:
        case ElementKind.Button: {
            if (element.kind === ElementKind.Text) {
                elementDto = {
                    ...elementDto,
                    __textKind: true
                };
            } else if (element.kind === ElementKind.Button) {
                elementDto = {
                    ...elementDto,
                    __buttonKind: true
                };
            }

            const textLikeElementDto = elementDto as TextLikeElementDto;
            convertTextPropertiesToDto(element, textLikeElementDto);
            break;
        }
        case ElementKind.Widget: {
            const customProperties = element.customProperties.map(property => {
                let value = property.versionPropertyId ? undefined : property.value;

                if (property.unit === 'color') {
                    value = toRGBA(property.value as IColor);
                }

                return {
                    label: property.label,
                    name: property.name,
                    unit: property.unit,
                    value,
                    versionPropertyId: property.versionPropertyId
                } as OneOfCustomPropertyDto;
            });

            elementDto = {
                ...elementDto,
                customProperties: customProperties,
                __widgetKind: true
            };

            break;
        }
        case ElementKind.Image:
            elementDto = {
                ...elementDto,
                __imageKind: true,
                imageSettings: element.imageSettings
            };
            if (element.imageAsset) {
                const imageAsset = element.imageAsset;
                elementDto.imageAssetId = imageAsset.id;
            } else if (element.feed) {
                elementDto.feed = element.feed;
            } else {
                throw new Error('An image element must either have an image asset or feed.');
            }
            break;
        case ElementKind.Video: {
            const videoSettingsDto = convertVideoSettingsToDto(element.videoSettings);

            elementDto = {
                ...elementDto,
                __videoKind: true,
                videoSettings: videoSettingsDto
            };
            if (element.videoAsset) {
                elementDto.videoAssetId = element.videoAsset.id;
            } else if (element.feed) {
                elementDto.feed = element.feed;
            } else {
                throw new Error('A video element must either have a video asset or feed.');
            }
            break;
        }
    }

    if (isMaskingSupportedDto(elementDto) && isMaskingSupported(element) && element.masking) {
        elementDto.masking = element.masking;
    }

    return elementDto;
}

/** Mutates the elementDto with serialized properties */
export function convertSharedPropertiesToDto(
    element: OneOfElementDataNodes,
    elementDto: OneOfElementsDto,
    elements?: OneOfElementDataNodes[]
): void {
    elementDto.x = roundPosition(element.x);
    elementDto.y = roundPosition(element.y);
    elementDto.width = roundDimension(element.width);
    elementDto.height = roundDimension(element.height);
    elementDto.time = decimal(element.time);
    elementDto.duration = decimal(element.duration);
    elementDto.opacity = serializeNumberOrFormula(element.opacity, 'opacity');
    elementDto.originX = serializeNumberOrFormula(element.originX, 'originX');
    elementDto.originY = serializeNumberOrFormula(element.originY, 'originY');
    elementDto.rotationX = serializeNumberOrFormula(element.rotationX, 'rotationX');
    elementDto.rotationY = serializeNumberOrFormula(element.rotationY, 'rotationY');
    elementDto.rotationZ = serializeNumberOrFormula(element.rotationZ, 'rotationZ');
    elementDto.scaleX = serializeNumberOrFormula(element.scaleX, 'scaleX');
    elementDto.scaleY = serializeNumberOrFormula(element.scaleY, 'scaleY');
    elementDto.hidden = element.hidden;
    elementDto.locked = element.locked;
    elementDto.mirrorX = element.mirrorX;
    elementDto.mirrorY = element.mirrorY;
    elementDto.ratio = element.ratio ? decimal(element.ratio) : undefined;

    elementDto.actions = element.actions.map(action => convertActionToDto(action, elements));
    elementDto.animations = element.animations.map(animation => convertAnimationToDto(animation));
    elementDto.states = element.states.map(state => convertStateToDto(state));

    elementDto.border = convertBorderToDto(element.border);
    elementDto.fill = element.fill ? convertColorToDto(element.fill) : undefined;
    elementDto.filters = convertFiltersToDto(element.filters);
    elementDto.radius = convertRadiusToDto(element.radius);
    elementDto.shadows = element.shadows ? convertShadowsToDto(element.shadows) : undefined;
}

/** Mutates the elementDto with serialized properties */
function convertTextPropertiesToDto(
    element: ITextElementDataNode | IButtonElementDataNode,
    textLikeElementDto: TextLikeElementDto
): void {
    textLikeElementDto.font = element.font?.id ?? element.__fontStyleId;
    textLikeElementDto.fontSize = clamp(
        serializeNumberOrFormula(element.fontSize, 'fontSize'),
        1,
        2500
    );
    textLikeElementDto.textColor = convertColorToDto(element.textColor);
    textLikeElementDto.lineHeight = serializeNumberOrFormula(element.lineHeight, 'lineHeight');
    textLikeElementDto.characterSpacing = serializeNumberOrFormula(
        element.characterSpacing,
        'characterSpacing'
    );
    textLikeElementDto.horizontalAlignment = element.horizontalAlignment;
    textLikeElementDto.verticalAlignment = element.verticalAlignment;
    textLikeElementDto.padding = convertPaddingToDto(element.padding);
    textLikeElementDto.underline = element.underline;
    textLikeElementDto.uppercase = element.uppercase;
    textLikeElementDto.strikethrough = element.strikethrough;
    textLikeElementDto.maxRows = element.maxRows ?? 0;
    textLikeElementDto.textOverflow = element.textOverflow;
    textLikeElementDto.textShadows = convertTextShadowsToDtos(element.textShadows ?? []);

    const characterStyles: CharacterStyleDto[] = [];
    for (const [id, style] of element.characterStyles.entries()) {
        characterStyles.push({ id, value: convertCharacterStyleToDto(style) });
    }

    textLikeElementDto.characterStyles = characterStyles
        .slice()
        .sort((a, b) => a.id.localeCompare(b.id));
}

export function deserializeDataNode(dataNode: OneOfDataNodes, nodeDto: OneOfNodesDto): void {
    dataNode.id = nodeDto.id;
    dataNode.locked = nodeDto.locked;
    dataNode.hidden = nodeDto.hidden;

    if (isGroupDataNode(dataNode)) {
        return;
    }

    const elementDto = nodeDto as OneOfElementsDto;
    dataNode.parentId = elementDto.parentId ?? undefined;

    deserializeDataElement(dataNode, elementDto);
}

export function deserializeDataElement(
    dataElement: OneOfElementDataNodes,
    elementDto: OneOfElementsDto
): void {
    dataElement.states = elementDto.states.map((state: OneOfStatesDto) => deserializeState(state));
    dataElement.animations = elementDto.animations.map(deserializeAnimation);
    dataElement.actions = elementDto.actions.map(action => deserializeAction(action));
    dataElement.time = elementDto.time ?? 0;
    dataElement.duration = elementDto.duration;
    dataElement.ratio = elementDto.ratio;
    dataElement.opacity = elementDto.opacity;
    dataElement.x = elementDto.x;
    dataElement.y = elementDto.y;
    dataElement.width = elementDto.width;
    dataElement.height = elementDto.height;
    dataElement.originX = elementDto.originX;
    dataElement.originY = elementDto.originY;
    dataElement.rotationX = elementDto.rotationX;
    dataElement.rotationY = elementDto.rotationY;
    dataElement.rotationZ = elementDto.rotationZ;
    dataElement.scaleX = elementDto.scaleX;
    dataElement.scaleY = elementDto.scaleY;
    dataElement.radius = deserializeRadius(elementDto.radius);
    dataElement.mirrorX = elementDto.mirrorX;
    dataElement.mirrorY = elementDto.mirrorY;

    /**
     * Fallback filters values could be removed after document migration.
     * It does however require fixing all e2e test json files that are missing the filters property
     */
    dataElement.filters = deserializeFilter(elementDto.filters);

    // Optional properties
    dataElement.border = deserializeBorder(elementDto.border);
    dataElement.fill = elementDto.fill ? parseColor(elementDto.fill) : undefined;
    dataElement.shadows = deserializeShadows(elementDto.shadows);
    if (isMaskingSupportedDto(elementDto) && isMaskingSupported(dataElement)) {
        dataElement.masking = elementDto.masking;
    }

    if (isImageNode(dataElement)) {
        const imageElementDto = elementDto as ImageElementDto;

        // Note that image asset should already be set
        dataElement.imageSettings = deserializeImageSettings(imageElementDto, dataElement.imageAsset);
    } else if (isVideoNode(dataElement)) {
        const videoElementDto = elementDto as VideoElementDto;
        dataElement.videoSettings = deserializeVideoSettings(videoElementDto.videoSettings);
    }
}

export function deserializeTextLikeDataElement(
    dataElement: OneOfTextDataNodes,
    textLikeElementDto: TextLikeElementDto
): void {
    dataElement.fontSize = textLikeElementDto.fontSize;
    dataElement.lineHeight = textLikeElementDto.lineHeight;
    dataElement.characterSpacing = textLikeElementDto.characterSpacing;
    dataElement.textColor = parseColor(textLikeElementDto.textColor);
    dataElement.underline = textLikeElementDto.underline;
    dataElement.uppercase = textLikeElementDto.uppercase;
    dataElement.strikethrough = textLikeElementDto.strikethrough;
    dataElement.maxRows = textLikeElementDto.maxRows;
    dataElement.padding = textLikeElementDto.padding;
    dataElement.textOverflow = textLikeElementDto.textOverflow;
    dataElement.horizontalAlignment = textLikeElementDto.horizontalAlignment;
    dataElement.verticalAlignment = textLikeElementDto.verticalAlignment;
    dataElement.textShadows = deserializeTextShadows(textLikeElementDto.textShadows);

    const { characterStyles, styleHashMap } = deserializeCharacterStyles(
        textLikeElementDto.characterStyles
    );

    dataElement.characterStyles = characterStyles;
    dataElement.__deletedStyleHashMap = new Map</* styleHash */ string, /* styleId */ string>();
    dataElement.__styleHashMap = styleHashMap;
    dataElement.__fontStyleId = textLikeElementDto.font;
}
