import { createAction, props } from '@ngrx/store';

export const toggleAnimations = createAction('[AnimationControl] Toggle animations');

export const playAnimations = createAction('[AnimationControl] Play animations');

export const pauseAnimations = createAction('[AnimationControl] Stop animations');

export const stepForward = createAction('[AnimationControl] Step forward');

export const stepBack = createAction('[AnimationControl] Step back');

export const stepToStart = createAction('[AnimationControl] Step to start');

export const stepToPreload = createAction('[AnimationControl] Step to preload image');

export const initShowcaseState = createAction('[AnimationControl] Init showcase state');

export const initDisabledState = createAction('[AnimationControl] Init disabled animations state');

export const disableAnimations = createAction(
    '[AnimationControl] Disable animations',
    props<{ isDisabled: boolean }>()
);

export const showElement = createAction(
    '[AnimationControl] Show Element',
    props<{ elementId: string }>()
);
