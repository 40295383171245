<div
    class="manage-view-container"
    [ngClass]="{
        'no-translations': (isShowingMultipleVersions$ | async) || hideTranslationPanel,
        showcase: (inShowcaseMode$ | async)
    }">
    @if (creativeset) {
        <ui-header
            class="header"
            [full]="true"
            [showLogo]="false">
            <studio-topbar>
                <!--    centering version picker through collapsable state of translation panel -->
                <ng-container left>
                    <ng-container *ngTemplateOutlet="topbarContentTemplate"></ng-container>
                </ng-container>
                <ng-container center>
                    <ng-container *media="Breakpoint.DesktopUp">
                        <ng-container *ngTemplateOutlet="versionPickerTemplate"></ng-container>
                    </ng-container>
                    <ng-container *media="Breakpoint.DesktopDown">
                        <ng-container *ngTemplateOutlet="topbarContentTemplate"></ng-container>
                    </ng-container>
                </ng-container>
                <ng-container right>
                    @if ((isEmployee$ | async) === true && (inShowcaseMode$ | async) === false) {
                        <feature-toggle
                            uiTooltip="Features in development. Use at your own risk!"
                            uiTooltipPosition="bottom" />
                        <toggle-environment />
                    }

                    <current-users></current-users>

                    <comments-overview
                        [creativesetId]="creativeset.id"
                        [creativesetName]="creativeset.name">
                    </comments-overview>

                    <!--   remove method call when data structure allows it-->
                    @if (hasDesigns()) {
                        <ng-container>
                            <campaign-actions
                                *permissions="['Default', 'CampaignActions']"
                                data-test-id="campaign-actions"></campaign-actions>
                        </ng-container>
                    }

                    <ui-button
                        *permissions="['TranslationPage', 'TranslateVersions']; operation: 'OR'"
                        id="interaction-open-translation-page"
                        class="translations-button"
                        routerLink="./translate"
                        [ngClass]="{ showcase: (inShowcaseMode$ | async) }"
                        queryParamsHandling="merge"
                        type="default"
                        text="TRANSLATIONS"
                        svgIcon="translation">
                    </ui-button>

                    <mark-done-button></mark-done-button>

                    <help-menu class="help-menu" />
                </ng-container>
            </studio-topbar>
            @if ((isMobile$ | async) && (isShowingAllVersions$ | async)) {
                <size-list-horizontal> </size-list-horizontal>
            }
        </ui-header>
    }

    <div class="sidebar">
        <div
            *permissions="['Default', 'AddSize']"
            class="add">
            <ui-button
                id="add-design-button"
                icon="plus-skinny-minimal"
                text="Add size"
                type="primary"
                (click)="openAddSizeDialog()"></ui-button>
        </div>
        <filter-list
            *media="Breakpoint.DesktopUp"
            #filterList
            class="creative-filters ui-scrollbar"></filter-list>

        <div class="controls">
            <animation-control
                id="interaction-animation-control"
                class="animation-controls"></animation-control>
            <size-selector *media="Breakpoint.DesktopDown"></size-selector>
        </div>
        <ng-container *media="Breakpoint.DesktopDown">
            <ng-container *ngTemplateOutlet="versionPickerTemplate"></ng-container>
        </ng-container>
    </div>

    <div
        #scrollView
        cdk-scrollable
        class="ui-scrollbar creative-list"
        [ngClass]="{ 'add-margin': (isMobile$ | async) && (isShowingAllVersions$ | async) }"
        (mouseenter)="scrollViewMouseEnter()"
        (mouseleave)="scrollViewMouseLeave()"
        (click)="deselect($event)"
        (scroll)="creativeListScroll($event)">
        <mv-context-menu></mv-context-menu>

        @if ((hasActiveFilters$ | async) && isViewEmpty()) {
            <div class="no-search-results">No creatives matching your filters</div>
        }

        <creative-list
            [creativeItemHeaderTemplate]="headerTemplate"
            [creativeItemFooterTemplate]="footerTemplate">
            <ng-template
                #headerTemplate
                let-creative
                let-creativeIndex="creativeIndex"
                let-isCreativeGroup="isCreativeGroup">
                <creative-list-item-select
                    [index]="creativeIndex"
                    [creative]="creative">
                </creative-list-item-select>
                <creative-list-item-title
                    [index]="creativeIndex"
                    [isCreativeGroup]="isCreativeGroup"
                    [creative]="creative">
                </creative-list-item-title>
                <creative-list-item-menu [creative]="creative"></creative-list-item-menu>
            </ng-template>
            <ng-template
                #footerTemplate
                let-creative
                let-scale="scale">
                <heavy-video-indicator
                    class="footer__item"
                    [creative]="creative">
                </heavy-video-indicator>

                <creative-list-item-weight
                    class="footer__item"
                    [creative]="creative">
                </creative-list-item-weight>

                <creative-list-item-preview
                    class="footer__item"
                    [scale]="scale"
                    [creative]="creative">
                </creative-list-item-preview>
            </ng-template>
        </creative-list>
    </div>

    <ng-container *permissions="'TranslateVersions'">
        <ng-container *media="Breakpoint.DesktopUp">
            @if ((isShowingMultipleVersions$ | async) === false) {
                <div
                    class="translation-panel ui-scrollbar deselect-trigger"
                    data-test-id="translation-panel">
                    <translation-panel></translation-panel>
                </div>
            }
        </ng-container>
    </ng-container>
</div>

<ng-template #topbarContentTemplate>
    <topbar-context-menu [target]="manageMenu.dropdown"></topbar-context-menu>
    <manage-menu
        #manageMenu
        [hasDesigns]="hasDesigns()"></manage-menu>
    <div
        class="creativeset-name"
        (dblclick)="showButtons = !showButtons">
        <span
            [uiTooltip]="creativeset.name"
            truncateSpan
            [spanText]="creativeset.name"></span>
    </div>

    @if (showButtons) {
        <div class="test-buttons">
            <div
                class="test-button"
                (click)="openManageView()">
                Manage view
            </div>
        </div>
    }
</ng-template>

<ng-template #versionPickerTemplate>
    <size-picker *media="Breakpoint.DesktopUp"></size-picker>
    <version-picker
        class="versions"
        [allowManageVersions]="true"
        [showAllVersions]="true">
    </version-picker>
</ng-template>
<studio-devtools [manageView]="this"></studio-devtools>
