import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslationPageEffects } from '../state/translation-page.effects';
import * as fromTranslationPage from '../state/translation-page.reducer';

@NgModule({
    imports: [
        StoreModule.forFeature(
            fromTranslationPage.TRANSLATION_PAGE_FEATURE_KEY,
            fromTranslationPage.reducer
        ),
        EffectsModule.forFeature([TranslationPageEffects])
    ]
})
export class TranslationPageStoreModule {}
