import { Injectable, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { AllowedOperations } from '@domain/showcase';
import { Role, UserPermission } from '@domain/user';
import { Store } from '@ngrx/store';
import { User as OidcUser } from 'oidc-client';
import { firstValueFrom, take } from 'rxjs';
import * as UserActions from './user.actions';
import * as UserSelectors from './user.selectors';

const forbiddenEditRoles = [
    Role.TextEditor,
    Role.TextEditorExtended,
    Role.Publisher,
    Role.Analyzer,
    Role.PublisherExtended
];

@Injectable({ providedIn: 'root' })
export class UserService {
    canChangeDesign = false;
    loaded$ = this.store.select(UserSelectors.getUserLoaded);
    error$ = this.store.select(UserSelectors.getUserError);
    user$ = this.store.pipe(UserSelectors.getUserWhenLoaded, take(1));
    oidcUser$ = this.store.select(UserSelectors.getOidcUser).pipe(take(1));
    isEmployee$ = this.store.pipe(UserSelectors.getIsEmployeeWhenLoaded);
    showcaseToken$ = this.store.select(UserSelectors.getShowcaseToken);
    role$ = this.store.select(UserSelectors.getRole);
    accessToken$ = this.store.select(UserSelectors.getAccessToken);
    isAuthenticated$ = this.store.select(UserSelectors.getIsAuthenticated);
    isTokenExpired$ = this.store.select(UserSelectors.getIsTokenExpired);
    allowedOperations$ = this.store.select(UserSelectors.getAllowedOperations);
    private permissions$ = this.store.pipe(UserSelectors.getPermissionsWhenLoaded);
    private allowedOperations: AllowedOperations[];
    private userRole: Role | undefined;

    constructor(private store: Store) {
        this.allowedOperations$.subscribe(allowedOperations => {
            this.allowedOperations = allowedOperations;
        });
        this.role$.subscribe(userRole => {
            this.userRole = userRole;
            this.canChangeDesign = !userRole || !forbiddenEditRoles.includes(userRole);
        });
    }

    loadUser(accountSlug: string): void {
        this.store.dispatch(UserActions.loadUser({ accountSlug }));
    }

    loadOIDCUser(): void {
        this.store.dispatch(UserActions.loadOIDCUser());
    }

    setOIDCUser(oidcUser: OidcUser): void {
        this.store.dispatch(UserActions.setOIDCUser({ oidcUser }));
    }

    renewToken(): void {
        this.store.dispatch(UserActions.renewToken());
    }

    login(): void {
        this.store.dispatch(UserActions.login());
    }

    getAccessToken(): Promise<string | undefined> {
        return firstValueFrom(this.accessToken$);
    }

    getShowcaseToken(): Promise<string | undefined> {
        return firstValueFrom(this.showcaseToken$);
    }

    getIsEmployee(): Signal<boolean> {
        return toSignal(this.isEmployee$, { initialValue: false });
    }

    /**
     * This will wait until the user is loaded and then return its permissions.
     * In showcase mode, the user is not loaded,and defaults to an empty array.
     */
    async getPermissions(): Promise<UserPermission[]> {
        return firstValueFrom(this.permissions$);
    }

    /**
     * Be aware this will return false in showcase mode, the user is not loaded and hence no permissions.
     */
    async hasPermission(permission: UserPermission, requireEmployee?: boolean): Promise<boolean> {
        const userPermissions = await this.getPermissions();

        return requireEmployee
            ? userPermissions.includes(permission) && (await this.isEmployee())
            : userPermissions.includes(permission);
    }

    hasRoleAccess(roles: Role[]): boolean {
        return roles.some(role => role === this.userRole);
    }

    /**
     * This will wait until the user is loaded and then return the isEmployee flag.
     * In showcase mode, the user is not loaded and defaults to false.
     */
    async isEmployee(): Promise<boolean> {
        return firstValueFrom(this.isEmployee$);
    }

    operationsAllowed(operations: AllowedOperations[]): boolean {
        return operations.every(operation => this.allowedOperations.includes(operation));
    }
}
