import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { environment } from '../../../environments/environment';
import { UserService } from '../../shared/user/state/user.service';
import { Logger } from '@bannerflow/sentinel-logger';
import { firstValueFrom, skip } from 'rxjs';

export const authGuardCanActivate: CanActivateFn = async () => {
    const logger = new Logger('AuthGuard');

    const userService = inject(UserService);

    if (environment.stage === 'test') {
        return true;
    }
    logger.debug('Checking user state...');

    userService.loadOIDCUser();
    const authenticated = await firstValueFrom(userService.isAuthenticated$.pipe(skip(1)));

    const isTokenExpired = await firstValueFrom(userService.isTokenExpired$);
    logger.debug({ authenticated, isTokenExpired });

    if (authenticated) {
        if (isTokenExpired) {
            logger.debug('Token expired, trying to renew it.');
            userService.renewToken();
            await userService.getAccessToken();
        }
        return true;
    }
    userService.login();
    logger.warn('Not authorized. Redirecting to login!');
    return false;
};
