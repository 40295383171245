import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { BrandService } from '../../brand/state/brand.service';
import { BANNERFLOW_INTERNAL_ACCOUNT_SLUGS } from './feature-whitelist.constants';

export type Feature = (typeof FeatureService)['FEATURE_LIST'][number];

@Injectable({ providedIn: 'root' })
export class FeatureService {
    private static FEATURE_LIST = [
        'widget-content-url',
        'design-api',
        'conversion-to-design-api',
        'dynamic-content'
    ] as const;
    private _activatedFeatures = new Set<Feature>();
    readonly features = [...FeatureService.FEATURE_LIST];
    private accountSlug: string;

    constructor(
        private router: Router,
        private brandService: BrandService
    ) {
        const url = new URL(window.location.href);
        const featureParams = url.searchParams.get('features');

        if (featureParams) {
            this.parseQueryParam(featureParams);
        }

        this.brandService.accountSlug$.pipe(takeUntilDestroyed()).subscribe(accountSlug => {
            this.accountSlug = accountSlug;
        });
    }

    private parseQueryParam(param: string): void {
        const values = param.split(',');
        for (const value of values) {
            for (const feature of FeatureService.FEATURE_LIST) {
                if (feature.toLocaleLowerCase() === value.toLowerCase()) {
                    this._activatedFeatures.add(feature);
                }
            }
        }
    }

    getActivatedFeatures(): Feature[] {
        return [...this._activatedFeatures];
    }

    isFeatureEnabled(feature: Feature): boolean {
        return this._activatedFeatures.has(feature);
    }

    setEnabledFeatures(features: Feature[]): void {
        this._activatedFeatures.clear();
        for (const feature of features) {
            this._activatedFeatures.add(feature);
        }

        const featuresParam = this._activatedFeatures.size
            ? [...this._activatedFeatures].join(',')
            : undefined;

        this.router.navigate([], {
            queryParams: {
                features: featuresParam
            },
            queryParamsHandling: 'merge'
        });
    }

    isDesignApiFeatureEnabled(): boolean {
        return this.isFeatureEnabled('design-api') || this.isFeatureEnabled('conversion-to-design-api');
    }

    isWidgetContentUrlEnabled(): boolean {
        const isInternalAccount = BANNERFLOW_INTERNAL_ACCOUNT_SLUGS.some(
            slug => slug === this.accountSlug
        );
        return isInternalAccount || this.isFeatureEnabled('widget-content-url');
    }
}
