import { z } from 'zod';
import { ZAsset } from './library-asset';
import { IMediaAsset } from '../element-asset';
import { zodOptional } from '@zod/zod';

export interface ImageLibraryAsset {
    id: string;
    original: IMediaAsset;
    thumbnail: IMediaAsset;
    animatedThumbnail?: IMediaAsset;
    url: string;
    name: string;
    created: string;
    modified?: string;
    fileSize: number;
    height: number;
    width: number;
    isGenAi?: boolean;
}

export type ImageFileUpload = Pick<ImageLibraryAsset, 'thumbnail' | 'animatedThumbnail' | 'original'>;

export interface ICreateImageVariables {
    image: {
        name: string;
        fileSize: number;
        original: IMediaAsset;
        thumbnail: IMediaAsset;
        animatedThumbnail?: IMediaAsset;
    };
}

export type ImageLibraryAssetDto = Omit<ImageLibraryAsset, 'width' | 'height' | 'url'>;
export type CreateImageAssetDto = ImageLibraryAssetDto;
export type CreateImageAssetResult = { createImageAsset: CreateImageAssetDto };

/**
 * @deprecated Use ImageLibraryAsset interface instead
 */
export const ZImageLibraryAsset = z.object({
    id: z.string(),
    original: ZAsset,
    thumbnail: ZAsset,
    animatedThumbnail: z.optional(ZAsset),
    url: z.string().min(1),
    name: z.string(),
    created: z.string(),
    modified: zodOptional(z.string()),
    fileSize: z.number(),
    height: z.number(),
    width: z.number()
});
