import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    inject
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UIDropdownComponent, UIModule } from '@bannerflow/ui';
import { ICreative } from '@domain/creativeset/creative';
import { CreativeSize } from '@domain/creativeset/size';
import { Breakpoint } from '@studio/utils/breakpoints';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EditCreativeService } from '../../../pages/manage-view/services/edit-creative.service';
import {
    GroupedSizeCreatives,
    TileSelectService
} from '../../../pages/manage-view/services/tile-select.service';
import { StatusDropdownComponent } from '../../../pages/manage-view/status-dropdown/status-dropdown.component';
import { DisplayCampaignService } from '../../display-campaign/state/display-campaign.service';
import { isCreativeInCampaign } from '../../display-campaign/state/display-campaign.utils';
import { MediaDirective } from '../../directives/media.directive';
import { PermissionsDirective } from '../../directives/permissions.directive';
import { TruncateSpanComponent } from '../../directives/truncate-span.directive';
import { FiltersService } from '../../filters/filters.service';
import { EnvironmentService } from '../../services/environment.service';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        UIModule,
        StatusDropdownComponent,
        TruncateSpanComponent,
        MediaDirective,
        PermissionsDirective
    ],
    selector: 'creative-list-groupheader',
    templateUrl: './creative-list-groupheader.component.html',
    styleUrls: ['./creative-list-groupheader.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreativeListGroupHeaderComponent implements OnInit {
    private displayCampaignService = inject(DisplayCampaignService);

    @Input() group: GroupedSizeCreatives;
    @Input() uiDropDown: UIDropdownComponent;

    @Output() collapse = new EventEmitter<boolean>(false);

    @ViewChild('sizeNameCalc') sizeNameCalc: ElementRef;
    @ViewChild('nameInput') set nameInput(inputElement: ElementRef) {
        /* sovles angulars struggles with focus in combination with *ngIf */
        if (inputElement) {
            inputElement.nativeElement.focus();
        }
    }
    @ViewChild('groupMenu') groupMenu: UIDropdownComponent;

    selected$: Observable<boolean>;
    canActivateDesigns: boolean;

    canDeleteSize$ = this.displayCampaignService.campaigns$.pipe(
        map(
            campaigns =>
                !!this.group &&
                !this.group.creatives.some(creative => isCreativeInCampaign(creative, campaigns))
        )
    );

    isEditing = false;
    isSaving = false;
    hovered = false;

    isMobileShowcase$: Observable<boolean>;
    Breakpoint = Breakpoint;

    constructor(
        public tileSelectService: TileSelectService,
        private editCreativeService: EditCreativeService,
        private filtersService: FiltersService,
        private environmentService: EnvironmentService
    ) {
        this.isMobileShowcase$ = this.environmentService.isMobileShowcase$;

        this.selected$ = this.tileSelectService.selection$.pipe(
            map(creatives =>
                this.group.creatives.every(groupCreative =>
                    creatives.some(creative => groupCreative.id === creative.id)
                )
            )
        );
    }

    ngOnInit(): void {
        this.canActivateDesigns = this.group.creatives[0].design === undefined;
    }

    toggleCollapse(): void {
        this.group.collapsed = !this.group.collapsed;
        this.collapse.emit(this.group.collapsed);
    }

    selectGroup(): void {
        const creatives = this.group.creatives;
        this.tileSelectService.add(...creatives);
    }

    setSelectGroup(): void {
        const creatives = this.group.creatives;
        this.tileSelectService.set(...creatives);
    }

    deselectGroup(): void {
        const creatives = this.group.creatives;
        this.tileSelectService.remove(...creatives);
    }

    applyGroupFilter(size: CreativeSize): void {
        this.filtersService.setSizeFilter([size.id]);
    }

    deactivateDesign(creative: ICreative): void {
        this.editCreativeService.deactivateDesign([creative]);
    }

    activateDesign(creative: ICreative): void {
        this.editCreativeService.activateDesign(creative);
    }

    deleteSize(creative: ICreative): void {
        this.editCreativeService.deleteSizes([creative]);
    }

    editGroupName(): void {
        this.isEditing = true;
    }

    cancelEditSizeName(): void {
        this.isEditing = false;
    }

    async saveSizeName(name: string): Promise<void> {
        if (this.isSaving) {
            return;
        }
        this.isSaving = true;
        this.isEditing = false;

        await this.editCreativeService.saveSizeName(this.group.creatives[0].size, name);
        this.isSaving = false;
    }
}
