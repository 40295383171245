import { TimingFunctionKey } from './timing-functions';
import { IBorder, IFilterMap, IRadius, IShadow, ITextShadow } from './style';
import { IColor } from './color';

export interface ITransition {
    id?: string;
    type: 'in' | 'out';
    name: string;
    timingFunction?: TimingFunctionKey;
    template?: string;
    settings?: TransitionSettings;
    __time?: number;
}

export type TransitionSettings = { [key: string]: ITransitionSetting };

export interface ITransitionSetting {
    name: string;
    value: any;
}

function stringLiteralArray<T extends string>(a: T[]): T[] {
    return a;
}

export const animatableProperties = stringLiteralArray([
    'x',
    'y',
    'originX',
    'originY',
    'rotationX',
    'rotationY',
    'rotationZ',
    'opacity',
    'scaleX',
    'scaleY',
    'fill',
    'radius',
    'shadows',
    'filters',
    'textColor',
    'border'
]);

export type AnimatableProperty = (typeof animatableProperties)[number];

export type AnimatablePropertyValue =
    | IFilterMap
    | IColor
    | IShadow[]
    | ITextShadow[]
    | IBorder
    | IRadius
    | number;

export const transitionSettingPropertyToUnitMap = {
    direction: 'angle',
    distance: 'number'
};
