import { Injectable, OnDestroy } from '@angular/core';
import { CSConfig, CommentService, ThreadConfig, ThreadData } from '@bannerflow/comments';
import { Logger } from '@bannerflow/sentinel-logger';
import { CommentsInitErrorEvent, EventLoggerService } from '@studio/monitoring/events';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CreativesetDataService } from '../creativeset/creativeset.data.service';
import { OidcUserService } from '../user/oidc-user.service';
import { UserService } from '../user/state/user.service';
import { EnvironmentService } from './environment.service';

@Injectable({ providedIn: 'root' })
export class StudioCommentService implements OnDestroy {
    creativesToken = environment.commentServiceCreativeToken;
    creativesetToken = environment.commentServiceCreativesetToken;
    private _commentThreadClicked$ = new Subject<ThreadData>();
    commentThreadClicked$ = this._commentThreadClicked$.asObservable();
    private _init$ = new BehaviorSubject<boolean>(false);
    init$ = this._init$.asObservable();
    private logger = new Logger('Comments');
    private unsubscribe$ = new Subject<void>();

    constructor(
        private creativesetDataService: CreativesetDataService,
        private userService: UserService,
        private oidcUserService: OidcUserService,
        private commentService: CommentService,
        private eventLoggerService: EventLoggerService,
        private environmentService: EnvironmentService
    ) {
        this.creativesetDataService.creativeset$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => this.init());
    }

    init(): void {
        if (environment.stage === 'test') {
            this._init$.next(true);
            return;
        }

        const threads: ThreadConfig[] = this.creativesetDataService.creativeset.creatives.map(
            creative => {
                const version = creative.version;
                const { width, height } = creative.size;
                const name = creative.size.name || '';
                const subject = `${version.name} ・ ${width} × ${height} ${name}`;
                return {
                    tokenId: environment.commentServiceCreativeToken,
                    parentId: creative.id,
                    subject
                } as ThreadConfig;
            }
        );

        threads.push({
            tokenId: this.creativesetToken,
            parentId: this.creativesetDataService.creativeset.id,
            subject: `${this.creativesetDataService.creativeset.name}`
        } as ThreadConfig);

        const csConfig: CSConfig = {
            apiUrl: environment.origins.commentService,
            authUserManager: this.oidcUserService.userSession,
            threads,
            external: false
        };

        if (this.environmentService.inShowcaseMode) {
            if (this.userService.operationsAllowed(['makeComments'])) {
                csConfig.external = true;
            } else {
                this._init$.next(true);
                return;
            }
        }

        this.commentService
            .setup(csConfig)
            .then(() => {
                this._init$.next(true);
            })
            .catch(err => {
                this.eventLoggerService.log(new CommentsInitErrorEvent(err), this.logger);
                this._init$.next(false);
            });
    }

    threadClicked(threadData: ThreadData): void {
        this._commentThreadClicked$.next(threadData);
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
