import {
    AfterViewInit,
    Component,
    DestroyRef,
    ElementRef,
    forwardRef,
    HostBinding,
    HostListener,
    inject,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UIDropdownComponent, UIDropdownTargetDirective, UINotificationService } from '@bannerflow/ui';
import { inAnimationTemplates, outAnimationTemplates } from '@creative/animation-templates';
import {
    getInAnimationDuration,
    getOutAnimationDuration,
    moveElementToPlayhead
} from '@creative/animation.utils';
import {
    createVersionedTextFromText,
    getLibraryKindFromElementKind,
    initializeElementProperties,
    isBannerFlowLibraryWidget,
    isElementDataNode,
    isGroupDataNode,
    isImageNode,
    isOriginalBannerFlowLibraryWidget,
    isSelectionVisibleAtTime,
    isTextNode,
    isVideoNode,
    isVisibleAtTime,
    isWidgetNode,
    positionIsInBounds
} from '@creative/nodes/helpers';
import { IRenderer } from '@creative/renderer.header';
import { getBoundingBoxOfElementWithState } from '@creative/rendering';
import { visitOneNode } from '@creative/visitor';
import { IAnimationTemplate } from '@domain/animation';
import { IBrandLibraryElement, IElementCreationOptions } from '@domain/creativeset';
import { IDesign } from '@domain/creativeset/design';
import { IElementProperty, INewElementProperty } from '@domain/creativeset/element';
import { IBoundingBox, IBoundingCorners, IPosition, ISize } from '@domain/dimension';
import { ElementKind } from '@domain/elements';
import { IFontFamily } from '@domain/font-families';
import { IHotkeyContext } from '@domain/hotkeys/hotkeys.types';
import { OneOfDataNodes, OneOfElementDataNodes, OneOfTextViewElements } from '@domain/nodes';
import { ISnapLine, TransformMode } from '@domain/workspace';
import { BrowserDefaultHotkeys } from '@studio/hotkeys';
import { cloneDeep } from '@studio/utils/clone';
import {
    isElementDescendantOfElement,
    isElementDescendantOfElementWithClass
} from '@studio/utils/dom-utils';
import { createElement, createElementProperty } from '@studio/utils/element.utils';
import { getBoundingCorners, getCenter } from '@studio/utils/geom';
import { MIDDLE_MOUSE_DOWN, MouseObservable } from '@studio/utils/mouse-observable';
import { clamp, rotatePosition } from '@studio/utils/utils';
import { fromEvent, Subject } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { CreativesetDataService } from '../../../shared/creativeset/creativeset.data.service';
import { FontFamiliesService } from '../../../shared/font-families/state/font-families.service';
import { BrandLibraryDataService } from '../../../shared/media-library/brand-library.data.service';
import { MediaLibraryService } from '../../../shared/media-library/state/media-library.service';
import { HotkeyBetterService } from '../../../shared/services/hotkeys/hotkey.better.service';
import { PointerStatesService } from '../../../shared/services/pointer-state.service';
import { IDesignViewAnimationSettings } from '../../../shared/user-settings/state/user-settings.model';
import { UserSettingsService } from '../../../shared/user-settings/state/user-settings.service';
import { DesignViewComponent } from '../design-view.component';
import { BrandLibraryElementService } from '../media-library';
import { BrandLibraryElementEditService } from '../media-library/brandlibrary-element-edit.service';
import { PropertiesService } from '../properties-panel';
import { ElementAlign } from '../properties-panel/element-align.enum';
import { ElementDistribution } from '../properties-panel/element-distribution.enum';
import {
    EditorEventService,
    EditorStateService,
    ElementCreatorService,
    ElementHighlightService,
    ElementSelectionBoundingBoxService,
    ElementSelectionService,
    SelectionNetService
} from '../services';
import { ElementChangeType } from '../services/editor-event';
import { MutatorService } from '../services/mutator.service';
import { AnimationRecorderService } from '../timeline';
import { CanvasLayerComponent } from './canvas-layer.component';
import { ContextMenu, ContextMenuComponent } from './context-menu/context-menu.component';
import { StudioWorkspaceService } from './services/studio-workspace.service';
import { WorkspaceUploadAssetService } from './services/workspace-upload-asset.service';
import { WorkspaceGizmoDrawer } from './workspace-gizmo-drawer';
import { WorkspaceGradientHelperService } from './workspace-gradient-helper.service';
import { WorkspacePanService } from './workspace-pan.service';
import { WorkspaceTransformService } from './workspace-transform.service';
import { ZoomControlService } from './zoom-control/zoom-control.service';

export type CoordinateSystem = 'workspace' | 'canvas';

export interface IPositionWithOffsetToTopleft {
    position: IPosition;
    offset: IPosition;
}

export interface IBoundingCornersWithOffsetToTopLeft {
    topLeft: IPositionWithOffsetToTopleft;
    topRight: IPositionWithOffsetToTopleft;
    bottomLeft: IPositionWithOffsetToTopleft;
    bottomRight: IPositionWithOffsetToTopleft;
    [name: string]: IPositionWithOffsetToTopleft;
}

@Component({
    selector: 'studio-workspace',
    templateUrl: './studio-workspace.component.html',
    providers: [WorkspaceTransformService, WorkspacePanService, WorkspaceUploadAssetService],
    styleUrls: ['./studio-workspace.component.scss']
})
export class StudioWorkspaceComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('canvas') canvas: CanvasLayerComponent;
    @Input() design: IDesign;
    @Input() position?: IPosition;
    @Input() transparent = false;
    @Input() hotkeysExclusions: string[] = [];

    @ViewChild('feedFieldTrigger') feedFieldTrigger: UIDropdownTargetDirective;

    @ViewChild('contextMenu') contextMenu: ContextMenuComponent;
    @ViewChild('dynamicContentMenu') dynamicContentMenu: UIDropdownComponent;

    @HostListener('mouseenter') mouseEnter = (): void =>
        this.pointerStatesService.workspaceHovered.next(true);
    @HostListener('mouseleave') mouseLeave = (): void =>
        this.pointerStatesService.workspaceHovered.next(false);

    @HostBinding('style.user-select')
    @HostBinding('style.-webkit-user-select')
    userSelect = 'none';
    private boundingRect: DOMRect = {
        x: 50,
        y: 50
    } as DOMRect; // Hardcode this for performance
    get canvasSize(): Readonly<ISize> {
        return this.editorStateService.canvasSize;
    }
    contextMenuOpen = false;
    loaded = false;
    isPanning = false;
    wasPanning = false;
    isResizingCanvas = false;
    canvasStartPosition: IPosition = { x: 0, y: 0 };
    canvasCenterPosition: IPosition = { x: 0, y: 0 };
    zoomBox?: IBoundingBox;
    createElementBox?: IBoundingBox;
    createElementKind?: ElementKind;
    mousePosition?: IPosition;
    canvasMousePosition?: IPosition;
    gizmoDrawer: WorkspaceGizmoDrawer;
    mouseDownTimestamp: number;
    horizontalSnapLines: ISnapLine[] = [];
    verticalSnapLines: ISnapLine[] = [];
    isZooming = false;
    wasZooming = false;
    isEditingGuideline$ = new Subject<boolean>();
    isElementDragging$ = new Subject<boolean>();
    isZoomControlHovered: boolean;
    toolbar: { isOpen: boolean; width: number } = { isOpen: false, width: 0 };
    disableGuidelinePreview = false;
    previewGuidelinePresent = false;
    ignoreGuidelineChange = false;
    isMediaLibraryOpen = false;
    selectionToolActive = true;
    renderer: IRenderer;
    mouseObervable: MouseObservable;
    private hotkeyContext: IHotkeyContext;
    private centerStartTimout?: number;
    private centerStopTimout?: number;
    private isCopyPastingNode = false;
    private isWidgetUpdateFromLibrary = false;
    private animationSettings: IDesignViewAnimationSettings;
    private selectedBrandlibraryElements: IBrandLibraryElement[] = [];
    private fontFamilies: IFontFamily[] = [];
    private timelineHeight = 0;
    private destroyRef = inject(DestroyRef);

    constructor(
        public host: ElementRef,
        public betterHotkeyService: HotkeyBetterService,
        public transform: WorkspaceTransformService,
        public pan: WorkspacePanService,
        public gradientHelper: WorkspaceGradientHelperService,
        @Inject(forwardRef(() => DesignViewComponent))
        public designView: DesignViewComponent,
        readonly editorStateService: EditorStateService,
        public creativesetDataService: CreativesetDataService,
        public brandLibraryElementService: BrandLibraryElementService,
        public zoomControlService: ZoomControlService,
        public userSettingsService: UserSettingsService,
        public pointerStatesService: PointerStatesService,
        public mediaLibraryService: MediaLibraryService,
        private workspaceUploadAssetService: WorkspaceUploadAssetService,
        private propertiesService: PropertiesService,
        private animationRecorder: AnimationRecorderService,
        private uiNotificationService: UINotificationService,
        private editorEventService: EditorEventService,
        public mutatorService: MutatorService,
        private elementSelectionService: ElementSelectionService,
        private elementHighlightService: ElementHighlightService,
        private selectionNetService: SelectionNetService,
        private elementSelectionBoundingBoxService: ElementSelectionBoundingBoxService,
        private animationRecorderService: AnimationRecorderService,
        private elementCreationService: ElementCreatorService,
        private fontFamiliesService: FontFamiliesService,
        private studioWorkspaceService: StudioWorkspaceService,
        private brandLibraryDataService: BrandLibraryDataService,
        private brandLibraryElementEditService: BrandLibraryElementEditService
    ) {
        this.mouseObervable = new MouseObservable(this.host.nativeElement, {
            offset: {
                x: this.boundingRect.x,
                y: this.boundingRect.y
            }
        });

        this.renderer = this.editorStateService.renderer;

        this.mediaLibraryService.isOpen$.subscribe(isOpen => {
            this.isMediaLibraryOpen = isOpen;
        });

        this.elementSelectionService.change$.pipe(takeUntilDestroyed()).subscribe(() => {
            if (this.gradientHelper.active) {
                this.gradientHelper.onSelectionChange();
            }
            this.redrawGizmos();
        });

        this.animationRecorder.recording$
            .pipe(
                distinctUntilChanged(),
                filter(() => !!this.gizmoDrawer),
                takeUntilDestroyed()
            )
            .subscribe(isRecording => {
                this.notifyRecordingStatus(isRecording);
                this.redrawGizmos();
            });

        fromEvent<MouseEvent>(window, 'resize')
            .pipe(takeUntilDestroyed())
            .subscribe(() => this.updateBoundingRect());

        this.elementCreationService.create$
            .pipe(takeUntilDestroyed())
            .subscribe(
                ({ node, elementProperties, values, isCopyPasting, isWidgetUpdateFromLibrary }) => {
                    this.isCopyPastingNode = isCopyPasting;
                    this.isWidgetUpdateFromLibrary = isWidgetUpdateFromLibrary;
                    this.addNodeToCanvas(node, values, elementProperties);
                }
            );

        this.userSettingsService.animation$
            .pipe(takeUntilDestroyed())
            .subscribe(animationSettings => (this.animationSettings = animationSettings));

        this.brandLibraryElementService.selectedElements$
            .pipe(takeUntilDestroyed())
            .subscribe(
                brandLibraryElements => (this.selectedBrandlibraryElements = brandLibraryElements)
            );

        this.fontFamiliesService.fontFamilies$
            .pipe(takeUntilDestroyed())
            .subscribe(fontFamilies => (this.fontFamilies = fontFamilies));

        this.editorEventService.renderedCanvas$.pipe(takeUntilDestroyed()).subscribe(() => {
            const currentZoom = this.editorStateService.zoom;
            this.setZoom(1);
            this.canvas.render();
            this.setZoom(currentZoom);
        });
    }

    @HostListener('click') onClick(): void {
        this.mutatorService.workspaceFocused = true;
        if (this.transform.workspace !== this) {
            this.setThisAsActiveWorkspace();
        }
    }

    @HostListener('window:pointerdown', ['$event']) onWindowClick(e: PointerEvent): void {
        if (!isElementDescendantOfElementWithClass(e.target, 'ui-dropdown')) {
            this.contextMenu.tryCloseMenus();
        }
    }

    @HostListener('window:contextmenu', ['$event']) windowContextMenu = (event: MouseEvent): void => {
        /**
         * @description Only trigger the contextMenu when mousePosition is inside the workspace
         */
        this.contextMenu.tryCloseMenus();

        if (this.designView.timeline.selectedElementAnimations) {
            event.preventDefault();
            event.stopPropagation();
        }

        const { left, top, right, bottom } = this.boundingRect;

        // Prevent contextmenu from opening in things that doesn't belong to the editorpage, e.g in modals
        if (!isElementDescendantOfElement(this.designView.host.nativeElement, event.target)) {
            return;
        }

        if (
            event.clientX >= left &&
            event.clientX <= right &&
            event.clientY >= top &&
            event.clientY <= bottom
        ) {
            if (this.designView.mediaLibrary.isHovered || this.mutatorService.preview) {
                return;
            } else {
                event.preventDefault();
                event.stopPropagation();
                const canvasMousePosition = this.getMousePositionRelativeToCanvas(event);
                const elementUnderMousePosition =
                    this.findElementAtPosition(canvasMousePosition) ||
                    this.elementHighlightService.currentHighlight?.node;

                if (elementUnderMousePosition && (event.target as HTMLElement).tagName === 'INPUT') {
                    // if we press the element name input we should also select the element
                    this.elementSelectionService.latestSelectionType = 'element';
                    this.elementSelectionService.setSelection(elementUnderMousePosition);
                }
                const selectionType = this.elementSelectionService.latestSelectionType;

                let menuType: ContextMenu = 'canvas';

                if (selectionType === 'animation' || selectionType === 'keyframe') {
                    menuType = 'animation';
                } else if (selectionType === 'element' && elementUnderMousePosition) {
                    menuType = 'element';
                }

                if (menuType !== 'animation' && this.propertiesService.inStateView) {
                    menuType = 'state';
                }

                const element = this.elementSelectionService.currentSelection.element;

                this.contextMenu.contextMenuHandler(event, menuType, element);
            }
        }
    };

    @HostListener('dragover', ['$event']) onDragOver(event: Event): void {
        // Prevent file from opening in the browser
        event.preventDefault();
    }

    @HostListener('drop', ['$event']) onDropFile(event: MouseEvent): void {
        event.preventDefault();

        if (this.designView.editingElement !== undefined) {
            return;
        }

        this.deselectAllElements();

        const files: File[] = [];
        const dataTransfer = (event as DragEvent).dataTransfer;

        if (dataTransfer!.items) {
            for (const i in dataTransfer!.items) {
                const item = dataTransfer!.items[i];
                if (item.kind === 'file') {
                    const file = dataTransfer!.files[i];
                    files.push(file);
                }
            }
        }

        this.uploadAssets(files, event);
    }

    async uploadAssets(files: File[], event: MouseEvent): Promise<void> {
        const { pageX: x, pageY: y } = event;
        const currImagePosition = this.getCanvasPositionFromDocumentPosition({ x, y });
        this.workspaceUploadAssetService.uploadAssets(files, currImagePosition);
    }

    private alignLeft = (): void => this.alignSelection(ElementAlign.Left);
    private alignRight = (): void => this.alignSelection(ElementAlign.Right);
    private alignTop = (): void => this.alignSelection(ElementAlign.Top);
    private alignBottom = (): void => this.alignSelection(ElementAlign.Bottom);
    private alignCenter = (): void => this.alignSelection(ElementAlign.Center);
    private alignMiddle = (): void => this.alignSelection(ElementAlign.Middle);
    private distributeHorizontally = (): void =>
        this.distributeSelection(ElementDistribution.Horizontal);
    private distributeVertically = (): void => this.distributeSelection(ElementDistribution.Vertical);

    private setPanOn = (): void => this.setPan(true);
    private setPanOff = (): void => this.setPan(false);

    private createText = (): Promise<void> => this.beginCreateNewElement(ElementKind.Text);
    private createButton = (): Promise<void> => this.beginCreateNewElement(ElementKind.Button);
    private createRectangle = (): Promise<void> => this.beginCreateNewElement(ElementKind.Rectangle);
    private createEllipse = (): Promise<void> => this.beginCreateNewElement(ElementKind.Ellipse);

    private setupMouseEventListeners(): void {
        this.mouseObervable.mouseDown$
            .pipe(filter(({ event }) => event.button === MIDDLE_MOUSE_DOWN))
            .subscribe(() => this.setPanOn());

        this.mouseObervable.mouseUp$
            .pipe(filter(({ event }) => event.button === MIDDLE_MOUSE_DOWN))
            .subscribe(() => {
                this.setPanOff();
            });
    }

    private setupHotkeyListeners(): void {
        this.betterHotkeyService.on('PanOn', this.setPanOn);
        this.betterHotkeyService.on('PanOff', this.setPanOff);
        this.betterHotkeyService.on('CreateTextElement', this.createText);
        this.betterHotkeyService.on('CreateButtonElement', this.createButton);
        this.betterHotkeyService.on('CreateRectangleElement', this.createRectangle);
        this.betterHotkeyService.on('CreateEllipseElement', this.createEllipse);
        this.betterHotkeyService.on('ToggleSelectionTool', this.toggleSelectionTool);
        this.betterHotkeyService.on('ToggleLock', this.contextMenu.elementMenuComponent.toggleLock);
        this.betterHotkeyService.on(
            'ToggleVisibility',
            this.contextMenu.elementMenuComponent.toggleVisibility
        );
        this.betterHotkeyService.on('GroupElements', this.contextMenu.elementMenuComponent.group);
        this.betterHotkeyService.on('UngroupElements', this.contextMenu.elementMenuComponent.ungroup);
        this.betterHotkeyService.on(
            'FindInLibrary',
            this.contextMenu.elementMenuComponent.findInBrandLibrary
        );
        this.betterHotkeyService.on('ToggleMask', this.contextMenu.elementMenuComponent.toggleMasking);
        this.betterHotkeyService.on('DeleteElement', this.removeSelectedElements);
        this.betterHotkeyService.on('AlignLeft', this.alignLeft);
        this.betterHotkeyService.on('AlignRight', this.alignRight);
        this.betterHotkeyService.on('AlignTop', this.alignTop);
        this.betterHotkeyService.on('AlignBottom', this.alignBottom);
        this.betterHotkeyService.on('AlignCenter', this.alignCenter);
        this.betterHotkeyService.on('AlignMiddle', this.alignMiddle);
        this.betterHotkeyService.on('DistributeHorizontally', this.distributeHorizontally);
        this.betterHotkeyService.on('DistributeVertically', this.distributeVertically);
    }

    private removeHotkeyListeners(): void {
        this.betterHotkeyService.off('PanOn', this.setPanOn);
        this.betterHotkeyService.off('PanOff', this.setPanOff);
        this.betterHotkeyService.off('CreateTextElement', this.createText);
        this.betterHotkeyService.off('CreateButtonElement', this.createButton);
        this.betterHotkeyService.off('CreateRectangleElement', this.createRectangle);
        this.betterHotkeyService.off('CreateEllipseElement', this.createEllipse);
        this.betterHotkeyService.off('ToggleSelectionTool', this.toggleSelectionTool);
        this.betterHotkeyService.off('ToggleLock', this.contextMenu.elementMenuComponent.toggleLock);
        this.betterHotkeyService.off(
            'ToggleVisibility',
            this.contextMenu.elementMenuComponent.toggleVisibility
        );
        this.betterHotkeyService.off('GroupElements', this.contextMenu.elementMenuComponent.group);
        this.betterHotkeyService.off('UngroupElements', this.contextMenu.elementMenuComponent.ungroup);
        this.betterHotkeyService.off(
            'FindInLibrary',
            this.contextMenu.elementMenuComponent.findInBrandLibrary
        );
        this.betterHotkeyService.off('ToggleMask', this.contextMenu.elementMenuComponent.toggleMasking);
        this.betterHotkeyService.off('DeleteElement', this.removeSelectedElements);
        this.betterHotkeyService.off('AlignLeft', this.alignLeft);
        this.betterHotkeyService.off('AlignRight', this.alignRight);
        this.betterHotkeyService.off('AlignTop', this.alignTop);
        this.betterHotkeyService.off('AlignBottom', this.alignBottom);
        this.betterHotkeyService.off('AlignCenter', this.alignCenter);
        this.betterHotkeyService.off('AlignMiddle', this.alignMiddle);
        this.betterHotkeyService.off('DistributeHorizontally', this.distributeHorizontally);
        this.betterHotkeyService.off('DistributeVertically', this.distributeVertically);
        this.betterHotkeyService.popContext();
    }

    setThisAsActiveWorkspace(): void {
        this.setZoom(1);
        this.editorStateService.setRenderer(this.renderer);
        this.pan.workspace = this;
        this.transform.workspace = this;
        this.pan.init();
        this.gradientHelper.setup(this);
        window.workspace = this;
    }

    setPan(set: boolean): void {
        this.isPanning = set;
        this.pan.setCursor(set);
        this.gizmoDrawer.draw();
    }

    updateBoundingRect(): void {
        this.boundingRect = this.host.nativeElement.getBoundingClientRect();
        this.mouseObervable.setOffsets({
            x: this.boundingRect.x,
            y: this.boundingRect.y
        });
    }

    toggleSelectionTool = (): void => {
        this.selectionToolActive = !this.selectionToolActive;
        if (this.selectionToolActive) {
            this.stopCreateNewElement();
            this.mediaLibraryService.closeMediaLibrary(
                this.brandLibraryElementEditService.isEditingName$$.value
            );
        }
    };

    ngAfterViewInit(): void {
        this.userSettingsService.timelineHeight$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(timelineHeight => {
                this.timelineHeight = timelineHeight;
                this.centerCanvas();
            });

        this.setThisAsActiveWorkspace();

        window.addEventListener('resize', this.centerCanvas);
        this.setupHotkeyListeners();
        this.setupMouseEventListeners();

        setTimeout(() => {
            this.editorEventService.workspaceViewInit();
            this.workspaceUploadAssetService.initService(this);
            this.updateBoundingRect();
        });
    }

    ngOnInit(): void {
        this.initializeGizmoDrawer();
        this.initializeHotkeyService();

        setTimeout(() => {
            this.centerCanvas();
            this.transform.onInit();
            this.loaded = true;
        });

        this.zoomControlService.zoomControlHovered
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(isHovered => (this.isZoomControlHovered = isHovered));
    }

    private initializeGizmoDrawer(): void {
        this.gizmoDrawer = new WorkspaceGizmoDrawer(
            this.host.nativeElement,
            this,
            this.editorStateService,
            this.editorEventService,
            this.userSettingsService,
            this.elementSelectionService,
            this.selectionNetService,
            this.elementHighlightService,
            this.elementSelectionBoundingBoxService,
            this.creativesetDataService
        );
    }

    private initializeHotkeyService(): void {
        if (this.mutatorService.preview) {
            return;
        }

        this.hotkeyContext = {
            name: 'Workspace',
            input: window,
            keyDefaultBehaviourExclusions: Object.values(BrowserDefaultHotkeys),
            keyPropogationExclusions: this.hotkeysExclusions
        };

        this.betterHotkeyService.pushContext(this.hotkeyContext);
    }

    private addNodeToCanvas<DataNode extends OneOfDataNodes>(
        node: DataNode,
        values?: IElementCreationOptions['values'],
        elementProperties: INewElementProperty[] = []
    ): DataNode {
        if (this.animationRecorderService.isRecording) {
            this.animationRecorderService.stopRecording();
        }

        const brandLibrary = this.brandLibraryDataService.brandLibrary;

        const globalOrBrandElement = [
            ...this.editorStateService.elements,
            // Brand Library elements can be undefined when loading while entering the DV
            ...(brandLibrary ? cloneDeep(brandLibrary.elements) : [])
        ].find(element => element.id === node.parentId);

        const isBannerflowLibraryWidget =
            globalOrBrandElement &&
            (isBannerFlowLibraryWidget(globalOrBrandElement) ||
                isOriginalBannerFlowLibraryWidget(globalOrBrandElement));

        const element = createElement({
            id: node.id,
            type: isBannerflowLibraryWidget ? ElementKind.BannerflowLibraryWidgetInstance : node.kind,
            name: node.name,
            properties: elementProperties
        });

        this.editorStateService.addElement(element);

        if (isGroupDataNode(node)) {
            this.finalizeNodeCreation(node);
            return node;
        }

        if (isTextNode(node) && !values) {
            const content = node.content;
            const epv = createElementProperty({
                name: 'content',
                value: createVersionedTextFromText(content)
            });
            const property = this.editorStateService.propertyAsVersionableProperty(epv, 'content');
            element.properties.push(property as IElementProperty);
        } else if (isWidgetNode(node)) {
            const allVersionProperties = [
                ...this.editorStateService.versionProperties,
                ...this.editorStateService.defaultVersionProperties
            ];
            for (const customProperty of node.customProperties) {
                if (customProperty.versionPropertyId) {
                    const versionProperty = allVersionProperties.find(
                        ({ id }) => id === customProperty.versionPropertyId
                    );
                    if (versionProperty) {
                        customProperty.value = versionProperty.value;
                    }
                }
            }
        } else if (values) {
            initializeElementProperties(node, { ...values, fontFamilies: this.fontFamilies });
        }

        const applyDefaultTransition =
            this.animationSettings.useDefaultAnimations &&
            !isImageNode(node) &&
            !isVideoNode(node) &&
            !node.animations.length &&
            !this.isCopyPastingNode &&
            !this.isWidgetUpdateFromLibrary;

        if (applyDefaultTransition) {
            this.setDefaultAnimationsOnElement(node);
        }

        visitOneNode(node, this.renderer);
        this.editorStateService.document.addNode_m(node);
        this.renderer.updateElementOrder_m();

        if (!this.isCopyPastingNode && !this.isWidgetUpdateFromLibrary) {
            this.placeElementOnPlayhead(node);
        }
        this.finalizeNodeCreation(node);

        return node;
    }

    private finalizeNodeCreation(node: OneOfDataNodes): void {
        if (this.isCopyPastingNode) {
            return;
        }

        this.selectElement(node);

        if (isTextNode(node) && this.selectedBrandlibraryElements.length <= 1) {
            const viewElement = this.renderer.getViewElementById<OneOfTextViewElements>(node.id);
            viewElement?.__richTextRenderer?.editor_m!.resolveCharacterStyles();
            this.mutatorService.startEditText(node);
            if (this.transform.isSafari) {
                this.userSelect = 'all';
            }
            this.transform.setTransformMode(TransformMode.EditText);
            this.designView.updateElementsVersionedPropertiesForSelectedVersion();
        }

        this.editorEventService.creative.change('elements', undefined, ElementChangeType.Burst);
    }

    private placeElementOnPlayhead(element: OneOfElementDataNodes): void {
        const animator = this.designView.animator;
        if (!animator) {
            throw new Error('No animator exists when placing element on playhead');
        }

        const time = animator.time;

        moveElementToPlayhead(element, this.editorStateService.document.elements, time);

        const startTime = element.time + getInAnimationDuration(element.animations);
        const endTime = element.time + element.duration - getOutAnimationDuration(element.animations);

        // Force update (but skip history)
        this.mutatorService.setElementPropertyValue(
            element,
            'time',
            element.time,
            ElementChangeType.Skip
        );

        // Get a time in which element is fully visible
        const seekTo = clamp(time, startTime, endTime);

        // Move playhead if time have changed
        animator.seek(seekTo);
    }

    private setDefaultAnimationsOnElement(element: OneOfElementDataNodes): void {
        const findFade = (animation: IAnimationTemplate): boolean => animation.name === 'Fade';

        const inAnimation = inAnimationTemplates.find(findFade) || inAnimationTemplates[0];
        const outAnimation = outAnimationTemplates.find(findFade) || outAnimationTemplates[0];

        this.mutatorService.applyAnimationTemplateOnElement(inAnimation, element);
        this.mutatorService.applyAnimationTemplateOnElement(outAnimation, element);
    }

    private notifyRecordingStatus(isRecording: boolean): void {
        if (isRecording) {
            this.uiNotificationService.open('Record animation mode is now active', {
                type: 'warning',
                autoCloseDelay: 5000,
                placement: 'top'
            });
        } else {
            this.uiNotificationService.open('Record animation mode is now disabled', {
                type: 'info',
                autoCloseDelay: 5000,
                placement: 'top'
            });
        }
    }

    redrawGizmos = (): void => {
        if (this.gizmoDrawer) {
            this.gizmoDrawer.draw();
        }
        if (this.designView.timeline) {
            this.designView.timeline.gizmoDrawer.draw();
        }
    };

    ngOnDestroy(): void {
        this.clearCenterTimeouts();
        this.transform.destroy();
        this.gizmoDrawer.destroy();
        this.pan.destroy();

        window.removeEventListener('resize', this.centerCanvas);
        this.mouseObervable.destroy();
        this.removeHotkeyListeners();
        (window as any).workspace = undefined;
    }

    resize(size: ISize, element: OneOfElementDataNodes): void {
        this.mutatorService.creativeDocument.width = size.width;
        this.mutatorService.creativeDocument.height = size.height;
        const centerX = this.canvasSize.width / 2 - element.width / 2;
        const centerY = this.canvasSize.height / 2 - element.height / 2;
        this.mutatorService.moveStart(element);
        this.mutatorService.move(centerX, centerY);

        if (this.mutatorService.preview) {
            this.canvas.host.nativeElement.style.width = `${this.canvasSize.width}px`;
            this.canvas.host.nativeElement.style.height = `${this.canvasSize.height}px`;
        }
    }

    renderCanvas(): void {
        this.canvas.render();
    }

    selectElement(element: OneOfDataNodes): void {
        if (!this.isZooming) {
            this.elementHighlightService.clearHighlight();
            this.elementSelectionService.setSelection(element);
            this.elementSelectionService.latestSelectionType = 'element';
        }
    }

    deselectElement(element: OneOfElementDataNodes): void {
        this.elementSelectionService.deleteSelection(element);
    }

    deselectAllElements(): void {
        this.elementHighlightService.clearHighlight();
        this.elementSelectionService.clearSelection();
        this.transform.cancel();
        this.gizmoDrawer.clear();
    }

    alignSelection(align: ElementAlign): void {
        const selection = this.elementSelectionService.currentSelection;
        if (!selection) {
            throw Error('No element selected when aligning selection');
        }
        this.mutatorService.alignSelection(align, selection);
    }

    distributeSelection(distribution: ElementDistribution): void {
        this.mutatorService.distributeSelection(distribution);
    }

    zoomControlHover(isHovered: boolean): void {
        if (isHovered) {
            this.wasZooming = this.isZooming;
            this.isZooming = false;
            this.designView.setCursor(this.host.nativeElement, 'selection-0');
        } else if (!isHovered && this.wasZooming) {
            this.isZooming = true;
            this.designView.setCursor(this.host.nativeElement, 'zoom-in');
        } else {
            this.designView.setCursor(this.host.nativeElement, 'selection-0');
        }
    }

    async beginCreateNewElement(kind: ElementKind): Promise<void> {
        this.selectionToolActive = false;
        if (this.createElementKind === kind) {
            this.stopCreateNewElement();
            // TODO the BrandLibraryElementService instance is available for design-view.component and its children,
            // but because of this it's not possible to use it from MediaLibraryEffects, in case this service is provided
            // elsewhere in the future it should be possible to clean it up and use it directly from MediaLibraryEffects
            this.mediaLibraryService.closeMediaLibrary(
                this.brandLibraryElementEditService.isEditingName$$.value
            );
            return;
        }
        this.mediaLibraryService.openMediaLibrary(getLibraryKindFromElementKind(kind));

        this.deselectAllElements();
        this.transform.cancel();
        setTimeout(() => {
            this.createElementKind = kind;
            this.designView.setCursor(this.host.nativeElement, `create-element-${kind}-0`);
        });
    }

    stopCreateNewElement(): void {
        this.createElementKind = undefined;
        this.createElementBox = undefined;
        if (!this.isPanning) {
            this.designView.setCursor(this.host.nativeElement, 'selection-0');
        }
        this.designView.toolbar.setToolbarCursors();
    }

    async createNewElement(
        pos: Partial<IPosition & ISize> = {},
        kind: ElementKind | undefined = this.createElementKind
    ): Promise<OneOfElementDataNodes | undefined> {
        if (this.isZooming || !(kind && pos)) {
            return;
        }

        const defaultWidth = Math.min(this.canvasSize.height, 100);
        const defaultHeight = Math.min(this.canvasSize.width, kind === ElementKind.Button ? 34 : 100);
        const width = pos.width || defaultWidth;
        const height = pos.height || defaultHeight;
        const clickedOut = pos.width === undefined && pos.height === undefined;
        let x: number;
        let y: number;
        // X & Y. Center if no width and height is provided (user has clicked out the element)
        // Except for text elements, due to different scaling we want to center it later.
        if (kind !== ElementKind.Text) {
            const posX = pos.x ?? this.canvasSize.width / 2;
            const posWidth = pos.width ? 0 : width / 2;
            x = posX - posWidth;

            const posY = pos.y ?? this.canvasSize.height / 2;
            const posHeight = pos.height ? 0 : height / 2;
            y = posY - posHeight;
        } else {
            x = pos.x || 0;
            y = pos.y || 0;
        }

        // Default values overwritten by injected values
        const box: IPosition & ISize = {
            x,
            y,
            width,
            height
        };

        this.stopCreateNewElement();

        return await this.elementCreationService.createElement(kind, box, { clickedOut });
    }

    getMousePositionRelativeToWorkspace(event: MouseEvent): IPosition {
        const boundingRect = this.boundingRect;

        return {
            x: event.clientX - boundingRect.left,
            y: event.clientY - boundingRect.top
        };
    }

    getMousePositionRelativeToCanvas(event: MouseEvent): IPosition {
        const canvasPosition = this.getCanvasPositionRelativeToWorkspace();
        const position = this.getMousePositionRelativeToWorkspace(event);

        return {
            x: (position.x - canvasPosition.x) / this.editorStateService.zoom,
            y: (position.y - canvasPosition.y) / this.editorStateService.zoom
        };
    }

    getPositionRelativeToCanvas(position: IPosition): IPosition {
        const canvasPosition = this.getCanvasPositionRelativeToWorkspace();
        return {
            x: (position.x - canvasPosition.x) / this.editorStateService.zoom,
            y: (position.y - canvasPosition.y) / this.editorStateService.zoom
        };
    }

    getBoundingCorners(
        element: IBoundingBox | OneOfElementDataNodes,
        coordinateSystem: CoordinateSystem = 'workspace',
        inset = 0,
        calculateCenter = false
    ): IBoundingCorners {
        const { x, y, width, height } = this.getBoundingRect(element, coordinateSystem);

        return getBoundingCorners(
            {
                x,
                y,
                width,
                height,
                rotationZ: element.rotationZ
            },
            inset,
            calculateCenter
        );
    }

    getBoundingCornersWithOffsetToTopLeft(
        element: IBoundingBox,
        coordinateSystem: CoordinateSystem = 'workspace',
        inset = 0
    ): IBoundingCornersWithOffsetToTopLeft {
        const { x, y, width, height } = this.getBoundingRect(element, coordinateSystem);
        const origin: IPosition = { x: x + width / 2, y: y + height / 2 };
        const topLeft: IPosition = { x: x + inset, y: y + inset };
        const topRight: IPosition = { x: x + width - inset, y: y + inset };
        const bottomLeft: IPosition = { x: x + inset, y: y + height - inset };
        const bottomRight: IPosition = { x: x + width - inset, y: y + height - inset };

        const topLeftRotated = rotatePosition(topLeft, origin, -element.rotationZ!);
        const topRightRotated = rotatePosition(topRight, origin, -element.rotationZ!);
        const bottomLeftRotated = rotatePosition(bottomLeft, origin, -element.rotationZ!);
        const bottomRightRotated = rotatePosition(bottomRight, origin, -element.rotationZ!);

        return {
            topLeft: {
                position: topLeftRotated,
                offset: {
                    x: topLeftRotated.x - topLeft.x,
                    y: topLeftRotated.y - topLeft.y
                }
            },
            topRight: {
                position: topRightRotated,
                offset: {
                    x: topRightRotated.x - topLeft.x,
                    y: topRightRotated.y - topLeft.y
                }
            },
            bottomLeft: {
                position: bottomLeftRotated,
                offset: {
                    x: bottomLeftRotated.x - topLeft.x,
                    y: bottomLeftRotated.y - topLeft.y
                }
            },
            bottomRight: {
                position: bottomRightRotated,
                offset: {
                    x: bottomRightRotated.x - topLeft.x,
                    y: bottomRightRotated.y - topLeft.y
                }
            }
        };
    }

    getBoundingRect(
        element: IBoundingBox | OneOfElementDataNodes,
        coordinateSystem: CoordinateSystem
    ): IBoundingBox {
        if (coordinateSystem !== 'workspace') {
            return element;
        }

        let box = element;

        if (isElementDataNode(element)) {
            box = getBoundingBoxOfElementWithState(element, undefined, true);
        }

        return {
            ...this.getPositionRelativeToWorkspace(box || ({} as IPosition)),
            width: box.width * this.editorStateService.zoom,
            height: box.height * this.editorStateService.zoom
        };
    }

    getPositionRelativeToWorkspace(position: IPosition): IPosition {
        const canvasPosition = this.getCanvasPositionRelativeToWorkspace();

        return {
            x:
                (position.x + canvasPosition.x / this.editorStateService.zoom) *
                this.editorStateService.zoom,
            y:
                (position.y + canvasPosition.y / this.editorStateService.zoom) *
                this.editorStateService.zoom
        };
    }

    getCanvasPositionRelativeToWorkspace(): IPosition {
        const canvasClientPosition = this.canvas.getLocalPosition();
        const workspaceBoundingRect = this.boundingRect;

        return {
            x: canvasClientPosition.x - workspaceBoundingRect.x,
            y: canvasClientPosition.y - workspaceBoundingRect.y
        };
    }

    getTimelinePositionRelativeToWorkspace(): IPosition {
        const canvasClientPosition = this.designView.timeline.getLocalPosition();
        const workspaceBoundingRect = this.boundingRect;

        return {
            x: canvasClientPosition.x - workspaceBoundingRect.x,
            y: canvasClientPosition.y - workspaceBoundingRect.y
        };
    }

    getCanvasPositionFromDocumentPosition(documentPosition: IPosition): IPosition {
        const boundingRect = this.canvas.getBoundingRect();

        return {
            x: documentPosition.x - boundingRect.left,
            y: documentPosition.y - boundingRect.top
        };
    }

    findElementAtPosition(position: IPosition): OneOfElementDataNodes | undefined {
        const { creativeDocument, time_m } = this.renderer;
        const elements = creativeDocument.elements;
        const selection = this.elementSelectionService.currentSelection;

        // Start from the element in the front
        for (let i = elements.length - 1; i >= 0; i--) {
            const dataElement = elements[i];
            const dataElementOrigin = getCenter(dataElement);
            const dataElementRotatedPosition = rotatePosition(
                position,
                dataElementOrigin,
                dataElement.rotationZ
            );

            const viewElement = this.renderer.getViewElementById(dataElement.id);
            if (!viewElement && !selection.has(dataElement)) {
                continue;
            }

            // this part does not take into account scaled down elements in record mode, this might cause some issues because it will detect an element
            // even though it is smaller and not visible at the position
            if (
                isVisibleAtTime(dataElement, time_m) &&
                positionIsInBounds(dataElementRotatedPosition, viewElement ?? dataElement)
            ) {
                return dataElement;
            }
        }
    }

    findAllElementAtPosition(position: IPosition): OneOfElementDataNodes[] {
        const elementsAtPosition: OneOfElementDataNodes[] = [];
        const { creativeDocument, time_m } = this.renderer;
        const elements = creativeDocument.elements;

        for (let i = elements.length - 1; i >= 0; i--) {
            const element = elements[i];
            const origin = getCenter(element);
            const rotatedPosition = rotatePosition(position, origin, element.rotationZ);

            if (
                isSelectionVisibleAtTime(element, time_m) &&
                positionIsInBounds(rotatedPosition, element)
            ) {
                elementsAtPosition.push(element);
            }
        }
        return elementsAtPosition;
    }

    setZoom(zoom: number): void {
        this.canvas.setScale(zoom);
        this.editorStateService.zoom = zoom;
        if (this.gradientHelper.gradient) {
            this.gradientHelper.drawPicker(this.gradientHelper.gradient);
        }
        this.gizmoDrawer.setCanvasSize();
        this.gizmoDrawer.draw();
    }

    centerCanvas = (): void => {
        const bounds = this.host.nativeElement.getBoundingClientRect();
        const element = this.canvas.host.nativeElement;
        const bottomPanelCorrection = this.mutatorService.preview ? 0 : this.timelineHeight / 2;

        if (this.position) {
            element.style.left = '0';
            element.style.top = '0';
            element.style.transform = `translate(${this.position.x - bounds.x}px, ${
                this.position.y - bounds.y
            }px)`;

            this.clearCenterTimeouts();
            this.centerStartTimout = window.setTimeout(() => {
                element.style.transition = 'transform 0.5s cubic-bezier(0.190, 1.000, 0.220, 1.000)';
                element.style.transform = `translate(${Math.round(
                    bounds.width / 2 - this.canvasSize.width / 2
                )}px, ${Math.round(bounds.height / 2 - this.canvasSize.height / 2)}px)`;
            }, 50);

            this.centerStopTimout = window.setTimeout(() => {
                this.gizmoDrawer.drawCreativeBorder = true;
                element.style.transition = 'unset';
                element.style.transform = 'unset';
                this.canvas.setPosition({
                    x: Math.round(bounds.width / 2 - this.canvasSize.width / 2),
                    y: Math.round(
                        bounds.height / 2 - this.canvasSize.height / 2 - bottomPanelCorrection
                    )
                });
                this.gizmoDrawer.draw();
                this.position = undefined;
            }, 605);
        } else {
            this.canvas.host.nativeElement.style.transition = 'unset';
            this.canvas.setPosition({
                x: Math.round(bounds.width / 2 - this.canvasSize.width / 2),
                y: Math.round(bounds.height / 2 - this.canvasSize.height / 2 - bottomPanelCorrection)
            });
            this.gizmoDrawer.drawCreativeBorder = true;
            this.gizmoDrawer.draw();
        }
    };

    removeSelectedElements = (): void => {
        if (this.elementSelectionService.latestSelectionType === 'keyframe') {
            return;
        }
        this.studioWorkspaceService.removeElement(this.elementSelectionService.currentSelection);
        this.deselectAllElements();
    };

    removeLockedElementsFromSelection(): void {
        const selection = this.elementSelectionService.currentSelection;
        if (selection) {
            const unlockedSelectedElements = selection.elements.filter(({ locked }) => !locked);
            this.elementSelectionService.setSelection(...unlockedSelectedElements);
        }
    }

    private clearCenterTimeouts(): void {
        if (this.centerStartTimout) {
            clearInterval(this.centerStartTimout);
            this.centerStartTimout = undefined;
        }
        if (this.centerStopTimout) {
            clearInterval(this.centerStopTimout);
            this.centerStopTimout = undefined;
        }
    }
}
