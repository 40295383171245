import {
    ChangeDetectionStrategy,
    Component,
    HostListener,
    OnDestroy,
    computed,
    inject,
    viewChild
} from '@angular/core';
import { NodeCreatorService } from '../../design-view/services/data-node-creator.service';
import { CreativeConverterService } from '../creative-converter/creative-converter.service';
import { SizeAddCustomInputComponent } from './size-add-custom-input/size-add-custom-input.component';
import { UIDialogComponent, UIModule, UINotificationService } from '@bannerflow/ui';
import { CreativeConverterUploadService } from '../creative-converter/creative-converter-upload.service';
import { PsdImportService } from '../creative-converter/psd/psd-import.service';
import { SizeAddService } from './size-add.service';
import { ISelectableSize, ISizeList } from '@studio/domain/components/size-list.types';
import { CommonModule } from '@angular/common';
import { SizeAddOverviewComponent } from './size-add-overview/size-add-overview.component';
import { SizeListComponent } from './size-list/size-list.component';
import { PsdListComponent } from '../creative-converter/psd/psd-list/psd-list.component';
import { SizeAddPreviewComponent } from './size-add-preview/size-add-preview.component';
import { PsdImportPreviewComponent } from '../creative-converter/psd/psd-import-preview/psd-import-preview.component';
import { SizeAddTabsComponent } from './size-add-tabs/size-add-tabs.component';

enum Tabs {
    SIZE_ADD = 0,
    PSD_IMPORT = 1
}

@Component({
    standalone: true,
    imports: [
        CommonModule,
        UIModule,
        SizeAddCustomInputComponent,
        SizeAddTabsComponent,
        SizeAddOverviewComponent,
        SizeListComponent,
        PsdListComponent,
        SizeAddPreviewComponent,
        PsdImportPreviewComponent
    ],
    selector: 'size-add-dialog',
    templateUrl: './size-add-dialog.component.html',
    styleUrls: ['./size-add-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[attr.data-test-id]': '"size-add-dialog"'
    },
    providers: [
        PsdImportService,
        CreativeConverterUploadService,
        CreativeConverterService,
        NodeCreatorService
    ]
})
export class SizeAddDialogComponent implements OnDestroy {
    private dialog = inject(UIDialogComponent);
    private sizeAddService = inject(SizeAddService);
    private uiNotificationService = inject(UINotificationService);

    private customSizeInputComp = viewChild.required(SizeAddCustomInputComponent);

    addedSizes = computed(() => this.sizeAddService.addedSizes());
    sizeCategories = computed(() => this.sizeAddService.sizeCategories());

    Tabs = Tabs;
    selectedTab: Tabs = Tabs.SIZE_ADD;

    @HostListener('document:keydown.escape') onEscape(): void {
        const isCustomInputFocused = this.customSizeInputComp().isFocused();
        if (!isCustomInputFocused) {
            this.close();
        }
    }

    ngOnDestroy(): void {
        this.uiNotificationService.close();
        this.deselectAllSizes();
    }

    close(): void {
        this.dialog.close();
    }

    selectTab(tab: Tabs): void {
        this.selectedTab = tab;
        this.deselectAllSizes();
    }

    deselectAllSizes(): void {
        this.sizeAddService.deselectAllSizes();
    }

    selectSize(size: ISelectableSize): void {
        this.sizeAddService.selectSize(size);
    }

    selectedSizes(selectedSizes: ISelectableSize[], category: ISizeList): void {
        this.sizeAddService.selectedSizes(selectedSizes, category);
    }

    toggleAllSizes(): void {
        this.sizeAddService.toggleAllSizes();
    }

    addCustomSize(customSize: ISelectableSize): void {
        this.sizeAddService.addCustomSize(customSize);
        this.customSizeInputComp().resetInputs();
    }
}
