import { zodOptional } from '@zod/zod';
import { z } from 'zod';
import { ElementKind } from '../elements';
import { IFontFamily } from '../font-families';
import { OneOfDataNodes } from '../nodes';
import { IBrandLibraryElement } from './brand-library';
import { IVersionProperty } from './version';

export interface IElement {
    id: string;
    type: ElementKind;
    name: string;
    properties: IElementProperty[];
}

export interface IProperty {
    id: string;
    name: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value?: any; // Any for now, too much to fix for it to be a correct type
    label?: string;
}

export interface IElementValues {
    versionProperties: IVersionProperty[];
    defaultVersionProperties: IVersionProperty[];
    fontFamilies: IFontFamily[];
}

export interface IElementPropertyValues extends IElementValues {
    elementProperties: IElementProperty[];
    newDefaultVersionId?: string;
}

export const ZElementProperty = z.object({
    id: z.string(),
    clientId: zodOptional(z.string()),
    label: zodOptional(z.string()),
    versionPropertyId: zodOptional(z.string()),
    name: z.string(),
    value: z.any(),
    unit: z.enum([
        'array',
        'boolean',
        'color',
        'feed',
        'font',
        'id',
        'image',
        'number',
        'object',
        'select',
        'string',
        'text'
    ])
});

export const ZElement = z.object({
    id: z.string(),
    type: z.nativeEnum(ElementKind),
    name: z.string(),
    parentId: zodOptional(z.string()),
    properties: z.array(ZElementProperty)
});

export type ElementPropertyUnit =
    | 'array'
    | 'boolean'
    | 'color'
    | 'feed'
    | 'font'
    | 'id'
    | 'image'
    | 'number'
    | 'object'
    | 'select'
    | 'string'
    | 'text';

export interface IElementProperty extends IProperty {
    // <K extends ElementPropertyKeys | WidgetPropertyKeys> {
    clientId: string;
    versionPropertyId?: string;
    unit?: ElementPropertyUnit;
}

type NewInstance<T extends { id?: string }> = Omit<T, 'id'> & { id?: string };

export interface INewElementProperty extends NewInstance<IElementProperty> {}
export interface INewElement extends NewInstance<IElement> {}
export interface INewBrandLibraryElement extends NewInstance<IBrandLibraryElement> {}

export interface IInitializedNode {
    node: OneOfDataNodes;
    values?: IElementCreationOptions['values'];
    elementProperties?: INewElementProperty[];
    isCopyPasting: boolean;
    isWidgetUpdateFromLibrary: boolean;
}

export interface IElementCreationOptions {
    values?: Omit<IElementPropertyValues, 'fontFamilies'>;
    element?: IElement | IBrandLibraryElement;
}

export interface ITextCreationOptions extends IElementCreationOptions {
    clickedOut?: boolean;
    shouldRescale?: boolean;
}

export interface IWidgetCreationOptions extends IElementCreationOptions {
    skipEmit?: boolean;
    inWidgetEditor?: boolean;
}
