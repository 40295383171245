import { createAction, props } from '@ngrx/store';
import { ApprovalStatus } from '@domain/creativeset/creative';

export const init = createAction('[Filters] Init', props<{ sizeIds: string[] }>());

export const setAllSizes = createAction('[Filters] Set all Sizes', props<{ sizeIds: string[] }>());

export const loadFiltersSuccess = createAction(
    '[Filters] Load Filters Success',
    props<{
        sizes: string[];
        statuses: ApprovalStatus[];
    }>()
);

export const updatedFilters = createAction('[Filters] Updated Filters');

// --- Size
export const addSizesToFilter = createAction('[Filters] Adds Sizes', props<{ sizeIds: string[] }>());

export const removeSizesFromFilter = createAction(
    '[Filters] Removes Sizes',
    props<{ sizeIds: string[] }>()
);

export const setSizesFilter = createAction('[Filters] Set Sizes', props<{ sizeIds: string[] }>());

export const clearAllSizesFilter = createAction('[Filters] Clear all Sizes');

export const showAllSizesFilter = createAction('[Filters] Show all Sizes');

export const toggleAllSizesFilter = createAction('[Filters] Toggle all Sizes');

export const toggleSelectedSizes = createAction('[Filters] Toggle selected Sizes');

// --- Status
export const addStatusesToFilter = createAction(
    '[Filters] Add Statuses',
    props<{ statuses: ApprovalStatus[] }>()
);

export const removeStatusesFromFilter = createAction(
    '[Filters] Remove Statuses',
    props<{ statuses: ApprovalStatus[] }>()
);

export const setStatusesFilter = createAction(
    '[Filters] Set Statuses',
    props<{ statuses: ApprovalStatus[] }>()
);

export const clearAllStatusesFilter = createAction('[Filters] Clear all status');

// --- Version
export const setVersionsFilter = createAction(
    '[Filters] Set Versions',
    props<{ versionIds: string[]; updateOldSelection?: boolean }>()
);

export const addVersionsToFilter = createAction(
    '[Filters] Add Versions',
    props<{ versionIds: string[] }>()
);
export const removeVersionsFromFilter = createAction(
    '[Filters] Remove Versions',
    props<{ versionIds: string[] }>()
);

export const clearVersionsFilter = createAction('[Filters] Clear Versions');

export const selectPreviousVersionSelection = createAction('[Filters] Select Previous Versions');
export const updatePreviousVersionSelection = createAction('[Filters] Update Previous Versions');
export const selectAllVersions = createAction('[Filters] Select All Versions');

// Context menu actions

export const hideOtherSizes = createAction('[Filters] Hide other sizes');
export const hideSelectedSizes = createAction('[Filters] Hide selected sizes');
