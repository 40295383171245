import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApprovalStatus } from '@domain/creativeset/creative';
import { IVersion } from '@domain/creativeset/version';
import * as FiltersActions from './state/filters.actions';
import * as FiltersSelectors from './state/filters.selectors';
import * as VersionSelectors from '../versions/state/versions.selectors';
import { CreativesetDataService } from '../creativeset/creativeset.data.service';
import { map } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FiltersService {
    filtersState$ = this.store.select(FiltersSelectors.getFiltersState);

    sizes$ = this.store.select(FiltersSelectors.getSizesFilter);
    statuses$ = this.store.select(FiltersSelectors.getStatusesFilter);
    allSizes$ = this.store.select(FiltersSelectors.getAllSizes);
    selectedSizes$ = this.store.select(FiltersSelectors.getSelectedSizes);

    creativeSizes$ = this.allSizes$.pipe(map(() => this.creativesetDataService.creativeset.sizes));

    selectedCreativeSizes$ = this.sizes$.pipe(
        map(sizesIds =>
            this.creativesetDataService.creativeset.sizes.filter(size => sizesIds.includes(size.id))
        )
    );

    hasActiveFilters$ = this.store.select(FiltersSelectors.getHasActiveFilters);
    hasSizesFilter$ = this.store.select(FiltersSelectors.getHasSizesFilter);
    hasStatusesFilter$ = this.store.select(FiltersSelectors.getHasStatusesFilter);

    isFilteringAllSizes$ = this.store.select(FiltersSelectors.getIsFilteringAllSizes);
    isFilteringAnySize$ = this.store.select(FiltersSelectors.getIsFilteringAnySize);

    toggleAllTooltipText$ = this.store.select(FiltersSelectors.getToggleAllTooltipText);

    selectedVersionIds$ = this.store.select(FiltersSelectors.getSelectedVersionsIds);
    isShowingMultipleVersions$ = this.store.select(VersionSelectors.getIsShowingMultipleVersions);
    isShowingAllVersions$ = this.store.select(VersionSelectors.getIsShowingAllVersions);

    constructor(
        private readonly store: Store,
        private creativesetDataService: CreativesetDataService
    ) {}

    initFilters(sizeIds: string[]): void {
        this.store.dispatch(FiltersActions.init({ sizeIds }));
    }

    setAllSizes(): void {
        this.store.dispatch(
            FiltersActions.setAllSizes({
                sizeIds: this.creativesetDataService.creativeset.creatives.map(({ size: { id } }) => id)
            })
        );
    }

    // Sizes
    addFilteredSizes(sizeIds: string[]): void {
        this.store.dispatch(FiltersActions.addSizesToFilter({ sizeIds }));
    }

    removeFilteredSizes(sizeIds: string[]): void {
        this.store.dispatch(FiltersActions.removeSizesFromFilter({ sizeIds }));
    }

    setSizeFilter(sizeIds: string[]): void {
        this.store.dispatch(FiltersActions.setSizesFilter({ sizeIds }));
    }

    clearSizesFilter(): void {
        this.store.dispatch(FiltersActions.clearAllSizesFilter());
    }

    toggleSizesFilter(): void {
        this.store.dispatch(FiltersActions.toggleAllSizesFilter());
    }

    toggleSelectedSizes(): void {
        this.store.dispatch(FiltersActions.toggleSelectedSizes());
    }

    // Status
    addFilteredStatuses(statuses: ApprovalStatus[]): void {
        this.store.dispatch(FiltersActions.addStatusesToFilter({ statuses }));
    }

    removeFilteredStatuses(statuses: ApprovalStatus | ApprovalStatus[]): void {
        this.store.dispatch(
            FiltersActions.removeStatusesFromFilter({
                statuses: Array.isArray(statuses) ? statuses : [statuses]
            })
        );
    }

    clearAllStatuses(): void {
        this.store.dispatch(FiltersActions.clearAllStatusesFilter());
    }

    // Version

    selectVersionById(versionId: string, updateOldSelection: boolean = false): void {
        this.store.dispatch(
            FiltersActions.setVersionsFilter({ versionIds: [versionId], updateOldSelection })
        );
    }

    selectVersion(version: IVersion, multiSelect: boolean = false): void {
        if (multiSelect) {
            this.store.dispatch(FiltersActions.addVersionsToFilter({ versionIds: [version.id] }));
        } else {
            this.selectVersionById(version.id);
        }
    }

    deselectVersion(version: IVersion): void {
        this.store.dispatch(FiltersActions.removeVersionsFromFilter({ versionIds: [version.id] }));
    }

    selectAllVersions(): void {
        this.store.dispatch(FiltersActions.selectAllVersions());
    }

    selectPreviousVersionSelection(): void {
        this.store.dispatch(FiltersActions.selectPreviousVersionSelection());
    }

    updatePreviousVersionSelection(): void {
        this.store.dispatch(FiltersActions.updatePreviousVersionSelection());
    }

    // Context menu actions
    hideOthers(): void {
        this.store.dispatch(FiltersActions.hideOtherSizes());
    }

    hideSelected(): void {
        this.store.dispatch(FiltersActions.hideSelectedSizes());
    }
}
