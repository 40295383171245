/** Prefer using factory function */
export interface IMixedProperty<T> {
    value: T;
    isMixed: boolean;
}

export function createMixedProperty<T>(
    value: undefined,
    isMixed?: boolean
): IMixedProperty<T | undefined>;
export function createMixedProperty<T>(
    value?: undefined,
    isMixed?: boolean
): IMixedProperty<T | undefined>;
export function createMixedProperty<T>(value?: T, isMixed?: boolean): IMixedProperty<T | undefined>;
export function createMixedProperty<T>(value: T, isMixed: boolean = false): IMixedProperty<T> {
    return {
        isMixed: isMixed,
        value: value
    };
}
