import type { HttpErrorResponse } from '@angular/common/http';
import {
    CreativesetErrorMessage,
    OneOfCreativeSetProblemDetails,
    OneOfShowcaseProblemDetails,
    ShowcaseErrorMessage
} from '@domain/api/problem-details';
import { ProblemDetails } from '@domain/api/generated/sapi';
import {
    BadRequestResponse,
    ConflictResponse,
    ErrorStatus,
    ForbiddenResponse,
    HttpErrorWithProblemDetails,
    KnownErrorResponse,
    NotFoundResponse
} from './error.types';

function errorIsObject(error: unknown): error is object {
    return Boolean(error) && typeof error === 'object';
}

function isErrorWithStatus(error: unknown): error is object & { status: number } {
    return errorIsObject(error) && 'status' in error && typeof error.status === 'number';
}

export function isBadRequestStatus(status: number): status is ErrorStatus.BadRequest {
    return status === ErrorStatus.BadRequest;
}

export function isForbiddenStatus(status: number): status is ErrorStatus.Forbidden {
    return status === ErrorStatus.Forbidden;
}

export function isNotFoundStatus(status: number): status is ErrorStatus.NotFound {
    return status === ErrorStatus.NotFound;
}

export function isConflictStatus(status: number): status is ErrorStatus.Conflict {
    return status === ErrorStatus.Conflict;
}

export function isBadRequestError(httpError: unknown): httpError is BadRequestResponse {
    return isErrorWithStatus(httpError) && isBadRequestStatus(httpError.status);
}

export function isForbiddenError(httpError: unknown): httpError is ForbiddenResponse {
    return isErrorWithStatus(httpError) && isForbiddenStatus(httpError.status);
}

export function isNotFoundError(httpError: unknown): httpError is NotFoundResponse {
    return isErrorWithStatus(httpError) && isNotFoundStatus(httpError.status);
}

export function isConflictError(httpError: unknown): httpError is ConflictResponse {
    return isErrorWithStatus(httpError) && isConflictStatus(httpError.status);
}

export function isKnownErrorResponse(httpError: unknown): httpError is KnownErrorResponse {
    return (
        isBadRequestError(httpError) ||
        isForbiddenError(httpError) ||
        isNotFoundError(httpError) ||
        isConflictError(httpError)
    );
}

export function isHttpErrorWithProblemDetail(
    httpError: unknown
): httpError is HttpErrorWithProblemDetails {
    return errorIsObject(httpError) && 'error' in httpError && isProblemDetailError(httpError);
}

export function isProblemDetailError(error: unknown): error is ProblemDetails {
    if (
        !error ||
        typeof error !== 'object' ||
        !('errorType' in error) ||
        typeof error.errorType !== 'string'
    ) {
        return false;
    }

    return true;
}

export function isValidShowcaseError(
    errorResponse: HttpErrorResponse
): errorResponse is HttpErrorWithProblemDetails {
    const error = errorResponse.error;
    return isShowcaseProblemDetail(error) && isValidShowcaseStatus(error.status);
}

export function isValidShowcaseStatus(
    status: number
): status is ErrorStatus.BadRequest | ErrorStatus.NotFound {
    return isBadRequestStatus(status) || isNotFoundStatus(status);
}

export function isShowcaseProblemDetail(error: unknown): error is OneOfShowcaseProblemDetails {
    if (!isProblemDetailError(error)) {
        return false;
    }

    return !!Object.keys(ShowcaseErrorMessage).includes(error.errorType);
}

export function isCreativesetProblemDetail(error: unknown): error is OneOfCreativeSetProblemDetails {
    if (!isProblemDetailError(error)) {
        return false;
    }

    return !!Object.keys(CreativesetErrorMessage).includes(error.errorType);
}
