import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UserEffects } from './user.effects';
import * as fromUser from './user.reducer';
import { UserService } from './user.service';

@NgModule({
    imports: [
        StoreModule.forFeature(fromUser.USER_FEATURE_KEY, fromUser.reducer),
        EffectsModule.forFeature([UserEffects])
    ],
    providers: [UserService]
})
export class UserStoreModule {}
