import { IColor } from '@domain/color';
import { IInlineStyledText, IVersionedText } from '@domain/creativeset/version';
import { IFontStyle } from '@domain/font';
import { ITextShadow } from '@domain/style';
import {
    CharacterPropertyKeys,
    ICharacterProperties,
    IStyleIdMap,
    ITextVariable,
    SpanType
} from '@domain/text';
import { deserializePropertyValue, serializePropertyValue } from './property-value-serializer';

interface ISerializedStyleMap {
    documentId: string;
    styleId: string;
}

interface ISerializedTextSpan {
    type: SpanType;
    position: number;
    length: number;
    styleIds: ISerializedStyleMap[];
    variable?: ITextVariable;
}

interface ISerializedInlineStyledTextSpan {
    type: SpanType;
    position: number;
    length: number;
    style: string;
}

interface ISerializedInlineStyledText {
    text: string;
    styles: ISerializedInlineStyledTextSpan[];
}

interface ISerializedVersionedText {
    text: string;
    styles: ISerializedTextSpan[];
}

export function deserializeVersionedText(text: string): IVersionedText {
    let versionedText: ISerializedVersionedText;

    // Try to serialize
    try {
        versionedText = JSON.parse(text) as ISerializedVersionedText;
    } catch (e) {
        const msg = `Could not deserialize text: '${text}'`;

        // JSON is provided but could not parse anyway
        if ((text !== undefined && text.charAt(0) === '{') || text.charAt(0) === '<') {
            throw new Error(msg);
        }
        versionedText = { text: text, styles: [] };
    }

    // JSON.parse on a number is valid and does not go into the catch
    // Happens for widget custom text properties with a numeric value in a string e.g "0.85"
    if (typeof versionedText === 'number') {
        versionedText = { text: text, styles: [] };
    }

    const remappedSpans = versionedText.styles.map(span => {
        const styleIdMap: IStyleIdMap = {};
        for (const style of span.styleIds) {
            styleIdMap[style.documentId] = style.styleId;
        }
        return {
            ...span,
            styleIds: styleIdMap
        };
    });
    return {
        text: versionedText.text,
        styles: remappedSpans
    };
}

export function serializeStyle(style: Partial<ICharacterProperties>): string {
    const styleText: string[] = [];
    for (const property in style) {
        if (property === '__fontFamilyId') {
            continue;
        }
        const value = style[property as CharacterPropertyKeys];
        if (value !== undefined) {
            styleText.push(
                `${property}:${serializePropertyValue(
                    property === 'variable' ? 'feed' : property,
                    value
                )}`
            );
        }
    }
    return styleText.sort().join(';');
}

export function deserializeStyle(text: string): Partial<ICharacterProperties> {
    const properties: [string, string][] = text
        .split(';')
        .filter(p => p.includes(':'))
        .map(p => {
            // Handle links which includes 'http://'.
            const propertyLastIndex = p.indexOf(':');
            return [p.substring(0, propertyLastIndex), p.substring(propertyLastIndex + 1)];
        });
    const style: Partial<ICharacterProperties> = {};
    for (const property of properties) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (style as any)[property[0]] = deserializePropertyValue(
            property[0] === 'variable' ? 'feed' : property[0],
            property[1]
        );
    }
    return style;
}

export function deserializeElementStyle(element: HTMLElement): Partial<ICharacterProperties> {
    const propertiesToUse = element.dataset.studioValues ? element.dataset.studioValues.split(' ') : [];

    const style: Partial<ICharacterProperties> = {};
    for (const property of propertiesToUse) {
        switch (property as CharacterPropertyKeys) {
            case 'font':
                style.font = deserializePropertyValue('font', element.dataset.font) as IFontStyle;
                break;
            case 'textColor':
                style.textColor = deserializePropertyValue('color', element.style.color) as IColor;
                break;
            case 'fontSize':
                style.fontSize = deserializePropertyValue('number', element.style.fontSize) as number;
                break;
            case 'strikethrough':
            case 'underline':
                if (element.style.textDecoration.indexOf('line-through') > -1) {
                    style.strikethrough = true;
                }
                if (element.style.textDecoration.indexOf('underline') > -1) {
                    style.underline = true;
                }
                break;
            case 'uppercase':
                style.uppercase = element.style.textTransform.indexOf('uppercase') > -1;
                break;
            case 'characterSpacing':
                style.characterSpacing = deserializePropertyValue(
                    'number',
                    element.style.letterSpacing
                ) as number;
                break;
            case 'lineHeight':
                style.lineHeight = deserializePropertyValue(
                    'number',
                    element.style.lineHeight
                ) as number;
                break;
            case 'textShadows':
                style.textShadows = deserializePropertyValue(
                    'textShadows',
                    element.style.textShadow
                ) as ITextShadow[];
                break;
            case 'variable':
                style.variable = deserializePropertyValue(
                    'feed',
                    element.dataset.variable
                ) as ITextVariable;
        }
    }
    return style;
}

export function serializeInlineStyledText(text: IInlineStyledText): string {
    const spans: ISerializedInlineStyledTextSpan[] = [];
    for (const span of text.styles) {
        if (span.type === SpanType.End) {
            break;
        }
        spans.push({
            type: span.type,
            length: span.length,
            position: span.position,
            style: serializeStyle(span.style)
        });
    }
    return JSON.stringify({ text: text.text, styles: spans } as ISerializedInlineStyledText);
}

export function serializeVersionedText(text: IVersionedText): string {
    const spans: ISerializedTextSpan[] = [];
    for (const span of text.styles) {
        if (span.type === SpanType.End) {
            break;
        }
        if (span.type === SpanType.Composition) {
            continue;
        }
        const styleIds: ISerializedStyleMap[] = [];
        for (const [documentId, styleId] of Object.entries(span.styleIds)) {
            styleIds.push({ documentId, styleId });
        }

        let variable: ITextVariable | undefined;
        if (span.type === SpanType.Variable && span.variable) {
            variable = { ...span.variable, type: 'text' };
        }
        spans.push({
            type: span.type,
            length: span.length,
            position: span.position,
            styleIds: styleIds.sort((a, b) => {
                if (a.documentId > b.documentId) {
                    return 1;
                } else if (a.documentId < b.documentId) {
                    return -1;
                } else if (a.styleId > b.styleId) {
                    return 1;
                } else if (a.styleId < b.styleId) {
                    return -1;
                }
                throw new Error('StyleId is not unique');
            }),
            variable
        });
    }
    return JSON.stringify({ text: text.text, styles: spans });
}

export function deserializeInlineStyledText(text: string): IInlineStyledText {
    const inlineStyledText = JSON.parse(text) as ISerializedInlineStyledText;
    const remappedSpans = inlineStyledText.styles.map(span => ({
        ...span,
        style: deserializeStyle(span.style)
    }));
    return {
        text: inlineStyledText.text,
        styles: remappedSpans
    };
}
