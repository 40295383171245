import { ISize } from '@domain/dimension';
import { AtLeastOne } from '@studio/domain/utils/at-least-one';

export type OutpaintSettings = AtLeastOne<{
    up: number;
    down: number;
    right: number;
    left: number;
}>;

export enum OutpaintInputMode {
    Joint = 'joint',
    Separate = 'separate'
}

export interface IScaledSize extends ISize {
    scale: number;
}
export interface OutpaintUnderlay extends ISize, OutpaintSettings {}
