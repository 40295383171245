import { getSpansFromNewValue } from '@app/translation-page/utils/span.utils';
import { isVersionedText } from '@creative/elements/rich-text/text-nodes';
import { isWidgetElement } from '@creative/nodes';
import { ICreative } from '@domain/creativeset/creative';
import { IVersion } from '@domain/creativeset/version';

// TranslationPanel doesn't update the styles array when updating widgets
// This is a soft migration for all widgets when the user triggers a save
export function fixWidgetVersionProperties(versions: IVersion[], creatives: ICreative[]): IVersion[] {
    for (const version of versions) {
        version.properties = version.properties.map(property => {
            if (!isVersionedText(property) || !isWidgetVersionProperty(property.id, creatives)) {
                return property;
            }
            const newStylesArray = getSpansFromNewValue(property.value.text, '', []);
            return {
                ...property,
                value: {
                    ...property.value,
                    styles: newStylesArray
                }
            };
        });
    }

    return versions;
}

function isWidgetVersionProperty(versionPropertyId: string, creatives: ICreative[]): boolean {
    for (const creative of creatives) {
        for (const element of creative.design?.elements ?? []) {
            if (!isWidgetElement(element)) {
                continue;
            }
            if (
                element.properties.some(
                    elementProperty => versionPropertyId === elementProperty.versionPropertyId
                )
            ) {
                return true;
            }
        }
    }
    return false;
}
