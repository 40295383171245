import { NgModule } from '@angular/core';
import { UserSettingsService } from './user-settings.service';
import { StoreModule } from '@ngrx/store';
import * as fromUserSettings from './user-settings.reducer';

@NgModule({
    imports: [
        StoreModule.forFeature(fromUserSettings.USER_SETTINGS_FEATURE_KEY, fromUserSettings.reducer)
    ],
    providers: [UserSettingsService]
})
export class UserSettingsStoreModule {}
