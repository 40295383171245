import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SessionStorageService {
    getItem(key: string): string[] | null {
        return this.parseItem(sessionStorage.getItem(key) || '[]') || [];
    }

    parseItem(item: string): string[] | null {
        try {
            return JSON.parse(item);
        } catch (e) {
            return [];
        }
    }

    setSessionStorageItem(key: string, value: string): void {
        sessionStorage.removeItem(key);
        sessionStorage.setItem(key, value);
    }
}
