import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TileSelectService } from '../../../pages/manage-view/services/tile-select.service';
import { CreativesetDataService } from '../../creativeset/creativeset.data.service';
import { getFiltersFromQueryParams } from '../filters.utils';
import { VersionsActions } from '../../versions/state/versions.actions';
import { VersionsService } from '../../versions/state/versions.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { of } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { FiltersService } from '../filters.service';
import * as FiltersActions from './filters.actions';
import { ApprovalStatus } from '@domain/creativeset/creative';
import { FiltersState } from './filters.reducer';

@Injectable()
export class FiltersEffects {
    initFilters$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FiltersActions.init),
            concatLatestFrom(() => this.activatedRoute.queryParams),
            switchMap(([_, queryParams]) => {
                const filters = getFiltersFromQueryParams(queryParams);
                filters.sizes = this.filterAvailableSizes(filters.sizes);
                filters.statuses = this.filterAvailableStatuses(filters.statuses);
                return of(FiltersActions.loadFiltersSuccess(filters));
            })
        );
    });

    // Dispatch updatedFilters
    loadFilterSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                FiltersActions.loadFiltersSuccess,
                // Size
                FiltersActions.addSizesToFilter,
                FiltersActions.removeSizesFromFilter,
                FiltersActions.setSizesFilter,
                FiltersActions.clearAllSizesFilter,
                FiltersActions.showAllSizesFilter,
                // Status
                FiltersActions.addStatusesToFilter,
                FiltersActions.removeStatusesFromFilter,
                FiltersActions.setStatusesFilter,
                FiltersActions.clearAllStatusesFilter
            ),
            switchMap(() => of(FiltersActions.updatedFilters()))
        );
    });

    initializeVersionsSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(VersionsActions.initSuccess),
            switchMap(({ versions, selectedVersionsIds, defaultVersion }) => {
                if (selectedVersionsIds.includes('all')) {
                    return of(FiltersActions.selectAllVersions());
                }

                const selectableVersionsIds = selectedVersionsIds.filter(id =>
                    versions.some(version => version.id === id)
                );

                let versionIds = [defaultVersion.id];
                if (selectableVersionsIds.length) {
                    versionIds = Array.from(new Set(selectableVersionsIds));
                }
                return of(
                    FiltersActions.setVersionsFilter({
                        versionIds
                    })
                );
            })
        );
    });

    selectAllVersions$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FiltersActions.selectAllVersions),
            concatLatestFrom(() => this.versionsService.selectableVersions$),
            switchMap(([_, selectableVersions]) => {
                return of(
                    FiltersActions.setVersionsFilter({
                        versionIds: selectableVersions.map(({ id }) => id)
                    })
                );
            })
        );
    });

    saveNewVersion$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(VersionsActions.createVersionSuccess),
            switchMap(({ versions }) => {
                return of(
                    FiltersActions.setVersionsFilter({
                        versionIds: [versions[0].id]
                    })
                );
            })
        );
    });

    deleteVersions$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(VersionsActions.deleteVersionsSuccess),
            concatLatestFrom(() => [
                this.versionsService.defaultVersion$,
                this.filtersService.selectedVersionIds$
            ]),
            switchMap(([{ versionsIds }, defaultVersion, selectedVersionIds]) => {
                const newSelectedIds = selectedVersionIds.filter(id => !versionsIds.includes(id));
                const newVersionIds = newSelectedIds.length ? newSelectedIds : [defaultVersion.id];
                return of(
                    FiltersActions.setVersionsFilter({
                        versionIds: newVersionIds
                    })
                );
            })
        );
    });

    // Context menu actions
    hideOthers$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FiltersActions.hideOtherSizes),
            concatLatestFrom(() => this.tileSelectService.selection$),
            switchMap(([_, selectedCreatives]) => {
                const selectedSizes = selectedCreatives.map(({ size }) => size.id);
                return of(FiltersActions.setSizesFilter({ sizeIds: selectedSizes }));
            })
        );
    });

    hideSelectedSizes$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FiltersActions.hideSelectedSizes),
            concatLatestFrom(() => this.tileSelectService.selection$),
            concatLatestFrom(() => this.filtersService.allSizes$),
            switchMap(([[_, selectedCreatives], allSizeIds]) => {
                const selectedSizes = selectedCreatives.map(({ size }) => size.id);
                const newFilteredSizeIds = allSizeIds.filter(sizeId => !selectedSizes.includes(sizeId));
                return of(FiltersActions.setSizesFilter({ sizeIds: newFilteredSizeIds }));
            })
        );
    });

    // Sizes
    toggleAllSizes$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FiltersActions.toggleAllSizesFilter),
            concatLatestFrom(() => this.filtersService.isFilteringAnySize$),
            switchMap(([_, isFilteringAnySize]) =>
                isFilteringAnySize
                    ? of(FiltersActions.clearAllSizesFilter())
                    : of(FiltersActions.showAllSizesFilter())
            )
        );
    });

    toggleSelectedSizes$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FiltersActions.toggleSelectedSizes),
            concatLatestFrom(() => this.filtersService.isFilteringAnySize$),
            switchMap(([_, isFilteringAnySize]) => {
                return isFilteringAnySize
                    ? of(FiltersActions.clearAllSizesFilter())
                    : of(FiltersActions.hideOtherSizes());
            })
        );
    });

    updateFiltersQueryParams$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(
                    FiltersActions.updatedFilters,
                    FiltersActions.selectAllVersions,
                    FiltersActions.selectPreviousVersionSelection,
                    FiltersActions.setVersionsFilter,
                    FiltersActions.addVersionsToFilter
                ),
                filter(() => !this.router.getCurrentNavigation()),
                concatLatestFrom(() => [
                    this.filtersService.filtersState$,
                    this.filtersService.isShowingAllVersions$,
                    this.activatedRoute.queryParams
                ]),
                tap(([_, filtersState, isShowingAllVersions, queryParams]) => {
                    this.updateFiltersQueryParams(filtersState, isShowingAllVersions, queryParams);
                })
            );
        },
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private filtersService: FiltersService,
        private tileSelectService: TileSelectService,
        private versionsService: VersionsService,
        private creativesetDataService: CreativesetDataService
    ) {}

    private filterAvailableSizes(sizeIds: string[]): string[] {
        return sizeIds.filter(sizeId =>
            this.creativesetDataService.creativeset.sizes.some(({ id }) => id === sizeId)
        );
    }

    private filterAvailableStatuses(statuses: ApprovalStatus[]): ApprovalStatus[] {
        return statuses.filter(status => Object.values(ApprovalStatus).includes(status));
    }

    private updateFiltersQueryParams(
        { sizes, statuses, versions }: FiltersState,
        isShowingAllVersions: boolean,
        queryParams: Params
    ): void {
        const size = sizes.length ? sizes.join(',') : undefined;
        const status = statuses.length ? statuses.join(',') : undefined;
        const version = isShowingAllVersions ? 'all' : versions.join(',');

        const newQueryParams = {
            ...queryParams,
            size,
            status,
            version: version !== queryParams['version'] ? version : queryParams['version']
        };

        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: newQueryParams,
            queryParamsHandling: 'merge',
            replaceUrl: true
        });
    }
}
