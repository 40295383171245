import { parseColor } from '@creative/color.utils';
import { deserializeFeedString } from '@creative/serialization/property-value-serializer';
import { deserializeVersionedText } from '@creative/serialization/text-serializer';
import { WidgetFontStyleDto } from '@domain/api/generated/sapi';
import { IFontStyle } from '@domain/font';
import { IWidgetElementProperty, IWidgetImage, OneOfCustomPropertyValue } from '@domain/widget';
import { isUrl } from '@studio/utils/url';
import { clamp } from '@studio/utils/utils';

/** Deserialize widget element properties. Mainly needed for Brandlibrary elements */
export function deserializeWidgetProperty(property: IWidgetElementProperty): OneOfCustomPropertyValue {
    const { unit, value } = property;

    switch (unit) {
        case 'text':
            return deserializeVersionedText(value as string);
        case 'number': {
            return clamp(Number(value), -9999999, 9999999);
        }
        case 'boolean': {
            if (typeof value === 'string') {
                return value.toLowerCase() === 'true';
            }
            if (typeof value === 'boolean') {
                return value as boolean;
            }

            throw new Error(`Could not deserialize property with unit ${unit}`);
        }
        case 'select':
            return typeof window !== 'undefined'
                ? JSON.parse(atob(value as string))
                : JSON.parse(Buffer.from(value as string, 'base64').toString());
        case 'color': {
            if (typeof value === 'string') {
                return parseColor(value);
            }

            throw new Error(`Could not deserialize property with unit ${unit}`);
        }
        case 'image': {
            const splits = (value as string).split(' '); // TODO: clean this up. It shouldn't be a string
            return { id: splits[0], src: splits[1] } satisfies IWidgetImage;
        }
        case 'font': {
            const splits = (value as string).split(' '); // TODO: clean this up. It shouldn't be a string

            let src = splits[3];

            try {
                // Support old data
                if (!isUrl(src)) {
                    if (typeof window !== 'undefined') {
                        src = atob(splits[3]);
                    } else {
                        src = Buffer.from(splits[3], 'base64').toString();
                    }
                }
            } catch {
                // eslint-disable-next-line no-empty
            }

            const style = (splits[2] as WidgetFontStyleDto['style']) ?? 'normal';
            return {
                id: splits[0],
                weight: parseInt(splits[1], 10) as 100,
                style,
                src,
                fontFamilyId: splits[4]
            } satisfies IFontStyle;
        }
        case 'feed': {
            return deserializeFeedString(value);
        }
    }
}
