<div
    class="dropdown-trigger"
    #menuTrigger="uiDropdownTarget"
    [uiDropdownTarget]="menu"
    [hasBackdrop]="false"
    (dropdownClosed)="contextMenu.contextMenuClosed()"></div>

<ui-dropdown
    #menu
    type="menu"
    [width]="'240'"
    [minWidth]="240">
    <ui-dropdown-item
        class="custom-dropdown"
        (click)="cut()">
        <div class="custom-item">
            <div class="custom-column"></div>
            <div class="custom-text">Cut</div>
            <div [class]="contextMenu.isMac ? 'mac custom-action' : 'custom-action'">
                {{ keyboardShortcuts.Workspace.Cut }}
            </div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-item
        class="custom-dropdown"
        data-cy="copy-selection"
        (click)="copy()">
        <div class="custom-item">
            <div class="custom-column"></div>
            <div class="custom-text">Copy</div>
            <div [class]="contextMenu.isMac ? 'mac custom-action' : 'custom-action'">
                {{ keyboardShortcuts.Workspace.Copy }}
            </div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-item
        class="custom-dropdown"
        #pasteSectionElement
        data-cy="paste-selection"
        [uiDropdownTarget]="pasteSection"
        (dropdownOpened)="contextMenu.openSubmenu(pasteSectionElement)"
        (dropdownClosed)="contextMenu.leaveSubmenu(pasteSectionElement)">
        <div class="custom-item">
            <div class="custom-column"></div>
            <div class="custom-text">Paste</div>
            <div class="custom-action"></div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-item
        class="custom-dropdown"
        data-test-id="duplicate-creative-item"
        (click)="duplicate()">
        <div class="custom-item">
            <div class="custom-column"></div>
            <div class="custom-text">Duplicate</div>
            <div [class]="contextMenu.isMac ? 'mac custom-action' : 'custom-action'"></div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-item
        id="interaction-detach-from-other-sizes"
        class="custom-dropdown"
        data-test-id="detach-creative-item"
        (click)="detach()"
        [disabled]="!isDetachable">
        <div class="custom-item">
            <div class="custom-column"></div>
            <div class="custom-text">Detach from other sizes</div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-item
        class="custom-dropdown"
        *ngIf="selectionCanBeReplaced"
        [uiDropdownTarget]="replaceDropdown.dropdown">
        <div class="custom-item">
            <div class="custom-column"></div>
            <div class="custom-text">Replace {{ selectionElementsType }}</div>
        </div>
    </ui-dropdown-item>
    <ng-container *permissions="'StudioGenAiImage'">
        @if (isImageSupportedByAIStudio() && (isBrandLibraryCompatible$ | async) && !selectionIsVideo) {
            @let _aiStudioState = aiStudioState();

            @let supported = _aiStudioState.supported;
            @let disabledReason = _aiStudioState.message;
            @let tooltipDisabled = supported || !disabledReason;

            <ui-dropdown-item
                id="interaction-open-ai-studio-right-click"
                class="custom-dropdown"
                [disabled]="!supported"
                [uiTooltip]="disabledReason"
                [uiTooltipDisabled]="tooltipDisabled"
                [uiTooltipPosition]="'right'"
                (click)="openInAIStudio()">
                <div class="custom-item">
                    <ui-svg-icon
                        class="custom-column icon"
                        icon="ai_stars" />
                    <div class="custom-text">Open in AI Studio</div>
                </div>
            </ui-dropdown-item>
        }
    </ng-container>

    <ui-dropdown-item
        class="custom-dropdown"
        (click)="fillElementsToCanvas()"
        [disabled]="selectionHasLockedElements"
        id="fill-to-canvas">
        <div class="custom-item">
            <div class="custom-column"></div>
            <div class="custom-text">Fill to canvas</div>
            <div class="custom-action"></div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-item
        class="custom-dropdown"
        [disabled]="hasAnimation()"
        (click)="addAnimation()">
        <div class="custom-item">
            <div class="custom-column"></div>
            <div class="custom-text">Add animation</div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-divider></ui-dropdown-divider>
    <ui-dropdown-item
        id="menu-group-toggle"
        class="custom-dropdown"
        [disabled]="!canCreateGroup"
        (click)="group()">
        <div class="custom-item">
            <div class="custom-column"></div>
            <div class="custom-text">Group</div>
            <div class="custom-action">{{ keyboardShortcuts.Workspace.GroupElements }}</div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-item
        *ngIf="selectionIsGroup"
        id="menu-ungroup-toggle"
        class="custom-dropdown"
        [disabled]="!canCreateGroup && !selectionIsGroup"
        (click)="ungroup()">
        <div class="custom-item">
            <div class="custom-column"></div>
            <div class="custom-text">Ungroup</div>
            <div class="custom-action">{{ keyboardShortcuts.Workspace.UngroupElements }}</div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-item
        class="custom-dropdown"
        #moveToGroupElement
        data-cy="move-to-group"
        [uiDropdownTarget]="moveToGroupMenu"
        [disabled]="!creativeGroups.length"
        (dropdownOpened)="contextMenu.openSubmenu(moveToGroupElement)"
        (dropdownClosed)="contextMenu.leaveSubmenu(moveToGroupElement)">
        <div class="custom-item">
            <div class="custom-column"></div>
            <div class="custom-text">Move to Group</div>
            <div class="custom-action"></div>
        </div>
    </ui-dropdown-item>
    @if (isMasked) {
        <ui-dropdown-item
            data-test-id="remove-masking"
            class="custom-dropdown"
            (click)="removeMasking()">
            <div class="custom-item">
                <div class="custom-column"></div>
                <div class="custom-text">Remove mask</div>
                <div class="custom-action"></div>
            </div>
        </ui-dropdown-item>
    } @else {
        <ui-dropdown-item
            class="custom-dropdown"
            data-test-id="add-masking"
            [disabled]="!canBeMasked"
            (click)="maskElements()">
            <div class="custom-item">
                <div class="custom-column"></div>
                <div class="custom-text">Use as mask</div>
                <div class="custom-action"></div>
            </div>
        </ui-dropdown-item>
    }
    <ui-dropdown-divider></ui-dropdown-divider>
    <ui-dropdown-item
        class="custom-dropdown"
        (click)="toggleLock()">
        <div class="custom-item">
            <ui-svg-icon
                class="custom-column icon"
                [icon]="isSelectionLocked ? 'lock-open' : 'lock-closed'"></ui-svg-icon>
            <div class="custom-text">{{ isSelectionLocked ? 'Unlock' : 'Lock' }}</div>
            <div class="custom-action">{{ keyboardShortcuts.Workspace.ToggleLock }}</div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-item
        class="custom-dropdown"
        (click)="toggleVisibility()"
        data-test-id="toggle-element-visibility">
        <div class="custom-item">
            <ui-svg-icon
                class="custom-column icon"
                [icon]="isAllSelectionsHidden ? 'visibility-hidden' : 'visibility-visible'">
            </ui-svg-icon>
            <div class="custom-text">{{ isAllSelectionsHidden ? 'Unhide' : 'Hide' }}</div>
            <div class="custom-action">{{ keyboardShortcuts.Workspace.ToggleVisibility }}</div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-divider></ui-dropdown-divider>
    <ui-dropdown-item
        class="custom-dropdown"
        data-test-id="delete-element"
        data-cy="delete-element"
        (click)="deleteElement()">
        <div class="custom-item">
            <ui-svg-icon
                class="custom-column icon"
                icon="delete"></ui-svg-icon>
            <div class="custom-text">Delete</div>
            <div class="custom-action">{{ keyboardShortcuts.Workspace.DeleteElement }}</div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-divider></ui-dropdown-divider>
    <ui-dropdown-item
        class="custom-dropdown"
        #arrangeSectionElement
        data-cy="arrange-options"
        [uiDropdownTarget]="arrangeSection"
        (dropdownOpened)="contextMenu.openSubmenu(arrangeSectionElement)"
        (dropdownClosed)="contextMenu.leaveSubmenu(arrangeSectionElement)">
        <div class="custom-item">
            <div class="custom-column"></div>
            <div class="custom-text">Arrange</div>
            <div class="custom-action"></div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-divider></ui-dropdown-divider>
    <ui-dropdown-item
        class="custom-dropdown"
        *ngIf="isBrandLibraryCompatible$ | async"
        #brandLibraryMenuTrigger
        [uiDropdownTarget]="brandLibraryMenu"
        data-cy="brand-library-options"
        (dropdownOpened)="contextMenu.openSubmenu(brandLibraryMenuTrigger)"
        (dropdownClosed)="contextMenu.leaveSubmenu(brandLibraryMenuTrigger)">
        <div class="custom-item">
            <div class="custom-column"></div>
            <div class="custom-text">Brand library</div>
            <div class="custom-action"></div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-item class="custom-dropdown transform">
        <div class="custom-item transform">
            <div class="custom-column"></div>
            <div class="custom-text">{{ transformHint }}</div>
            <div
                class="transform-action first"
                appPreviewDirective
                (preview)="rotate(-90)"
                (revert)="revertRotate(-90)"
                (apply)="applyTransform(-90)">
                <ui-svg-icon
                    class="action-icon"
                    icon="rotate-left"></ui-svg-icon>
            </div>
            <div
                class="transform-action"
                appPreviewDirective
                (preview)="rotate(90)"
                (revert)="revertRotate(90)"
                (apply)="applyTransform(90)">
                <ui-svg-icon
                    class="action-icon"
                    icon="rotate-right"></ui-svg-icon>
            </div>
            <div
                class="transform-action"
                appPreviewDirective
                (preview)="flip('mirrorX')"
                (revert)="revertFlip('mirrorX')"
                (apply)="applyTransform('mirrorX')">
                <ui-svg-icon
                    class="action-icon"
                    icon="flip-h"></ui-svg-icon>
            </div>
            <div
                class="transform-action last"
                appPreviewDirective
                (preview)="flip('mirrorY')"
                (revert)="revertFlip('mirrorY')"
                (apply)="applyTransform('mirrorY')">
                <ui-svg-icon
                    class="action-icon"
                    icon="flip-v"></ui-svg-icon>
            </div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-divider></ui-dropdown-divider>
    <ui-dropdown-item
        class="custom-dropdown"
        #alignSectionElement
        [uiDropdownTarget]="alignSection"
        (dropdownOpened)="contextMenu.openSubmenu(alignSectionElement)"
        (dropdownClosed)="contextMenu.leaveSubmenu(alignSectionElement)">
        <div class="custom-item">
            <div class="custom-column"></div>
            <div class="custom-text">Align & Distribution</div>
            <div class="custom-action"></div>
        </div>
    </ui-dropdown-item>
</ui-dropdown>
<ui-dropdown
    type="menu"
    #alignSection
    [minWidth]="240"
    [width]="'240'">
    <ui-dropdown-item
        class="custom-dropdown"
        (click)="alignSelection(ElementAlign.Left)">
        <div class="custom-item">
            <ui-svg-icon
                class="custom-column icon"
                icon="align-left"></ui-svg-icon>
            <div class="custom-text">Align left</div>
            <div class="custom-action">{{ keyboardShortcuts.Editor.AlignLeft }}</div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-item
        class="custom-dropdown"
        (click)="alignSelection(ElementAlign.Center)">
        <div class="custom-item">
            <ui-svg-icon
                class="custom-column icon"
                icon="align-center"></ui-svg-icon>
            <div class="custom-text">Align center</div>
            <div class="custom-action">{{ keyboardShortcuts.Editor.AlignCenter }}</div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-item
        class="custom-dropdown"
        (click)="alignSelection(ElementAlign.Right)">
        <div class="custom-item">
            <ui-svg-icon
                class="custom-column icon"
                icon="align-right"></ui-svg-icon>
            <div class="custom-text">Align right</div>
            <div class="custom-action">{{ keyboardShortcuts.Editor.AlignRight }}</div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-item
        class="custom-dropdown"
        (click)="distributeSelection(ElementDistribution.Horizontal)">
        <div class="custom-item">
            <ui-svg-icon
                class="custom-column icon"
                icon="distribute-horisontally"></ui-svg-icon>
            <div class="custom-text">Distribute horizontally</div>
            <div class="custom-action">{{ keyboardShortcuts.Editor.DistributeHorizontally }}</div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-divider></ui-dropdown-divider>
    <ui-dropdown-item
        class="custom-dropdown"
        (click)="alignSelection(ElementAlign.Top)">
        <div class="custom-item">
            <ui-svg-icon
                class="custom-column icon"
                icon="align-top"></ui-svg-icon>
            <div class="custom-text">Align top</div>
            <div class="custom-action">{{ keyboardShortcuts.Editor.AlignTop }}</div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-item
        class="custom-dropdown"
        (click)="alignSelection(ElementAlign.Middle)">
        <div class="custom-item">
            <ui-svg-icon
                class="custom-column icon"
                icon="align-middle"></ui-svg-icon>
            <div class="custom-text">Align middle</div>
            <div class="custom-action">{{ keyboardShortcuts.Editor.AlignMiddle }}</div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-item
        class="custom-dropdown"
        (click)="alignSelection(ElementAlign.Bottom)">
        <div class="custom-item">
            <ui-svg-icon
                class="custom-column icon"
                icon="align-bottom"></ui-svg-icon>
            <div class="custom-text">Align bottom</div>
            <div class="custom-action">{{ keyboardShortcuts.Workspace.AlignBottom }}</div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-item
        class="custom-dropdown"
        (click)="distributeSelection(ElementDistribution.Vertical)">
        <div class="custom-item">
            <ui-svg-icon
                class="custom-column icon"
                icon="distribute-vertically"></ui-svg-icon>
            <div class="custom-text">Distribute vertically</div>
            <div class="custom-action">{{ keyboardShortcuts.Editor.DistributeVertically }}</div>
        </div>
    </ui-dropdown-item>
</ui-dropdown>

<ui-dropdown
    type="menu"
    #arrangeSection
    [width]="'200'"
    [minWidth]="200"
    [useTargetWidth]="false">
    <ui-dropdown-item
        class="custom-dropdown"
        data-cy="arrange-front"
        [disabled]="!canMoveForward()"
        (click)="arrange(ElementArrange.Front)">
        <div class="custom-item">
            <div></div>
            <div class="custom-text">To front</div>
            <div class="custom-action">{{ keyboardShortcuts.Timeline.MoveElementFront }}</div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-item
        class="custom-dropdown"
        data-cy="arrange-forward"
        [disabled]="!canMoveForward()"
        (click)="arrange(ElementArrange.Forward)">
        <div class="custom-item">
            <div></div>
            <div class="custom-text">Forward</div>
            <div class="custom-action">{{ keyboardShortcuts.Timeline.MoveElementUp }}</div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-item
        class="custom-dropdown"
        data-cy="arrange-backward"
        [disabled]="!canMoveBackward()"
        (click)="arrange(ElementArrange.Backward)">
        <div class="custom-item">
            <div></div>
            <div class="custom-text">Backward</div>
            <div class="custom-action">{{ keyboardShortcuts.Timeline.MoveElementDown }}</div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-item
        class="custom-dropdown"
        data-cy="arrange-back"
        [disabled]="!canMoveBackward()"
        (click)="arrange(ElementArrange.Back)">
        <div class="custom-item">
            <div></div>
            <div class="custom-text">To Back</div>
            <div class="custom-action">{{ keyboardShortcuts.Timeline.MoveElementBack }}</div>
        </div>
    </ui-dropdown-item>
</ui-dropdown>

<ui-dropdown
    #pasteSection
    [useTargetWidth]="false"
    [width]="'240'"
    [minWidth]="240"
    type="menu">
    <ui-dropdown-item
        class="custom-dropdown"
        [disabled]="!designView.copiedSnapshot"
        (click)="paste()">
        <div class="custom-item">
            <div class="custom-column"></div>
            <div class="custom-text">Paste</div>
            <div [class]="contextMenu.isMac ? 'mac custom-action' : 'custom-action'">
                {{ keyboardShortcuts.Editor.Paste }}
            </div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-item
        class="custom-dropdown"
        [disabled]="!selectionLength()"
        (click)="pasteStyle()">
        <div class="custom-item">
            <div class="custom-column"></div>
            <div class="custom-text">Paste style</div>
            <div [class]="contextMenu.isMac ? 'mac custom-action' : 'custom-action'">
                {{ keyboardShortcuts.Editor.PasteStyle }}
            </div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-item
        class="custom-dropdown"
        (click)="pasteLayout()"
        [disabled]="!canPasteLayout()">
        <div class="custom-item">
            <div class="custom-column"></div>
            <div class="custom-text">Paste position & size</div>
            <div [class]="contextMenu.isMac ? 'mac custom-action' : 'custom-action'">
                {{ keyboardShortcuts.Editor.PasteLayout }}
            </div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-item
        class="custom-dropdown"
        (click)="pasteAnimations()"
        [disabled]="!canPasteAnimations()">
        <div class="custom-item">
            <div class="custom-column"></div>
            <div class="custom-text">Paste animations</div>
            <div [class]="contextMenu.isMac ? 'mac custom-action' : 'custom-action'">
                {{ keyboardShortcuts.Editor.PasteAnimation }}
            </div>
        </div>
    </ui-dropdown-item>
</ui-dropdown>

<ui-dropdown
    #brandLibraryMenu
    [minWidth]="240"
    [width]="'240'"
    type="menu">
    <ui-dropdown-item
        *ngIf="isBrandLibraryElement$ | async"
        class="custom-dropdown"
        data-cy="find-in-brandlibrary"
        (click)="findInBrandLibrary()"
        [id]="
            selectionIsWidget
                ? 'interaction-ctx-menu-widget-find-in-library'
                : 'interaction-ctx-menu-find-in-library'
        ">
        <div class="custom-item">
            <div class="custom-column"></div>
            <div class="custom-text">Find in brand library</div>
            <div class="custom-action">{{ keyboardShortcuts.Workspace.FindInLibrary }}</div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-divider *ngIf="isBrandLibraryElement$ | async"></ui-dropdown-divider>
    <ui-dropdown-item
        *ngIf="showAddToBrandLibrary$ | async"
        id="interaction-add-to-bl"
        data-cy="add-to-brandlibrary"
        class="custom-dropdown"
        [disabled]="disableBrandLibraryUpdate"
        [ngClass]="disableBrandLibraryUpdate ? 'disabled-with-tooltip' : ''"
        [uiTooltip]="'This action requires the element to be visible and not used in masking.'"
        [uiTooltipPosition]="'right'"
        [uiTooltipDisabled]="!disableBrandLibraryUpdate"
        (click)="uploadToMediaLibrary(false)">
        <div class="custom-item">
            <div class="custom-column"></div>
            <div class="custom-text">Add to brand library</div>
            <div [class]="contextMenu.isMac ? 'mac custom-action' : 'custom-action'"></div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-item
        *ngIf="showUpdateWidgetFromLibrary$ | async"
        id="interaction-ctx-menu-update-widget-library"
        class="custom-dropdown"
        data-test-id="update-from-library"
        [disabled]="selectionHasHiddenElements"
        [ngClass]="selectionHasHiddenElements ? 'disabled-with-tooltip' : ''"
        [uiTooltip]="'This action requires the element to be visible'"
        [uiTooltipPosition]="'right'"
        [uiTooltipDisabled]="!selectionHasHiddenElements"
        (click)="updateWidgetFromLibrary()">
        <div class="custom-item">
            <div class="custom-column"></div>
            <div class="custom-text">Update from library</div>
            <div [class]="contextMenu.isMac ? 'mac custom-action' : 'custom-action'"></div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-item
        *ngIf="isAllowedToMutateBrandLibrary$ | async"
        class="custom-dropdown"
        [disabled]="disableBrandLibraryUpdate"
        [ngClass]="disableBrandLibraryUpdate ? 'disabled-with-tooltip' : ''"
        [uiTooltip]="'This action requires the element to be visible and not used in masking.'"
        [uiTooltipPosition]="'right'"
        [uiTooltipDisabled]="!disableBrandLibraryUpdate"
        (click)="uploadToMediaLibrary(true)">
        <div class="custom-item">
            <div class="custom-column"></div>
            <div class="custom-text">Update brand library element</div>
            <div [class]="contextMenu.isMac ? 'mac custom-action' : 'custom-action'"></div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-item
        *ngIf="isAllowedToMutateBrandLibrary$ | async"
        class="custom-dropdown"
        [disabled]="disableBrandLibraryUpdate"
        [ngClass]="disableBrandLibraryUpdate ? 'disabled-with-tooltip' : ''"
        [uiTooltip]="'This action requires the element to be visible and not used in masking.'"
        [uiTooltipPosition]="'right'"
        [uiTooltipDisabled]="!disableBrandLibraryUpdate"
        (click)="uploadToMediaLibrary(false)">
        <div class="custom-item">
            <div class="custom-column"></div>
            <div class="custom-text">Add as new to brand library</div>
            <div [class]="contextMenu.isMac ? 'mac custom-action' : 'custom-action'"></div>
        </div>
    </ui-dropdown-item>
</ui-dropdown>

<ui-dropdown
    #moveToGroupMenu
    type="menu"
    [width]="'240'"
    [minWidth]="240">
    <ui-dropdown-item
        class="custom-dropdown"
        *ngFor="let group of creativeGroups"
        data-cy="move-to-group-target"
        [disabled]="group.disabled"
        (click)="moveToGroup(group.node)">
        <div class="custom-item">
            <div class="custom-column"></div>
            <div class="custom-text">{{ group.node.name }}</div>
        </div>
    </ui-dropdown-item>
</ui-dropdown>

<asset-picker-dropdown
    #replaceDropdown
    [context]="AssetPropertyContext.Replace"
    [allowUpload]="true"
    [allowRemove]="false"
    [allowFeed]="true"
    [assetType]="selectionElementsType"></asset-picker-dropdown>
