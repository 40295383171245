<studio-ui-section
    headline="Widgets"
    [collapsable]="true">
    <ng-container *ngFor="let group of elements | groupwidgets | async | sortgroup">
        <div *ngIf="group.isExpanded; else groupedTexts">
            <ng-container *ngFor="let element of group.elements; let i = index">
                <div class="section-wrapper">
                    <div class="section">
                        <div class="title-wrapper">
                            <ui-svg-icon
                                (click)="group.isExpanded = false"
                                *ngIf="i === 0"
                                class="icon"
                                icon="collapse"></ui-svg-icon>
                            <span
                                *ngIf="i > 0"
                                class="title-prefix"
                                >-</span
                            >
                            <div class="title element-label">
                                {{ element.name }}
                            </div>
                        </div>
                        <div
                            *ngFor="let property of element.properties; let i = index"
                            class="widget-input-wrapper"
                            [ngStyle]="{
                                'margin-bottom': i === element.properties.length - 1 ? '0px' : '10px'
                            }">
                            <ng-container *ngIf="isVersionedText(property)">
                                <div
                                    class="widget-label"
                                    [uiTooltip]="property.label">
                                    {{ property.label }}
                                </div>
                                <ui-textarea
                                    class="widget-input"
                                    [id]="element.elementId"
                                    [value]="property.value.text"
                                    (valueChange)="onTextChange($event, property, [element])"
                                    [maxCharacters]="2000">
                                </ui-textarea>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>

        <ng-template #groupedTexts>
            <div class="section-wrapper">
                <div class="section">
                    <div class="title-wrapper">
                        <ui-svg-icon
                            (click)="group.isExpanded = true"
                            class="icon"
                            icon="expand"
                            *ngIf="group.elements.length > 1"></ui-svg-icon>
                        <div
                            class="title"
                            [uiTooltip]="group.elements | headline">
                            {{ group.elements | headline }}
                        </div>
                    </div>
                    <div
                        *ngFor="let property of group.elements[0].properties; let i = index"
                        class="widget-input-wrapper"
                        [ngStyle]="{
                            'margin-bottom':
                                i === group.elements[0].properties.length - 1 ? '0px' : '10px'
                        }">
                        <ng-container *ngIf="isVersionedText(property)">
                            <div
                                class="widget-label"
                                [uiTooltip]="property.label">
                                {{ property.label }}
                            </div>
                            <ui-textarea
                                class="widget-input"
                                [id]="group.elements[0].elementId"
                                [value]="property.value.text"
                                (valueChange)="onTextChange($event, property, group.elements)"
                                [maxCharacters]="2000">
                            </ui-textarea>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-template>
    </ng-container>
</studio-ui-section>
