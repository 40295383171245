import { ITextSpan, IVersionProperty, IVersionedText } from '@domain/creativeset/version';
import Ajv from 'ajv/dist/jtd';
import { ensureStringOrDefault } from '../serialization.utils';
import { ISerializedStyleMap, ISerializedVersionedText, versionedTextSchema } from './version-schemas';

const ajv = new Ajv();
const serializeText = ajv.compileSerializer(versionedTextSchema);

export function serializeVersionedText(versionedText: IVersionProperty<IVersionedText>): string {
    const validText = {
        text: ensureStringOrDefault(versionedText.value.text),
        styles: versionedText.value.styles.map(span => ({
            ...span,
            styleIds: serializeStyleIds(span.styleIds)
        }))
    };

    return serializeText(validText as unknown as ISerializedVersionedText);
}

function serializeStyleIds(styleIds: ITextSpan['styleIds'] | undefined): ISerializedStyleMap[] {
    const serializedStyleIds: ISerializedStyleMap[] = [];
    for (const [documentId, styleId] of Object.entries(styleIds ?? {})) {
        serializedStyleIds.push({ documentId, styleId });
    }
    return serializedStyleIds.sort((a, b) => {
        if (a.documentId > b.documentId) {
            return 1;
        } else if (a.documentId < b.documentId) {
            return -1;
        } else if (a.styleId > b.styleId) {
            return 1;
        } else if (a.styleId < b.styleId) {
            return -1;
        }
        throw new Error('StyleId is not unique');
    });
}
