import { isValidUrl } from '@studio/utils/validation';
import { Component } from '@angular/core';
import { ICreative } from '@domain/creativeset/creative';
import { addMissingProtocol } from '@studio/utils/url';
import { CommonModule } from '@angular/common';
import { UIModule } from '@bannerflow/ui';
import { VersionFromIdPipe } from '../../../shared/pipes/version-from-id.pipe';

@Component({
    standalone: true,
    imports: [CommonModule, UIModule, VersionFromIdPipe],
    selector: 'url-dialog',
    templateUrl: 'url-dialog.component.html',
    styleUrls: ['url-dialog.component.scss']
})
export class UrlDialogComponent {
    url: string;
    label: string;
    buttonLabel = 'Save target url';
    placeholder = `Leave blank to use the version's target URL`;
    creatives: ICreative[];
    tooltip: string;

    private closePromise: Promise<any>;
    private resolveClosePromise: (value?: string | 'cancel' | undefined) => void;

    initiate(
        url: string | undefined,
        config?: IUrlDialogConfig
    ): Promise<string | 'cancel' | undefined> {
        if (config) {
            this.creatives = config.creatives;
        }

        if (url) {
            this.url = url;
        }

        if (!this.closePromise) {
            this.closePromise = new Promise(resolve => (this.resolveClosePromise = resolve));
        }
        return this.closePromise;
    }

    openUrl(): void {
        if (this.url) {
            window.open(addMissingProtocol(this.url), '_blank');
        }
    }

    cancel(): void {
        this.resolveClosePromise('cancel');
    }

    save(): void {
        if (this.isValid()) {
            const value = this.isBlank(this.url) ? undefined : addMissingProtocol(this.url);
            this.resolveClosePromise(value);
        }
    }

    isValid(): boolean {
        if (this.url) {
            return isValidUrl(addMissingProtocol(this.url));
        }
        return true;
    }

    private isBlank = (str: string): boolean => !str || /^\s*$/.test(str);
}

export interface IUrlDialogConfig {
    creatives: ICreative[];
}
