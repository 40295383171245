import { ImageAssetDtoV2, VideoAssetDtoV2 } from '@domain/api/generated/sapi';
import { CreateImageAssetDto, VideoLibraryAsset } from '@domain/creativeset';
import { ImageLibraryAsset } from '@domain/creativeset/image-asset';
import { removeGraphQlTypename } from './creativeset';
import { ImageAssetDto } from '@domain/api/generated/design-api';

export function deserializeImageAssets(
    images: ImageAssetDtoV2[] | CreateImageAssetDto[] | ImageAssetDto[]
): ImageLibraryAsset[] {
    return images.map(deserializeImageAsset);
}

export function deserializeImageAsset(
    image: ImageAssetDtoV2 | CreateImageAssetDto | ImageAssetDto
): ImageLibraryAsset {
    if (image.animatedThumbnail) {
        removeGraphQlTypename(image.animatedThumbnail);
    }

    return {
        url: image.original.url,
        width: image.original.width,
        height: image.original.height,
        id: `${image.id}`,
        original: {
            url: image.original.url,
            width: image.original.width,
            height: image.original.height
        },
        thumbnail: {
            url: image.thumbnail.url,
            width: image.thumbnail.width,
            height: image.thumbnail.height
        },
        animatedThumbnail: image.animatedThumbnail,
        name: image.name,
        created: image.created,
        modified: image.modified,
        fileSize: image.fileSize,
        isGenAi: image.isGenAi
    };
}

export function deserializeVideoAsset(video: VideoAssetDtoV2): VideoLibraryAsset {
    return {
        url: video.url,
        width: video.width,
        height: video.height,
        id: video.id,
        thumbnail: {
            url: video.thumbnail.url,
            width: video.thumbnail.width,
            height: video.thumbnail.height
        },
        name: video.name,
        created: video.created,
        modified: video.modified,
        durationInMilliseconds: video.durationInMilliseconds,
        fileSize: video.fileSize
    };
}
