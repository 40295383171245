<studio-ui-section
    *ngIf="!propertiesService.inStateView; else stateSection"
    headline="Text"
    [toggle]="true"
    [empty]="hideProperties"
    (toggleClick)="toggleProperties($event)">
    <div
        *ngIf="!propertiesService.inStateView"
        class="property-input margin-bottom">
        <font-picker
            [selectedFontFamilyId]="fontFamilyId || defaultFontFamilyId"
            [selectedFontStyleId]="fontStyleId || defaultFontStyleId"
            [showAsPlaceholder]="propertiesService.stateValueIsUndefined('font')"
            (previewFontChange)="updateFontStyle($event.fontStyle, true, false)"
            (mousedown)="suspendNextBlur()"
            (onPreviewStop)="onPreviewStop()"
            (selectedFontChange)="updateFontStyle($event.fontStyle, false, true)"></font-picker>
    </div>
    <!-- STYLE -->
    <div
        *ngIf="!propertiesService.inStateView"
        class="setting-row">
        <div class="setting-label"></div>
        <div class="setting-value">
            <div class="property-input textDecoration">
                <button-toggle
                    id="strikethrough-button"
                    class="decoration"
                    icon="strikethrough"
                    [(active)]="strikethrough"
                    [class.empty-state]="propertiesService.stateValueIsUndefined('strikethrough')"
                    (activeChange)="setStrikethrough()"></button-toggle>
                <button-toggle
                    id="underline-button"
                    class="decoration"
                    icon="underline"
                    [(active)]="underline"
                    [class.empty-state]="propertiesService.stateValueIsUndefined('underline')"
                    (activeChange)="setUnderline()"></button-toggle>
                <button-toggle
                    id="uppercase-button"
                    class="decoration"
                    icon="uppercase"
                    [(active)]="uppercase"
                    [class.empty-state]="propertiesService.stateValueIsUndefined('uppercase')"
                    (activeChange)="setUppercase()"></button-toggle>
            </div>
        </div>
    </div>
    <div
        id="text-property-color"
        class="setting-row">
        <div class="setting-label">Color</div>
        <div class="setting-value color">
            <color-button
                [color]="color.value"
                [colorMixed]="color.isMixed"
                (click)="colorService.toggleColorPicker('textColor')"
                data-test-id="color-button"></color-button>
        </div>
    </div>
    <div
        class="color-picker"
        *ngIf="'textColor' | picker | async">
        <section-expand
            arrowPosition="33px"
            [showBackground]="true"
            [removeContentWhenCollapsed]="false"
            [expanded]="true">
            <color-section
                name="textColor"
                [preventCloseElements]="['.color-picker', '#text-property-color']"
                [color]="color.value"
                [colorMixed]="color.isMixed"
                (editStart)="suspendNextBlur()"
                (onColorChange)="previewColor($event)"
                (onColorChanged)="updateColor($event, ElementChangeType.Force)"
                (preview)="previewColor($event)"
                (previewStop)="previewColorStopped($event)"
                (onPickerFocus)="suspendNextBlur()">
            </color-section>
        </section-expand>
    </div>
    <div
        *ngIf="!propertiesService.inStateView"
        class="setting-row">
        <div class="setting-label">Size</div>
        <div class="setting-value col-2 size">
            <div class="property-input">
                <ui-number-input
                    id="text-font-size-input"
                    unitLabel="px"
                    [(value)]="fontSize.value"
                    [allowEmpty]="true"
                    [btnDownDisabled]="fontSize.value === inputValidation.fontSize.min"
                    [btnUpDisabled]="fontSize.value === inputValidation.fontSize.max"
                    [class.empty-state]="propertiesService.stateValueIsUndefined('fontSize')"
                    [max]="inputValidation.fontSize.max"
                    [min]="inputValidation.fontSize.min"
                    [step]="1"
                    [placeholder]="'-'"
                    [keyboardEmit]="true"
                    (blur)="setFontSize()"
                    (submit)="setFontSize()">
                </ui-number-input>
            </div>
            <div class="property-input">
                <ui-number-input
                    id="text-max-rows-input"
                    minLabel="-"
                    uiTooltipPosition="left"
                    [uiTooltipWidth]="150"
                    unitLabel="Max rows"
                    [(value)]="maxRows.value"
                    [allowEmpty]="true"
                    [class.empty-state]="propertiesService.stateValueIsUndefined('maxRows')"
                    [disabled]="textOverflow.value === 'scroll'"
                    [btnDownDisabled]="(maxRows.value ? maxRows.value : 0) <= 0"
                    [min]="inputValidation.maxRows.min"
                    [max]="inputValidation.maxRows.max"
                    [step]="1"
                    [placeholder]="'-'"
                    [uiTooltip]="
                        maxRows.value! > 0
                            ? 'Maximum number of rows allowed'
                            : 'Any number of rows allowed'
                    "
                    [keyboardEmit]="true"
                    (blur)="setMaxRows()"
                    (submit)="setMaxRows()">
                </ui-number-input>
            </div>
        </div>
    </div>

    <div
        *ngIf="!propertiesService.inStateView"
        class="setting-row">
        <div class="setting-label"></div>
        <div class="setting-value col-1">
            <div class="property-input select">
                <ui-select
                    id="text-overflow-select"
                    uiTooltip="What to do when text can't fit inside element boundaries"
                    [uiTooltipWidth]="150"
                    [useTargetWidth]="true"
                    [(selected)]="textOverflow.value"
                    [backdropCss]="'prevent-text-blur'"
                    [class.empty-state]="propertiesService.stateValueIsUndefined('textOverflow')"
                    (selectedChange)="setTextOverflow($event)">
                    <ng-container *ngIf="textOverflow.value === 'Mixed'">
                        <ui-option
                            [value]="'Mixed'"
                            [selected]="true">
                            Mixed
                        </ui-option>
                        <div class="option-divider"></div>
                    </ng-container>
                    <ui-option
                        *ngFor="let option of textOverflowOptions"
                        [value]="option.value"
                        id="{{ option.id }}"
                        (mousedown)="suspendNextBlur()"
                        >{{ option.name }}</ui-option
                    >
                </ui-select>
            </div>
        </div>
    </div>

    <!-- SPACING -->
    <div
        *ngIf="!propertiesService.inStateView"
        class="setting-row">
        <div class="setting-label">Spacing</div>
        <div class="setting-value col-2">
            <div class="property-input">
                <ui-number-input
                    id="text-character-spacing-input"
                    unitLabel="Char"
                    [(value)]="characterSpacing.value"
                    [allowEmpty]="true"
                    [class.empty-state]="propertiesService.stateValueIsUndefined('characterSpacing')"
                    [disableUndo]="true"
                    [btnDownDisabled]="characterSpacing.value! <= -1"
                    [keyboardEmit]="true"
                    [min]="inputValidation.characterSpacing.min"
                    [max]="inputValidation.characterSpacing.max"
                    [step]="0.01"
                    placeholder="-"
                    (redo)="emitRedo()"
                    (valueChange)="setCharacterSpacing()"
                    (undo)="emitUndo()"></ui-number-input>
            </div>
            <div class="property-input">
                <ui-number-input
                    id="text-line-height-input"
                    unitLabel="Line"
                    [(value)]="lineHeight.value"
                    [allowEmpty]="true"
                    [class.empty-state]="propertiesService.stateValueIsUndefined('lineHeight')"
                    [disableUndo]="true"
                    [btnDownDisabled]="lineHeight.value! <= 0.1"
                    [keyboardEmit]="true"
                    [min]="inputValidation.lineHeight.min"
                    [max]="inputValidation.lineHeight.max"
                    [step]="0.1"
                    placeholder="-"
                    (redo)="emitRedo()"
                    (valueChange)="setLineHeight()"
                    (undo)="emitUndo()"></ui-number-input>
            </div>
        </div>
    </div>

    <!-- PADDING -->
    <div
        *ngIf="!propertiesService.inStateView"
        class="setting-row">
        <div class="setting-label">Padding</div>
        <div class="setting-value">
            <div class="property-input">
                <ui-number-input
                    id="text-character-padding-input"
                    unitLabel="Padding"
                    [(value)]="paddingTop.value"
                    [allowEmpty]="
                        paddingTop.isMixed ? paddingTop.isMixed : propertiesService.inStateView
                    "
                    [class.empty-state]="propertiesService.stateValueIsUndefined('padding')"
                    [disableUndo]="true"
                    [keyboardEmit]="true"
                    [btnDownDisabled]="(paddingTop.value ?? 0) <= 0"
                    [min]="inputValidation.padding.min"
                    [max]="inputValidation.padding.max"
                    [placeholder]="
                        paddingTop.isMixed ? '-' : propertiesService.getPlaceholderValue('padding')
                    "
                    [step]="1"
                    (blur)="setPadding()"
                    (redo)="emitRedo()"
                    (submit)="setPadding()"
                    (undo)="emitUndo()"></ui-number-input>
            </div>
        </div>
    </div>
    <div
        *ngIf="!propertiesService.inStateView"
        class="setting-row">
        <div class="setting-label">Align</div>
        <div class="setting-value col-1 button-group">
            <div class="property-input">
                <button-toggle-group
                    [class.empty-state]="propertiesService.stateValueIsUndefined('horizontalAlignment')"
                    [options]="horizontalAlignmentOptions"
                    [value]="horizontalAlignment"
                    (change)="setHorizontalAlignment($event)"></button-toggle-group>
            </div>
        </div>
    </div>
    <div
        *ngIf="!propertiesService.inStateView"
        class="setting-row">
        <div class="setting-label"></div>
        <div class="setting-value col-1 button-group">
            <div class="property-input">
                <button-toggle-group
                    [class.empty-state]="propertiesService.stateValueIsUndefined('verticalAlignment')"
                    [options]="verticalAlignmentOptions"
                    [value]="verticalAlignment ? verticalAlignment : ''"
                    (change)="setVerticalAlignment($event)"></button-toggle-group>
            </div>
        </div>
    </div>
</studio-ui-section>
<studio-ui-section
    *ngIf="!propertiesService.inStateView"
    id="text-property-shadow"
    headline="Shadow"
    data-test-id="text-property-shadow"
    [actions]="[
        { id: 'add-text-shadow-button', icon: 'plus-small', action: addTextShadows, hidden: false }
    ]"
    [clickableHeader]="true"
    [empty]="false"
    (headerClick)="addTextShadows()">
    <ng-container *ngIf="!textShadowsDifferentLength; else textShadowPlaceholder">
        <ng-container
            *ngFor="
                let shadowItem of textShadows;
                let i = index;
                let last = last;
                trackBy: trackByIndex
            ">
            <div
                data-test-id="shadow-setting-body"
                class="setting-value col-3 shadow margin-bottom">
                <color-button
                    [id]="'shadow-color-button-' + i"
                    [color]="shadowItem.color"
                    [colorMixed]="shadowItem.colorMixed!"
                    (click)="colorService.toggleColorPicker('textShadow' + i)"
                    data-test-id="color-button"></color-button>
                <div></div>
                <div class="input-group setting-value col-4 shadow">
                    <ui-number-input
                        class="ui-number-input"
                        [id]="'shadow-offsetX-' + i"
                        data-test-id="shadow-offsetX"
                        [(value)]="shadowItem.offsetX"
                        [allowEmpty]="false"
                        placeholder="-"
                        [disableUndo]="true"
                        [keyboardEmit]="true"
                        [min]="inputValidation.textShadowOffset.min"
                        [max]="inputValidation.textShadowOffset.max"
                        (blur)="refocusTextElement()"
                        (redo)="emitRedo()"
                        (undo)="emitUndo()"
                        (valueChange)="setTextShadows(ElementChangeType.Burst)"></ui-number-input>
                    <ui-number-input
                        class="ui-number-input-no-radius"
                        [id]="'shadow-offsetY-' + i"
                        data-test-id="shadow-offsetY"
                        [(value)]="shadowItem.offsetY"
                        [allowEmpty]="false"
                        placeholder="-"
                        [disableUndo]="true"
                        [keyboardEmit]="true"
                        [min]="inputValidation.textShadowOffset.min"
                        [max]="inputValidation.textShadowOffset.max"
                        (blur)="refocusTextElement()"
                        (redo)="emitRedo()"
                        (undo)="emitUndo()"
                        (valueChange)="setTextShadows(ElementChangeType.Burst)"></ui-number-input>
                    <ui-number-input
                        class="ui-number-input-last"
                        [id]="'shadow-blur-' + i"
                        data-test-id="shadow-blur"
                        [(value)]="shadowItem.blur"
                        [allowEmpty]="false"
                        placeholder="-"
                        [disableUndo]="true"
                        [keyboardEmit]="true"
                        [min]="inputValidation.textShadowBlur.min"
                        [max]="inputValidation.textShadowBlur.max"
                        (blur)="refocusTextElement()"
                        (redo)="emitRedo()"
                        (undo)="emitUndo()"
                        (valueChange)="setTextShadows(ElementChangeType.Burst)"></ui-number-input>
                </div>
                <div></div>
                <ui-svg-icon
                    class="remove-button"
                    icon="delete"
                    (mousedown)="clearTextShadow(shadowItem)"></ui-svg-icon>
            </div>
            <div
                *ngIf="textShadows && textShadows.length > 0 && last"
                class="setting-value col-3 shadow">
                <div></div>
                <div></div>
                <div class="input-group-text setting-value col-4 shadow">
                    <div>X</div>
                    <div>Y</div>
                    <div>B</div>
                </div>
                <div></div>
                <div></div>
            </div>
            <div
                class="color-picker shadow"
                *ngIf="'textShadow' + i | picker | async">
                <section-expand
                    arrowPosition="33px"
                    [showBackground]="true"
                    [removeContentWhenCollapsed]="false"
                    [expanded]="true">
                    <color-section
                        [name]="'textShadow' + i"
                        [preventCloseElements]="['#text-property-shadow']"
                        [color]="shadowItem.color"
                        [colorMixed]="shadowItem.colorMixed"
                        (editStart)="suspendNextBlur()"
                        (onColorChange)="setTextShadow($event, i, ElementChangeType.Skip)"
                        (onColorChanged)="setTextShadow($event, i)"
                        (preview)="previewTextShadows(i, $event)"
                        (previewStop)="setTextShadows(ElementChangeType.Skip)"
                        (onPickerFocus)="suspendNextBlur()">
                    </color-section>
                </section-expand>
            </div>
        </ng-container>
    </ng-container>
    <ng-template #textShadowPlaceholder>
        <p class="placeholder-text">Click + to replace mixed properties</p>
    </ng-template>
</studio-ui-section>
<ng-template #stateSection>
    <studio-ui-section
        *ngIf="propertiesService.inStateView"
        headline="Text"
        [clickableHeader]="stateData?.textColor === undefined"
        (headerClick)="addStateColor()"
        [empty]="hideProperties"
        [actions]="[
            {
                id: 'set-color-button',
                icon: 'plus-small',
                action: addStateColor,
                hidden: !hideProperties
            }
        ]">
        <div
            id="text-property-color-state"
            class="setting-row">
            <div class="setting-value slider col-4 color">
                <color-button
                    [color]="color.value"
                    (click)="colorService.toggleColorPicker('textStateColor')"
                    data-test-id="color-button"></color-button>
                <div></div>
                <studio-ui-slider
                    *ngIf="color"
                    [value]="color.value?.alpha || 0"
                    [config]="{
                        min: inputValidation.textColorAlpha.min,
                        max: inputValidation.textColorAlpha.max
                    }"
                    (mouseUp)="setAlpha()"
                    (valueChange)="setAlpha($event, ElementChangeType.Skip)"></studio-ui-slider>
                <div></div>
                <ui-number-input
                    unitLabel="%"
                    [value]="color.value?.alpha"
                    [keyboardEmit]="true"
                    [allowEmpty]="false"
                    [min]="inputValidation.textColorAlpha.min"
                    [max]="inputValidation.textColorAlpha.max"
                    (undo)="emitUndo()"
                    (redo)="emitRedo()"
                    (valueChange)="setAlpha($event)"
                    [disableUndo]="true"></ui-number-input>
                <div></div>
                <ui-svg-icon
                    id="remove-state-color-button"
                    class="remove-button"
                    icon="delete"
                    (click)="clearStateColor()">
                </ui-svg-icon>
            </div>
        </div>
        <div
            class="color-picker"
            *ngIf="'textStateColor' | picker | async">
            <section-expand
                arrowPosition="33px"
                [showBackground]="true"
                [removeContentWhenCollapsed]="false"
                [expanded]="true">
                <color-section
                    name="textStateColor"
                    [preventCloseElements]="['.color-picker', '#text-property-color-state']"
                    [color]="color.value"
                    (onColorChange)="previewColor($event)"
                    (onColorChanged)="updateColor($event)"
                    (preview)="previewColor($event)"
                    (previewStop)="updateColor($event, ElementChangeType.Skip)"
                    (onPickerFocus)="suspendNextBlur()">
                </color-section>
            </section-expand>
        </div>
    </studio-ui-section>
</ng-template>
