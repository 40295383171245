import { IFeed, IFeedStep } from '@domain/feed';
import { ITextVariable, SpanType } from '@domain/text';
import { JTDSchemaType } from 'ajv/dist/jtd';

// VersionedFeed type
const feedStepSchema: JTDSchemaType<IFeedStep> = {
    additionalProperties: false,
    properties: {
        size: { type: 'int32' },
        start: { type: 'int32' },
        occurrence: { enum: ['loop', 'none'] }
    }
};
export const feedSchema: JTDSchemaType<IFeed> = {
    properties: {
        id: { type: 'string' },
        fallback: { type: 'string' },
        path: { type: 'string' },
        type: { enum: ['text', 'image', 'video', 'number'] },
        step: feedStepSchema
    },
    additionalProperties: false
};

// VersionedText type
export interface ISerializedStyleMap {
    documentId: string;
    styleId: string;
}

const styleIdsSchema: JTDSchemaType<ISerializedStyleMap[]> = {
    elements: {
        properties: {
            documentId: { type: 'string' },
            styleId: { type: 'string' }
        },
        optionalProperties: {},
        additionalProperties: false
    }
};

interface ISerializedContentTextSpan {
    type: SpanType.Word | SpanType.Newline | SpanType.Space;
    position: number;
    length: number;
    styleIds: ISerializedStyleMap[];
}
interface ISerializedNewLineTextSpan extends ISerializedContentTextSpan {
    type: SpanType.Newline;
}
interface ISerializedWordTextSpan extends ISerializedContentTextSpan {
    type: SpanType.Word;
}
interface ISerializedSpaceTextSpan extends ISerializedContentTextSpan {
    type: SpanType.Space;
}

const contentTextSpanSchema: JTDSchemaType<Omit<ISerializedContentTextSpan, 'type'>> = {
    properties: {
        position: { type: 'int32' },
        length: { type: 'int32' },
        styleIds: styleIdsSchema
    },
    additionalProperties: false
};

export interface ISerializedVariableTextSpan {
    type: SpanType.Variable;
    position: number;
    length: number;
    styleIds: ISerializedStyleMap[];
    variable: ITextVariable;
}
export const variableTextSpanSchema: JTDSchemaType<Omit<ISerializedVariableTextSpan, 'type'>> = {
    properties: {
        position: { type: 'int32' },
        length: { type: 'int32' },
        styleIds: styleIdsSchema,
        variable: {
            properties: {
                id: { type: 'string' },
                path: { type: 'string' },
                fallback: { type: 'string' },
                type: { enum: ['text'] },
                step: feedStepSchema
            },
            optionalProperties: {
                spanId: { type: 'string' }
            },
            additionalProperties: false
        }
    },
    additionalProperties: false
};

export interface ISerializedVersionedText {
    text: string;
    styles: (
        | ISerializedWordTextSpan
        | ISerializedNewLineTextSpan
        | ISerializedSpaceTextSpan
        | ISerializedVariableTextSpan
    )[];
}
export const versionedTextSchema: JTDSchemaType<ISerializedVersionedText> = {
    properties: {
        text: { type: 'string' },
        styles: {
            elements: {
                discriminator: 'type',
                mapping: {
                    word: contentTextSpanSchema,
                    newline: contentTextSpanSchema,
                    space: contentTextSpanSchema,
                    variable: variableTextSpanSchema
                }
            }
        }
    }
};
