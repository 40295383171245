import { parseColor } from '@creative/color.utils';
import { createRichTextFromSegments } from '@creative/elements/rich-text/text-nodes';
import {
    CreativeDataNode,
    createVersionedTextFromText,
    isDapiImageNodeDto,
    isDapiVideoNodeDto,
    isImageNode,
    isTextDataElement,
    isVideoNode
} from '@creative/nodes';
import { deserializeCharacterProperties, deserializeOneOfNodes } from '@creative/serialization';
import {
    DesignApiElementOverrideOrElement,
    DesignApiOverrideElement,
    OneOfElementDtos
} from '@domain/api/design-api.interop';
import {
    CreativeDto,
    CreativeSetDto,
    EllipseElementDto,
    EllipseElementOverrideDto,
    GroupNodeDto,
    ImageAssetDto,
    ImageElementDto,
    ImageElementOverrideDto,
    RectangleElementDto,
    RectangleElementOverrideDto,
    SizeDto,
    TextLikeElementDto,
    TextLikeElementOverrideDto,
    TextSegmentDto,
    VersionDto,
    VideoAssetDto,
    VideoElementDto,
    VideoElementOverrideDto,
    WidgetElementDto,
    WidgetElementOverrideDto
} from '@domain/api/generated/design-api';
import {
    CreativeSize,
    ICreativeset,
    IDesign,
    IElement,
    IElementProperty,
    ImageLibraryAsset,
    VideoLibraryAsset
} from '@domain/creativeset';
import { ApprovalStatus, ICreative } from '@domain/creativeset/creative';
import { IVersion, IVersionProperty, IVersionedText } from '@domain/creativeset/version';
import { AssetReference } from '@domain/element-asset';
import { ElementKind } from '@domain/elements';
import { FeededReference } from '@domain/feed';
import { IImageElementDataNode, IVideoElementDataNode, OneOfDataNodes } from '@domain/nodes';
import { OneOfNodesDto } from '@domain/serialization';
import { ICharacterProperties } from '@domain/text';
import { createElement, createElementProperty } from '@studio/utils/element.utils';
import { uuidv4 } from '@studio/utils/id';

export function convertDesignApiCreativesetToOldModel(creativeSetDto: CreativeSetDto): ICreativeset {
    const versions: IVersion[] = [];
    const elements: IElement[] = [];

    for (const version of creativeSetDto.versions) {
        const { id: versionId, name, targetUrl, elements: versionElements } = version;

        const migratedVersion: IVersion = {
            id: `${versionId}`,
            localization: {
                id: version.localizationId
            },
            name,
            targetUrl,
            properties: []
        };

        for (const elementId of Object.keys(versionElements)) {
            const element = versionElements[elementId];
            const poolElement = creativeSetDto.elementsPool.find(({ id }) => id === elementId);
            if (poolElement && '__textLikeKind' in poolElement) {
                const existingElement = elements.find(({ id }) => id === elementId);

                const existingProperty = existingElement?.properties.find(
                    property => property.name === 'content'
                );

                const versionProperty: IVersionProperty = {
                    id: existingProperty?.versionPropertyId ?? uuidv4(),
                    name: 'content',
                    value: createVersionedTextFromText(
                        createRichTextFromSegments(element['textSegments'])
                    )
                };

                migratedVersion.properties.push(versionProperty);

                if (!existingElement) {
                    const migratedElement: IElement = createElement({
                        id: elementId,
                        name,
                        type: getKindFromDesignApiElement(poolElement),
                        properties: [
                            createElementProperty({
                                id: uuidv4(),
                                unit: 'text',
                                name: 'content',
                                versionPropertyId: versionProperty.id
                            })
                        ]
                    });
                    elements.push(migratedElement);
                }
            }
        }

        versions.push(migratedVersion);
    }

    const designs: IDesign[] = [];

    const creativeSizes: CreativeSize[] = creativeSetDto.sizes.map(({ id, width, height, name }) => ({
        id: `${id}`,
        width,
        height,
        name
    }));

    const creatives: ICreative[] = creativeSetDto.creatives.map(creative => {
        const creativeSize = creativeSizes.find(({ id }) => id === `${creative.sizeId}`)!;
        const size = creativeSetDto.sizes.find(({ id }) => id === creative.sizeId)!;
        const version = versions.find(({ id }) => id === `${creative.versionId}`)!;
        const apiElements = compileDesignApiElements(creativeSetDto, creative).map(element => {
            if ('__textLikeKind' in element) {
                const isButton = element.isButton;
                if (isButton) {
                    return { ...element, __buttonKind: true };
                }
                return { ...element, __textKind: true };
            }

            return element;
        });

        apiElements.forEach(element => {
            const existingElement = elements.find(({ id: elId }) => elId === element.id);
            if (!existingElement) {
                elements.push(
                    createElement({
                        id: element.id,
                        name: element.name,
                        type: getKindFromDesignApiElement(element),
                        properties: getPropertiesFromDesignApiElement(element)
                    })
                );
            }
        });

        const nodes = apiElements.map(mapElementDtoToDataNode);

        for (const apiElement of apiElements) {
            apiElement['textSegments'].forEach((segment: TextSegmentDto, index: number) => {
                const element = elements.find(({ id }) => id === apiElement.id);
                const node = nodes.find(({ id }) => id === element!.id);

                populateCharacterStylesByTextSegment(
                    segment,
                    index,
                    element,
                    node,
                    version,
                    creative.id
                );
            });
        }

        const design: IDesign = {
            id: `${creative.design?.legacy_DesignId}`,
            elements: elements.filter(element => size.elements[element.id]),
            hasHeavyVideo: creative.design?.hasHeavyVideo ?? false,
            name: creative.design?.name ?? 'design',
            document: new CreativeDataNode({
                id: `${creative.id}`,
                width: size.width,
                height: size.height,
                fill: parseColor(creative.design?.fill ?? '#FFFFFF'),
                nodes: nodes
            })
        };

        designs.push(design);

        return {
            id: `${creative.id}`,
            checksum: creative.checksum,
            approvalStatus: ApprovalStatus.None,
            connectedCampaigns: [],
            size: creativeSize,
            design,
            version
        };
    });

    const imageAssets: ImageLibraryAsset[] = designs
        .flatMap(({ document }) => document.elements)
        .map(element => {
            if (isImageNode(element)) {
                const poolElement = creativeSetDto.elementsPool.find(
                    ({ id }) => id === element.id
                ) as IImageElementDataNode;
                const asset = poolElement.imageAsset as unknown as ImageAssetDto;
                if (!asset) {
                    return;
                }
                return {
                    id: asset.id.toString(),
                    created: Date.now().toString(),
                    height: asset.original.height,
                    name: asset.name!,
                    original: {
                        height: asset.original.height,
                        url: asset.original.url,
                        width: asset.original.width
                    },
                    thumbnail: {
                        height: asset.thumbnail.height,
                        url: asset.thumbnail.url,
                        width: asset.thumbnail.width
                    },
                    fileSize: 0,
                    url: asset.original.url,
                    width: asset.original.width
                } satisfies ImageLibraryAsset;
            }
        })
        .filter(asset => asset !== undefined);

    const videoAssets: VideoLibraryAsset[] = designs
        .flatMap(({ document }) => document.elements)
        .map(element => {
            if (isVideoNode(element)) {
                const poolElement = creativeSetDto.elementsPool.find(
                    ({ id }) => id === element.id
                ) as IVideoElementDataNode;
                const asset = poolElement.videoAsset as unknown as VideoAssetDto;
                if (!asset) {
                    return;
                }
                return {
                    id: asset.id.toString(),
                    created: Date.now().toString(),
                    height: asset.height,
                    name: asset.name!,
                    thumbnail: {
                        height: asset.thumbnail.height,
                        url: asset.thumbnail.url,
                        width: asset.thumbnail.width
                    },
                    durationInMilliseconds: asset.durationInMilliseconds,
                    fileSize: 0,
                    url: asset.url,
                    width: asset.width
                } satisfies VideoLibraryAsset;
            }
        })
        .filter(asset => asset !== undefined);

    const defaultVersion = versions.find(({ id }) => id === `${creativeSetDto.defaultVersionId}`);

    if (!defaultVersion) {
        throw new Error(`Could not find default version.`);
    }

    return {
        id: `${creativeSetDto.id}`,
        brandId: creativeSetDto.brandId,
        name: creativeSetDto.name,
        stateId: creativeSetDto.stateId,
        elements,
        designs,
        sizes: creativeSizes,
        images: imageAssets,
        videos: videoAssets,
        widgets: [],
        creatives,
        versions,
        defaultVersion
    };
}

function mapElementDtoToDataNode(element: OneOfElementDtos): OneOfDataNodes {
    const elementDto = convertToStudioElement(element);
    const images = isDapiImageNodeDto(element) ? [element.imageAsset] : [];
    const videos = isDapiVideoNodeDto(element) ? [element.videoAsset] : [];
    return deserializeOneOfNodes(elementDto, {
        head: {
            asset: {
                images: images.map(image => ({
                    id: image.id.toString(),
                    height: image.original.height,
                    src: image.original.url,
                    width: image.original.width,
                    name: image.name
                })),
                videos: videos.map(video => ({
                    id: video.id.toString(),
                    name: video.name,
                    fileSize: video.fileSize,
                    height: video.height,
                    url: video.url,
                    width: video.width
                }))
            },
            elements: []
        },
        body: {} as any
    });
}

function getPropertiesFromDesignApiElement(
    element: DesignApiElementOverrideOrElement
): IElementProperty[] {
    if ('__videoKind' in element) {
        if (element.legacy_VideoElementPropertyId) {
            return [
                createElementProperty({
                    clientId: uuidv4(),
                    id: element.legacy_VideoElementPropertyId,
                    unit: 'id',
                    name: AssetReference.Video,
                    value: element.videoAsset.id.toString()
                })
            ];
        } else if (
            element.legacy_FeededVideoElementPropertyId &&
            element.legacy_FeededVideoElementPropertyValue
        ) {
            return [
                createElementProperty({
                    clientId: uuidv4(),
                    id: element.legacy_FeededVideoElementPropertyId,
                    unit: 'id',
                    name: FeededReference.Video,
                    value: element.legacy_FeededVideoElementPropertyValue
                })
            ];
        }
    } else if ('__imageKind' in element) {
        if (element.legacy_ImageElementPropertyId) {
            return [
                createElementProperty({
                    clientId: uuidv4(),
                    id: element.legacy_ImageElementPropertyId,
                    unit: 'id',
                    name: AssetReference.Image,
                    value: element.imageAsset.id.toString()
                })
            ];
        } else if (
            element.legacy_FeededImageElementPropertyId &&
            element.legacy_FeededImageElementPropertyValue
        ) {
            return [
                createElementProperty({
                    clientId: uuidv4(),
                    id: element.legacy_FeededImageElementPropertyId,
                    unit: 'id',
                    name: FeededReference.Image,
                    value: element.legacy_FeededImageElementPropertyValue
                })
            ];
        }
    }

    return [];
}

function populateCharacterStylesByTextSegment(
    segment: TextSegmentDto,
    segmentIndex: number,
    element: IElement | undefined,
    node: OneOfDataNodes | undefined,
    version: IVersion,
    creativeId: number
): void {
    const hasStyles = Object.keys(segment.value).length > 0;
    if (!hasStyles) {
        return;
    }

    const contentProperty = element?.properties.find(({ name }) => name === 'content');
    const versionProperty = version?.properties.find(
        ({ id }) => id === contentProperty?.versionPropertyId
    );
    if (!versionProperty) {
        return;
    }

    // We have no other way to match a segment to a span on vp property than by index.
    // Assuming that all spans are present in vp prop & as segment no matter if it has a style or not
    const versionedStyle = (versionProperty.value as IVersionedText).styles[segmentIndex];

    if (versionedStyle) {
        // Apply character styles to node
        const styleId = uuidv4();
        versionedStyle.styleIds[`${creativeId}`] = styleId;

        if (isTextDataElement(node)) {
            const characterProperties = deserializeCharacterProperties(
                segment.value
            ) as Partial<ICharacterProperties>;
            node.characterStyles.set(styleId, characterProperties);
        }
    }
}

function compileDesignApiElements(
    creativeSetDto: CreativeSetDto,
    creative: CreativeDto
): OneOfElementDtos[] {
    const size = creativeSetDto.sizes.find(({ id }) => id === creative.sizeId)!;

    return Object.keys(size.elements).map(elementId =>
        compileDesignApiElement(creativeSetDto, { elementId, creative, size })
    );
}

function compileDesignApiElement(
    creativeSetDto: CreativeSetDto,
    { elementId, creative, size }: CompileElementOptions
): OneOfElementDtos {
    const elementPool = creativeSetDto.elementsPool;
    const versionId = creative.versionId;
    const version = creativeSetDto.versions.find(({ id }) => id === versionId);

    if (!version) {
        throw new Error();
    }

    const rootElement = elementPool.find(({ id }) => id === elementId)!;

    return {
        ...rootElement,
        ...getElementOverrides(rootElement, { size, creative, version })
    };
}

function getKindFromDesignApiElement(element: DesignApiElementOverrideOrElement): ElementKind {
    if ('__textLikeKind' in element && element.isButton) {
        return ElementKind.Button;
    }

    const kindDiscriminator = Object.keys(element).find(
        key => key.startsWith('__') && key.endsWith('Kind')
    )!;

    return kindDiscriminator.replace('__', '').replace(/(Like)?Kind/gi, '') as ElementKind;
}

function convertToStudioElement(node: OneOfElementDtos): OneOfNodesDto {
    if (isDapiImageNodeDto(node)) {
        return { ...node, imageAssetId: node.imageAsset.id.toString() };
    }
    if (isDapiVideoNodeDto(node)) {
        return { ...node, videoAssetId: node.videoAsset.id.toString() };
    }
    return node as OneOfNodesDto;
}

type CompileElementOptions = {
    elementId: string;
    size: SizeDto;
    creative: CreativeDto;
};

function getElementOverrides<Element extends OneOfElementDtos>(
    rootElement: Element,
    {
        creative,
        size,
        version
    }: {
        size: SizeDto;
        version: VersionDto;
        creative: CreativeDto;
    }
): InferredElementOverride<Element> {
    const sizeElement: undefined | DesignApiOverrideElement = size.elements[rootElement.id];
    const versionElement: undefined | DesignApiOverrideElement = version.elements[rootElement.id];
    const creativeElement: undefined | DesignApiOverrideElement = creative.elements[rootElement.id];

    return {
        ...sizeElement,
        ...versionElement,
        ...creativeElement
    } as InferredElementOverride<Element>;
}

type RectangleElement<E extends OneOfElementDtos> = E extends RectangleElementDto
    ? RectangleElementOverrideDto
    : never;
type EllipseElement<E extends OneOfElementDtos> = E extends EllipseElementDto
    ? EllipseElementOverrideDto
    : never;
type ImageElement<E extends OneOfElementDtos> = E extends ImageElementDto
    ? ImageElementOverrideDto
    : never;
type VideoElement<E extends OneOfElementDtos> = E extends VideoElementDto
    ? VideoElementOverrideDto
    : never;
type WidgetElement<E extends OneOfElementDtos> = E extends WidgetElementDto
    ? WidgetElementOverrideDto
    : never;
type TextElement<E extends OneOfElementDtos> = E extends TextLikeElementDto
    ? TextLikeElementOverrideDto
    : never;
type GroupElement<E extends OneOfElementDtos> = E extends GroupNodeDto ? GroupNodeDto : never;

type InferredElementOverride<E extends OneOfElementDtos> =
    | RectangleElement<E>
    | EllipseElement<E>
    | ImageElement<E>
    | VideoElement<E>
    | WidgetElement<E>
    | TextElement<E>
    | GroupElement<E>;
