import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UIModule } from '@bannerflow/ui';
import { ApprovalStatus } from '@domain/creativeset/creative';
import { CreativeSize } from '@domain/creativeset/size';
import { IVersion } from '@domain/creativeset/version';
import { CreativesetDataService } from '../../../../shared/creativeset/creativeset.data.service';
import { FiltersService } from '../../../../shared/filters/filters.service';
import { SortByGroupWidthPipe } from '../../../../shared/pipes/filter-list.pipe';
import { EnvironmentService } from '../../../../shared/services/environment.service';
import { VersionsService } from '../../../../shared/versions/state/versions.service';
import { filterCreatives } from '../../utils/filter-creatives.utils';

@Component({
    standalone: true,
    imports: [CommonModule, UIModule, SortByGroupWidthPipe],
    selector: 'filter-list-sizes',
    templateUrl: './filter-list-sizes.component.html',
    styleUrls: ['./filter-list-sizes.component.scss']
})
export class FilterListSizesComponent {
    @Input() sizes: CreativeSize[];

    isMobileShowcase: boolean;
    collapsed = false;

    private lastClicked: CreativeSize;
    private statusesFilter = new Set<ApprovalStatus>();
    private sizesFilter = new Set<string>();

    private selectedVersions: IVersion[] = [];

    private readonly sortPipe = new SortByGroupWidthPipe();

    constructor(
        public filtersService: FiltersService,
        private creativesetDataService: CreativesetDataService,
        private versionsService: VersionsService,
        private environmentService: EnvironmentService
    ) {
        this.isMobileShowcase = this.environmentService.isMobileShowcase;

        this.versionsService.selectedVersions$
            .pipe(takeUntilDestroyed())
            .subscribe(selectedVersions => {
                this.selectedVersions = selectedVersions;
            });

        this.filtersService.sizes$.pipe(takeUntilDestroyed()).subscribe(sizes => {
            this.sizesFilter = new Set(sizes);
        });

        this.filtersService.statuses$.pipe(takeUntilDestroyed()).subscribe(statuses => {
            this.statusesFilter = new Set(statuses);
        });
    }

    collapse(): void {
        this.collapsed = !this.collapsed;
    }

    toggleAll(): void {
        this.filtersService.toggleSizesFilter();
    }

    toggleFilter(event: MouseEvent, size: CreativeSize): void {
        if (event.shiftKey && this.lastClicked && size.id !== this.lastClicked.id) {
            const lastClickedIsBeingFiltered = this.sizesFilter.has(this.lastClicked.id);

            const sizeIds = this.shiftSelect(size).map(({ id }) => id);

            if (lastClickedIsBeingFiltered) {
                if (!this.canApplySizesFilter([...sizeIds])) {
                    this.filtersService.clearAllStatuses();
                }
                this.filtersService.addFilteredSizes(sizeIds);
            } else {
                this.filtersService.removeFilteredSizes(sizeIds);
            }
            return;
        }

        this.lastClicked = size;

        if (!this.sizesFilter.has(size.id)) {
            if (!this.canApplySizesFilter([size.id])) {
                this.filtersService.clearAllStatuses();
            }
            this.filtersService.addFilteredSizes([size.id]);
        } else {
            const futureActiveSizeFilter = [
                ...Array.from(this.sizesFilter).filter(sizeFilter => sizeFilter !== size.id)
            ];

            if (this.canApplySizesFilter(futureActiveSizeFilter)) {
                this.filtersService.removeFilteredSizes([size.id]);
            }
        }
    }

    private canApplySizesFilter(sizeIds: string[]): boolean {
        const creatives = this.creativesetDataService.getCreativesFromVersionsOrAll(
            this.selectedVersions
        );
        const filteredCreatives = filterCreatives(creatives, sizeIds, Array.from(this.statusesFilter));

        return !!filteredCreatives.length;
    }

    private shiftSelect(size: CreativeSize): CreativeSize[] {
        let sizesToBeAdded: CreativeSize[] = [];

        const sortedSizes = this.sortPipe.transform(this.sizes);
        const currentIndex = sortedSizes.indexOf(size);
        const lastSelectedIndex = sortedSizes.findIndex(e => e.id === this.lastClicked.id);

        sizesToBeAdded = [...sizesToBeAdded, size];
        if (lastSelectedIndex >= 0 && currentIndex >= 0 && lastSelectedIndex !== currentIndex) {
            for (
                let i = Math.min(lastSelectedIndex, currentIndex);
                i < Math.max(lastSelectedIndex, currentIndex);
                i++
            ) {
                sizesToBeAdded = [...sizesToBeAdded, sortedSizes[i]];
            }
        }

        return sizesToBeAdded;
    }

    isFiltered(size: CreativeSize): boolean {
        return this.sizesFilter.has(size.id);
    }
}
