import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { getSpansFromNewValue } from '@app/translation-page/utils/span.utils';
import { UIModule } from '@bannerflow/ui';
import { isVersionedText } from '@creative/elements/rich-text/text-nodes';
import {
    IVersionedElementPropertyValuesByElement,
    IVersionedText,
    IVersionProperty
} from '@domain/creativeset/version';
import { StudioUISectionComponent } from '../../../../shared/components/section/studio-ui-section/studio-ui-section.component';
import { GroupHeadlinePipe } from '../../../../shared/pipes/grouping/group-headline.pipe';
import { GroupWidgetsPipe } from '../../../../shared/pipes/grouping/group-widgets.pipe';
import { SortGroupPipe } from '../../../../shared/pipes/grouping/sort-group.pipe';
import { TranslationPanelService } from '../translation-panel.service';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        UIModule,
        GroupWidgetsPipe,
        SortGroupPipe,
        GroupHeadlinePipe,
        StudioUISectionComponent
    ],
    selector: 'mv-widget-properties',
    templateUrl: './mv-widget-properties.component.html',
    styleUrls: ['./mv-widget-properties.component.scss']
})
export class MvWidgetPropertiesComponent {
    private translationPanelService = inject(TranslationPanelService);

    @Input() elements: IVersionedElementPropertyValuesByElement<IVersionedText>[];
    isVersionedText = isVersionedText;

    onTextChange(
        text: string,
        property: IVersionProperty,
        elements: IVersionedElementPropertyValuesByElement[]
    ): void {
        // this is needed due to ui-textarea emits event on ngafterviewinit
        if (isVersionedText(property) && property.value.text !== text) {
            const newValue: IVersionedText = {
                text: text,
                styles: getSpansFromNewValue(text, property.value.text, [])
            };

            property.value = newValue;

            this.translationPanelService.onWidgetChanged(property, elements);
        }
    }
}
