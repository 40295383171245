import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AnimationControlState, ANIMATION_CONTROL_FEATURE_KEY } from './animation-control.reducer';

export const getAnimationControlsState = createFeatureSelector<any>(ANIMATION_CONTROL_FEATURE_KEY);

export const getIsPlaying = createSelector(
    getAnimationControlsState,
    ({ isPlaying }: AnimationControlState) => isPlaying
);

export const getIsDisabled = createSelector(
    getAnimationControlsState,
    ({ isDisabled }: AnimationControlState) => isDisabled
);

export const getShowPreloadImage = createSelector(
    getAnimationControlsState,
    ({ showPreloadImage }: AnimationControlState) => showPreloadImage
);

export const getShouldAutoPause = createSelector(
    getAnimationControlsState,
    ({ shouldAutoPause }: AnimationControlState) => shouldAutoPause
);

export const getIsInactive = createSelector(
    getAnimationControlsState,
    (state: AnimationControlState) => state.showPreloadImage && !state.isPlaying
);

export const getShownElement = createSelector(
    getAnimationControlsState,
    (state: AnimationControlState) => state.shownElementId
);
