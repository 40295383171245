import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    computed,
    DestroyRef,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    inject,
    Input,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
    ViewRef
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Logger } from '@bannerflow/sentinel-logger';
import {
    UIConfirmDialogResult,
    UIDialogService,
    UIDropdownComponent,
    UIInputComponent,
    UINotificationService
} from '@bannerflow/ui';
import { Icon } from '@bannerflow/ui/components/icon/svg-icon/icons';
import { isBannerFlowLibraryWidget, isOriginalBannerFlowLibraryWidget } from '@creative/nodes/helpers';
import { IBrandLibraryElement } from '@domain/creativeset';
import { IElement } from '@domain/creativeset/element';
import { OneOfLibraryAssets } from '@domain/creativeset/library-asset';
import { ElementKind } from '@domain/elements';
import { LibraryViewMode } from '@domain/media-library';
import { isImageLibraryAsset, isVideoLibraryAsset } from '@studio/domain/brand-library/assets';
import { IAISupported } from '@studio/domain/components/ai-studio.types';
import { distinctArrayById } from '@studio/utils/array';
import { cloneDeep } from '@studio/utils/clone';
import { getLibraryWidgetReferenceOfElement } from '@studio/utils/element.utils';
import { isHeavyVideo } from '@studio/utils/media';
import { removeFileExtension } from '@studio/utils/url';
import { combineLatest, filter, map, take } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AIStudioService } from '../../../shared/ai-studio/ai-studio.service';
import { GenAIService } from '../../../shared/ai-studio/state/gen-ai.service';
import { BrandLibraryDataService } from '../../../shared/media-library/brand-library.data.service';
import { MediaLibraryService } from '../../../shared/media-library/state/media-library.service';
import { FileDownloadService } from '../../../shared/services/filedownload.service';
import { WidgetService } from '../../../shared/services/widget.service';
import { UserService } from '../../../shared/user/state/user.service';
import {
    AssetUploadService,
    EditorSaveStateService,
    EditorStateService,
    ElementReplaceService,
    ElementSelectionService
} from '../services';
import { BrandLibraryElementDeletionService } from './brandlibrary-element-deletion.service';
import { BrandLibraryElementEditService } from './brandlibrary-element-edit.service';
import {
    BrandLibraryElementDataNode,
    BrandLibraryElementService
} from './brandlibrary-element.service';
import { ElementRenderingService } from './element-renderering-service';
import { LibraryElementRenderingService } from './library-element-renderer.service';
import { MediaLibraryComponent } from './media-library.component';
import { isAnimatedThumbnailSupportedAsset, isEffectLibraryElement } from './media-library.helpers';
import { UpdateWidgetDialogComponent } from './update-widget-dialog/update-widget-dialog.component';

@Component({
    selector: 'library-element',
    templateUrl: 'library-element.component.html',
    styleUrls: ['library-element.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('element', [
            state('void', style({})),
            state('enter', style({})),
            state('enteranimated', style({})),
            state('leave', style({ opacity: 0 })),
            transition('void => enteranimated', [
                animate(
                    '0.45s linear',
                    keyframes([
                        style({ opacity: 0, transform: 'scale(0)', offset: 0 }),
                        style({ opacity: 1, transform: 'scale(1.1)', offset: 0.4 }),
                        style({ opacity: 1, transform: 'scale(0.95)', offset: 0.6 }),
                        style({ opacity: 1, transform: 'scale(1)', offset: 0.8 })
                    ])
                )
            ])
        ])
    ],
    host: {
        '[class.uploading]': 'element.progress',
        '[class.element]': 'isElement',
        '[class.widget]': 'isWidget',
        '[class.image]': 'isImage',
        '[class.image-row]': 'isRowImage && isImage',
        '[class.inputHovered]': 'isInputHovered'
    }
})
export class LibraryElementComponent implements OnInit, AfterViewInit {
    @ViewChild('elementContainer') elementContainer: ElementRef;
    @ViewChild('nameInput') nameInput: UIInputComponent;
    @ViewChild('brandElementMenu') brandElementMenu: UIDropdownComponent;

    @Input() element: IProgressElement;
    @Input() progress: number;
    @Input() viewMode: LibraryViewMode;
    @Input() isRowImage = false;
    @Input() parentIsDialog = false;

    @Output() editElement = new EventEmitter();
    @Output() duplicateElement = new EventEmitter<IElement>();
    @Output() addToCanvas = new EventEmitter<void>();
    @Output() selectedElementToMoveToFolder = new EventEmitter<IElement>();
    @HostBinding('@element')
    animationState: 'void' | 'enter' | 'enteranimated' | 'leave' = 'void';

    thumbnailUrl?: string;
    itemName: string;
    get videoDuration(): number | undefined {
        return isVideoLibraryAsset(this.asset) ? this.asset.durationInMilliseconds : undefined;
    }
    isEditingName = false;
    isSelected = false;
    isInputHovered = false;
    isProcessing = false;
    LibraryViewMode = LibraryViewMode;
    isElement = false;
    isImage = false;
    isVideo = false;
    isWidgetOrMedia = false;
    isEditable = false;
    isBannerFlowLibraryWidget = false;
    isImportedEffect = false;
    isNonExportableWidget = false;
    isGenAi = false;
    elementIcon: Icon;
    isKebabMenuVisible: boolean;
    aiStudioState = computed(() => this.computeAIStudioState());

    public mediaLibrary = inject(MediaLibraryComponent);
    public mediaService = inject(MediaLibraryService);
    private aiStudioService = inject(AIStudioService);
    private assetUploadService = inject(AssetUploadService);
    private brandLibraryDataService = inject(BrandLibraryDataService);
    private brandLibraryElementDeletionService = inject(BrandLibraryElementDeletionService);
    private brandLibraryElementEditService = inject(BrandLibraryElementEditService);
    private brandlibraryElementService = inject(BrandLibraryElementService);
    private changeDetector = inject(ChangeDetectorRef);
    private destroyRef = inject(DestroyRef);
    private editorSaveStateService = inject(EditorSaveStateService);
    private editorStateService = inject(EditorStateService);
    private elementRenderingService = inject(ElementRenderingService);
    private elementReplaceService = inject(ElementReplaceService);
    private elementSelectionService = inject(ElementSelectionService);
    private fileDownload = inject(FileDownloadService);
    private genAIService = inject(GenAIService);
    private host = inject(ElementRef);
    private libraryElementRenderingService = inject(LibraryElementRenderingService);
    private renderer2 = inject(Renderer2);
    private uiDialogService = inject(UIDialogService);
    private uiNotificationService = inject(UINotificationService);
    private userService = inject(UserService);
    private widgetService = inject(WidgetService);
    private asset?: OneOfLibraryAssets;
    private logger = new Logger('LibraryElementComponent');

    @HostBinding('class.widget') isWidget = false;
    @HostBinding('class.effect') get isEffect(): boolean {
        return isEffectLibraryElement(this.element);
    }
    @HostBinding('class.is-importing-effect') isImportingEffect = false;

    @HostListener('mousedown', ['$event'])
    onHostMouseDown($event: Event): void {
        if (this.isEditingName || this.isProcessing) {
            this.stopPropagation($event);
        }
    }

    async ngOnInit(): Promise<void> {
        const asset = this.brandLibraryDataService.getAssetByElement(this.element);
        this.asset = asset;
        this.isElement = this.getIsElement();
        this.isImage = this.getIsElement(ElementKind.Image);
        this.isVideo = this.getIsElement(ElementKind.Video);
        this.isWidget =
            this.getIsElement(ElementKind.Widget) ||
            this.getIsElement(ElementKind.BannerflowLibraryWidget);
        this.isWidgetOrMedia = this.isImage || this.isWidget || this.isVideo;
        this.isImportedEffect =
            this.isEffect && this.mediaLibrary.isBrandLibraryElementImported(this.element);
        this.isKebabMenuVisible = this.getIsKebabMenuVisible(this.element);
        this.isNonExportableWidget = !(await this.isExportableWidget(this.element));

        // Needs to be before getIsEditable
        this.isBannerFlowLibraryWidget = isBannerFlowLibraryWidget(this.element);
        this.isEditable = await this.getIsEditable();

        this.elementIcon = this.getElementIcon();

        if (this.isImage) {
            this.displayImageThumbnail();
            if (isImageLibraryAsset(this.asset)) {
                this.isGenAi = !!this.asset.isGenAi;
            }
        } else if (this.isVideo) {
            this.displayVideoThumbnail();
        }

        this.tryUpdateWidgetThumbnail();

        this.changeDetector.detectChanges();
    }

    ngAfterViewInit(): void {
        if (this.elementContainer) {
            this.libraryElementRenderingService.renderStyledElement(
                this.element,
                this.elementContainer
            );
        }

        combineLatest([
            this.brandlibraryElementService.selectedElements$,
            this.mediaService.isDialogOpen$
        ])
            .pipe(
                filter(([_elements, isDialogOpen]) => isDialogOpen === this.parentIsDialog),
                map(([elements, _isDialogOpen]) => elements),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe(elements => {
                const isSelected = elements.some(element => this.element.id === element.id);
                const selectionChange = isSelected !== this.isSelected;

                if (selectionChange && isSelected) {
                    this.renderer2.addClass(this.host.nativeElement, 'selected');
                    this.isSelected = true;
                } else if (selectionChange) {
                    this.renderer2.removeClass(this.host.nativeElement, 'selected');
                    this.isSelected = false;
                    this.isEditingName = false;
                }

                if (selectionChange) {
                    this.detectChanges();
                }
            });
    }

    private displayImageThumbnail(): void {
        const asset = this.brandLibraryDataService.getAssetByElement(this.element);
        this.renderThumbnail();

        if (asset && asset.thumbnail.url.indexOf('data:image') > -1) {
            this.assetUploadService.uploadProgress$
                .pipe(
                    takeUntilDestroyed(this.destroyRef),
                    filter(uploadState => uploadState.status === 'COMPLETE'),
                    take(1)
                )
                .subscribe(() => {
                    this.renderThumbnail();
                });
        } else {
            const shouldAnimateProperty = this.element.properties.find(
                ({ name }) => name === 'shouldAnimateInLibrary'
            );
            this.animationState =
                shouldAnimateProperty && shouldAnimateProperty.value ? 'enteranimated' : 'enter';
            this.element.properties = this.element.properties.filter(
                ({ name }) => name !== 'shouldAnimateInLibrary'
            );
        }
    }

    isStyledElement(element: IElement): boolean {
        return this.elementRenderingService.isStyledElement(element);
    }

    private displayVideoThumbnail(): void {
        this.asset = this.brandLibraryDataService.getAssetByElement(this.element)!;
        this.renderThumbnail();
    }

    moveToFolder(): void {
        this.selectedElementToMoveToFolder.emit(this.element);
    }

    duplicate(): void {
        this.duplicateElement.emit(cloneDeep(this.element));
    }

    addElementToCanvas(): void {
        this.addToCanvas.emit();
    }

    editElementName($event: Event): void {
        if (this.isSelected && this.isElement) {
            $event.stopPropagation();
            $event.stopImmediatePropagation();
            this.isEditingName = true;
            this.isInputHovered = false;
            setTimeout(() => {
                this.nameInput.focus();
                this.brandLibraryElementEditService.isEditingName$$.next(true);
            });
        }
    }

    async onElementClick(): Promise<void> {
        if (!this.isEffect || this.isImportingEffect) {
            return;
        }

        const isImported = this.mediaLibrary.isBrandLibraryElementImported(this.element);
        const widget = this.mediaLibrary.getLibraryWidgetOfElement(this.element);
        if (!isImported && widget) {
            this.isImportingEffect = true;
            await this.mediaLibrary.importBannerflowLibraryWidget(widget);
            this.isImportingEffect = false;
            this.detectChanges();
        }
    }

    clickToContainer(): void {
        if (this.isEditingName) {
            this.isEditingName = false;
        }
        setTimeout(() => {
            this.brandLibraryElementEditService.isEditingName$$.next(false);
        });
    }

    downloadAsset(): void {
        if (!this.asset?.url) {
            throw new Error('Failed to get asset url to download.');
        }
        this.fileDownload.download(this.asset.url, removeFileExtension(this.asset.name));
    }

    copyAssetUrl(): void {
        const assetUrl = this.asset?.url;
        if (!assetUrl) {
            throw new Error('Could not get asset url to copy.');
        }

        const el = document.createElement('textarea');
        const url = new URL(assetUrl);
        el.value = assetUrl.replace(url.origin, environment.origins.studioBlobStorage);
        document.body.appendChild(el);
        el.select();
        navigator.clipboard.writeText(el.value);
        document.body.removeChild(el);
    }

    stopPropagation($event: Event): void {
        $event.stopPropagation();
        $event.stopImmediatePropagation();
    }

    updateName(input: UIInputComponent): void {
        this.isEditingName = false;
        this.isInputHovered = false;
        if (input.value === '') {
            input.value = this.element.name;
            return this.uiNotificationService.open('Element name cannot be empty.', {
                autoCloseDelay: 5000,
                type: 'error'
            });
        }
        if (input.value) {
            const dataNode = this.libraryElementRenderingService.dataNode;
            if (dataNode && input.value !== this.element.name) {
                dataNode.name = input.value;
                this.brandlibraryElementService.updateName(
                    dataNode as BrandLibraryElementDataNode,
                    this.element,
                    true
                );
            }

            input.blurInput();
        }
        this.brandLibraryElementEditService.isEditingName$$.next(false);
    }

    focusEditName(event: MouseEvent): void {
        if (this.isSelected) {
            this.isEditingName = true;
            event.preventDefault();
            event.stopImmediatePropagation();
            setTimeout(() => {
                this.nameInput.focus();
            });
            this.brandLibraryElementEditService.isEditingName$$.next(true);
        }
    }

    deleteAsset(): void {
        this.brandLibraryElementDeletionService.delete(this.element);
    }

    openWidgetInfo(): void {
        if (this.isEffect) {
            this.widgetService.openEffectInfoPage();
            return;
        }

        this.widgetService.openWidgetInfoPage();
    }

    async updateWidgetInCreativeSet(): Promise<void> {
        const dialogRef = this.uiDialogService.openComponent(UpdateWidgetDialogComponent, {
            headerText: `Update ${this.isEffect ? 'effect' : 'widget'} in this creative set?`,
            theme: 'default',
            width: '540px',
            data: { isEffect: this.isEffect }
        });

        await dialogRef.afterViewInit;
        const dialogInstance = dialogRef.subComponentRef.instance as UpdateWidgetDialogComponent;

        const response: UIConfirmDialogResult = await dialogInstance.initiate();

        if (response === 'confirm') {
            await this.handleWidgetUpdate();
        }
    }

    private async handleWidgetUpdate(): Promise<void> {
        const designs = distinctArrayById([
            this.editorStateService.designFork,
            ...this.editorStateService.designs
        ]);

        const dataElements = this.editorStateService.document.elements;
        const designDocuments = designs.map(({ document }) => document);
        const globalDataElements = designDocuments.flatMap(({ elements }) => elements);

        // We want to edit elements in both current editor & other designs
        const widgetDataElements = [...dataElements, ...globalDataElements].filter(node =>
            this.brandLibraryDataService.isParentElementOfNode(this.element, node)
        );

        if (widgetDataElements.length === 0) {
            this.uiNotificationService.open(
                'No widgets to update. This widget is not used in any creatives in this set.',
                {
                    autoCloseDelay: 5000,
                    placement: 'top',
                    type: 'error'
                }
            );
            return;
        }

        try {
            await this.elementReplaceService.updateWidgetInAllDesigns(this.element);

            const selection = [...this.elementSelectionService.currentSelection.elements];
            this.elementSelectionService.clearSelection();
            this.editorSaveStateService.save({
                saveAll: true,
                saveAndExit: false
            });
            this.elementSelectionService.setSelection(...selection);

            this.uiNotificationService.open(
                'The widget has now been updated in all creatives in this creative set.',
                {
                    autoCloseDelay: 5000,
                    placement: 'top',
                    type: 'success'
                }
            );
        } catch (error) {
            this.logger.error(error);
            this.uiNotificationService.open(
                'Something went wrong when updating widget in all creatives - please try again or contact support.',
                {
                    autoCloseDelay: 5000,
                    placement: 'top',
                    type: 'error'
                }
            );
        }
    }

    private detectChanges(): void {
        if (!(<ViewRef>this.changeDetector).destroyed) {
            this.changeDetector.detectChanges();
        }
    }

    private tryUpdateWidgetThumbnail(): void {
        if (this.isEffect && !this.isImportedEffect) {
            const url = this.mediaLibrary.getLibraryWidgetOfElement(this.element)?.thumbnailUrl;
            if (url) {
                this.thumbnailUrl = url;
                this.detectChanges();
            }
        } else if (this.isWidget) {
            const widgetAsset = this.brandLibraryDataService.getWidgetAssetByElement(this.element);
            if (widgetAsset && widgetAsset.thumbnail) {
                this.thumbnailUrl = widgetAsset.thumbnail;
                this.detectChanges();
            }
        }
    }

    private getIsElement(type?: ElementKind): boolean {
        if (type) {
            return this.element.type === type;
        }
        return (
            this.element.type === 'rectangle' ||
            this.element.type === 'ellipse' ||
            this.element.type === 'button' ||
            this.element.type === 'text'
        );
    }

    private getIsKebabMenuVisible(element: IBrandLibraryElement): boolean {
        if (this.isEffect && !this.mediaLibrary.isBrandLibraryElementImported(element)) {
            return false;
        }

        if (this.isElement || this.isWidgetOrMedia) {
            return true;
        }

        return false;
    }

    private async getIsEditable(): Promise<boolean> {
        return (
            (this.isElement &&
                !this.getIsElement(ElementKind.Text) &&
                !this.getIsElement(ElementKind.Button) &&
                !this.getIsElement(ElementKind.Rectangle) &&
                !this.getIsElement(ElementKind.Ellipse)) ||
            this.isImage ||
            this.isVideo ||
            (this.isWidget && (await this.canEditWidget()))
        );
    }

    renderThumbnail(): void {
        this.thumbnailUrl = this.asset?.thumbnail.url.replace(/\s/g, '%20');
    }

    toggleAnimatedThumbnail(play: boolean): void {
        if (this.isWidget) {
            const widgetAsset = this.brandLibraryDataService.getWidgetAssetByElement(this.element);
            const thumbnail = play ? widgetAsset?.animatedThumbnail : widgetAsset?.thumbnail;
            if (thumbnail) {
                this.thumbnailUrl = thumbnail.replace(/\s/g, '%20');
            }
        }
        if (this.isImage && this.asset && isAnimatedThumbnailSupportedAsset(this.asset)) {
            const thumbnail = play ? this.asset.animatedThumbnail?.url : this.asset.thumbnail.url;
            if (thumbnail) {
                this.thumbnailUrl = thumbnail.replace(/\s/g, '%20');
            }
        }
    }

    getElementIcon(): Icon {
        if (!('type' in this.element)) {
            return 'feed';
        }

        switch (this.element.type) {
            case ElementKind.Text:
                return 'text';
            case ElementKind.Button:
                return 'button';
            case ElementKind.Rectangle:
                return 'shape-rectangle';
            case ElementKind.Ellipse:
                return 'shape-oval';
            case ElementKind.Image:
                return 'image';
            case ElementKind.Video:
                return isHeavyVideo(this.asset?.fileSize) ? 'video-heavy' : 'video';
            case ElementKind.Widget:
                return 'widget';
            case ElementKind.BannerflowLibraryWidget:
                if (this.isEffect) {
                    return 'social-fx';
                }
                return 'widget';
            default:
                return 'image';
        }
    }

    /**
     * Only allow editing a widget if:
     *   it is a bannerflow library widget and the user has the permission
     *   it is not a bannerflow library widget
     */
    async canEditWidget(): Promise<boolean> {
        if (isOriginalBannerFlowLibraryWidget(this.element)) {
            return await this.userService.hasPermission('BannerflowLibrary', true);
        }
        if (this.isBannerFlowLibraryWidget) {
            return false;
        }
        return true;
    }

    emitEditElement(): void {
        this.editElement.emit(this.element);
    }

    openInAIStudio(): void {
        const { id, name } = this.element;
        this.genAIService.openElementInAIStudio(id, name);
    }

    private computeAIStudioState(): IAISupported {
        if (!this.asset) {
            return { supported: false };
        }

        return this.aiStudioService.isSupported(this.asset);
    }

    private async isExportableWidget(element: IBrandLibraryElement): Promise<boolean> {
        const widgetReference = getLibraryWidgetReferenceOfElement(element);
        if (widgetReference) {
            const bflWidget = await this.widgetService.get(widgetReference.value);
            return bflWidget.exportable;
        }

        return false;
    }
}

interface IProgressElement extends IBrandLibraryElement {
    progress: number;
}
