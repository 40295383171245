import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FiltersService } from '../filters.service';
import { FiltersEffects } from './filters.effects';
import * as fromFilter from './filters.reducer';

@NgModule({
    imports: [
        StoreModule.forFeature(fromFilter.FILTERS_FEATURE_KEY, fromFilter.reducer),
        EffectsModule.forFeature([FiltersEffects])
    ],
    providers: [FiltersService]
})
export class FiltersStoreModule {}
