import { inject, Injectable } from '@angular/core';
import {
    AssetUploadService,
    IAssetUploadAfterProgressState,
    IAssetUploadState
} from '@app/design-view/services';
import { BrandLibraryDataService } from '@app/shared/media-library/brand-library.data.service';
import { Logger } from '@bannerflow/sentinel-logger';
import { UINotificationService } from '@bannerflow/ui';
import { IBrandLibraryElement } from '@domain/creativeset';
import { AssetReference } from '@domain/element-asset';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { isImageLibraryAsset } from '@studio/domain/brand-library/assets';
import { createElementProperty } from '@studio/utils/element.utils';
import {
    catchError,
    EMPTY,
    filter,
    from,
    map,
    Observable,
    of,
    switchMap,
    take,
    tap,
    withLatestFrom
} from 'rxjs';
import { AIStudioService } from '../ai-studio.service';
import { base64ToFile, imageUrlToBlob } from '../utils';
import * as GenAIActions from './gen-ai.actions';
import { GenAIDataService } from './gen-ai.data.service';
import { GenAIService } from './gen-ai.service';
import { SaveType } from '@studio/domain/components/ai-studio.types';

@Injectable()
export class GenAIEffects {
    private actions$ = inject(Actions);
    private aiStudioService = inject(AIStudioService);
    private assetUploadService = inject(AssetUploadService);
    private brandLibraryDataService = inject(BrandLibraryDataService);
    private genAIDataService = inject(GenAIDataService);
    private genAIService = inject(GenAIService);
    private uiNotificationService = inject(UINotificationService);

    private logger = new Logger('GenAIEffects');

    generateImage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GenAIActions.generateImage),
            switchMap(({ prompt }) => {
                prompt = prompt.trim();
                if (!prompt) {
                    return of(
                        GenAIActions.generateImageFailure({
                            error: new Error('Invalid prompt provided')
                        })
                    );
                }

                return this.genAIDataService.generateImage(prompt).pipe(
                    switchMap(result => of(GenAIActions.generateImageSuccess({ result }))),
                    catchError(error => of(GenAIActions.generateImageFailure({ error })))
                );
            })
        );
    });

    inpaint$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GenAIActions.inpaint),
            withLatestFrom(
                this.genAIService.currentImageAsBase64$,
                this.genAIService.generativeFillImageMask$
            ),
            switchMap(([{ prompt, negativePrompt }, currentImageAsBase64, imageMask]) => {
                if (!currentImageAsBase64) {
                    return of(GenAIActions.inpaintFailure({ error: new Error('Invalid image') }));
                }
                prompt = prompt.trim();
                if (!prompt || !imageMask) {
                    return of(
                        GenAIActions.inpaintFailure({
                            error: new Error('Invalid prompt, imageUrl or imageMask provided')
                        })
                    );
                }

                return this.genAIDataService
                    .inpaint({
                        prompt,
                        negativePrompt,
                        imageInBase64: currentImageAsBase64,
                        imageMask: imageMask
                    })
                    .pipe(
                        switchMap(result => of(GenAIActions.inpaintSuccess({ result }))),
                        catchError(error => of(GenAIActions.inpaintFailure({ error })))
                    );
            })
        );
    });

    erase$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GenAIActions.erase),
            withLatestFrom(this.genAIService.currentImageAsBase64$, this.genAIService.eraseImageMask$),
            switchMap(([_, currentImageAsBase64, imageMask]) => {
                if (!currentImageAsBase64) {
                    return of(GenAIActions.inpaintFailure({ error: new Error('Invalid image') }));
                }

                return this.genAIDataService
                    .erase({
                        imageInBase64: currentImageAsBase64,
                        imageMask: imageMask
                    })
                    .pipe(
                        switchMap(result => of(GenAIActions.eraseSuccess({ result }))),
                        catchError(error => of(GenAIActions.eraseFailure({ error })))
                    );
            })
        );
    });

    removeBackground$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GenAIActions.removeBackground),
            withLatestFrom(this.genAIService.currentImageAsBase64$),
            switchMap(([_action, currentImageAsBase64]) => {
                if (!currentImageAsBase64) {
                    return of(
                        GenAIActions.removeBackgroundFailure({
                            error: new Error('Invalid image URL provided')
                        })
                    );
                }

                return this.genAIDataService.removeBackground(currentImageAsBase64).pipe(
                    switchMap(result => of(GenAIActions.removeBackgroundSuccess({ result }))),
                    catchError(error => of(GenAIActions.removeBackgroundFailure({ error })))
                );
            })
        );
    });

    searchAndReplace$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GenAIActions.searchAndReplace),
            withLatestFrom(this.genAIService.currentImageAsBase64$),
            switchMap(([{ searchPrompt, replacePrompt }, currentImageAsBase64]) => {
                searchPrompt = searchPrompt.trim();
                replacePrompt = replacePrompt.trim();

                if (!searchPrompt || !replacePrompt || !currentImageAsBase64) {
                    return of(
                        GenAIActions.inpaintFailure({
                            error: new Error('Invalid searchPrompt, replacePrompt or image provided')
                        })
                    );
                }

                return this.genAIDataService
                    .searchAndReplace(searchPrompt, replacePrompt, currentImageAsBase64)
                    .pipe(
                        switchMap(result => of(GenAIActions.searchAndReplaceSuccess({ result }))),
                        catchError(error => of(GenAIActions.searchAndReplaceFailure({ error })))
                    );
            })
        );
    });

    outpaint$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GenAIActions.outpaint),
            withLatestFrom(this.genAIService.currentImageAsBase64$),
            switchMap(([{ prompt, negativePrompt, settings: settigns }, currentImageAsBase64]) => {
                if (!currentImageAsBase64) {
                    return of(
                        GenAIActions.removeBackgroundFailure({
                            error: new Error('Invalid image URL provided')
                        })
                    );
                }
                if (!(settigns.down || settigns.right || settigns.up || settigns.left)) {
                    return of(
                        GenAIActions.outpaintFailure({
                            error: new Error(
                                'Invalid extend settings provided (left, right, up or down must be set to a value)'
                            )
                        })
                    );
                }
                return this.genAIDataService
                    .outpaint(currentImageAsBase64, settigns, prompt, negativePrompt)
                    .pipe(
                        switchMap(result => of(GenAIActions.outpaintSuccess({ result }))),
                        catchError(error => of(GenAIActions.outpaintFailure({ error })))
                    );
            })
        );
    });

    successNotifications$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(
                    GenAIActions.inpaintSuccess,
                    GenAIActions.outpaintSuccess,
                    GenAIActions.removeBackgroundSuccess,
                    GenAIActions.generateImageSuccess
                ),
                tap(() => this.showSuccessNotification())
            );
        },
        { dispatch: false }
    );

    errorNotification$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(
                    GenAIActions.generateImageFailure,
                    GenAIActions.inpaintFailure,
                    GenAIActions.outpaintFailure,
                    GenAIActions.removeBackgroundFailure,
                    GenAIActions.saveGeneratedImageToBrandLibraryFailure
                ),
                tap(({ error }) => this.showErrorNotification(error))
            );
        },
        { dispatch: false }
    );

    uploadImage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GenAIActions.saveGeneratedImageToBrandLibrary),
            switchMap(({ fileName }) => this.uploadFile(fileName)),
            switchMap(uploadProgress => {
                if (uploadProgress.status === 'COMPLETE') {
                    return of(GenAIActions.saveGeneratedImageToBrandLibrarySuccess());
                } else if (uploadProgress.status === 'FAIL') {
                    return of(
                        GenAIActions.saveGeneratedImageToBrandLibraryFailure({
                            error: new Error('An error has occurred while uploading the file')
                        })
                    );
                } else {
                    return EMPTY;
                }
            }),
            catchError(error =>
                of(GenAIActions.saveGeneratedImageToBrandLibraryFailure({ error: error }))
            )
        );
    });

    openInAiStudio$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GenAIActions.openInAiStudio),
            switchMap(({ fileName }) => this.uploadFile(fileName, false)),
            switchMap(uploadProgress => {
                if (uploadProgress.status === 'COMPLETE') {
                    return EMPTY;
                }
                if (uploadProgress.status === 'AFTER_PROGRESS') {
                    if (isImageLibraryAsset(uploadProgress.newAsset)) {
                        this.aiStudioService.openAIStudio();
                        return of(
                            GenAIActions.openInAiStudioSuccess({
                                element: undefined,
                                asset: uploadProgress.newAsset,
                                assetName: uploadProgress.newAsset.name
                            })
                        );
                    }
                }
                return of(
                    GenAIActions.openInAiStudioFailure({
                        error: new Error('An error has occurred while opening file')
                    })
                );
            }),
            catchError(error => of(GenAIActions.openInAiStudioFailure({ error })))
        );
    });

    openElementInAIStudio$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GenAIActions.openElementInAIStudio),
            tap(({ elementId, asset }) => {
                if (asset && !this.aiStudioService.isSupportedFileType(asset.url)) {
                    this.showErrorNotification(new Error('Unsupported file type'));
                    this.aiStudioService.closeAIStudio();
                    return EMPTY;
                }

                if (!elementId && !asset) {
                    this.aiStudioService.closeAIStudio();
                    return EMPTY;
                }

                this.aiStudioService.openAIStudio();
            }),
            switchMap(({ elementId, asset }) => {
                let imageAsset = asset;
                const brandLibraryElement = this.brandLibraryDataService.getElementById(elementId);
                if (brandLibraryElement) {
                    const brandLibraryImageAsset =
                        this.brandLibraryDataService.getAssetByElement(brandLibraryElement);
                    if (brandLibraryImageAsset) {
                        imageAsset = brandLibraryImageAsset;
                    }
                }
                if (!imageAsset) {
                    return EMPTY;
                }
                return from(imageUrlToBlob(imageAsset.url)).pipe(
                    switchMap(blob => {
                        const linkToBase64 = URL.createObjectURL(blob);
                        return of(GenAIActions.setImageAsset({ imageAsset, linkToBase64 }));
                    })
                );
            })
        );
    });

    closeAIStudio$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GenAIActions.closeAIStudio),
            tap(() => {
                this.aiStudioService.closeAIStudio();
            }),
            switchMap(() => {
                return of(GenAIActions.resetState());
            })
        );
    });

    saveToBrandLibrary$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GenAIActions.saveToBrandLibrary),
            withLatestFrom(this.genAIService.openedElementId$, this.genAIService.assetName$),
            switchMap(([{ replace }, elementId, assetName]) => {
                if (!assetName) {
                    const errorMessage = 'Invalid asset name';
                    return of(
                        GenAIActions.saveToBrandLibraryFailure({ error: new Error(errorMessage) })
                    );
                }

                if (!replace) {
                    return this.duplicateBrandLibraryElement(assetName);
                }

                if (!elementId) {
                    const errorMessage = 'Could not replace image';
                    return of(
                        GenAIActions.saveToBrandLibraryFailure({ error: new Error(errorMessage) })
                    );
                }
                const brandLibraryElement = this.brandLibraryDataService.getElementById(elementId);
                if (!brandLibraryElement) {
                    const errorMessage = 'Could not find element in Brand Library';
                    return of(
                        GenAIActions.saveToBrandLibraryFailure({ error: new Error(errorMessage) })
                    );
                }
                return this.replaceBrandLibraryImage(assetName, brandLibraryElement);
            })
        );
    });

    saveToBrandLibrarySuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GenAIActions.saveToBrandLibrarySuccess),
            tap(() => {
                this.uiNotificationService.open('Image saved to brand library successfully', {
                    placement: 'top',
                    type: 'success',
                    autoCloseDelay: 3000
                });
            }),
            map(() => {
                return GenAIActions.closeAIStudio();
            })
        );
    });

    saveOnCanvas$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GenAIActions.saveOnCanvas),
            withLatestFrom(this.genAIService.assetName$),
            switchMap(([{ saveType, replaceInAllDesigns }, assetName]) => {
                if (!assetName) {
                    return of(
                        GenAIActions.saveOnCanvasFailure({
                            error: new Error('Invalid asset name')
                        })
                    );
                }
                return this.handleUpload(assetName, saveType, replaceInAllDesigns);
            })
        );
    });

    private handleUpload(
        assetName: string,
        saveType: SaveType,
        replaceInAllDesigns: boolean | undefined
    ): Observable<Action> {
        return this.uploadFile(assetName, false).pipe(
            switchMap(this.handleUploadProgress),
            take(1),
            switchMap(uploadProgress => {
                this.genAIService.closeAIStudio();
                if (saveType === SaveType.Replace) {
                    return of(
                        GenAIActions.saveOnCanvasSuccess({
                            imageAsset: uploadProgress.newAsset,
                            replaceInAllDesigns,
                            saveType: SaveType.Replace
                        })
                    );
                }
                return of(
                    GenAIActions.saveOnCanvasSuccess({
                        imageAsset: uploadProgress.newAsset,
                        saveType: SaveType.Duplicate
                    })
                );
            }),
            catchError(error => {
                this.logger.error(error);
                return of(GenAIActions.saveOnCanvasFailure({ error }));
            })
        );
    }

    private replaceBrandLibraryImage(
        assetName: string,
        brandLibraryElement: IBrandLibraryElement
    ): Observable<Action> {
        return this.uploadFile(assetName, false).pipe(
            switchMap(this.handleUploadProgress),
            take(1),
            switchMap(uploadProgress => {
                const updatedElement: IBrandLibraryElement = {
                    ...brandLibraryElement,
                    name: assetName,
                    properties: [
                        createElementProperty({
                            unit: 'id',
                            name: AssetReference.Image,
                            value: uploadProgress.newAsset.id
                        })
                    ]
                };
                return this.brandLibraryDataService.updateElement(updatedElement).pipe(
                    switchMap(() => {
                        return of(GenAIActions.saveToBrandLibrarySuccess({ replace: true }));
                    })
                );
            }),
            catchError(error => {
                this.logger.error(error);
                return of(GenAIActions.saveToBrandLibraryFailure({ error }));
            })
        );
    }

    private duplicateBrandLibraryElement(assetName: string): Observable<Action> {
        return this.uploadFile(assetName, true).pipe(
            switchMap(this.handleUploadProgress),
            take(1),
            switchMap(() => {
                return of(GenAIActions.saveToBrandLibrarySuccess({ replace: false }));
            }),
            catchError(error => {
                this.logger.error(error);
                return of(GenAIActions.saveToBrandLibraryFailure({ error }));
            })
        );
    }

    private uploadFile(
        fileName: string,
        shouldUpdateMediaLibrary = true
    ): Observable<IAssetUploadState> {
        return this.genAIService.currentImageAsBase64$.pipe(
            take(1),
            map(currentImage => {
                if (!currentImage) {
                    throw new Error('No image to be saved');
                }
                const file = base64ToFile(currentImage, fileName);
                return file;
            }),
            switchMap(file => {
                const uploadAssets$ = from(
                    this.assetUploadService.uploadAssets({
                        files: [file],
                        shouldUpdateMediaLibrary,
                        isGenAi: true
                    })
                );

                let assetUploadId: string | undefined;
                return uploadAssets$.pipe(
                    tap(uploadId => (assetUploadId = uploadId ?? undefined)),
                    switchMap(() => this.assetUploadService.uploadProgress$),
                    filter(
                        uploadProgress =>
                            uploadProgress.uploadProcessId === assetUploadId &&
                            (uploadProgress.status === 'COMPLETE' ||
                                uploadProgress.status === 'FAIL' ||
                                uploadProgress.status === 'AFTER_PROGRESS')
                    )
                );
            })
        );
    }

    private showSuccessNotification(): void {
        this.uiNotificationService.open('Image generated successfully.', {
            placement: 'top',
            autoCloseDelay: 5000,
            type: 'success'
        });
    }

    private showErrorNotification(error: unknown): void {
        if (error instanceof Error) {
            this.uiNotificationService.open(`An error has occurred. "${error.message}"`, {
                type: 'error',
                placement: 'top',
                autoCloseDelay: 0
            });
            this.logger.error(error.message);
        } else {
            this.uiNotificationService.open(`An unknown error has occurred`, {
                type: 'error',
                placement: 'top',
                autoCloseDelay: 0
            });
            this.logger.error(error);
        }
    }

    private handleUploadProgress(
        uploadProgress: IAssetUploadState
    ): Observable<IAssetUploadAfterProgressState> {
        if (uploadProgress.status === 'COMPLETE') {
            return EMPTY;
        }
        if (uploadProgress.status === 'AFTER_PROGRESS') {
            return of(uploadProgress);
        }
        throw new Error('An error has occurred while uploading file');
    }
}
