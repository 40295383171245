import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { CreativeSize } from '@domain/creativeset';
import { map } from 'rxjs';
import { FiltersService } from '../../../../shared/filters/filters.service';
import { SortByGroupWidthPipe } from '../../../../shared/pipes/filter-list.pipe';

@Component({
    standalone: true,
    imports: [UIModule, CommonModule, SortByGroupWidthPipe],
    selector: 'size-picker',
    templateUrl: 'size-picker.component.html',
    styleUrls: ['size-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SizePickerComponent {
    private filtersService = inject(FiltersService);

    sizes$ = this.filtersService.creativeSizes$;
    selectedSizes$ = this.filtersService.selectedCreativeSizes$;
    placeholder$ = this.sizes$.pipe(map(sizes => (sizes.length ? 'All sizes' : 'No sizes')));

    selectedChange(sizes: CreativeSize[]): void {
        const sizesIds = sizes.map(size => size.id);
        this.filtersService.setSizeFilter(sizesIds);
    }
}
