import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class TruncationService {
    /**
     * Get width of element.
     */
    getWidth(element: HTMLElement): number {
        const computedStyle = window.getComputedStyle(element);
        const paddingRight = computedStyle.getPropertyValue('padding-right') || '0';
        const paddingLeft = computedStyle.getPropertyValue('padding-left') || '0';
        const padding = (parseFloat(paddingLeft) || 0) + (parseFloat(paddingRight) || 0);
        const width = element.offsetWidth - padding;

        return width;
    }

    /**
     * Truncate string from middle.
     */
    truncate(fullStr: string, maxLength: number, separator: string = '...'): string {
        if (fullStr.length <= maxLength) {
            return fullStr;
        }
        const sepLen = separator.length;
        const charsToShow = maxLength - sepLen;
        const frontChars = Math.ceil(charsToShow / 2);
        const backChars = Math.floor(charsToShow / 2);

        return this.truncateTextByPadding(fullStr, frontChars, backChars, separator);
    }

    truncateTextByPadding(
        fullStr: string,
        frontChars: number,
        backChars: number,
        separator: string
    ): string {
        return (
            fullStr.substr(0, frontChars).trim() +
            separator +
            fullStr.substr(fullStr.length - backChars).trim()
        );
    }
}
