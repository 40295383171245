import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UIDialogDirective } from '@bannerflow/ui';
import { CreativeSize } from '@domain/creativeset';
import { Breakpoint } from '@studio/utils/breakpoints';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MediaDirective } from '../../../shared/directives/media.directive';
import { PermissionsDirective } from '../../../shared/directives/permissions.directive';
import { FiltersService } from '../../../shared/filters/filters.service';
import { EditCreativeService } from '../services/edit-creative.service';
import { FilterListSizesComponent } from './filter-list-sizes/filter-list-sizes.component';
import { FilterListStatusComponent } from './status/filter-list-status.component';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        FilterListSizesComponent,
        FilterListStatusComponent,
        PermissionsDirective,
        MediaDirective
    ],
    selector: 'filter-list',
    templateUrl: './filter-list.component.html',
    styleUrls: ['./filter-list.component.scss']
})
export class FilterListComponent implements AfterViewInit, OnDestroy {
    @ViewChild('dialog') dialog: UIDialogDirective;

    sizes: CreativeSize[];

    private unsubscribe$ = new Subject<void>();
    Breakpoint = Breakpoint;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private editCreativeService: EditCreativeService,
        private filtersService: FiltersService
    ) {
        this.filtersService.creativeSizes$.pipe(takeUntilDestroyed()).subscribe(sizes => {
            this.sizes = sizes;
        });
    }

    ngAfterViewInit(): void {
        this.editCreativeService.updateView$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.filtersService.setAllSizes();
            this.changeDetectorRef.detectChanges();
        });

        this.editCreativeService.deletion.change$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.filtersService.setAllSizes();
            this.filtersService.clearSizesFilter();
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
