import { IFontFamily } from '@domain/font-families';
import { ILineColumnPosition, ILineColumnPositionDirection } from '@domain/rich-text';
import { IRichTextEditorKeyboardBindings } from '@domain/rich-text/rich-text.editor.keyboard-bindings.header';
import { IRichText } from '@domain/rich-text/rich-text.header';
import { ITextSelection } from '@domain/rich-text/rich-text.selection.header';
import {
    ICompositionSpan,
    IContentLine,
    OneOfEditableSpans,
    OneOfRenderedSpans,
    SpanType,
    TextDirection
} from '@domain/text';
import { IHotkeyBetterService } from '@domain/hotkeys/hotkeys.types';
import { BrowserDefaultHotkeys } from '@studio/hotkeys';
import { __inject, __parent, inject, parent } from '@studio/utils/di';
import { toRGBA } from '../../color.utils';
import { T } from '../../creative.container';
import { serializePropertyValue } from '../../serialization/property-value-serializer';
import { deserializeElementStyle } from '../../serialization/text-serializer';
import { FALLBACK_FONT_FAMILY } from './rich-text';
import { RichTextEditorService } from './rich-text.editor';
import { SELECTION_FOCUSED_BACKGROUND_COLOR } from './rich-text.selection';
import { CaretRange } from './rich-text.types';
import {
    copyStyle,
    createSpansFromString,
    hasSameStyle,
    isContentSpan,
    isNewlineLikeCharacter,
    isSpaceLikeCharacter,
    isVariableSpan,
    sequenceStyleIds
} from './text-nodes';

export const SELECTION_BLURRED_BACKGROUND_COLOR = '#ddd';

let lastInputId = 0;
export class RichTextEditorKeyboardBindings implements IRichTextEditorKeyboardBindings {
    textarea: HTMLTextAreaElement;
    compositionSpanElement: HTMLSpanElement;
    inCompositionMode: boolean;
    erasedTextBackwards: boolean;
    inputId: number;
    windowIsBlurred = false;
    isBackwardErasure = false;

    private preventNextKeypress = false;
    private undoTextChangeHandler: () => void;
    private redoTextChangeHandler: () => void;
    private saveAndExitHandler: () => void;
    private saveHandler: () => void;

    constructor(
        @parent() public editor: RichTextEditorService,
        @inject(T.HOTKEY_SERVICE) public hotkeyService: IHotkeyBetterService,
        @inject(T.FONT_FAMILIES, { optional: true }) private _fontFamilies?: IFontFamily[]
    ) {
        this.inputId = lastInputId++;
    }

    get textLines(): IContentLine[] {
        return this.text.textLines_m;
    }

    get selection(): ITextSelection | undefined {
        return this.editor.selection.selection;
    }

    set selection(selection: ITextSelection | undefined) {
        this.editor.selection.selection = selection;
    }

    get text(): IRichText {
        return this.editor.text;
    }

    init(): void {
        this.renderKeyboardInputHijacker();
        if (process.env.STUDIO_JS) {
            this.hotkeyService.pushContext({
                name: 'TextEditMode',
                input: this.textarea,
                keyPropogationExclusions: ['Z', 'Y'],
                keyDefaultBehaviourExclusions: [
                    BrowserDefaultHotkeys.Save,
                    BrowserDefaultHotkeys.SelectAll,
                    BrowserDefaultHotkeys.Undo,
                    BrowserDefaultHotkeys.Redo,
                    BrowserDefaultHotkeys.OpenSource,
                    BrowserDefaultHotkeys.GoBack,
                    BrowserDefaultHotkeys.GoForward
                ]
            });
        }
    }

    renderKeyboardInputHijacker(): void {
        this.textarea = document.createElement('textarea');
        this.textarea.classList.add('rich-text-textarea');
        this.textarea.setAttribute('data-test-id', 'rich-text-textarea');
        if (this.text.viewElement_m) {
            this.textarea.id = `rich-text-input-${this.text.viewElement_m.elementCid}`;
        }
        this.textarea.style.width = '1px';
        this.textarea.style.height = '1px';
        this.textarea.style.position = 'absolute';
        this.textarea.style.top = '0';
        this.textarea.style.left = '0';
        this.textarea.style.border = 'none';
        this.textarea.style.background = 'transparent';
        this.textarea.style.outline = 'none';
        this.textarea.style.padding = '0';
        this.textarea.style.color = 'transparent';
        this.textarea.style.opacity = '0.1';
        this.textarea.style.resize = 'none';
        this.textarea.style.overflow = 'hidden';
        this.textarea.style.zIndex = '1';
        this.textarea.setAttribute('wrap', 'off');
        this.textarea.setAttribute('autocorrect', 'off');
        this.textarea.setAttribute('autocapitalize', 'off');
        this.textarea.setAttribute('autocomplete', 'off');
        this.textarea.addEventListener('keydown', this.onTextareaKeyPress);
        this.textarea.addEventListener('compositionstart', this.onCompositionStart);
        this.textarea.addEventListener('compositionend', this.onCompositionEnd);
        this.textarea.addEventListener('focus', this.onTextareaFocus);
        this.textarea.addEventListener('blur', this.onTextareaBlur);
        window.addEventListener('blur', this.onWindowBlur);
        window.addEventListener('focus', this.onWindowFocus);
        this.text.rootElement_m.addEventListener('mousedown', this.editor.onRootElementMouseDown);

        document.body.appendChild(this.textarea);
    }

    private registerHotkeys(): void {
        this.hotkeyService.on('EraseWord', this.onEraseWord);
        this.hotkeyService.on('EraseToStartOfLine', this.onEraseToBeginningOfLine);

        this.hotkeyService.on('MoveCaretToStartOfLine', this.onMoveCaretToStartOfLine);
        this.hotkeyService.on('MoveCaretToEndOfLine', this.onMoveCaretToEndOfLine);
        this.hotkeyService.on('MoveCaretToStartOfParagraph', this.onMoveCaretToStartOfParagraph);
        this.hotkeyService.on('MoveCaretToEndOfParagraph', this.onMoveCaretToEndOfParagraph);
        this.hotkeyService.on('MoveCaretToStartOfText', this.onMoveCaretToStartOfText);
        this.hotkeyService.on('MoveCaretToEndOfText', this.onMoveCaretToEndOfText);
        this.hotkeyService.on('MoveCaretToPreviousWord', this.onMoveCaretToPreviousWord);
        this.hotkeyService.on('MoveCaretToNextWord', this.onMoveCaretToNextWord);

        this.hotkeyService.on('SelectTextToStartOfText', this.onSelectTextToStartOfText);
        this.hotkeyService.on('SelectTextToEndOfText', this.onSelectTextToEndOfText);
        this.hotkeyService.on('SelectTextToStartOfLine', this.onSelectTextToStartOfLine);
        this.hotkeyService.on('SelectTextToEndOfLine', this.onSelectTextToEndOfLine);
        this.hotkeyService.on('SelectTextToStartOfParagraph', this.onSelectTextToStartOfParagraph);
        this.hotkeyService.on('SelectTextToEndOfParagraph', this.onSelectTextToEndOfParagraph);
        this.hotkeyService.on('SelectTextToPreviousWord', this.onSelectTextToPreviousWord);
        this.hotkeyService.on('SelectTextToNextWord', this.onSelectTextToNextWord);
        this.hotkeyService.on('SelectTextForward', this.onSelectTextForward);
        this.hotkeyService.on('SelectTextBackward', this.onSelectTextBackward);
        this.hotkeyService.on('SelectTextUpward', this.onSelectTextUpward);
        this.hotkeyService.on('SelectTextDownward', this.onSelectTextDownward);
        this.hotkeyService.on(
            'SelectTextToCurrentClick',
            this.editor.mouseBindings.onSelectTextToCurrentClick
        );
        this.hotkeyService.on('SelectAllText', this.onSelectAllText);

        this.hotkeyService.on('EraseTextForward', this.onEraseTextForward);
        this.hotkeyService.on('EraseTextBackward', this.onEraseTextBackward);

        this.textarea.addEventListener('paste', this.onPasteText);
        this.hotkeyService.on('CopySelectedText', this.onCopySelectedText);
        this.hotkeyService.on('CutSelectedText', this.onCutSelectedText);

        this.hotkeyService.on('MoveCaretForward', this.onMoveCaretForward);
        this.hotkeyService.on('MoveCaretBackward', this.onMoveCaretBackward);
        this.hotkeyService.on('MoveCaretUpward', this.onMoveCaretUpward);
        this.hotkeyService.on('MoveCaretDownward', this.onMoveCaretDownward);

        this.undoTextChangeHandler = this.hotkeyService.forward('UndoTextChange', 'Undo');
        this.redoTextChangeHandler = this.hotkeyService.forward('RedoTextChange', 'Redo');

        this.saveHandler = this.hotkeyService.forward('SaveOnTextEditMode', 'Save');
        this.saveAndExitHandler = this.hotkeyService.forward(
            'SaveAndExitOnTextEditMode',
            'SaveAndExit'
        );
    }

    unregisterHotkeys(): void {
        if (!this.hotkeyService) {
            return;
        }
        this.hotkeyService.off('EraseWord', this.onEraseWord);
        this.hotkeyService.off('EraseToStartOfLine', this.onEraseToBeginningOfLine);

        this.hotkeyService.off('MoveCaretToStartOfLine', this.onMoveCaretToStartOfLine);
        this.hotkeyService.off('MoveCaretToEndOfLine', this.onMoveCaretToEndOfLine);
        this.hotkeyService.off('MoveCaretToStartOfParagraph', this.onMoveCaretToStartOfParagraph);
        this.hotkeyService.off('MoveCaretToEndOfParagraph', this.onMoveCaretToEndOfParagraph);
        this.hotkeyService.off('MoveCaretToStartOfText', this.onMoveCaretToStartOfText);
        this.hotkeyService.off('MoveCaretToEndOfText', this.onMoveCaretToEndOfText);
        this.hotkeyService.off('MoveCaretToPreviousWord', this.onMoveCaretToPreviousWord);
        this.hotkeyService.off('MoveCaretToNextWord', this.onMoveCaretToNextWord);

        this.hotkeyService.off('SelectTextToStartOfText', this.onSelectTextToStartOfText);
        this.hotkeyService.off('SelectTextToEndOfText', this.onSelectTextToEndOfText);
        this.hotkeyService.off('SelectTextToStartOfLine', this.onSelectTextToStartOfLine);
        this.hotkeyService.off('SelectTextToEndOfLine', this.onSelectTextToEndOfLine);
        this.hotkeyService.off('SelectTextToStartOfParagraph', this.onSelectTextToStartOfParagraph);
        this.hotkeyService.off('SelectTextToEndOfParagraph', this.onSelectTextToEndOfParagraph);
        this.hotkeyService.off('SelectTextToPreviousWord', this.onSelectTextToPreviousWord);
        this.hotkeyService.off('SelectTextToNextWord', this.onSelectTextToNextWord);
        this.hotkeyService.off('SelectTextForward', this.onSelectTextForward);
        this.hotkeyService.off('SelectTextBackward', this.onSelectTextBackward);
        this.hotkeyService.off('SelectTextUpward', this.onSelectTextUpward);
        this.hotkeyService.off('SelectTextDownward', this.onSelectTextDownward);
        this.hotkeyService.off(
            'SelectTextToCurrentClick',
            this.editor.mouseBindings.onSelectTextToCurrentClick
        );
        this.hotkeyService.off('SelectAllText', this.onSelectAllText);

        this.hotkeyService.off('EraseTextForward', this.onEraseTextForward);
        this.hotkeyService.off('EraseTextBackward', this.onEraseTextBackward);

        this.hotkeyService.off('CopySelectedText', this.onCopySelectedText);
        this.hotkeyService.off('CutSelectedText', this.onCutSelectedText);
        this.textarea?.removeEventListener('paste', this.onPasteText);

        this.hotkeyService.off('MoveCaretForward', this.onMoveCaretForward);
        this.hotkeyService.off('MoveCaretBackward', this.onMoveCaretBackward);
        this.hotkeyService.off('MoveCaretUpward', this.onMoveCaretUpward);
        this.hotkeyService.off('MoveCaretDownward', this.onMoveCaretDownward);

        this.hotkeyService.off('UndoTextChange', this.undoTextChangeHandler);
        this.hotkeyService.off('RedoTextChange', this.redoTextChangeHandler);

        this.hotkeyService.off('SaveOnTextEditMode', this.saveHandler);
        this.hotkeyService.off('SaveAndExitOnTextEditMode', this.saveAndExitHandler);
    }

    onSelectTextToStartOfText = (): void => {
        if (!this.editor.selection.selection) {
            return;
        }
        this.editor.mouseBindings.isHandlingDownClick = true;
        this.editor.selection.selection.focus = { line: 0, column: 0, dir: TextDirection.Ltr };
        this.editor.selection.updateSelection();
    };

    onSelectTextToEndOfText = (): void => {
        if (!this.editor.selection.selection) {
            return;
        }
        this.editor.mouseBindings.isHandlingDownClick = true;
        this.editor.selection.selection.focus = this.editor.selection.getEndPosition();
        this.editor.selection.updateSelection();
    };

    onEraseTextBackward = (): void => {
        if (!this.selection || this.editor.keyboardBindings.inCompositionMode) {
            this.editor.keyboardBindings.erasedTextBackwards = true;
            return;
        }

        // Flag used for keeping the current style. Backward erasure should not update the current style.
        this.isBackwardErasure = true;

        // Only erase backwards when it is collapsed.
        if (this.selection.isCollapsed) {
            const end = Object.assign({}, this.selection.end);
            const caretPosition = (this.selection.start = this.editor.selection.decrementCaretPosition(
                this.selection.start
            ));
            const characterPosition =
                this.editor.selection.getCharacterPositionFromCaretPosition(caretPosition);

            this.editor.eraseText(new CaretRange(caretPosition, end));

            const newCaretPosition = this.editor.selection.getLineColumnPositionFromCharacterPosition(
                '',
                characterPosition
            );
            this.editor.selection.setCaret(newCaretPosition);
        }

        // Otherwise, erase the selection.
        else {
            const characterPosition = this.editor.selection.getCharacterPositionFromCaretPosition(
                this.selection.start
            );
            this.editor.eraseText(this.selection.caretRange);
            const newCaretPosition = this.editor.selection.getLineColumnPositionFromCharacterPosition(
                '',
                characterPosition
            );
            this.editor.selection.setCaret(newCaretPosition);
        }
        this.isBackwardErasure = false;
        this.editor.triggerTextChange();
    };

    onEraseTextForward = (): void => {
        if (!this.selection) {
            return;
        }

        const caretPosition = this.selection.start;
        const characterPosition =
            this.editor.selection.getCharacterPositionFromCaretPosition(caretPosition);

        // Only erase forward if it is collapsed
        if (this.selection.isCollapsed) {
            const start = Object.assign({}, this.selection.start);
            const lastTextLine = this.textLines[this.textLines.length - 1];
            const textLine = this.textLines[caretPosition.line];

            if (
                caretPosition.line === this.textLines.length - 1 &&
                caretPosition.column === lastTextLine.characterWidth
            ) {
                // Don't do anything
            } else if (caretPosition.column === textLine.characterWidth) {
                caretPosition.line++;
                caretPosition.column = 0;
            } else {
                caretPosition.column++;
            }

            this.editor.eraseText(new CaretRange(start, caretPosition));
            const newCaretPosition = this.editor.selection.getLineColumnPositionFromCharacterPosition(
                '',
                characterPosition
            );
            this.editor.selection.setCaret(newCaretPosition);
        }

        // Otherwise, erase the selection.
        else {
            this.editor.eraseText(this.selection.caretRange);
            const newCaretPosition = this.editor.selection.getLineColumnPositionFromCharacterPosition(
                '',
                characterPosition
            );
            this.editor.selection.setCaret(newCaretPosition);
        }
        this.editor.triggerTextChange();
    };

    onSelectTextUpward = (): void => {
        if (!this.selection) {
            return;
        }
        const focus = this.editor.keyboardBindings.moveCaretUpward()!;
        this.selection.focus = focus;
        this.editor.selection.updateSelection();
    };

    onSelectTextDownward = (): void => {
        if (!this.selection) {
            return;
        }
        const focus = this.editor.keyboardBindings.moveCaretDownward()!;
        this.editor.selection.selection!.focus = focus;
        this.editor.selection.updateSelection();
    };

    onSelectAllText = (): void => {
        this.editor.selection.selectAllText();
        this.editor.selection.startCaretInterval();
    };

    onMoveCaretUpward = (): void => {
        if (!this.selection) {
            return;
        }
        const caretPosition = this.editor.keyboardBindings.moveCaretUpward();
        if (!caretPosition) {
            return;
        }
        this.editor.selection.setCaret(caretPosition, /* saveFocusColumn */ false);
    };

    onMoveCaretForward = (): void => {
        if (!this.selection) {
            return;
        }
        const caretPosition = this.selection.isCollapsed
            ? this.editor.selection.moveCaretForward(this.selection.end)
            : this.selection.end;
        if (!caretPosition) {
            return;
        }
        this.editor.selection.setCaret(caretPosition);
    };

    onMoveCaretBackward = (): void => {
        if (!this.selection) {
            return;
        }
        const caretPosition = this.selection.isCollapsed
            ? this.editor.selection.moveCaretBackward(this.selection.start)
            : this.selection.start;
        if (!caretPosition) {
            return;
        }
        this.editor.selection.setCaret(caretPosition);
    };

    onSelectTextToPreviousWord = (): void => {
        if (!this.selection) {
            return;
        }
        const position = this.editor.selection.getPreviousWordPosition();
        this.selection.focus = position;
        this.editor.selection.updateSelection();
    };

    onSelectTextToNextWord = (): void => {
        if (!this.selection) {
            return;
        }
        const position = this.editor.selection.getNextWordPosition();
        this.selection.focus = position;
        this.editor.selection.updateSelection();
    };

    onMoveCaretToPreviousWord = (): void => {
        if (!this.selection) {
            return;
        }
        const position = this.editor.selection.getPreviousWordPosition();
        this.editor.selection.selectText(position, position);
    };

    onMoveCaretToNextWord = (): void => {
        if (!this.selection) {
            return;
        }
        const position = this.editor.selection.getNextWordPosition();
        this.editor.selection.selectText(position, position);
    };

    onMoveCaretToStartOfText = (): void => {
        this.editor.selection.selectText(
            this.editor.selection.setDirectionInPosition({ line: 0, column: 0 }),
            this.editor.selection.setDirectionInPosition({ line: 0, column: 0 })
        );
    };

    onMoveCaretToEndOfText = (): void => {
        const endPosition = this.editor.selection.getEndPosition();
        this.editor.selection.selectText(endPosition, endPosition);
    };

    onSelectTextBackward = (): void => {
        if (!this.selection) {
            return;
        }
        const newFocus = this.editor.selection.moveCaretBackward(this.selection.focus)!;
        this.editor.selection.selectText(this.selection.anchor, newFocus);
    };

    onSelectTextForward = (): void => {
        if (!this.selection) {
            return;
        }
        const newFocus = this.editor.selection.moveCaretForward(this.selection.focus)!;
        this.editor.selection.selectText(this.selection.anchor, newFocus);
    };

    onMoveCaretToStartOfParagraph = (): void => {
        if (!this.selection) {
            return;
        }
        const position = this.editor.selection.getParagraphStartPosition();
        this.editor.selection.selectText(position, position);
    };

    onMoveCaretToEndOfParagraph = (): void => {
        if (!this.selection) {
            return;
        }
        const position = this.editor.selection.getParagraphEndPosition();
        this.editor.selection.selectText(position, position);
    };

    onSelectTextToStartOfParagraph = (): void => {
        if (!this.selection) {
            return;
        }
        const position = this.editor.selection.getParagraphStartPosition();
        this.selection.focus = position;
        this.editor.selection.updateSelection();
    };

    onSelectTextToEndOfParagraph = (): void => {
        if (!this.selection) {
            return;
        }
        const position = this.editor.selection.getParagraphEndPosition();
        this.selection.focus = position;
        this.editor.selection.updateSelection();
    };

    onSelectTextToStartOfLine = (): void => {
        if (!this.selection) {
            return;
        }
        this.selection.focus = this.editor.selection.getStartOfLinePosition();
        this.editor.selection.updateSelection();
    };

    onSelectTextToEndOfLine = (): void => {
        if (!this.selection) {
            return;
        }
        this.selection.focus = this.editor.selection.getEndOfLinePosition();
        this.editor.selection.updateSelection();
    };

    onEraseToBeginningOfLine = (): void => {
        if (!this.selection) {
            return;
        }
        const previousLineIndex = this.selection.start.line - 1;
        const previousLine = this.text.textLines_m[previousLineIndex];
        let start: ILineColumnPosition;
        if (!previousLine || (previousLine.endsWithNewline && this.selection.start.column !== 0)) {
            start = {
                line: this.selection.start.line,
                column: 0
            };
        } else {
            start = {
                line: previousLineIndex,
                column: previousLine.endsWithNewline
                    ? previousLine.characterWidth - 1
                    : previousLine.characterWidth
            };
        }
        const characterPosition = this.editor.selection.getCharacterPositionFromCaretPosition(start);
        this.editor.eraseText(new CaretRange(start, this.selection.end));
        const caretPosition = this.editor.selection.getLineColumnPositionFromCharacterPosition(
            '',
            characterPosition
        );
        this.editor.selection.setCaret(caretPosition);
    };

    onMoveCaretToStartOfLine = (): void => {
        if (!this.selection) {
            return;
        }
        this.editor.selection.setCaret(this.editor.selection.getStartOfLinePosition());
    };

    onMoveCaretToEndOfLine = (): void => {
        if (!this.selection) {
            return;
        }
        this.editor.selection.setCaret(this.editor.selection.getEndOfLinePosition());
    };

    onMoveCaretDownward = (): void => {
        if (!this.selection) {
            return;
        }
        const caretPosition = this.moveCaretDownward();
        if (!caretPosition) {
            return;
        }
        this.editor.selection.setCaret(caretPosition, /* saveFocusColumn */ false);
    };

    moveCaretDownward(): ILineColumnPositionDirection | undefined {
        if (!this.selection) {
            return;
        }
        const caretPosition = Object.assign({}, this.selection.focus);
        if (caretPosition.line === this.editor.text.textLines_m.length - 1) {
            // Don't do anything
        } else {
            const currentLine = this.editor.text.textLines_m[caretPosition.line];
            const currentLineWidth = currentLine.endsWithNewline
                ? currentLine.characterWidth - 1
                : currentLine.characterWidth;
            caretPosition.line++;
            const textLine = this.editor.text.textLines_m[caretPosition.line];
            if (this.selection.focus.column === currentLineWidth) {
                caretPosition.column = textLine.endsWithNewline
                    ? textLine.characterWidth - 1
                    : textLine.characterWidth;
            } else if (this.selection.focus.column === 0) {
                caretPosition.column = 0;
            } else if (
                caretPosition.line === this.editor.text.textLines_m.length - 1 &&
                this.editor.selection.verticalFocusColumn! >= textLine.characterWidth
            ) {
                caretPosition.column = textLine.characterWidth;
            } else if (this.editor.selection.verticalFocusColumn! > textLine.characterWidth - 1) {
                caretPosition.column = textLine.characterWidth - 1;
            } else {
                caretPosition.column = this.editor.selection.verticalFocusColumn!;
            }
        }
        return this.editor.selection.setDirectionInPosition(caretPosition);
    }

    /**
     * Move caret upward. It will try it best to preserve selection start column.
     * If the previous column has lesser lines than the selection start column,
     * it will move the caret to the end of the line.
     */
    moveCaretUpward(): ILineColumnPositionDirection | undefined {
        if (!this.selection) {
            return;
        }
        const caretPosition = Object.assign({}, this.selection.focus);
        if (caretPosition.line === 0) {
            // Don't do anything
        } else {
            const currentLine = this.editor.text.textLines_m[caretPosition.line];
            const currentLineWidth = currentLine.endsWithNewline
                ? currentLine.characterWidth - 1
                : currentLine.characterWidth;
            caretPosition.line--;
            const textLine = this.editor.text.textLines_m[caretPosition.line];
            if (this.selection.focus.column === currentLineWidth) {
                caretPosition.column = textLine.endsWithNewline
                    ? textLine.characterWidth - 1
                    : textLine.characterWidth;
            } else if (this.selection.focus.column === 0) {
                caretPosition.column = 0;
            } else if (
                caretPosition.line === this.editor.text.textLines_m.length - 1 &&
                this.editor.selection.verticalFocusColumn! >= textLine.characterWidth
            ) {
                caretPosition.column = textLine.characterWidth;
            } else if (this.editor.selection.verticalFocusColumn! > textLine.characterWidth - 1) {
                caretPosition.column = textLine.characterWidth - 1;
            } else {
                caretPosition.column = this.editor.selection.verticalFocusColumn!;
            }
        }
        return this.editor.selection.setDirectionInPosition(caretPosition);
    }

    private onEraseWord = (): void => {
        if (!this.selection) {
            return;
        }
        if (this.selection.isCollapsed) {
            let previousCaretPosition = this.editor.selection.getCurrentCaretPosition()!;
            let caretPosition: ILineColumnPositionDirection;
            let encounteredText = false;
            let n = 0;
            do {
                caretPosition = this.editor.selection.moveCaretBackward(previousCaretPosition)!;
                const character = this.getCharCodeAtPosition(caretPosition);
                if (character === -1) {
                    break;
                }
                if (isNewlineLikeCharacter(character)) {
                    // Only remove newline if it is the previous one.
                    if (n === 0) {
                        previousCaretPosition = Object.assign({}, caretPosition);
                    }
                    break;
                } else if (isSpaceLikeCharacter(character)) {
                    if (encounteredText) {
                        break;
                    }
                } else {
                    encounteredText = true;
                }
                previousCaretPosition = Object.assign({}, caretPosition);
                n++;
            } while (caretPosition.column !== 0 || caretPosition.line !== 0);
            const characterPosition =
                this.editor.selection.getCharacterPositionFromCaretPosition(previousCaretPosition);
            this.editor.eraseText(new CaretRange(previousCaretPosition, this.selection.start));
            const newCaretPosition = this.editor.selection.getLineColumnPositionFromCharacterPosition(
                '',
                characterPosition
            );
            this.editor.selection.setCaret(newCaretPosition);
        } else {
            this.editor.eraseCurrentSelection();
        }
    };

    onWindowFocus = (): void => {
        this.windowIsBlurred = false;
    };

    onWindowBlur = (): void => {
        this.windowIsBlurred = true;
        if (this.inputId === lastInputId) {
            if (this.selection) {
                if (this.selection.isCollapsed) {
                    clearInterval(this.editor.selection.caretIntervalReference);
                    this.editor.selection.hideCaret();
                    this.text.emit('blur');
                } else {
                    this.editor.selection.updateSelection();
                    for (const spanElement of this.text.selectionSpanElements_m) {
                        spanElement.style.backgroundColor = SELECTION_BLURRED_BACKGROUND_COLOR;
                        spanElement.style.opacity = '1';
                    }
                }
            }
        }
    };

    onTextareaFocus = (): void => {
        this.registerHotkeys();

        /**
         * Clear the active keys. If you lose focus by the system, e.g
         * alt + tab or hitting a breakpoint, the keys get stuck otherwise.
         *
         * Revision1: We cannot clear active keys. Since, Shift + Click multiple times doesn't work
         */
        // this.hotkeyService.clearActiveKeys();
        lastInputId = this.inputId;
        if (this.selection && !this.selection.isCollapsed) {
            for (const spanElement of this.text.selectionSpanElements_m) {
                spanElement.style.backgroundColor = SELECTION_FOCUSED_BACKGROUND_COLOR;
                spanElement.style.opacity = '1';
            }
        }
        this.editor.hasFocus = true;
        this.text.emit('focus');
        if (this.editor.mouseBindings.isHandlingDownClick) {
            return;
        }
        if (!this.selection) {
            this.editor.selection.selectAllText();
        } else {
            this.editor.selection.startCaretInterval();
        }
    };

    private onCompositionStart = (): void => {
        this.textarea.style.width = '1px';
        this.textarea.style.background = '#fff';
        this.textarea.style.opacity = '1';
        const lineHeight = this.editor.currentStyle.lineHeight || this.text.style.lineHeight;
        const fontSize = this.editor.currentStyle.fontSize
            ? this.text.resizedElementFontSize_m * this.editor.currentStyle.fontSize
            : this.text.resizedElementFontSize_m;
        this.textarea.style.height = `${lineHeight * fontSize + 5}px`;
        this.textarea.style.display = 'block';
        const textColor = this.editor.currentStyle.textColor || this.text.style.textColor;
        this.textarea.style.color = toRGBA(textColor);
        if (this.editor.currentStyle.font) {
            this.textarea.style.fontFamily = this.editor.currentStyle.font.id;
        } else {
            this.textarea.style.fontFamily = FALLBACK_FONT_FAMILY;
        }
        this.textarea.style.fontSize = `${fontSize}px`;
        this.inCompositionMode = true;
        this.preventNextKeypress = false;
        this.erasedTextBackwards = false;
        this.editor.selection.hideCaret();
    };

    private onCompositionEnd = (): void => {
        this.textarea.style.width = '1px';
        this.textarea.style.height = '1px';
        this.textarea.style.opacity = '0.1';
        this.removeCompositionSpan();
        if (
            this.textarea.value.length > 0 &&
            !(this.textarea.value.length === 1 && this.erasedTextBackwards)
        ) {
            this.editor.insertTextInSelection(this.textarea.value);
        } else {
            this.text.rerender();
        }
        this.textarea.value = '';
        this.inCompositionMode = false;
        this.editor.selection.startCaretInterval();
        this.preventNextKeypress = true;
    };

    private removeCompositionSpan(): void {
        this.editor.text.spans_m = this.text.spans_m.filter(s => s.type !== SpanType.Composition);
        for (const textLine of this.textLines) {
            for (let i = 0; i < textLine.spans.length; i++) {
                if (textLine.spans[i].type === SpanType.Composition) {
                    textLine.spans.splice(i, 1);
                    break;
                }
            }
        }
    }

    onTextareaKeyPress = (event: KeyboardEvent): void => {
        // The textarea's CTRL/CMD+Y is prioritzed so we have to stop default undo/redo right away.
        if (
            (event.ctrlKey || event.metaKey) &&
            (event.key.toLowerCase() === 'z' || event.key.toLowerCase() === 'y')
        ) {
            event.preventDefault();
        }

        // Need a timeout because of https://stackoverflow.com/a/1338497.
        setTimeout(() => {
            if (this.preventNextKeypress) {
                this.preventNextKeypress = false;
                return;
            }

            if (this.inCompositionMode) {
                const compositionSpan: ICompositionSpan = {
                    attributes: {},
                    type: SpanType.Composition,
                    top: 0,
                    left: 0,
                    width: 0,
                    height: 0,
                    lineHeight: 0,
                    content: this.textarea.value.replace('__magic_dumb_mouse_fix__', ''), // Can onle contains words(no whitespace etc.)
                    style: {},
                    styleIds: {}
                };
                this.editor.insertSpansInSelection([compositionSpan]);
                const resizedCompositionSpan = this.getCompositionSpan()!;
                this.textarea.style.height = `${Math.round(resizedCompositionSpan.lineHeight)}px`;
                const compositionLineElementRect = this.compositionSpanElement.getBoundingClientRect();
                const top = compositionLineElementRect.top;
                const left = compositionLineElementRect.left;
                this.textarea.style.top = `${top / this.text.zoom_m}px`;
                this.textarea.style.left = `${left / this.text.zoom_m}px`;
                const fontSize = this.editor.currentStyle.fontSize
                    ? this.text.resizedElementFontSize_m * this.editor.currentStyle.fontSize
                    : this.text.resizedElementFontSize_m;
                this.textarea.style.fontSize = `${fontSize}px`;
                this.textarea.style.display = 'block';
                this.textarea.style.lineHeight = `${
                    this.editor.currentStyle.lineHeight || this.text.style.lineHeight
                }`;
                const textColor = this.editor.currentStyle.textColor || this.text.style.textColor;
                this.textarea.style.color = toRGBA(textColor);

                if (this.editor.currentStyle.font) {
                    this.textarea.style.fontFamily = this.editor.currentStyle.font.id;
                } else {
                    this.textarea.style.fontFamily = FALLBACK_FONT_FAMILY;
                }

                this.textarea.style.width = `${resizedCompositionSpan.width}px`;
                let n = 0;
                while (n < 100) {
                    if (this.textarea.clientWidth === this.textarea.scrollWidth) {
                        this.textarea.style.width = `${this.textarea.scrollWidth - 1}px`;
                    } else {
                        this.textarea.style.width = `${this.textarea.scrollWidth + 1}px`;
                        break;
                    }
                    n++;
                }

                this.removeCompositionSpan();

                return;
            }

            if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'v') {
                this.textarea.value = '';
                return;
            }

            this.editor.insertTextInSelection(
                this.textarea.value.replace('__magic_dumb_mouse_fix__', ''),
                event
            );

            this.textarea.value = '';
        }, 20); // Cannot be zero on Firefox, because the textarea value can be empty.
    };

    onTextareaBlur = (): void => {
        // We need to a set timeout here, because we must have time to register the suspend blur flag.
        setTimeout(() => {
            if (this.editor.magicMouseFix) {
                return;
            }
            if (this.textarea.value.length > 0) {
                this.editor.insertTextInSelection(
                    this.textarea.value.replace('__magic_dumb_mouse_fix__', '')
                );
            }
            if (this.editor._suspendNextBlur) {
                this.editor._suspendNextBlur = false;
                if (
                    document.activeElement &&
                    document.activeElement.tagName !== 'INPUT' &&
                    document.activeElement.tagName !== 'TEXTAREA' &&
                    document.activeElement.tagName !== 'BUTTON'
                ) {
                    this.textarea.focus();
                }
                return;
            }
            if (
                this.textarea !== document.activeElement ||
                (!this.editor.mouseBindings.isHandlingDownClick && !this.windowIsBlurred)
            ) {
                // Should only blur if it is not a down click on the root element.
                this.selection = undefined;
                this.editor.selection.updateSelection();
                this.editor.selection.hideCaret();
                clearInterval(this.editor.selection.caretIntervalReference);
                this.editor.text.emit('blur');
            }
            this.editor.mouseBindings.isHandlingDownClick = false;
            this.editor.hasFocus = false;
        });
        this.unregisterHotkeys();
    };

    private getCompositionSpan(): ICompositionSpan | undefined {
        for (const textLine of this.textLines) {
            for (const span of textLine.spans) {
                if (span.type === SpanType.Composition) {
                    return span;
                }
            }
        }
        return undefined;
    }

    private getCharCodeAtPosition(position: ILineColumnPosition): number {
        for (let i = 0; i < this.textLines.length; i++) {
            if (position.line !== i) {
                continue;
            }
            const textLine = this.textLines[i];
            let column = 0;
            for (const span of textLine.spans) {
                if (column + span.content.length - 1 >= position.column) {
                    return span.content.charCodeAt(position.column - column);
                }
                column += span.content.length;
            }

            // Getting the char code of last column is the same as getting the char code of the first column in next line.
            const nextTextLine = this.textLines[i + 1];
            if (nextTextLine) {
                return nextTextLine.spans[0].content.charCodeAt(0);
            }
        }
        return -1;
    }

    private onPasteText = (event: ClipboardEvent): void => {
        if (event.clipboardData!.getData('text/html')) {
            const text = event.clipboardData!.getData('text/html');

            if (text.includes('copied-studio-text')) {
                // Allow HTML pasting from studio
                this.pasteTextFromClipboard(text, 'html');
                return;
            }
        }
        const text = event.clipboardData!.getData('text/plain');
        this.pasteTextFromClipboard(text, 'plain');
    };

    private pasteTextFromClipboard = (text: string, type: 'plain' | 'html'): void => {
        if (!this.text.textSelection_m) {
            return;
        }

        if (type === 'html') {
            const newSpans: OneOfEditableSpans[] = [];
            const domParser = new DOMParser();
            let doc = domParser.parseFromString(text, 'text/html');

            // Windows adds newline paddings on the body. We have to trim them.
            doc = domParser.parseFromString(
                `<html><body>${doc.body.innerHTML.trim()}</body></html>`,
                'text/html'
            );

            const findTextNodes = (el: HTMLElement): void => {
                if (el.tagName === 'BR') {
                    newSpans.push(...createSpansFromString('\n'));
                } else if (el.nodeType === 3 && el.parentElement) {
                    /**
                     * We set copied-studio-text on each span when copying text inside of studio.
                     * When pasting, we should only apply styles if the text actually was copied form studio.
                     */
                    const textContent = el.textContent!.replace(
                        /__I_AM_STUPID_IF_I_TYPE_EXACTLY_THIS_AND_PASTE_THIS_INSIDE_STUDIO__/,
                        '\n'
                    );
                    const isStudioSpan = el.parentElement.classList.contains('copied-studio-text');
                    const cssStyles = isStudioSpan
                        ? deserializeElementStyle(el.parentElement)
                        : undefined;
                    const styleIds = {};
                    if (el.parentElement.dataset.styleIds) {
                        for (const styleId of el.parentElement.dataset.styleIds.split(' ')) {
                            const [key, value] = styleId.split(',');
                            styleIds[key] = value;
                        }
                    }

                    // Filter out fonts we dont have.
                    if (cssStyles?.font) {
                        if (this._fontFamilies) {
                            const familiy = this._fontFamilies.find(
                                ff => ff.id === cssStyles.font?.fontFamilyId
                            );
                            if (familiy) {
                                if (!familiy.fontStyles.find(fs => fs.id === cssStyles.font?.id)) {
                                    cssStyles.font = undefined;
                                }
                            } else {
                                cssStyles.font = undefined;
                            }
                        } else {
                            cssStyles.font = undefined;
                        }
                    }

                    const spans = createSpansFromString(
                        textContent,
                        undefined,
                        cssStyles,
                        copyStyle,
                        undefined,
                        styleIds
                    );

                    if (this.isRichTextInput()) {
                        if (cssStyles) {
                            spans.forEach(span => {
                                if (isContentSpan(span)) {
                                    this.editor.mappedStyles!.forEach(value => {
                                        if (value && hasSameStyle(value.style, span.style)) {
                                            span.styleIds = value.styleIds;
                                        }
                                    });
                                    span.attributes.styleIndex = this.editor.styleIndexMap!.get(
                                        sequenceStyleIds(span.styleIds)
                                    );
                                    if (!isVariableSpan(span)) {
                                        span.style = {};
                                    }
                                }
                            });
                        }
                    }
                    newSpans.push(...spans);
                }
                if (el.childNodes) {
                    for (const child of Array.from(el.childNodes) as HTMLElement[]) {
                        findTextNodes(child);
                    }
                }
            };
            findTextNodes(doc.body);
            this.text.editor_m!.insertSpansInSelection(newSpans);
        } else {
            this.text.editor_m!.insertTextInSelection(text);
        }

        this.text.editor_m!.focus();
        setTimeout(() => (this.text.editor_m!.keyboardBindings.textarea.value = ''));
    };

    private isRichTextInput(): boolean {
        return !!this.editor.mappedStyles && this.editor.mappedStyles.size > 0;
    }

    private onCopySelectedText = (): void => {
        if (!this.text.textSelection_m) {
            return;
        }
        this.copySelectedTextToClipboard();
    };

    private onCutSelectedText = (): void => {
        this.copySelectedTextToClipboard();
        this.text.editor_m!.eraseCurrentSelection();
    };

    /**
     * **NOTE**
     *
     * It is not possible to debug (breakpoint) and get the text copied to the clipboard at the same time.
     */
    private copySelectedTextToClipboard(): void {
        const start = this.text.textSelection_m!.start;
        const end = this.text.textSelection_m!.end;

        const selectedSpans: OneOfRenderedSpans[] = [];

        for (let i = start.line; i <= end.line; i++) {
            const line = this.text.textLines_m[i];
            let currentColumn = 0;
            for (const span of line.spans) {
                if (span.type === SpanType.End) {
                    continue;
                }

                const content = span.content;
                let endColumn = end.column;
                let startColumn = start.column;
                let reachedEndColumn = false;

                if (i !== start.line) {
                    startColumn = 0;
                }

                if (i !== end.line) {
                    endColumn = line.characterWidth;
                }

                if (content.length + currentColumn <= startColumn) {
                    currentColumn += content.length;
                } else {
                    const copySpan = { ...span };

                    if (currentColumn < startColumn) {
                        copySpan.content = copySpan.content.slice(
                            Math.abs(currentColumn - startColumn),
                            Math.abs(endColumn - currentColumn)
                        );
                    } else if (currentColumn + copySpan.content.length > endColumn) {
                        copySpan.content = copySpan.content.slice(
                            0,
                            Math.abs(currentColumn - endColumn)
                        );
                        reachedEndColumn = true;
                    }

                    selectedSpans.push(copySpan);
                    currentColumn += span.content.length;
                    if (
                        reachedEndColumn ||
                        (i === end.line &&
                            (currentColumn >= endColumn ||
                                Math.abs(currentColumn - startColumn) >= endColumn))
                    ) {
                        break;
                    }
                }
            }
        }
        const copyElement = document.createElement('span');
        (selectedSpans as OneOfEditableSpans[]).forEach(span => {
            if (span.type === SpanType.Newline) {
                const br = document.createElement('br');
                copyElement.appendChild(br);
            } else {
                const spanEl = document.createElement('span');
                spanEl.innerHTML = span.content;
                if (isContentSpan(span)) {
                    if (this.editor.mappedStyles && span.attributes.styleIndex) {
                        this.editor.mappedStyles.forEach(mappedStyle => {
                            if (
                                sequenceStyleIds(span.styleIds) ===
                                sequenceStyleIds(mappedStyle.styleIds)
                            ) {
                                span.style = mappedStyle.style;
                            }
                        });
                    }

                    if (span.style) {
                        // Apply the shared styles first to the span element and the apply overrides.
                        // Can't apply it to a parent element because document.execCommand('copy') is stupid
                        this.text.applyStyleOnElement_m(span.style, spanEl, false);
                        spanEl.style.fontSize = `${span.style.fontSize}px`;
                        spanEl.classList.add('copied-studio-text');
                        spanEl.dataset.studioValues = Object.keys(span.style).join(' ');
                        if (span.style.font) {
                            spanEl.dataset.font = serializePropertyValue('font', span.style.font);
                        }
                        if (span.styleIds) {
                            spanEl.dataset.styleIds = Object.entries(span.styleIds).join(' ');
                        }
                        if (span.style.variable) {
                            spanEl.dataset.variable = serializePropertyValue(
                                'feed',
                                span.style.variable
                            );
                        }
                    }
                }
                copyElement.appendChild(spanEl);
            }
        });
        copyElement.style.opacity = '0';
        copyElement.style.position = 'absolute';
        copyElement.style.top = '-10000px';
        copyElement.style.left = '-10000px';

        /**
         * We have to create a dummy span with a unique styling as
         * the browser otherwise removes all classes from all spans if it's only one span
         * or multiple spans with the same styles.
         */
        const dummySpan = document.createElement('span');
        dummySpan.innerHTML = '';
        dummySpan.style.fontSize = '-0.00009px';
        dummySpan.style.opacity = '0';
        dummySpan.style.width = '0px';
        dummySpan.style.height = '0px';
        dummySpan.style.left = '-999999999px';
        dummySpan.style.position = 'absolute';
        dummySpan.classList.add('dummy-copy-span');
        copyElement.appendChild(dummySpan);
        this.copyHtmlToClipboard(copyElement);
        setTimeout(() => {
            this.text.editor_m!.focus();
            this.text.editor_m!.keyboardBindings.textarea.setSelectionRange(0, 0);
        });
    }

    private copyHtmlToClipboard = (element: HTMLSpanElement): void => {
        const handler = (event: ClipboardEvent): void => {
            event.clipboardData!.setData('text/plain', element.innerText);
            event.clipboardData!.setData('text/html', element.outerHTML);
            event.preventDefault();
            document.removeEventListener('copy', handler, true);
        };

        if (element) {
            document.addEventListener('copy', handler, true);
            document.execCommand('copy');
            setTimeout(() => document.removeEventListener('copy', handler));
        }
    };
}

__parent(RichTextEditorKeyboardBindings, 'editor', 0);
__inject(T.HOTKEY_SERVICE, {}, RichTextEditorKeyboardBindings, 'hotkeyService', 1);
