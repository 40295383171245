import { isVersionedText } from '@creative/elements/rich-text/text-nodes';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { IVersion, OneOfVersionableProperties } from '@domain/creativeset/version';
import { IAsyncState } from '@domain/store/async';
import { removeItemWithId } from '@studio/utils/array';
import { cloneDeep } from '@studio/utils/clone';
import { generateUniqueName, removeDuplicates } from '@studio/utils/utils';
import { cleanStyleIdsFromProperties, copyCharacterStylesToNewDesign } from '../versions.utils';
import { VersionEntity } from './version.entity';
import { VersionsActions } from './versions.actions';

export const VERSIONS_FEATURE_KEY = 'versions';

export interface VersionsState extends EntityState<VersionEntity>, IAsyncState {
    defaultVersionId?: string;
    newVersionPlaceholder?: IVersion;
    creativesetId: string; // TODO: Move this to its own store
    shouldCheckPristine: boolean;
    translationSaved: boolean;
    updatedSuccess: boolean;
    loading: boolean;
    newVersionPropertiesIds: string[]; // Version properties created on this session
    selectableIds?: string[];
}

export const adapter: EntityAdapter<VersionEntity> = createEntityAdapter<VersionEntity>();

export interface VersionsPartialState {
    readonly [VERSIONS_FEATURE_KEY]: VersionsState;
}

export const initialState: VersionsState = adapter.getInitialState({
    loaded: false,
    updatedSuccess: false,
    newVersionPropertiesIds: [],
    shouldCheckPristine: false,
    creativesetId: '',
    translationSaved: false,
    loading: false
});

export const reducer = createReducer(
    initialState,
    on(
        VersionsActions.initSuccess,
        (
            state,
            { selectedVersionsIds, versions, creativesetId, defaultVersion, selectableIds }
        ): VersionsState =>
            adapter.setAll(versions, {
                ...state,
                loaded: true,
                updatedSuccess: false,
                selectedVersionsIds:
                    !selectedVersionsIds.length || selectedVersionsIds.includes('all')
                        ? versions.map(({ id }) => id)
                        : selectedVersionsIds,
                creativesetId,
                defaultVersionId: defaultVersion.id,
                selectableIds
            })
    ),

    on(
        VersionsActions.resetVersionsSuccess,
        (state, { versions }): VersionsState => adapter.setAll(versions, state)
    ),

    on(
        VersionsActions.preparePlaceholder,
        (state, { localization, newVersionName }): VersionsState => ({
            ...state,
            newVersionPlaceholder: {
                id: '',
                localization,
                targetUrl: localization.targetUrl,
                properties: [],
                name:
                    newVersionName ??
                    generateUniqueName(
                        localization.name,
                        Object.values(state.entities).map(entity => ({ name: entity?.name || '' }))
                    )
            }
        })
    ),
    on(
        VersionsActions.cancelPlaceholder,
        (state): VersionsState => ({
            ...state,
            newVersionPlaceholder: undefined
        })
    ),

    on(
        VersionsActions.saveNewVersion,
        (state, { newVersionName }): VersionsState => ({
            ...state,
            newVersionPlaceholder: state.newVersionPlaceholder
                ? {
                      ...state.newVersionPlaceholder,
                      name: newVersionName
                  }
                : undefined,
            loaded: false
        })
    ),

    on(
        VersionsActions.createVersionSuccess,
        (state, { versions }): VersionsState =>
            adapter.addMany<VersionsState>(versions, {
                ...state,
                loaded: true,
                newVersionPlaceholder: undefined
            })
    ),

    on(VersionsActions.deleteVersionsSuccess, (state, { versionsIds }): VersionsState => {
        return adapter.removeMany(versionsIds, {
            ...state,
            loaded: true
        });
    }),

    on(
        VersionsActions.setDefaultVersionSuccess,
        (state, { version }): VersionsState =>
            adapter.updateOne(
                {
                    id: version.id,
                    changes: { ...version }
                },
                {
                    ...state,
                    defaultVersionId: version.id,
                    loaded: true
                }
            )
    ),

    on(
        VersionsActions.updateVersionsSuccess,
        (state, { versions }): VersionsState =>
            adapter.upsertMany(versions, {
                ...state,
                loaded: true,
                updatedSuccess: true,
                translationSaved: true
            })
    ),

    on(VersionsActions.onUpdatedDesignsSuccess, (state, { versions }): VersionsState => {
        const changes = versions.map(version => ({
            id: version.id,
            changes: {
                properties: version.properties
            }
        }));
        if (!changes.length) {
            return state;
        }
        return adapter.updateMany(changes, state);
    }),

    on(
        VersionsActions.deleteVersions,
        VersionsActions.setDefaultVersion,
        VersionsActions.createVersion,
        VersionsActions.saveNewVersion,
        VersionsActions.updateVersions,
        VersionsActions.updateVersionsAndCreatives,
        (state): VersionsState => ({
            ...state,
            loaded: false,
            loading: true,
            updatedSuccess: false,
            error: undefined,
            newVersionPropertiesIds: []
        })
    ),
    on(
        VersionsActions.deleteVersionsFailure,
        VersionsActions.setDefaultVersionFailure,
        VersionsActions.createVersionFailure,
        VersionsActions.updateVersionsFailure,
        (state, { error }): VersionsState => ({
            ...state,
            loaded: true,
            loading: false,
            updatedSuccess: true,
            error
        })
    ),
    on(
        VersionsActions.deleteVersionsSuccess,
        VersionsActions.setDefaultVersionSuccess,
        VersionsActions.createVersionSuccess,
        VersionsActions.updateVersionsSuccess,
        (state): VersionsState => ({
            ...state,
            loaded: true,
            loading: false
        })
    ),

    // Properties
    on(
        VersionsActions.updateVersionedText,
        (state, { versionId, versionPropertyId, value }): VersionsState => {
            const version = state.entities[versionId];
            if (!version) {
                throw Error('Version not found');
            }

            const properties =
                version.properties.length > 0
                    ? version.properties.map(prop => {
                          if (prop.id !== versionPropertyId) {
                              return prop;
                          }

                          if (!isVersionedText(prop)) {
                              throw Error('Version Property is not text');
                          }

                          return {
                              ...prop,
                              value: { ...prop.value, ...value }
                          };
                      })
                    : [
                          {
                              id: versionPropertyId,
                              name: 'content',
                              value: value as OneOfVersionableProperties
                          }
                      ];

            const updatedVersion = {
                ...version,
                properties
            };

            return adapter.upsertOne(updatedVersion, state);
        }
    ),
    on(VersionsActions.addVersionPropertiesToVersions, (state, { changes }): VersionsState => {
        return adapter.updateMany(
            changes.map(change => ({
                id: change.versionId,
                changes: {
                    properties: [
                        ...removeItemWithId(
                            state.entities[change.versionId]!.properties,
                            change.versionProperty.id
                        ),
                        change.versionProperty
                    ]
                }
            })),
            {
                ...state,
                newVersionPropertiesIds: [
                    ...state.newVersionPropertiesIds,
                    changes[0].versionProperty.id
                ]
            }
        );
    }),
    on(
        VersionsActions.addVersionProperty,
        VersionsActions.upsertVersionProperty,
        (state, { versionIds, versionProperty }): VersionsState => {
            const versions = removeDuplicates(versionIds).map(id => state.entities[id]);
            if (!versions.length) {
                throw new Error('Version not found');
            }
            const defaultVersion = state.entities[state.defaultVersionId!]!;
            let { newVersionPropertiesIds } = state;
            const existsInDefaultVersion = defaultVersion.properties.find(
                p => p.id === versionProperty.id
            );
            if (!existsInDefaultVersion || newVersionPropertiesIds.includes(versionProperty.id)) {
                newVersionPropertiesIds = [versionProperty.id, ...newVersionPropertiesIds];
                if (!existsInDefaultVersion) {
                    versions.unshift(defaultVersion);
                }
            }

            return adapter.updateMany(
                versions.map(version => ({
                    id: version!.id,
                    changes: {
                        properties: [
                            ...removeItemWithId(version!.properties, versionProperty.id),
                            versionProperty
                        ]
                    }
                })),
                {
                    ...state,
                    newVersionPropertiesIds
                }
            );
        }
    ),
    on(
        VersionsActions.addVersionProperties,
        (state, { versionId, versionProperties }): VersionsState => {
            const targetVersion = state.entities[versionId];
            if (!targetVersion) {
                throw new Error('Target version not found');
            }

            versionProperties = Array.isArray(versionProperties)
                ? versionProperties
                : [versionProperties];
            const propertiesWithoutDuplicates = removeItemWithId(
                targetVersion.properties,
                versionProperties.map(({ id }) => id)
            );
            return adapter.updateOne(
                {
                    id: targetVersion.id,
                    changes: {
                        properties: [...propertiesWithoutDuplicates, ...versionProperties]
                    }
                },
                state
            );
        }
    ),

    on(
        VersionsActions.updateVersionPropertyFeed,
        (state, { versionId, versionPropertyId, feedId, changes }): VersionsState => {
            const version = state.entities[versionId];
            if (!version) {
                throw new Error('Version not found');
            }
            const newVersionProperties = version.properties.map(property =>
                property.id !== versionPropertyId
                    ? property
                    : {
                          ...property,
                          value: {
                              ...property.value,
                              ...changes,
                              id: feedId
                          }
                      }
            );

            return adapter.updateOne(
                {
                    id: version.id,
                    changes: {
                        properties: newVersionProperties
                    }
                },
                state
            );
        }
    ),

    on(
        VersionsActions.removeVersionPropertiesFromVersions,
        (state, { propertyIdsToRemove, versionIds }): VersionsState => {
            const versionUpdates = versionIds.map(versionId => {
                const version = state.entities[versionId];
                const updatedProperties = version!.properties.filter(
                    property => !propertyIdsToRemove.includes(property.id)
                );
                return { id: versionId, changes: { properties: updatedProperties } };
            });

            return adapter.updateMany(versionUpdates, state);
        }
    ),

    on(
        VersionsActions.updateShouldCheckPristine,
        (state, { shouldCheckPristine }): VersionsState => ({
            ...state,
            shouldCheckPristine
        })
    ),

    on(
        VersionsActions.copyStyleIdsBetweenDocuments,
        (
            state,
            { sourceDocumentId: fromDocumentId, targetDocumentId: toDocumentId }
        ): VersionsState => {
            const propertiesToUpdate = copyCharacterStylesToNewDesign(
                cloneDeep(Object.values(state.entities) as IVersion[]),
                {
                    targetDocumentId: toDocumentId,
                    sourceDocumentId: fromDocumentId
                }
            );

            return adapter.updateMany(
                propertiesToUpdate.map(({ versionId, properties: updatedProperties }) => {
                    const version = state.entities[versionId]!;
                    return {
                        id: versionId,
                        changes: {
                            properties: [
                                ...version.properties.filter(
                                    property =>
                                        !updatedProperties.find(
                                            updatedProperty => updatedProperty.id === property.id
                                        )
                                ),
                                ...updatedProperties
                            ]
                        }
                    };
                }),
                state
            );
        }
    ),
    on(
        VersionsActions.cleanStyleIds,
        (state, { validDocumentIds }): VersionsState =>
            adapter.updateMany(
                Object.values(state.entities).map(version => ({
                    id: version!.id,
                    changes: {
                        properties: cleanStyleIdsFromProperties(version!.properties, validDocumentIds)
                    }
                })),
                state
            )
    ),

    on(
        VersionsActions.designUpdated,
        (state): VersionsState => ({
            ...state,
            newVersionPropertiesIds: []
        })
    )
);
