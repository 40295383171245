import { NgModule } from '@angular/core';
import { BrandService } from './brand.service';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BrandEffects } from './brand.effects';
import * as fromBrand from './brand.reducer';

@NgModule({
    imports: [
        StoreModule.forFeature(fromBrand.BRAND_FEATURE_KEY, fromBrand.reducer),
        EffectsModule.forFeature([BrandEffects])
    ],
    providers: [BrandService]
})
export class BrandStoreModule {}
