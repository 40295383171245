import { createFeatureSelector, select, createSelector } from '@ngrx/store';
import { pipe } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { UserState, USER_FEATURE_KEY } from './user.reducer';

export const getUserState = createFeatureSelector<UserState>(USER_FEATURE_KEY);

export const getUserWhenLoaded = pipe(
    select(getUserState),
    filter(state => state.loaded)
);

export const getUserLoaded = createSelector(getUserState, (state: UserState) => state?.loaded);

export const getUserError = createSelector(getUserState, (state: UserState) => state?.error);

export const getOidcUser = createSelector(getUserState, (state: UserState) => state?.user);

export const getIsEmployee = createSelector(getUserState, (state: UserState) => state?.isEmployee);

export const getIsEmployeeWhenLoaded = pipe(
    getUserWhenLoaded,
    map(state => state.isEmployee)
);

export const getShowcaseToken = createSelector(
    getUserState,
    (state: UserState) => state?.showcaseToken
);

export const getRole = createSelector(getUserState, (state: UserState) => state?.role);

export const getPermissions = createSelector(getUserState, (state: UserState) => state?.permissions);

export const getPermissionsWhenLoaded = pipe(
    getUserWhenLoaded,
    map(state => state.permissions)
);

export const getAllowedOperations = createSelector(
    getUserState,
    (state: UserState) => state?.allowedOperations
);

export const getAccessToken = createSelector(
    getUserState,
    (state: UserState) => state?.user?.access_token
);

export const getIsAuthenticated = createSelector(getUserState, (state: UserState) => !!state?.user);

export const getIsTokenExpired = createSelector(getUserState, (state: UserState) => {
    if (!state.user) {
        return true;
    }
    return state.user.expired;
});
