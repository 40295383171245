import { IAction } from './action';
import { IAnimation, ITimeAndDuration } from './animation';
import { IColor } from './color';
import { IBounds } from './dimension';
import { IImageElementAsset, IVideoElementAsset } from './element-asset';
import { IFeed } from './feed';
import { IImageSettings } from './image';
import { Masking, NotMaskable, OmitMasking } from './mask';
import { IState } from './state';
import { IBorder, IFilterMap, IPadding, IRadius, IShadow, ITextShadow } from './style';
import { HorizontalAlignment, ICharacterStylesMap, TextOverflow, VerticalAlignment } from './text';
import { IVideoSettings } from './video/video-settings';
import { IWidgetCustomProperty } from './widget';

export enum ElementKind {
    Rectangle = 'rectangle',
    Text = 'text',
    Image = 'image',
    Video = 'video',
    Group = 'group',
    Ellipse = 'ellipse',
    Button = 'button',
    Widget = 'widget',
    /** Only set when bfl-widget is in bl. Only applied to `element.type` */
    BannerflowLibraryWidget = 'bannerflowLibraryWidget',
    /** Only set when bfl-widget is on canvas. Only applied to `element.type`, never on `node.kind` */
    BannerflowLibraryWidgetInstance = 'bannerflowLibraryWidgetInstance'
}

export interface IElementWithKind<Kind extends ElementKind = ElementKind> {
    readonly kind: Kind;
}

export interface IPrimitiveElement<Kind extends ElementKind = ElementKind>
    extends IElementWithKind<Kind> {
    id: string;
    name: string;
    locked: boolean;
    hidden: boolean;
    parentId?: string;
    parentNodeId?: string;
}

export interface IBaseElement<Kind extends ElementKind = ElementKind>
    extends IPrimitiveElement<Kind>,
        IBounds,
        ITimeAndDuration {}

export interface IElementV2<Kind extends ElementKind = ElementKind> extends IBaseElement<Kind> {
    time: number;
    duration: number;
    radius: IRadius;
    mirrorX: boolean;
    mirrorY: boolean;
    opacity: number;
    originX: number;
    originY: number;
    rotationX: number;
    rotationY: number;
    rotationZ: number;
    scaleX: number;
    scaleY: number;
    filters: IFilterMap;
    states: IState[];
    actions: IAction[];
    animations: IAnimation[];
    fill?: IColor;
    border?: IBorder;
    shadows?: IShadow[];
    masking?: Masking;
    ratio?: number;
    feed?: IFeed;
}

export interface ITextKindElement<Kind extends ElementKind = ElementKind>
    extends OmitMasking<IElementV2<Kind>> {
    content?: string;
    textColor: IColor;
    textShadows?: ITextShadow[];
    uppercase: boolean;
    underline: boolean;
    strikethrough: boolean;
    horizontalAlignment: HorizontalAlignment;
    verticalAlignment: VerticalAlignment;
    lineHeight: number;
    characterSpacing: number;
    fontSize: number;
    maxRows: number;
    padding: IPadding;
    textOverflow: TextOverflow;
    font?: string;
    characterStyles: ICharacterStylesMap;
}

export interface IRectangleElement extends IElementV2<ElementKind.Rectangle> {}
export interface IEllipseElement extends IElementV2<ElementKind.Ellipse> {}
export interface ITextElement extends ITextKindElement<ElementKind.Text> {}
export interface IButtonElement extends ITextKindElement<ElementKind.Button> {}

export interface IImageElement extends IElementV2<ElementKind.Image> {
    imageAsset?: IImageElementAsset;
    feed?: IFeed;
    imageSettings: IImageSettings;
}

export interface IVideoElement extends NotMaskable<IElementV2<ElementKind.Video>> {
    videoSettings: IVideoSettings;
    videoAsset?: IVideoElementAsset;
    feed?: IFeed;
}

export interface IWidgetElement extends OmitMasking<IElementV2<ElementKind.Widget>> {
    html: string;
    js: string;
    ts: string;
    css: string;
    customProperties: IWidgetCustomProperty[];
    parentId?: string;
    widgetReference?: string;
}

export interface IGroupElement extends IPrimitiveElement<ElementKind.Group> {}

export type IMediaElement = IVideoElement | IImageElement;

export type OneOfTextElements = IButtonElement | ITextElement;

export type OneOfElements =
    | IRectangleElement
    | IEllipseElement
    | ITextElement
    | IButtonElement
    | IImageElement
    | IWidgetElement
    | IVideoElement;
