import { Injectable } from '@angular/core';
import { Logger } from '@bannerflow/sentinel-logger';
import { PSDRootElement } from '@domain/psd';
import { Psd, readPsd } from 'ag-psd';
import { createBlueprintFromPsd } from './psd-reader';

@Injectable({ providedIn: 'root' })
export class PsdConverterService {
    private logger = new Logger('PSDImportService');

    convertPSDtoBlueprint(fileBuffer: ArrayBuffer): PSDRootElement {
        this.logger.verbose('Reading PSD file');
        const psd: Psd = readPsd(fileBuffer);
        this.logger.verbose('Successfully read PSD file');
        this.logger.verbose('Parsing PSD file');
        this.validatePSDSize(psd);
        const rootElement = createBlueprintFromPsd(psd);
        this.logger.verbose('Successfully parsed PSD file');
        this.logger.debug(rootElement);
        return rootElement;
    }

    private validatePSDSize(psd: Psd): void {
        if (psd.width > 5000 || psd.height > 5000) {
            throw new Error(
                `The PSD artboard cannot be bigger than 5000x5000px. PSD has ${psd.width}x${psd.height}px`
            );
        }
    }
}
