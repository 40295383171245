import { Injectable } from '@angular/core';
import { Logger } from '@bannerflow/sentinel-logger';
import { hasWidgetReference } from '@creative/elements/widget/utils';
import {
    isImageElement,
    isImageNode,
    isVideoElement,
    isVideoNode,
    isWidgetNode
} from '@creative/nodes';
import { deserializeBrandlibrary } from '@data/deserialization/brand-library';
import {
    CREATE_ELEMENT,
    DELETE_ELEMENT,
    GET_BRAND_LIBRARY,
    UPDATE_ELEMENT
} from '@data/graphql/brand-library.queries';
import {
    ZElementCreationVariables,
    ZElementUpdateVariables
} from '@data/graphql/brand-library.queries.types';
import {
    BrandLibraryDto,
    IBrandLibrary,
    IBrandLibraryElement,
    IElement,
    INewBrandLibraryElement,
    INewElement,
    IWidgetLibraryAsset,
    OneOfLibraryAssets
} from '@domain/creativeset';
import { AssetReference, AssetReferenceValue, OneOfElementAssets } from '@domain/element-asset';
import { OneOfElementDataNodes } from '@domain/nodes';
import { cloneDeep } from '@studio/utils/clone';
import { Apollo } from 'apollo-angular';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { BrandService } from '../brand/state/brand.service';
import { CreativesetDataService } from '../creativeset/creativeset.data.service';

@Injectable({
    providedIn: 'root'
})
export class BrandLibraryDataService {
    brandLibraryLoaded: Promise<void>;
    private _brandLibrary: IBrandLibrary;
    get brandLibrary(): Readonly<IBrandLibrary> | undefined {
        return this._brandLibrary;
    }
    private _brandLibrary$ = new ReplaySubject<IBrandLibrary>(1);
    brandLibrary$ = this._brandLibrary$
        .asObservable()
        .pipe(tap(brandLibrary => (this._brandLibrary = brandLibrary)));

    private fetchBrandLibrary$ = new Subject<void>();
    private logger = new Logger('BrandLibraryDataService');

    constructor(
        private apollo: Apollo,
        private brandService: BrandService,
        private creativeSetDataService: CreativesetDataService
    ) {
        this.fetchBrandLibrary$
            .pipe(
                withLatestFrom(this.brandService.brandId$),
                switchMap(([_, brandId]) => this.fetchBrandLibrary(brandId))
            )
            .subscribe();
    }

    loadBrandLibrary(): void {
        this.logger.verbose('loadBrandLibrary');
        this.fetchBrandLibrary$.next();
    }

    getAssetByElement<AssetType extends OneOfLibraryAssets = OneOfLibraryAssets>(
        element: IElement
    ): AssetType | undefined {
        if (!element) {
            return;
        }

        let assets: OneOfLibraryAssets[] = [];

        if (isImageElement(element)) {
            assets = this._brandLibrary.images;
        }

        if (isVideoElement(element)) {
            assets = this._brandLibrary.videos;
        }

        if (!assets.length) {
            return;
        }

        const foundAsset = assets.find(asset => {
            if (isImageElement(element) || isVideoElement(element)) {
                return !!element.properties.find(({ value }) => value === asset.id);
            }

            return false;
        });

        return foundAsset as AssetType;
    }

    getElementByAssetId(
        referenceType: AssetReferenceValue,
        id?: OneOfElementAssets['id']
    ): IBrandLibraryElement | undefined {
        if (!id) {
            return undefined;
        }

        return this._brandLibrary.elements.find(({ properties }) => {
            return !!properties.find(({ value, name }) => value === id && name === referenceType);
        });
    }

    getWidgetAssetByElement(
        element: IBrandLibraryElement | INewBrandLibraryElement
    ): IWidgetLibraryAsset | undefined {
        const bannerflowLibraryWidget = element.properties.find(
            prop => prop.name === AssetReference.BannerflowLibraryWidget
        );
        if (bannerflowLibraryWidget) {
            return this._brandLibrary.widgets.find(widget => {
                return widget.bannerflowLibraryId === bannerflowLibraryWidget.value;
            });
        } else {
            return this._brandLibrary.widgets.find(widget => {
                const widgetReference = element.properties.find(p => p.name === AssetReference.Widget);
                return widget.id === widgetReference?.value;
            });
        }
    }

    private fetchBrandLibrary(brandId: string): Observable<IBrandLibrary> {
        this.logger.verbose('fetchBrandLibrary');

        return this.apollo
            .query({
                query: GET_BRAND_LIBRARY,
                fetchPolicy: 'network-only',
                variables: {
                    brandId
                }
            })
            .pipe(
                map(({ data }) => data.brandLibrary),
                this.handleBrandLibraryResponse,
                tap(brandLibrary => this._brandLibrary$.next(brandLibrary))
            );
    }

    createElement(element: INewElement, dataNode?: OneOfElementDataNodes): Observable<IBrandLibrary> {
        const variables = ZElementCreationVariables.parse({
            element,
            originalElementId: dataNode?.id,
            id: dataNode?.id,
            brandId: this._brandLibrary?.id
        });

        return this.apollo
            .mutate({
                mutation: CREATE_ELEMENT,
                fetchPolicy: 'network-only',
                variables
            })
            .pipe(
                map(({ data }) => data!.createElementInBrandLibrary),
                this.handleBrandLibraryResponse,
                tap(brandLibrary => this._brandLibrary$.next(brandLibrary))
            );
    }

    updateElement(element: IElement): Observable<IBrandLibrary> {
        const variables = ZElementUpdateVariables.parse({
            element,
            brandId: this._brandLibrary.id
        });

        return this.apollo
            .mutate({
                mutation: UPDATE_ELEMENT,
                fetchPolicy: 'network-only',
                variables
            })
            .pipe(
                map(({ data }) => data!.updateElementInBrandLibrary),
                this.handleBrandLibraryResponse,
                tap(brandLibrary => this._brandLibrary$.next(brandLibrary))
            );
    }

    deleteElements(ids: string[]): Observable<IBrandLibrary> {
        return this.apollo
            .mutate({
                mutation: DELETE_ELEMENT,
                fetchPolicy: 'network-only',
                variables: {
                    ids,
                    brandId: this._brandLibrary.id
                }
            })
            .pipe(
                map(({ data }) => data!.deleteElementsInBrandLibrary),
                this.handleBrandLibraryResponse,
                tap(brandLibrary => this._brandLibrary$.next(brandLibrary))
            );
    }

    mutateData<Key extends keyof IBrandLibrary, Data extends IBrandLibrary[Key]>(
        key: Key,
        data: Data
    ): void {
        this._brandLibrary$.next({
            ...this._brandLibrary,
            [key]: data
        });
    }

    getElementById(elementId: string | undefined): IBrandLibraryElement | undefined {
        return this.brandLibrary?.elements.find(({ id }) => id === elementId);
    }

    getElementByDataNode(node: OneOfElementDataNodes): IBrandLibraryElement | undefined {
        if (node.parentId) {
            return this.brandLibrary?.elements.find(({ id }) => id === node.parentId);
        }

        const isImage = isImageNode(node);
        const isVideo = isVideoNode(node);
        const isWidget = isWidgetNode(node);
        const isElementWithAsset = isImage || isVideo || isWidget;

        if (isElementWithAsset) {
            if (isImage && node.imageAsset?.id) {
                return this.getElementByAssetId(AssetReference.Image, node.imageAsset.id);
            }
            if (isVideo && node.videoAsset?.id) {
                return this.getElementByAssetId(AssetReference.Video, node.videoAsset.id);
            }
            if (isWidget) {
                const element = this.creativeSetDataService.creativeset.elements.find(
                    ({ id }) => id === node.id
                );
                const widgetReference = element?.properties.find(hasWidgetReference);

                return this.getElementByAssetId(AssetReference.Widget, widgetReference?.value);
            }
        }
    }

    isParentElementOfNode(blElement: IBrandLibraryElement, node: OneOfElementDataNodes): boolean {
        return this.getElementByDataNode(node)?.id === blElement.id;
    }

    private handleBrandLibraryResponse = (
        response: Observable<BrandLibraryDto>
    ): Observable<IBrandLibrary> => {
        return response.pipe(
            map(brandLibrary => {
                const brandLibraryResponse = cloneDeep(brandLibrary);
                setModifiedDate(brandLibraryResponse);
                return brandLibraryResponse;
            }),
            map(brandLibraryResponse => deserializeBrandlibrary(brandLibraryResponse))
        );
    };
}

function setModifiedDate(brandLibrary: BrandLibraryDto): void {
    brandLibrary.elements.forEach(element => (element.modified ||= element.created));
    brandLibrary.images.forEach(element => (element.modified ||= element.created));
    brandLibrary.widgets.forEach(element => (element.modified ||= element.created));
}
