import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { CreativesetDataService } from '../creativeset.data.service';
import { CreativesetEffects } from './creativeset.effects';

@NgModule({
    declarations: [],
    imports: [CommonModule, EffectsModule.forFeature([CreativesetEffects])],
    providers: [CreativesetDataService]
})
export class CreativesetStoreModule {}
