import { Injectable } from '@angular/core';
import { isVideoNode } from '@creative/nodes';
import { VideoLibraryAsset } from '@domain/creativeset';
import { IImageElementAsset } from '@domain/element-asset';
import { Subject } from 'rxjs';
import { EditorSaveStateService, ElementReplaceService, ElementSelectionService } from '../services';
import { IAssetSelectionEvent, IFeedItemSelection } from './asset-picker.component';

@Injectable()
export class AssetPickerService {
    private _feedSelected$ = new Subject<{ selection: IFeedItemSelection; replaceAll: boolean }>();
    private _assetSelected$ = new Subject<IAssetSelectionEvent>();
    private _assetRemoved$ = new Subject<void>();

    feedSelected$ = this._feedSelected$.asObservable();
    assetSelected$ = this._assetSelected$.asObservable();
    assetRemoved$ = this._assetRemoved$.asObservable();

    constructor(
        private elementSelectionService: ElementSelectionService,
        private elementReplaceService: ElementReplaceService,
        private editorSaveStateService: EditorSaveStateService
    ) {}

    selectFeed(feed: { selection: IFeedItemSelection; replaceAll: boolean }): void {
        this._feedSelected$.next(feed);
    }

    selectAsset(event: IAssetSelectionEvent): void {
        this._assetSelected$.next(event);
        if (isVideoNode(this.elementSelectionService.currentSelection.elements[0])) {
            this.onVideoSelected(event);
        } else {
            this.onImageSelected(event);
        }
    }

    removeAsset(): void {
        this._assetRemoved$.next();
    }

    onImageSelected(event: IAssetSelectionEvent): void {
        const { id, url, width, height, name } = event.asset;
        const asset: IImageElementAsset = {
            id: id,
            url: url,
            name: name,
            height: height,
            width: width
        };
        this.elementReplaceService.replaceImage(asset, event.replaceAll);

        if (event.replaceAll) {
            this.saveAll();
        }
    }

    onVideoSelected(selection: IAssetSelectionEvent): void {
        const asset = selection.asset as VideoLibraryAsset;
        this.elementReplaceService.replaceVideo(asset, selection.replaceAll);

        if (selection.replaceAll) {
            this.saveAll();
        }
    }

    private saveAll(): void {
        this.editorSaveStateService.save({
            saveAll: true,
            saveAndExit: false
        });
    }
}
