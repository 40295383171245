import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ShowcaseState, CREATIVESET_SHOWCASE_FEATURE_KEY } from './creativeset-showcase.reducer';

export const getShowcaseState = createFeatureSelector<ShowcaseState>(CREATIVESET_SHOWCASE_FEATURE_KEY);

export const getShowcasesLoading = createSelector(
    getShowcaseState,
    (state: ShowcaseState) => !state.loaded
);

export const getShowcasesError = createSelector(
    getShowcaseState,
    (state: ShowcaseState) => state.error
);

export const getShowcases = createSelector(getShowcaseState, (state: ShowcaseState) => state.showcases);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getShowcaseByKey = (key: string) =>
    createSelector(getShowcaseState, (state: ShowcaseState) =>
        state.showcases.find(showcase => showcase.showcaseKey === key)
    );

export const getCreatedShowcase = createSelector(
    getShowcaseState,
    (state: ShowcaseState) => state.createdShowcase
);

export const getShowcasesUsers = createSelector(
    getShowcaseState,
    (state: ShowcaseState) => state.users
);

export const getShowcasesTranslators = createSelector(
    getShowcaseState,
    (state: ShowcaseState) => state.translators
);

export const getTemporaryShowcaseLink = createSelector(
    getShowcaseState,
    (state: ShowcaseState) => state.temporaryLink
);

export const getTemporaryShowcaseLinkLoading = createSelector(
    getShowcaseState,
    (state: ShowcaseState) => state.loadingTemporaryLink
);
