import { ICreativesetShowcase, IShowcaseTranslator, IShowcaseUser } from '@domain/showcase';
import { IAsyncState } from '@domain/store/async';
import { Action, createReducer, on } from '@ngrx/store';
import { creativesetShowcaseActions } from './creativeset-showcase.actions';

export const CREATIVESET_SHOWCASE_FEATURE_KEY = 'creativesetShowcase';

export interface CreativesetShowcasePartialState {
    readonly [CREATIVESET_SHOWCASE_FEATURE_KEY]: ShowcaseState;
}

export interface ShowcaseState extends IAsyncState {
    showcases: ICreativesetShowcase[];
    translators: IShowcaseTranslator[];
    users: IShowcaseUser[];
    createdShowcase?: ICreativesetShowcase;
    temporaryLink?: string;
    loadingTemporaryLink: boolean;
}

export const initialState: ShowcaseState = {
    loaded: false,
    showcases: [],
    translators: [],
    users: [],
    loadingTemporaryLink: false
};

export const creativesetShowcaseReducer = createReducer(
    initialState,
    on(
        creativesetShowcaseActions.loadCreativesetShowcases,
        (state): ShowcaseState => ({
            ...state,
            loaded: false,
            error: undefined
        })
    ),
    on(
        creativesetShowcaseActions.loadCreativesetShowcasesSuccess,
        (state, { response }): ShowcaseState => ({
            ...state,
            showcases: state.showcases.length ? state.showcases : response.showcases,
            translators: response.translators,
            users: response.users,
            loaded: true
        })
    ),
    on(
        creativesetShowcaseActions.loadCreativesetShowcasesFailure,
        (state, { error }): ShowcaseState => ({ ...state, error })
    ),

    on(
        creativesetShowcaseActions.createCreativesetShowcase,
        (state, { showcase }): ShowcaseState => ({
            ...state,
            temporaryLink: undefined,
            loadingTemporaryLink: showcase.isTemporary ? true : state.loadingTemporaryLink
        })
    ),
    on(
        creativesetShowcaseActions.createCreativesetShowcaseSuccess,
        (state, { showcase }): ShowcaseState => ({
            ...state,
            showcases: [...state.showcases, showcase],
            createdShowcase: showcase,
            temporaryLink: showcase.isTemporary ? showcase.showcaseLink : state.temporaryLink,
            loadingTemporaryLink: showcase.isTemporary ? false : state.loadingTemporaryLink
        })
    ),
    on(
        creativesetShowcaseActions.createCreativesetShowcaseFailure,
        (state, { error }): ShowcaseState => ({
            ...state,
            error,
            loadingTemporaryLink: false
        })
    ),

    on(
        creativesetShowcaseActions.updateCreativesetShowcaseSuccess,
        (state, { showcase }): ShowcaseState => ({
            ...state,
            showcases: state.showcases.map(s => (s.showcaseKey === showcase.showcaseKey ? showcase : s))
        })
    ),
    on(
        creativesetShowcaseActions.updateCreativesetShowcaseFailure,
        (state, { error }): ShowcaseState => ({ ...state, error })
    ),

    on(
        creativesetShowcaseActions.deleteCreativesetShowcaseSuccess,
        (state, { showcaseKey }): ShowcaseState => ({
            ...state,
            showcases: state.showcases.filter(s => s.showcaseKey !== showcaseKey)
        })
    ),
    on(
        creativesetShowcaseActions.deleteCreativesetShowcaseFailure,
        (state, { error }): ShowcaseState => ({ ...state, error })
    ),

    on(
        creativesetShowcaseActions.resetCreatedShowcase,
        (state): ShowcaseState => ({ ...state, createdShowcase: undefined })
    ),

    // Showcase Manage View Actions
    on(
        creativesetShowcaseActions.loadShowcaseCreativesetFailure,
        (state, { error }): ShowcaseState => ({ ...state, error })
    )
);

export function reducer(state: ShowcaseState | undefined, action: Action): ShowcaseState {
    return creativesetShowcaseReducer(state, action);
}
