import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FontFamiliesEffects } from './font-families.effects';
import * as fromFontFamilies from './font-families.reducer';
import { FontFamiliesService } from './font-families.service';

@NgModule({
    imports: [
        StoreModule.forFeature(fromFontFamilies.FONT_FAMILIES_FEATURE_KEY, fromFontFamilies.reducer),
        EffectsModule.forFeature([FontFamiliesEffects])
    ],
    providers: [FontFamiliesService]
})
export class FontFamiliesStoreModule {}
