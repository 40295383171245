import { IAsyncState } from '@domain/store/async';
import { createReducer, on } from '@ngrx/store';
import { IDuplicationResponse, ICLVList } from '@studio/domain/api/duplicate-creatives.types';
import * as DuplicateCreativeActions from './duplicate-creative.actions';

export const DUPLICATE_CREATIVES_FEATURE_KEY = 'duplicateCreatives';

export interface DuplicateCreativesPartialState {
    readonly [DUPLICATE_CREATIVES_FEATURE_KEY]: DuplicateCreativesState;
}

export interface DuplicateCreativesState extends IAsyncState {
    setsAndFolders?: ICLVList;
    searchTerm?: string;
    pageIndex: number;
    duplicationResult?: IDuplicationResponse;
    isDuplicating: boolean;
}

export const initialState: DuplicateCreativesState = {
    loaded: false,
    pageIndex: 1,
    isDuplicating: false
};

export const reducer = createReducer(
    initialState,

    on(
        DuplicateCreativeActions.loadDuplicateCreativesPage,
        (state): DuplicateCreativesState => ({
            ...state,
            loaded: false,
            setsAndFolders: undefined,
            isDuplicating: false
        })
    ),
    on(
        DuplicateCreativeActions.loadDuplicateCreativesPageSuccess,
        (state, { data, pageIndex }): DuplicateCreativesState => ({
            ...state,
            loaded: true,
            setsAndFolders: data,
            pageIndex
        })
    ),

    on(
        DuplicateCreativeActions.searchDuplicateCreatives,
        (state, { searchTerm }): DuplicateCreativesState => ({
            ...state,
            loaded: false,
            searchTerm,
            setsAndFolders: undefined,
            isDuplicating: false
        })
    ),
    on(
        DuplicateCreativeActions.searchDuplicateCreativesSuccess,
        (state, { searchTerm, data }): DuplicateCreativesState =>
            searchTerm !== state.searchTerm // Ignore responses to old searches
                ? { ...state }
                : {
                      ...state,
                      loaded: true,
                      searchTerm,
                      setsAndFolders: data
                  }
    ),

    on(
        DuplicateCreativeActions.clearSearchDuplicateCreatives,
        (state): DuplicateCreativesState => ({
            ...state,
            loaded: true,
            searchTerm: undefined,
            setsAndFolders: undefined
        })
    ),

    on(
        DuplicateCreativeActions.createDuplicationToNew,
        DuplicateCreativeActions.createDuplicationToExisting,
        (state): DuplicateCreativesState => ({
            ...state,
            loaded: false,
            duplicationResult: undefined,
            isDuplicating: true
        })
    ),
    on(
        DuplicateCreativeActions.createDuplicationToNewSuccess,
        DuplicateCreativeActions.createDuplicationToExistingSuccess,
        (state, { data: duplicationResult }): DuplicateCreativesState => ({
            ...state,
            loaded: true,
            duplicationResult
        })
    ),

    on(
        DuplicateCreativeActions.searchDuplicateCreativesFailure,
        DuplicateCreativeActions.loadDuplicateCreativesPageFailure,
        DuplicateCreativeActions.createDuplicationToNewFailure,
        DuplicateCreativeActions.createDuplicationToExistingFailure,
        (state, { error }): DuplicateCreativesState => ({
            ...state,
            error,
            loaded: true,
            setsAndFolders: undefined
        })
    ),

    on(DuplicateCreativeActions.clearDuplicationState, () => ({ ...initialState })),

    on(
        DuplicateCreativeActions.createNewFolderFailure,
        (state): DuplicateCreativesState => ({
            ...state,
            error: 'Failed to create new folder'
        })
    ),

    on(
        DuplicateCreativeActions.createNewFolderSuccess,
        (state, { newFolder }): DuplicateCreativesState => {
            if (!state.setsAndFolders) {
                return { ...state };
            }

            return {
                ...state,
                setsAndFolders: {
                    ...state.setsAndFolders,
                    items: [newFolder, ...state.setsAndFolders?.items]
                }
            };
        }
    )
);
