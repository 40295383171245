import { Injectable } from '@angular/core';
import { getSizeIdsFromCreatives, getVersionIdsFromCreatives } from '@creative/utils';
import { ICreativesetShowcase, ICreativesetShowcaseCreateData } from '@domain/showcase';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { filter } from 'rxjs/operators';
import { TileSelectService } from '../../../pages/manage-view/services/tile-select.service';
import { CreativesetDataService } from '../../creativeset/creativeset.data.service';
import { QRCodeService } from '../../services/qr-code.service';
import { creativesetShowcaseActions } from './creativeset-showcase.actions';
import * as CreativesetShowcaseSelectors from './creativeset-showcase.selectors';

@Injectable({
    providedIn: 'root'
})
export class CreativesetShowcaseService {
    loading$ = this.store.select(CreativesetShowcaseSelectors.getShowcasesLoading);
    error$ = this.store.select(CreativesetShowcaseSelectors.getShowcasesError);
    showcases$ = this.store.select(CreativesetShowcaseSelectors.getShowcases);
    createdShowcase$ = this.store.select(CreativesetShowcaseSelectors.getCreatedShowcase);
    translators$ = this.store.select(CreativesetShowcaseSelectors.getShowcasesTranslators);
    users$ = this.store.select(CreativesetShowcaseSelectors.getShowcasesUsers);

    showcaseDeleted$ = this.actions.pipe(
        ofType(creativesetShowcaseActions.deleteCreativesetShowcaseSuccess)
    );
    showcaseCreativesetResponse$ = this.actions.pipe(
        ofType(creativesetShowcaseActions.loadShowcaseCreativesetSuccess)
    );
    translationCompleted$ = this.actions.pipe(
        ofType(creativesetShowcaseActions.completeTranslationSuccess)
    );

    temporaryShowcaseLinkLoading$ = this.store.select(
        CreativesetShowcaseSelectors.getTemporaryShowcaseLinkLoading
    );
    temporaryShowcaseLink$ = this.store
        .select(CreativesetShowcaseSelectors.getTemporaryShowcaseLink)
        .pipe(filter(link => !!link)); // used for the QR code modal

    constructor(
        private readonly store: Store,
        private readonly actions: Actions,
        private creativesetDataService: CreativesetDataService,
        private qrCodeService: QRCodeService,
        private tileSelectService: TileSelectService
    ) {}

    loadShowcaseCreativeset(key: string): void {
        this.store.dispatch(creativesetShowcaseActions.loadShowcaseCreativeset({ key }));
    }

    loadShowcasesOfCreativeset(): void {
        this.store.dispatch(
            creativesetShowcaseActions.loadCreativesetShowcases({
                creativesetId: this.creativesetDataService.creativeset.id
            })
        );
    }

    createShowcaseLink(showcase: ICreativesetShowcaseCreateData): void {
        this.store.dispatch(creativesetShowcaseActions.createCreativesetShowcase({ showcase }));
    }

    createTemporaryShowcaseLink(versions: string[] = [], sizes: string[] = []): void {
        const showcase: ICreativesetShowcaseCreateData = {
            allowedOperations: [],
            creativesetId: this.creativesetDataService.creativeset.id,
            invitationMessage: '',
            invitations: [],
            sizeIds: sizes,
            versionIds: versions,
            isTemporary: true
        };
        this.createShowcaseLink(showcase);
    }

    updateShowcaseLink(changes: Partial<ICreativesetShowcase>, showcaseKey: string): void {
        this.store.dispatch(
            creativesetShowcaseActions.updateCreativesetShowcase({ changes, showcaseKey })
        );
    }

    deleteShowcaseLink(showcaseKey: string): void {
        this.store.dispatch(creativesetShowcaseActions.deleteCreativesetShowcase({ showcaseKey }));
    }

    resetCreatedShowcase(): void {
        this.store.dispatch(creativesetShowcaseActions.resetCreatedShowcase());
    }

    completeTranslation(creativesetId: string, showcaseKey: string): void {
        this.store.dispatch(
            creativesetShowcaseActions.completeTranslation({ creativesetId, showcaseKey })
        );
    }

    async openQRLinkModal(): Promise<void> {
        const selectedCreatives = await firstValueFrom(this.tileSelectService.selection$);
        const selectedVersions = getVersionIdsFromCreatives(selectedCreatives);
        const selectedSizes = getSizeIdsFromCreatives(selectedCreatives);

        this.createTemporaryShowcaseLink(selectedVersions, selectedSizes);

        const link = await firstValueFrom(this.temporaryShowcaseLink$);

        this.qrCodeService.openQRCodeModal(link!);
    }
}
