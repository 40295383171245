import { Color } from '@creative/color';
import { deserializeInlineStyledText } from '@creative/serialization/text-serializer';
import { IElementProperty } from '@domain/creativeset/element';
import { IImageElementAsset, IVideoElementAsset } from '@domain/element-asset';
import { IFontStyle } from '@domain/font';
import { AllDataNodes, OneOfElementDataNodes, OneOfTextDataNodes } from '@domain/nodes';
import { INLINE_STYLED_TEXT, propertyToUnitMap } from '@domain/property';
import { OneOfElementsDto, TextLikeElementDto } from '@domain/serialization';
import { BorderStyle, IBorder } from '@domain/style';
import {
    createTextFromInlineStyledText,
    isImageNode,
    isTextDataElement,
    isVideoNode
} from '../nodes/helpers';
import { isFormulaValue } from '../rendering';
import { convertAnimationSettingsToDto } from './animation-serializer';
import { deserializeDataElement, deserializeTextLikeDataElement } from './data-element-serializer';
import { deserializePropertyValue } from './property-value-serializer';

export function deserializeElementProperties(
    dataNode: OneOfElementDataNodes,
    elementProperties: IElementProperty[]
): void {
    const elementDto = {} as OneOfElementsDto;

    for (const property of elementProperties) {
        // Some elements can somehow have a property with the name 'name'
        if (property.name === 'name') {
            continue;
        }
        switch (property.name) {
            case INLINE_STYLED_TEXT:
                if (isTextDataElement(dataNode)) {
                    dataNode.content = createTextFromInlineStyledText(
                        deserializeInlineStyledText(property.value as string)
                    );
                }
                break;
            case 'inTransition':
            case 'outTransition': {
                const transition =
                    typeof property.value === 'string' ? JSON.parse(property.value) : property.value;
                if (transition.settings && typeof transition.settings === 'object') {
                    transition.settings = convertAnimationSettingsToDto(transition.settings);
                }
                elementDto[property.name] = transition;
                break;
            }
            case 'states':
            case 'animations':
            case 'actions':
                if (typeof property.value === 'string') {
                    elementDto[property.name] = property.value.length
                        ? JSON.parse(property.value)
                        : property.value;
                    break;
                }
                elementDto[property.name] = property.value;
                break;
            case 'imageAsset':
                if (isImageNode(dataNode)) {
                    dataNode.imageAsset = property.value as unknown as IImageElementAsset;
                }
                break;
            case 'videoAsset':
                if (isVideoNode(dataNode)) {
                    dataNode.videoAsset = deserializePropertyValue(
                        property.name,
                        property.value
                    ) as IVideoElementAsset;
                }
                break;
            case 'font':
                elementDto[property.name] = (property.value as IFontStyle).fontFamilyId;
                dataNode[property.name] = property.value as IFontStyle;
                break;
            default:
                elementDto[property.name] = property.value;
                break;
        }
    }

    if (isTextDataElement(dataNode)) {
        deserializeTextLikeDataElement(
            dataNode as OneOfTextDataNodes,
            elementDto as TextLikeElementDto
        );
    }
    deserializeDataElement(dataNode as Required<typeof dataNode>, elementDto);
}

export function validateElementProperty(
    property: keyof AllDataNodes,
    value?: AllDataNodes[keyof AllDataNodes],
    isState = false
): boolean {
    const unit = propertyToUnitMap[property];

    // States are allowed to have undefined values
    if (isState && value === undefined) {
        return true;
    }

    switch (unit) {
        case 'number':
            return validateNumberProperty(property, value, isState);
        case 'Color':
            return value === undefined || value instanceof Color;
        case 'Shadow[]':
        case 'TextShadow[]':
            return value === undefined || Array.isArray(value);
        case 'Border':
            return validateBorderProperty(value);
    }
    return true;
}

function validateNumberProperty<Keys extends keyof AllDataNodes>(
    property: Keys,
    value?: AllDataNodes[Keys],
    isState = false
): boolean {
    // States are allowed to have undefined values or be a formula
    if (isState) {
        if (
            value === undefined ||
            ((typeof value === 'number' || typeof value === 'string') && isFormulaValue(value))
        ) {
            return true;
        }
    }

    if (typeof value === 'number') {
        if (isFinite(value)) {
            switch (property) {
                case 'scaleX':
                case 'scaleY':
                case 'width':
                case 'height':
                    return value >= 0;
                case 'opacity':
                    return value >= 0 && value <= 1;
                case 'ratio':
                    return value > 0;
            }
            return true;
        }
    } else if (property === 'ratio') {
        // Optional properties that are allowed to be undefined
        return typeof value === 'undefined';
    }

    return false;
}

function validateBorderProperty(value: unknown): value is IBorder {
    if (value === undefined) {
        return true;
    }

    // Must be a defined object if not not undefined
    if (typeof value !== 'object') {
        return false;
    }

    const border = value as IBorder;
    const validColor = border.color instanceof Color || typeof border.color === 'string';
    const validStyles: BorderStyle[] = ['solid', 'dotted', 'dashed'];

    return (
        typeof border.thickness === 'number' &&
        border.thickness >= 0 &&
        validStyles.indexOf(border.style) >= 0 &&
        validColor
    );
}
