import { LibraryKind, LibraryViewMode } from '@domain/media-library';
import { IAsyncState } from '@domain/store/async';
import { Action, createReducer, on } from '@ngrx/store';
import * as MediaLibraryActions from './media-library.actions';

export const MEDIA_LIBRARY_FEATURE_KEY = 'mediaLibrary';

export interface MediaLibraryPartialState {
    readonly [MEDIA_LIBRARY_FEATURE_KEY]: MediaLibraryState;
}

export interface MediaLibraryState extends IAsyncState {
    isOpen: boolean;
    isPinned: boolean;
    isDialogOpen: boolean;
    kind: LibraryKind;
    viewMode: LibraryViewMode;
    selectedFeedId: string | 'none';
    isEditingElement: boolean;
    searchQuery?: string;
}

export const initialState: MediaLibraryState = {
    loaded: false,
    isOpen: false,
    isDialogOpen: false,
    selectedFeedId: 'none',
    isPinned: false,
    kind: LibraryKind.Selection,
    viewMode: LibraryViewMode.Grid,
    isEditingElement: false,
    searchQuery: undefined
};

export const mediaLibraryReducer = createReducer(
    initialState,
    on(
        MediaLibraryActions.setSelectedFeedId,
        (state, { selectedFeedId }): MediaLibraryState => ({ ...state, selectedFeedId })
    ),

    on(
        MediaLibraryActions.openDialog,
        (state): MediaLibraryState => ({ ...state, isDialogOpen: true })
    ),
    on(
        MediaLibraryActions.closeDialog,
        (state): MediaLibraryState => ({ ...state, isDialogOpen: false })
    ),

    on(MediaLibraryActions.setKind, (state, { kind }): MediaLibraryState => ({ ...state, kind })),
    on(
        MediaLibraryActions.setViewMode,
        (state, { viewMode }): MediaLibraryState => ({ ...state, viewMode })
    ),
    on(
        MediaLibraryActions.setIsEditingElement,
        (state, { isEditingElement }): MediaLibraryState => ({ ...state, isEditingElement })
    ),
    on(
        MediaLibraryActions.pin,
        (state): MediaLibraryState => ({
            ...state,
            isPinned: true,
            kind: state.kind,
            isOpen: state.isOpen
        })
    ),
    on(
        MediaLibraryActions.unpin,
        (state): MediaLibraryState => ({
            ...state,
            isPinned: false,
            kind: LibraryKind.Selection,
            isOpen: false
        })
    ),
    on(
        MediaLibraryActions.open,
        (state, { kind }): MediaLibraryState => ({
            ...state,
            isOpen: true,
            kind
        })
    ),
    on(
        MediaLibraryActions.closeSuccess,
        (state, { force, isEditingBrandLibraryElementName }): MediaLibraryState => ({
            ...state,
            isOpen:
                state.isEditingElement || (state.isPinned && !force) || isEditingBrandLibraryElementName
        })
    ),
    on(
        MediaLibraryActions.initSuccess,
        (state, { kind, isPinned, selectedFeedId }): MediaLibraryState => ({
            ...state,
            isOpen: isPinned,
            kind,
            isPinned,
            selectedFeedId
        })
    ),
    on(
        MediaLibraryActions.search,
        (state, { searchQuery }): MediaLibraryState => ({
            ...state,
            searchQuery
        })
    )
);

export function reducer(state: MediaLibraryState | undefined, action: Action): MediaLibraryState {
    return mediaLibraryReducer(state, action);
}
