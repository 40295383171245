import { createAction, props } from '@ngrx/store';
import { BrandManagerBrandDto } from '@domain/creativeset/brand';

export const loadBrand = createAction('[Brand] Load Brand', props<{ brandId: string }>());

export const loadBrandSuccess = createAction(
    '[Brand] Load Brand Success',
    props<{ brand: BrandManagerBrandDto }>()
);

export const loadBrandFailure = createAction('[Brand] Load Brand Failure', props<{ error: any }>());
