import { getBrowserVersion, isSafari, preciseIOSVersion } from './browser';

/**
 * Insert element after another element in DOM
 * @param afterNode
 * @param newNode
 */
export function insertAfter(
    afterNode: HTMLElement | SVGElement,
    newNode: HTMLElement | SVGElement
): void {
    if (afterNode.parentNode) {
        afterNode.parentNode.insertBefore(newNode, afterNode.nextSibling);
    }
}

/**
 * Get the topmost window we're allowed access to
 */
export function getTopSafeFrame(): Window {
    let w = window as Window;

    // As long as a parent exists
    while (w.parent && w !== w.parent) {
        try {
            w.parent.location.href;
            if (!w.parent.document) {
                break;
            }
            w = w.parent;
        } catch (e) {
            break;
        }
    }
    return w;
}

/**
 * If we have access to top window, return it.
 */
export function accessTopWindow(): Window | undefined | null {
    let topWindow;

    // Check if top window is accessible
    try {
        // Break if access to document is forbidden (throws an error)
        const readyState = window.top?.document.readyState;
        topWindow = readyState ? window.top : undefined;
        // eslint-disable-next-line no-empty
    } catch (e) {}

    return topWindow;
}

export function isIframe(w: Window = window): boolean {
    try {
        return w.self !== window.top;
    } catch (e) {
        return true;
    }
}

/**
 * Iframe load event does not occur in iOS 16.3 or Safari 17 (OSX) because
 * `contentWindow` never gets initialized if 'about:blank' is set as src
 * and the parent wrapping iframe has a different src (e.g) javascript:''.
 * Note that 'about:blank' is in the WHATWG standard.
 * This only happens in certain iframe environments, e.g Adform's
 *
 * Related tickets:
 * ADA-1055
 * AD-793
 */
export function emptyIframeSrc(): string {
    const isSafari17 = isSafari && getBrowserVersion() >= 17;
    return isSafari17 || preciseIOSVersion.simple >= 16.3 ? `javascript:''` : 'about:blank';
}

/**
 * Ensures that the styles won't change, even if the user enables the "Auto dark mode" option in chrome
 */
export function addColorSchemeMetaTag(iframe: HTMLIFrameElement): void {
    const meta = document.createElement('meta');
    meta.name = 'color-scheme';
    meta.content = 'light only';
    iframe.contentDocument?.head.appendChild(meta);
}
