import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FiltersState, FILTERS_FEATURE_KEY } from './filters.reducer';

export const getFiltersState = createFeatureSelector<FiltersState>(FILTERS_FEATURE_KEY);

export const getSizesFilter = createSelector(getFiltersState, ({ sizes }: FiltersState) => sizes);

export const getStatusesFilter = createSelector(
    getFiltersState,
    ({ statuses }: FiltersState) => statuses
);

export const getAllSizes = createSelector(getFiltersState, ({ allSizes }: FiltersState) => allSizes);

export const getSelectedSizes = createSelector(getSizesFilter, getAllSizes, (sizes, allSizes) =>
    sizes.length === 0 ? allSizes : sizes
);

export const getHasSizesFilter = createSelector(
    getFiltersState,
    ({ sizes }: FiltersState) => !!sizes.length
);

export const getHasStatusesFilter = createSelector(
    getFiltersState,
    ({ statuses }) => !!statuses.length
);

export const getSelectedVersionsIds = createSelector(
    getFiltersState,
    ({ versions }: FiltersState) => versions
);

export const getHasActiveFilters = createSelector(
    getHasStatusesFilter,
    getHasSizesFilter,
    (hasStatusesFilter, hasSizeFilter) => hasStatusesFilter || hasSizeFilter
);

export const getIsFilteringAllSizes = createSelector(
    getAllSizes,
    getSizesFilter,
    (allSizes, sizes) => allSizes.length === sizes.length
);

export const getIsFilteringAnySize = createSelector(getSizesFilter, sizes => !!sizes.length);

export const getToggleAllTooltipText = createSelector(getIsFilteringAnySize, isFilteringAnySize =>
    isFilteringAnySize ? 'Clear filter' : 'Show all'
);
