import { Injectable } from '@angular/core';
import { UINotificationService } from '@bannerflow/ui';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { TranslationPanelDoneErrorEvent, EventLoggerService } from '@studio/monitoring/events';
import { CreativesetShowcaseDataService } from '../creativeset-showcase.data.service';
import { creativesetShowcaseActions } from './creativeset-showcase.actions';
import { getShowcaseByKey } from './creativeset-showcase.selectors';

@Injectable()
export class CreativesetShowcaseEffects {
    loadShowcaseCreativeset$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(creativesetShowcaseActions.loadShowcaseCreativeset),
            switchMap(action =>
                this.showcaseDataService.getShowcaseCreativeset(action.key).pipe(
                    map(showcase =>
                        creativesetShowcaseActions.loadShowcaseCreativesetSuccess(showcase)
                    ),
                    catchError(error =>
                        of(creativesetShowcaseActions.loadShowcaseCreativesetFailure({ error }))
                    )
                )
            )
        );
    });

    loadCreativesetShowcases$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(creativesetShowcaseActions.loadCreativesetShowcases),
            switchMap(action =>
                this.showcaseDataService.getShowcasesOfCreativeset(action.creativesetId).pipe(
                    map(response =>
                        creativesetShowcaseActions.loadCreativesetShowcasesSuccess({ response })
                    ),
                    catchError(error =>
                        of(creativesetShowcaseActions.loadCreativesetShowcasesFailure({ error }))
                    )
                )
            )
        );
    });

    createCreativesetShowcase$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(creativesetShowcaseActions.createCreativesetShowcase),
            switchMap(({ showcase }) =>
                this.showcaseDataService.createCreativesetShowcase(showcase).pipe(
                    map(showcase =>
                        creativesetShowcaseActions.createCreativesetShowcaseSuccess({ showcase })
                    ),
                    catchError(error =>
                        of(creativesetShowcaseActions.createCreativesetShowcaseFailure({ error }))
                    )
                )
            )
        );
    });

    createCreativesetShowcaseSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(creativesetShowcaseActions.createCreativesetShowcaseSuccess),
                tap(({ showcase }) => {
                    // filter when creating QR showcase links
                    if (showcase.invitations.length > 0) {
                        const message = 'Invitations sent to selected users.';
                        this.uiNotificationService.open(message, {
                            placement: 'top',
                            autoCloseDelay: 5000
                        });
                    }
                })
            );
        },
        { dispatch: false }
    );

    createCreativesetShowcaseFailure$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(creativesetShowcaseActions.createCreativesetShowcaseFailure),
                tap(() => {
                    this.uiNotificationService.open('Could not create shared links.', {
                        placement: 'top',
                        type: 'error',
                        autoCloseDelay: 5000
                    });
                })
            );
        },
        { dispatch: false }
    );

    updateCreativesetShowcase$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(creativesetShowcaseActions.updateCreativesetShowcase),
            concatLatestFrom(({ showcaseKey }) => this.store.select(getShowcaseByKey(showcaseKey))),
            switchMap(([{ changes, showcaseKey }, showcase]) => {
                if (!showcase) {
                    throw new Error(`Showcase ${showcaseKey} not found`);
                }
                const updateShowcase = { ...showcase, ...changes };
                return this.showcaseDataService.updateCreativesetShowcase(updateShowcase).pipe(
                    map(response =>
                        creativesetShowcaseActions.updateCreativesetShowcaseSuccess({
                            showcase: response
                        })
                    ),
                    catchError(error =>
                        of(creativesetShowcaseActions.updateCreativesetShowcaseFailure({ error }))
                    )
                );
            })
        );
    });

    updateCreativesetShowcaseSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(creativesetShowcaseActions.updateCreativesetShowcaseSuccess),
                tap(() => {
                    this.uiNotificationService.open('Link updated successfully.', {
                        placement: 'top',
                        autoCloseDelay: 5000,
                        type: 'success'
                    });
                })
            );
        },
        { dispatch: false }
    );

    updateCreativesetShowcaseFailure$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(creativesetShowcaseActions.updateCreativesetShowcaseFailure),
                tap(() => {
                    this.uiNotificationService.open('Could not update shared link.', {
                        placement: 'top',
                        type: 'error',
                        autoCloseDelay: 5000
                    });
                })
            );
        },
        { dispatch: false }
    );

    deleteCreativesetShowcase$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(creativesetShowcaseActions.deleteCreativesetShowcase),
            switchMap(({ showcaseKey }) => {
                return this.showcaseDataService.deleteCreativesetShowcase(showcaseKey).pipe(
                    map(() =>
                        creativesetShowcaseActions.deleteCreativesetShowcaseSuccess({ showcaseKey })
                    ),
                    catchError(error =>
                        of(creativesetShowcaseActions.deleteCreativesetShowcaseFailure({ error }))
                    )
                );
            })
        );
    });

    deleteCreativesetShowcaseSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(creativesetShowcaseActions.deleteCreativesetShowcaseSuccess),
                tap(() => {
                    this.uiNotificationService.open('Link deleted successfully.', {
                        placement: 'top',
                        autoCloseDelay: 5000
                    });
                })
            );
        },
        { dispatch: false }
    );

    deleteCreativesetShowcaseFailure$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(creativesetShowcaseActions.deleteCreativesetShowcaseFailure),
                tap(() => {
                    this.uiNotificationService.open('Could not delete showcase.', {
                        type: 'error',
                        placement: 'top',
                        autoCloseDelay: 5000
                    });
                })
            );
        },
        { dispatch: false }
    );

    completeTranslation$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(creativesetShowcaseActions.completeTranslation),
            switchMap(({ creativesetId, showcaseKey }) => {
                return this.showcaseDataService
                    .completeShowcaseCreativesetTranslation(creativesetId, showcaseKey)
                    .pipe(
                        map(() => creativesetShowcaseActions.completeTranslationSuccess()),
                        catchError(error =>
                            of(creativesetShowcaseActions.completeTranslationFailure({ error }))
                        )
                    );
            })
        );
    });

    completeTranslationFailure$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(creativesetShowcaseActions.completeTranslationFailure),
                tap(({ error }) => {
                    this.eventLoggerService.log(new TranslationPanelDoneErrorEvent(error as Error));

                    this.uiNotificationService.open('Could not complete translation.', {
                        type: 'error',
                        placement: 'top',
                        autoCloseDelay: 5000
                    });
                })
            );
        },
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private store: Store,
        private showcaseDataService: CreativesetShowcaseDataService,
        private uiNotificationService: UINotificationService,
        private eventLoggerService: EventLoggerService
    ) {}
}
