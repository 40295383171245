import { inject, Injectable } from '@angular/core';
import { LibraryKind, LibraryViewMode } from '@domain/media-library';
import { OneOfElementDataNodes } from '@domain/nodes';
import { Store } from '@ngrx/store';
import * as MediaLibraryActions from './media-library.actions';
import * as MediaLibrarySelectors from './media-library.selectors';
import { cloneDeep } from '@studio/utils/clone';

@Injectable()
export class MediaLibraryService {
    private store = inject(Store);

    loaded$ = this.store.select(MediaLibrarySelectors.getLoaded);
    error$ = this.store.select(MediaLibrarySelectors.getError);

    mediaLibraryState$ = this.store.select(MediaLibrarySelectors.getMediaLibraryState);
    isOpen$ = this.store.select(MediaLibrarySelectors.getIsOpen);
    isPinned$ = this.store.select(MediaLibrarySelectors.getIsPinned);
    isDialogOpen$ = this.store.select(MediaLibrarySelectors.getIsDialogOpen);
    isEditingElement$ = this.store.select(MediaLibrarySelectors.getIsEditingElement);
    kind$ = this.store.select(MediaLibrarySelectors.getKind);
    selectedFeedId$ = this.store.select(MediaLibrarySelectors.getSelectedFeedId);
    search$ = this.store.select(MediaLibrarySelectors.search);
    viewMode$ = this.store.select(MediaLibrarySelectors.getViewMode);

    setSelectedFeedId(selectedFeedId: string): void {
        this.store.dispatch(MediaLibraryActions.setSelectedFeedId({ selectedFeedId }));
    }

    unselectFeed(): void {
        this.store.dispatch(MediaLibraryActions.setSelectedFeedId({ selectedFeedId: 'none' }));
    }

    toggleMediaLibrary(kind: LibraryKind, isEditingElement: boolean): void {
        this.store.dispatch(MediaLibraryActions.toggle({ kind, isEditingElement }));
    }

    openMediaLibrary(kind: LibraryKind): void {
        this.store.dispatch(MediaLibraryActions.open({ kind }));
    }

    closeMediaLibrary(isEditingElement: boolean, force: boolean = false): void {
        this.store.dispatch(MediaLibraryActions.close({ force, isEditingElement }));
    }

    togglePinned(): void {
        this.store.dispatch(MediaLibraryActions.togglePinned());
    }

    unpin(): void {
        this.store.dispatch(MediaLibraryActions.unpin());
    }

    setLibraryKind(kind: LibraryKind): void {
        this.store.dispatch(MediaLibraryActions.setKind({ kind }));
    }

    setViewMode(viewMode: LibraryViewMode): void {
        this.store.dispatch(MediaLibraryActions.setViewMode({ viewMode }));
    }

    setIsEditingElement(isEditingElement: boolean): void {
        this.store.dispatch(MediaLibraryActions.setIsEditingElement({ isEditingElement }));
    }

    openDialog(): void {
        this.store.dispatch(MediaLibraryActions.openDialog());
    }

    closeDialog(): void {
        this.store.dispatch(MediaLibraryActions.closeDialog());
    }

    init(): void {
        this.store.dispatch(MediaLibraryActions.init());
    }

    filterByElement(node: OneOfElementDataNodes): void {
        this.store.dispatch(MediaLibraryActions.filterLibraryByElement({ node: cloneDeep(node) }));
    }

    search(searchQuery?: string): void {
        this.store.dispatch(
            MediaLibraryActions.search({
                searchQuery
            })
        );
    }
}
