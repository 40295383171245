import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SizeAddOverviewComponent } from '../../../size-add-dialog/size-add-overview/size-add-overview.component';
import { UIModule } from '@bannerflow/ui';
import { LayerItem } from '@domain/psd';
import { Observable } from 'rxjs';
import { hasCriticalError } from '../../conversion-errors';
import { PsdImportPreviewComponent } from '../psd-import-preview/psd-import-preview.component';
import { PsdImportService } from '../psd-import.service';

export enum LayerSelectedState {
    Unselected,
    SomeSelected,
    AllSelected
}
@Component({
    standalone: true,
    imports: [UIModule, CommonModule, SizeAddOverviewComponent, PsdImportPreviewComponent],
    selector: 'psd-list',
    templateUrl: './psd-list.component.html',
    styleUrls: ['./psd-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PsdListComponent implements OnDestroy {
    layers$: Observable<LayerItem[]>;
    selectedPsdLayers: number;

    LayerSelectedState = LayerSelectedState;

    constructor(private psdImportService: PsdImportService) {
        this.layers$ = this.psdImportService.layers$;

        this.psdImportService.selectedPsdLayers$
            .pipe(takeUntilDestroyed())
            .subscribe(layers => (this.selectedPsdLayers = layers.length));
    }

    ngOnDestroy(): void {
        this.psdImportService.resetPsd();
    }

    toggleGroupCollapse(groupLayer: LayerItem): void {
        this.psdImportService.toggleGroupCollapse(groupLayer);
    }

    toggleVisibility(layer: LayerItem): void {
        this.psdImportService.toggleVisibility(layer);
    }

    toggleGroupVisibility(groupLayer: LayerItem): void {
        this.psdImportService.toggleGroupVisibility(groupLayer);
    }

    toggleSelection(layer: LayerItem): void {
        this.psdImportService.toggleSelection(layer);
    }

    toggleGroupSelection(groupLayer: LayerItem): void {
        this.psdImportService.toggleGroupSelection(groupLayer);
    }

    toggleAllSelection(): void {
        this.psdImportService.toggleAllSelection();
    }

    getSelectedChildrenCount(layer: LayerItem): number {
        const selectedChildrenCount = this.psdImportService
            .getChildLayers(layer)
            .filter(
                child => child.selected && !hasCriticalError(child) && child.type !== 'group'
            ).length;

        this.updateLayerSelection(layer, selectedChildrenCount);
        return selectedChildrenCount;
    }

    getChildrenCount(layer: LayerItem): number {
        return this.psdImportService.getChildLayers(layer).filter(child => child.type !== 'group')
            .length;
    }

    selectionState(layer: LayerItem): LayerSelectedState {
        const childrenLength = this.getChildrenCount(layer);
        const selectedChildren = this.getSelectedChildrenCount(layer);

        if (selectedChildren === 0) {
            return LayerSelectedState.Unselected;
        }

        if (selectedChildren === childrenLength) {
            return LayerSelectedState.AllSelected;
        }

        return LayerSelectedState.SomeSelected;
    }

    private updateLayerSelection(layer: LayerItem, selectedChildrenCount: number): void {
        if (selectedChildrenCount === 0) {
            layer.selected = false;
        } else {
            layer.selected = true;
        }
    }
}
