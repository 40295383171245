import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { EnvironmentService } from '../shared/services/environment.service';
import { concatLatestFrom } from '@ngrx/operators';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { StudioHubService } from '../shared/services/studio-hub.service';
import { UserService } from '../shared/user/state/user.service';

const origins = new Set(Object.values(environment.origins));
const API_ORIGINS = [location.origin, ...origins];

/**
 Intercepts HTTPClient requests and adds the authorization header,
 as well as the signalR connection header.

 This works for all libraries that use the Angular HttpClient. The apollo client for collections is not included.
 **/
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(
        private userService: UserService,
        private studioHubService: StudioHubService,
        private environmentService: EnvironmentService
    ) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return this.userService.oidcUser$.pipe(
            concatLatestFrom(() => this.userService.showcaseToken$),
            switchMap(([user, showcaseToken]) => {
                if (environment.stage === 'test') {
                    return next.handle(request);
                }

                let setAccessToken = false;
                for (const origin of API_ORIGINS) {
                    if (request.url.startsWith(origin)) {
                        setAccessToken = true;
                        break;
                    }
                }

                if (setAccessToken && !this.environmentService.inShowcaseMode) {
                    const urlChunks = location.pathname.split('/') ?? [];
                    const bfHeaders = {
                        'BF-Brand-Id': urlChunks[2] ?? '',
                        'BF-CreativeSet-Id': urlChunks[4] ?? ''
                    };

                    const connectionId = this.studioHubService.connectionId;
                    request = request.clone({
                        setHeaders: {
                            Authorization: `Bearer ${user?.access_token}`,
                            ...bfHeaders,
                            ...(connectionId && { 'X-BF-ConnectionId': connectionId }), // remove once the concurrency feature was released and SAPI switched - ADA-18
                            ...(connectionId && { 'BF-Studio-SignalR-ConnectionId': connectionId })
                        }
                    });
                } else if (this.environmentService.inShowcaseMode) {
                    request = request.clone({
                        setHeaders: {
                            Authorization: `Bearer ${showcaseToken}`
                        }
                    });
                }

                return next.handle(request);
            })
        );
    }
}
