import { IAd } from '@domain/ad/ad';
import { IAdData } from '@domain/ad/ad-data';
import { INameAndId } from '@domain/ad/ad-data-creative';
import { AdEvents } from '@domain/ad/ad-events';
import { IRedirect } from '@domain/ad/redirect';
import { TCData } from '@domain/ad/tcf';
import {
    ICustomEvent,
    ICustomProgressEvent,
    ITrackerClickData,
    ITrackerClickEvent,
    ITrackerErrorEvent,
    ITracking,
    ITrackingData,
    ITrackingDataClickEventValue,
    ITrackingDataCustomEventValue,
    ITrackingDataErrorEventValue,
    ITrackingDataEvent,
    TrackingDataEventValue,
    TrackingEventType
} from '@domain/ad/tracking-data';
import { getCurrentDomain, loadPixel, post, send } from '@studio/utils/ad/http';
import { PageVisibilityState, visibilityChange } from '@studio/utils/ad/visibilitychange';
import { sanitizeUrl } from '@studio/utils/sanitizer';
import { concatUrl, getFirstUrl, parameterToBool, parameterToUrl } from '@studio/utils/url';
import { Redirect } from '../redirect';
import { hasPermission, replaceTcDataIfOverMaximumAllowedLength } from './tcf';
import { isEPrivacyCountry } from './tracking-country-check';

const REDIRECT_PARAMETER_PREFIX = 'redirect_';
const CUSTOM_EVENT_LENGTH_LIMIT = 100;

export class Tracking implements ITracking {
    /**
     * List of domains that a deeplink is allowed to override (when using third party redirect)
     */
    deeplinkWhitelist: string[] = [];

    /**
     * Unique session id for tracker to map events from the same session
     */
    private _sessionId: string;

    /**
     * Queue of events to send to server
     */
    private _queue: ITrackingDataEvent[] = [];

    /**
     * Queue of events to send to server
     */
    private _completedProgressEvents: ICustomProgressEvent[] = [];

    /**
     * Track if impression has been sent to only send it once.
     */
    private _impressionQueued?: boolean;

    /**
     * Track if click has been sent to only send it once.
     */
    private _clickQueued?: boolean;

    /**
     * Track if error has been sent to only send it once.
     * No point in sending more than the first error.
     */
    private _errorQueued?: boolean;

    /**
     * If the viewers country is an ePrivacy country.
     * Only needed in some tracking scenarios.
     */
    private _isEPrivacyCountry = true;

    constructor(private _ad: IAd) {
        // Generate unique session id
        this._sessionId = `${new Date().getTime()}_${Math.floor(Math.random() * 1000000)}`;

        if (window.bfstudio) {
            return;
        }

        visibilityChange.onChange(this._trackOnUnload);
        this._observeAdIntervention();

        this._ad.tcDataSubject.subscribe(tcData => {
            this._checkCountry(tcData);
        });
    }

    private _checkCountry(tcData?: TCData): void {
        if (window.app === 'bannerflow') {
            return;
        }
        // Get country information if we have no consent and TCF check is not disabled
        const hasToFetchCountry = !this._ad.isTCFCheckDisabled() && !tcData;
        if (!hasToFetchCountry) {
            return;
        }
        console.debug('[Ad] checking ePrivacy');
        isEPrivacyCountry((isEPC: boolean) => {
            console.debug(`[Ad] isEPrivacyCountry: ${isEPC}`);
            this._isEPrivacyCountry = isEPC;
        });
    }

    /**
     * Send queued tracking pixels once per view when visibilityChange becomes
     * hidden. This normally happens on page navigation, tab switch, tab close,
     * browser close and window blur. Uses PageLifecycle.js (local fork)
     * that accommodates for various bugs and inconsistencies between browsers.
     * @param  {PageVisibilityState} event
     * @returns void
     */
    private _trackOnUnload = (event: PageVisibilityState): void => {
        if (event === 'hidden') {
            this._send();
            visibilityChange.removeListener_m(this._trackOnUnload);
        }
    };

    /**
     * Add event to queue.
     * @param type
     * @param eventData
     * @param asProgressEvent
     */
    private _enqueue(
        type: TrackingEventType,
        eventData?: ITrackerClickEvent | ICustomEvent | ITrackerErrorEvent,
        asProgressEvent = false
    ): void {
        let eventValue: TrackingDataEventValue;

        switch (type) {
            case TrackingEventType.Impression:
                if (this._impressionQueued) {
                    return;
                }
                this._impressionQueued = true;
                break;
            case TrackingEventType.Click:
                if (this._clickQueued) {
                    return;
                }
                eventValue = this._getClickEventValue(eventData as ITrackerClickEvent);
                this._clickQueued = true;
                break;
            case TrackingEventType.Custom:
                eventValue = this._getCustomEventValue(eventData as ICustomEvent);

                if (!eventValue) {
                    throw new Error(`Can't track custom event value without name`);
                }

                const progressEventCompleted = this._completedProgressEvents.find(
                    event => event.name === (eventValue as ITrackingDataCustomEventValue).n
                );

                if (asProgressEvent && eventData && !progressEventCompleted) {
                    for (let i = 0; i < this._queue.length; i++) {
                        const item = this._queue[i];
                        if (
                            item.t === type &&
                            item.v &&
                            (item.v as ITrackingDataCustomEventValue).n === eventValue.n
                        ) {
                            (item.v as ITrackingDataCustomEventValue).m = eventValue.m;
                            return;
                        }
                    }
                }

                break;
            case TrackingEventType.Error:
                if (this._errorQueued) {
                    return;
                }
                this._errorQueued = true;
                eventValue = this._getGetErrorEventValue(eventData as ITrackerErrorEvent);
                break;
        }

        this._queue.push({
            t: type,
            d: new Date().getTime(), // Date.now() is causing issues in older browsers
            v: eventValue
        });
    }

    private _getTrackerUrl(trackingData: ITrackingData): string {
        // For a more detailed explanation of this stuff: STUDIO-8569
        const brandId = this._ad.data.brand?.id || '';

        const tcfConsentGiven = hasPermission(this._ad.tcDataSubject.value);
        const tcfCheckDisabled = trackingData.a.tcd === 1;
        const pixelEnabled = this._ad.data.tracking?.pixelEnabled;
        const cookielessTrackerUrl = `/tr/v2/pixel/`;
        const trackerUrl = `/tr/v2/pixel/${brandId}`;

        if (!pixelEnabled) {
            return cookielessTrackerUrl;
        }

        if (tcfConsentGiven || tcfCheckDisabled) {
            return trackerUrl;
        }

        if (!this._isEPrivacyCountry) {
            return trackerUrl;
        }

        return cookielessTrackerUrl;
    }

    /**
     * Send all queued events to tracker. Is send beacon better or worse than a regular post?
     * @param useSendBeacon
     */
    private _send = (useSendBeacon = true): void => {
        const trackingMeta = this._ad.data.tracking;

        if (trackingMeta && !trackingMeta.disabled && this._queue?.length) {
            const trackingData = this._getTrackingObject(this._queue);
            const trackingDataString = JSON.stringify(trackingData);

            const trackerUrl = this._getTrackerUrl(trackingData);

            const url = concatUrl(this._ad.getOrigin(), trackerUrl);

            // Don't track stuff at bannerflow domain, errors excluded
            if (this._onBannerflowDomain() && !this._errorQueued) {
                // eslint-disable-next-line
                console.log('Events not sent to tracker on bannerflow domains: \n', trackingDataString);
            } else if (useSendBeacon) {
                send(url, trackingDataString);
            } else {
                post(url, trackingDataString);
            }
            this._queue = [];
        }
    };

    /**
     * Util for clicking.
     * Open target url, track and handle exernal networks
     * @param targetUrl
     */
    click({ x, y, deepLinkUrl, time }: ITrackerClickData): void {
        const redirect = this.getRedirect_m(deepLinkUrl);
        const clickUrl = redirect?.toString();
        const isRedirectAllowed = redirect && !redirect.prevent;

        this._ad.emit(AdEvents.Click, clickUrl);

        // track the click event even if the redirect is prevented
        this._trackClick({
            x,
            y,
            time,
            targetUrl: clickUrl // clickUrl can be empty
        });

        // Redirect user if redirect is allowed and target url is set
        if (isRedirectAllowed && clickUrl) {
            this._open(clickUrl);
        }
    }

    /**
     * Handle window.open in different contexts
     * @param url
     */
    private _open(url: string): void {
        // Custom scripts handles the open
        if (this._ad.adScripts?.overrideOpen(url)) {
            return;
        }
        // Default open handling
        else if (url) {
            url = sanitizeUrl(url);

            const mraid = window.mraid;
            const enabler = window.Enabler;

            // Try Enabler first
            if (enabler?.exit) {
                enabler.exit('Click on banner');
            } else if (mraid?.open) {
                mraid.open(url);
            } else {
                window.open(url, this._getTargetWindow());
            }
        }
    }

    /**
     * Add domains to list of domains that are allowed to override when deeplinking.
     * Used to make sure we're not overriting a third party tracker in redirect chain but only valid target urls.
     * @param domains
     */
    private _whitelistDomains(...domains: Array<string | undefined>): void {
        if (domains?.length) {
            // Filter out duplicates
            const filtered = domains.filter(
                (domain, index) =>
                    typeof domain === 'string' && // Only string values
                    domains.indexOf(domain) === index && // No duplicates in input
                    this.deeplinkWhitelist.indexOf(domain) === -1 // No duplicates in list
            ) as string[];
            this.deeplinkWhitelist.push(...filtered);
        }
    }

    /**
     * Send error to tracker
     */
    trackError_m(error?: string | Error): void {
        // Check this here to prevent us from infinite loop if crasch
        if (!this._errorQueued) {
            const msg: string = error && typeof error !== 'string' ? error.message : (error as string);

            this._enqueue(TrackingEventType.Error, { error: msg || 'UnknownError' });
            this._send();
        }
    }

    /**
     * Track click event. Also make sure all external click pixels are fired
     * @param clickData
     */
    private _trackClick(clickData: ITrackerClickEvent): void {
        this._triggerUrlParameterPixel();
        this._enqueue(TrackingEventType.Click, clickData);
        this._send();
    }

    /**
     * Track impression event. Also make sure all external click pixels are fired
     */
    trackImpression_m(): void {
        this._enqueue(TrackingEventType.Impression);
        this._send();
    }

    /**
     * Track custom event
     * @param eventData
     */
    trackCustomEvent(eventData: ICustomEvent): void {
        this._enqueue(TrackingEventType.Custom, eventData);
        this._send();
    }

    /**
     * Track custom event
     * @param eventData
     */
    trackCustomProgressEvent(eventData: ICustomProgressEvent): void {
        this._enqueue(TrackingEventType.Custom, eventData, true);
        if (eventData.value === 1) {
            const completedEvent = this._completedProgressEvents.find(
                event => event.name === eventData.name
            );
            if (!completedEvent) {
                this._completedProgressEvents.push(eventData);
            }
            this._send();
        }
    }

    /**
     * Get redirecturl parameter (or deprecated targeturl) on ad tag. Return false to prevent click
     */
    getRedirectUrl(): string | false {
        const parameters = this._ad.parameters;
        const url = parameters.redirecturl || parameters.targeturl;
        if (parameterToBool(url) === false) {
            return false;
        }
        return sanitizeUrl(parameterToUrl(url));
    }

    /**
     * Get third party adjusted target url. Method getTargetUrl should be used instead of this in most cases.
     */
    getRedirect_m(deepLinkUrl?: string): IRedirect | undefined {
        const parameters = this._ad.parameters;
        const redirectParameters = this._getRedirectParameters();
        const parameterUrl = this.getRedirectUrl();
        const targetUrl = this.getTargetUrl(deepLinkUrl);
        const adScripts = this._ad.adScripts;
        const allowDeeplink = parameterToBool(parameters.deeplink) || !parameterUrl;

        // Prevent click if off etc is provded.
        if (parameterUrl === false) {
            return undefined;
        }

        // Append missing protocol. TODO: whitelist should be added here and be kept to work fully with AdScripts
        const redirect: Redirect | undefined = new Redirect(parameterUrl);

        // Whitelist domains from script tag src
        this._whitelistDomainsFromScriptTag();

        // Whitelist target url domains so we can overwrite them if passed from external network
        this._whitelistDomains(targetUrl, deepLinkUrl);

        // Pass through adScripts
        if (adScripts?.hasMethod('overrideRedirect')) {
            const customRedirect = this._ad.adScripts.overrideRedirect(redirect, targetUrl);

            // Use the override if provided
            if (customRedirect) {
                const final = customRedirect.getFinalRedirect() as Redirect;
                this._addParametersToFinalRedirectUrl(final, redirectParameters);
                // If final is an empty string, we want to ignore the AdScript behavior, unless it's preventing the redirection
                if (final.prevent || final.toString()) {
                    return customRedirect;
                }
            }
        }

        // Set targeturl/deeplink if provided
        if (targetUrl && redirect) {
            // Only set deeplink if it should be controlled by us.
            // Note that empty Redirects means that a deeplink parameter is provided but empty.
            // That means we should set it anyway
            if (allowDeeplink || redirect.deeplinkRequired_m()) {
                redirect.setDeeplink(targetUrl, this.deeplinkWhitelist);
            }

            // Pass parameters
            this._addParametersToFinalRedirectUrl(redirect.getFinalRedirect(), redirectParameters);
        }

        return redirect;
    }

    /**
     * Get default target url. Primary use ad url, secondary deeplink and at last use creative url.
     */
    getTargetUrl(deepLinkUrl?: string): string | undefined {
        const data = this._ad.data;
        const adUrl = data.target ? data.target.url : undefined;
        const creativeUrl = this._ad.selectedCreative.targetUrl;
        const adScripts = this._ad.adScripts;
        let targetUrl = getFirstUrl(adUrl, deepLinkUrl, creativeUrl);

        // Pass through adScripts
        if (adScripts?.hasMethod('overrideTargetUrl')) {
            targetUrl = this._ad.adScripts.overrideTargetUrl(targetUrl);
        }

        // Make sure url is safe
        return targetUrl ? sanitizeUrl(targetUrl) : targetUrl;
    }

    /**
     * Get target window to use in window.open(). Defaults to '_blank'
     */
    private _getTargetWindow(): string {
        const parameter = this._ad.parameters.targetwindow;
        const target = this._ad.data.target;
        if (parameter) {
            return parameter;
        }
        if (target?.window) {
            return target.window;
        }
        return '_blank';
    }

    destroy(): void {
        this._send();
        this._ad.events.off(window, 'beforeunload', this._send);
        visibilityChange.destroy_m();
    }

    getDomain(): string {
        return this._ad.adScripts?.overrideDomain() || getCurrentDomain(this._ad.parameters);
    }

    private _onBannerflowDomain(): boolean {
        const domain = this.getDomain();
        return /^(https?:)(\/\/)?(.*\.)?bannerflow\.com\/?/.test(domain);
    }

    private _addParametersToFinalRedirectUrl(redirect: Redirect, parameters: object): void {
        const final = redirect.getFinalRedirect();
        Object.keys(parameters).forEach(key => {
            const value = parameters[key];
            final.setParameter(key, value, true);
        });
    }

    /**
     * Fire clickpixel passed as a parameter
     */
    private _triggerUrlParameterPixel(): void {
        const parameters = this._ad.parameters;

        // Custom click tracking pixel
        if (parameters.clickpixel) {
            loadPixel(decodeURIComponent(parameters.clickpixel));
        }
    }

    /**
     * Create object with tracking data required by tracker.
     */
    private _getTrackingObject(events: ITrackingDataEvent[]): ITrackingData {
        // To support error tracking be ultra generous on data availability
        const data = this._ad.data || ({} as IAdData);
        const creative = this._ad.selectedCreative || { size: {}, version: {} };
        const params = this._ad.parameters || {};
        const dest = data.destination || {};
        const brand = data.brand || {};
        const account = data.account || {};
        const design = creative.design || ({} as INameAndId);
        const size = creative.size || {};
        const version = creative.version || {};
        const localization = version.localization || {};
        const creativeset = creative.creativeset || {};
        const campaign = data.campaign || { id: data.campaignId };
        let tcf = this._ad.tcDataSubject.value && JSON.stringify(this._ad.tcDataSubject.value);
        tcf = replaceTcDataIfOverMaximumAllowedLength(tcf);
        const limitIp = parameterToBool(params.limit_ip) ? 1 : 0;

        return {
            e: events,
            u: {
                sr: [window.screen.width, window.screen.height], // Resolution
                tz: this._getClientTimeZone(), // TimeZone (ex: '+0400')
                r: this.getDomain(), //  Referrer url (complete url without querystrings)
                s: this._sessionId
            },
            a: {
                // Advertiser
                vs: creative.adVersion !== undefined ? `${creative.adVersion}` : 'studio',
                a: account.slug, // Account slug
                br: brand.id, // Brand ID
                c: creativeset.id, // Campaign ID
                p: data.id, // Ad ID
                b: creative.id, // Banner ID
                pl: this._ad.preloadImage_m && this._ad.displayType_m !== 'image' ? 1 : 0, // polite loading (1/0)
                r: data.responsive ? 1 : 0, // Responsive (1/0)
                an: this._ad.displayType_m === 'animated' ? 1 : 0, // Animated (1/0)
                s: size.id, //  ID of the size format
                t: version.id, // Translation / Version ID
                l: localization.id, // Localization ID
                bf: design.id, //  BannerFormat
                cp: campaign.id, // ID of the DSP campaign
                si: data.scheduleId, //  ID of current schedule
                cid: params.customid, // Custom ID that could be an affiliate ID, site ID or another custom ID set by the client
                at: data.adTagId, // AD Tag ID
                ch: params.did || dest.id, // Destination (To which network/adserver the creative set is published)
                po: dest.publishOptionId, // Publish option
                no: data.nodeId, // Ad service node ID
                tcf,
                tcd: this._ad.isTCFCheckDisabled() ? 1 : 0, // TCF check disabled (1/0)
                lip: limitIp, // No IP processing,
                rl: params.reportinglabel // Reporting label for Analytics
            }
        } as ITrackingData;
    }

    private _getRedirectParameters(): object {
        const params = this._ad.parameters;
        const redirectParameters = {};
        Object.keys(params).forEach(key => {
            if (key.toLowerCase().indexOf(REDIRECT_PARAMETER_PREFIX) === 0) {
                redirectParameters[key.toLowerCase().replace(REDIRECT_PARAMETER_PREFIX, '')] =
                    params[key];
            }
        });
        return redirectParameters;
    }

    /**
     * Convert custom event to the event value accepted by tracker
     * @param eventData
     */
    private _getCustomEventValue(eventData: ICustomEvent): ITrackingDataCustomEventValue | undefined {
        if (eventData.name) {
            const click = eventData;
            const id = eventData.id;
            const label = eventData.label;
            const val = parseFloat(`${eventData.value}`);
            const eventValue: ITrackingDataCustomEventValue = {
                n: String(click.name).slice(0, CUSTOM_EVENT_LENGTH_LIMIT)
            };

            if (id !== undefined && id !== null) {
                eventValue.id = String(id).slice(0, CUSTOM_EVENT_LENGTH_LIMIT);
            }
            if (label !== undefined && label !== null) {
                eventValue.l = String(label).slice(0, CUSTOM_EVENT_LENGTH_LIMIT);
            }
            if (!isNaN(val)) {
                eventValue.m = Math.round(val * 100) / 100; // Round to two decimals
            }

            return eventValue;
        }
    }

    /**
     * Get data from click. Currently only x and y
     */
    private _getClickEventValue(eventData: ITrackerClickEvent): ITrackingDataClickEventValue {
        return {
            x: eventData.x,
            y: eventData.y,
            t: eventData.time,
            u: eventData.targetUrl
        };
    }

    /**
     * Get data from click. Currently only x and y
     */
    private _getGetErrorEventValue(eventData: ITrackerErrorEvent): ITrackingDataErrorEventValue {
        return {
            error: eventData.error
        };
    }

    /**
     * The users time zone in the format '+0400"
     */
    private _getClientTimeZone(): string {
        const tzo = -new Date().getTimezoneOffset();
        const dif = tzo >= 0 ? '+' : '-';
        const pad = (num: number): string => {
            const norm = Math.floor(Math.abs(num));
            return (norm < 10 ? '0' : '') + norm;
        };
        return dif + pad(tzo / 60) + pad(tzo % 60);
    }

    private _whitelistDomainsFromScriptTag(): void {
        const whitelist = this._ad.parameters.whitelist;
        if (whitelist) {
            const domains = decodeURIComponent(whitelist).split(',');
            if (domains.length) {
                this._whitelistDomains(...domains);
            }
        }
    }

    private _observeAdIntervention(): void {
        if (window.bfstudio) {
            return;
        }
        if (window.ReportingObserver) {
            // Callback that will handle intervention reports
            const sendInterventionReports = (reports: ReportList): void => {
                if (window.app === 'bannerflow') {
                    if (reports.length > 0) {
                        // report interventions inside creative-preview via errors
                        const err = new Error(`AdIntervention - ${reports[0].body?.['message']}`);
                        this._ad.emit(AdEvents.Error, { event: err });
                    }
                    return;
                }
                const adId = this._ad.data.id;
                const creativeId = this._ad.selectedCreative.id;
                const reportUrl = concatUrl(this._ad.getOrigin(), `/tr/blocked/${adId}/${creativeId}`);
                send(reportUrl, JSON.stringify(reports));
            };

            // Create the observer with the callback
            const observer = new window.ReportingObserver(
                reports => {
                    sendInterventionReports(reports);
                },
                { buffered: true }
            );

            // Start watching for interventions
            observer.observe();

            this._ad.events.once(window, 'pagehide', () => {
                // https://developer.chrome.com/blog/heavy-ad-interventions
                const reports = observer.takeRecords();
                sendInterventionReports(reports);
            });
        }
    }
}
