import { AllowedOperations } from '@domain/showcase';
import { IAsyncState } from '@domain/store/async';
import { Role, UserPermission } from '@domain/user';
import { Action, createReducer, on } from '@ngrx/store';
import { User as OidcUser } from 'oidc-client';
import { creativesetShowcaseActions } from '../../creativeset-showcase/state/creativeset-showcase.actions';
import * as UserActions from './user.actions';

export const USER_FEATURE_KEY = 'user';

export interface UserPartialState {
    readonly [USER_FEATURE_KEY]: UserState;
}

export interface UserState extends IAsyncState {
    user?: OidcUser;
    isEmployee: boolean;
    permissions: UserPermission[];
    role?: Role;
    showcaseToken?: string;
    allowedOperations: AllowedOperations[];
}

export const initialState: UserState = {
    loaded: false,
    error: undefined,

    isEmployee: false,
    permissions: [],
    allowedOperations: []
};

export const userReducer = createReducer(
    initialState,
    on(UserActions.loadUser, (state): UserState => ({ ...state, loaded: false, error: undefined })),
    on(
        UserActions.loadUserSuccess,
        (state, { user }): UserState => ({
            ...state,
            isEmployee: !!user.isEmployee,
            permissions: user.permissions || [],
            role: user.role,
            loaded: true
        })
    ),
    on(UserActions.loadUserFailure, (state, { error }): UserState => ({ ...state, error })),

    on(
        UserActions.loadOIDCUserSuccess,
        (state, { oidcUser }): UserState => ({ ...state, user: oidcUser })
    ),
    on(UserActions.loadOIDCUserFailure, (state, { error }): UserState => ({ ...state, error })),

    on(UserActions.setOIDCUser, (state, { oidcUser }): UserState => ({ ...state, user: oidcUser })),

    on(
        creativesetShowcaseActions.loadShowcaseCreativesetSuccess,
        (state, { showcaseToken, allowedOperations }): UserState => ({
            ...state,
            showcaseToken,
            allowedOperations,
            loaded: true
        })
    )
);

export function reducer(state: UserState | undefined, action: Action): UserState {
    return userReducer(state, action);
}
