import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Sort } from '@studio/domain/components/duplicate-creatives/duplicate-creatives.types';
import { uuidv4 } from '@studio/utils/id';
import { map } from 'rxjs';
import * as DuplicateCreativesActions from './duplicate-creative.actions';
import * as DuplicateCreativesSelectors from './duplicate-creative.selectors';

@Injectable({
    providedIn: 'root'
})
export class DuplicateCreativesService {
    isDuplicating$ = this.store.select(DuplicateCreativesSelectors.getDuplicateCreativeIsDuplicating);
    loaded$ = this.store.select(DuplicateCreativesSelectors.getDuplicateCreativeLoaded);
    error$ = this.store.select(DuplicateCreativesSelectors.getDuplicateCreativeError);

    itemsPage$ = this.store.pipe(
        DuplicateCreativesSelectors.getDuplicateCreativeWhenLoaded,
        map(({ searchTerm, setsAndFolders }) => ({ searchTerm, setsAndFolders }))
    );

    duplicationResponse$ = this.store.pipe(
        DuplicateCreativesSelectors.getDuplicateCreativeWhenLoaded,
        map(({ duplicationResult }) => duplicationResult)
    );

    constructor(private readonly store: Store) {}

    loadPage(folderId?: string, pageIndex = 1, sort?: Sort): void {
        this.store.dispatch(
            DuplicateCreativesActions.loadDuplicateCreativesPage({ folderId, pageIndex, sort })
        );
    }

    search(searchTerm?: string, pageIndex = 1, sort?: Sort): void {
        this.store.dispatch(
            DuplicateCreativesActions.searchDuplicateCreatives({ searchTerm, pageIndex, sort })
        );
    }

    clearSearch(): void {
        this.store.dispatch(DuplicateCreativesActions.clearSearchDuplicateCreatives());
    }

    createNewFolder(brandId: string, name: string, targetFolderId?: string): void {
        this.store.dispatch(
            DuplicateCreativesActions.createNewFolder({ brandId, name, targetFolderId })
        );
    }

    duplicateToNew(
        creativesetId: string,
        sourceSizeIds: string[],
        sourceVersionIds: string[],
        targetFolderId: string
    ): void {
        const actionCorrelationId = uuidv4();
        this.store.dispatch(
            DuplicateCreativesActions.createDuplicationToNew({
                creativesetId,
                sourceSizeIds,
                sourceVersionIds,
                targetFolderId,
                actionCorrelationId
            })
        );
    }

    duplicateToExisting(
        creativeset: string,
        sourceSizeIds: string[],
        sourceVersionIds: string[],
        targetCreativesetId: string
    ): void {
        this.store.dispatch(
            DuplicateCreativesActions.createDuplicationToExisting({
                creativeset,
                sourceSizeIds,
                sourceVersionIds,
                targetCreativesetId
            })
        );
    }

    clearState(): void {
        this.store.dispatch(DuplicateCreativesActions.clearDuplicationState());
    }
}
