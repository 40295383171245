import { IAd } from '@domain/ad/ad';
import { AdEvents } from '@domain/ad/ad-events';
import { IViewability } from '@domain/ad/viewability';
import { isMobile } from '@studio/utils/ad/device';
import { PageVisibilityState, visibilityChange } from '@studio/utils/ad/visibilitychange';
import { hasParameter, parameterToBool } from '@studio/utils/url';

export class Viewability implements IViewability {
    visible: boolean;
    private _iabVisible: boolean;
    private _observer: IntersectionObserver;
    private _ratio: number;

    constructor(private ad: IAd) {
        if (this._supported()) {
            this._initIntersectionObserver();
            this._initMRaidViewability();
            this._initPageVisibility();
        }
    }

    /**
     * This browser has support for viewability
     */
    private _supported(): boolean {
        return 'IntersectionObserver' in window && document.hidden !== undefined;
    }

    /**
     * If this creative should pause or not when leaving view
     */
    pauseOutsideView(): boolean {
        if (!this._supported()) {
            return false;
        }
        const parameters = this.ad.parameters;
        if (hasParameter(parameters, 'pauseoutsideview')) {
            return !!parameterToBool(parameters.pauseoutsideview);
        }
        return this.ad.data.pauseOutsideView !== false; // true or undefined should enable this
    }

    /**
     * Detect when creative enter or leaves users viewport
     */
    private _initIntersectionObserver(): void {
        if (this._supported()) {
            const options = {
                threshold: [0, 0.3, 0.5] // 0.3 and 0.5 is for IAB viewability
            };
            const observer = new IntersectionObserver(entries => {
                const ratio = entries[0].intersectionRatio;
                this._onVisibilityUpdate(ratio);
            }, options);
            observer.observe(this.ad.container);
            this._observer = observer;
        }
    }

    /**
     * Detect if page visibility is changed (when user switches tab)
     */
    private _initPageVisibility(): void {
        if (typeof document.addEventListener !== 'undefined' && document.hidden !== undefined) {
            visibilityChange.onChange(this._onVisibilityUpdate);
        }
    }

    /**
     * Use Mraid to detect if creative is in view or not
     */
    private _initMRaidViewability(): void {
        const mraid = window.mraid;
        const event = this.ad.events;

        if (mraid?.getState) {
            // Init mraid
            const mraidReady = (): void => {
                // Fire event if viewable on init
                if (mraid.isViewable()) {
                    this._onVisibilityUpdate(1);
                }

                // Listen on viewability changes
                event.on(mraid, 'viewableChange', viewable => {
                    if (viewable) {
                        this._onVisibilityUpdate(1);
                    } else {
                        this._onVisibilityUpdate(0);
                    }
                });
            };

            // Wait for the SDK to become ready
            if (mraid.getState() === 'loading') {
                event.on(mraid, 'ready', mraidReady);
            } else {
                mraidReady();
            }
        }
    }

    /**
     * Destroy add and clean up listeners
     */
    destroy(): void {
        visibilityChange.destroy_m();
        if (this._observer) {
            this._observer.disconnect();
        }
    }

    private _onVisibilityUpdate = (ratio?: number | PageVisibilityState): void => {
        if (typeof ratio === 'number') {
            this._ratio = ratio;
        }

        const pageVisible = !document.hidden;
        const iabVisible = this._ratio >= this._getIABThreshold() && pageVisible;
        const visible = this._ratio > 0 && pageVisible;

        // State has changed
        if (visible !== this.visible) {
            if (visible) {
                this.ad.emit(AdEvents.ViewEnter);
            } else {
                this.ad.emit(AdEvents.ViewLeave);
            }
        }

        // IAB View state have chanced. TODO: use this to measure viewed ads
        if (iabVisible !== this._iabVisible) {
            if (iabVisible) {
                this.ad.emit(AdEvents.IABViewEnter);
            } else {
                this.ad.emit(AdEvents.IABViewLeave);
            }
        }

        this.visible = visible;
    };

    /**
     * According to IAB a creative is required to have at least 30% of the creative to count as in view.
     */
    private _getIABThreshold(): 0.3 | 0.5 {
        const container = this.ad.container;
        const size = this.ad.selectedCreative.size;

        // According to IAB mobile devices should require at least 50% of the creative in view to count
        if (isMobile()) {
            return 0.5;
        }

        const w = container.clientWidth || size.width;
        const h = container.clientHeight || size.height;

        // This should maybe adjust in real time for responsive ads?
        return w * h > 242500 ? 0.3 : 0.5;
    }
}
