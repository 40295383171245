@if (images(); as elements) {
    <studio-ui-section
        class="no-padding"
        headline="Image">
        <div class="setting">
            <asset-property
                [context]="AssetPropertyContext.Replace"
                [showReplaceButton]="true"
                label="Content"
                [allowFeed]="true"
                [allowRemove]="false"
                [elements]="elements"
                [sizeMode]="sizeMode"
                [isSizeModeEnabled]="isSizeModeEnabled"
                (sizeModeChanged)="updateImageSizeMode($event)"
                (feedSettingsChanged)="onFeedSettingsChanged($event)" />
            <ng-container *permissions="'StudioGenAiImage'">
                @if (isOneImage(elements)) {
                    @let _aiStudioState = aiStudioState();

                    @let supported = _aiStudioState.supported;
                    @let disabledReason = _aiStudioState.message;
                    @let tooltipDisabled = supported || !disabledReason;

                    <div
                        [uiTooltip]="disabledReason"
                        [uiTooltipDisabled]="tooltipDisabled"
                        [uiTooltipPosition]="'left'">
                        <ui-button
                            id="interaction-open-ai-studio-pp"
                            type="primary"
                            class="ai-studio"
                            text="Open in AI Studio"
                            [disabled]="!supported"
                            (click)="openInAIStudio()" />
                    </div>
                }
            </ng-container>
        </div>

        @if (isImageOptimizationEnabled) {
            <div class="body">
                <image-optimization
                    [elements]="elements"
                    (highDpiChange)="updateHighDPI($event)"
                    (qualityChange)="updateQuality($event)" />
            </div>
        }
    </studio-ui-section>
}
