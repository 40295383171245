import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { deserializeFontFamilies } from '@data/deserialization/font-families';
import {
    COMPLETE_CREATIVESET_SHOWCASE_TRANSLATION,
    CREATE_CREATIVESET_SHOWCASE,
    DELETE_CREATIVESET_SHOWCASE,
    GET_SHOWCASES_OF_CREATIVESET,
    UPDATE_CREATIVESET_SHOWCASE
} from '@data/graphql/showcase.queries';
import { CreativeSetShowcaseDtoV2 } from '@domain/api/generated/sapi';
import {
    ICreativesetShowcase,
    ICreativesetShowcaseCreateData,
    ICreativesetShowcasesResponse,
    IShowcaseCreativesetResponse,
    IShowcaseCreativesetUpdateResponse
} from '@domain/showcase';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { convertBrandDto } from './creativeset-showcase.utils';

@Injectable({
    providedIn: 'root'
})
export class CreativesetShowcaseDataService {
    private get API_ROUTE(): string {
        return `${environment.origins.sapi}/showcase`;
    }

    constructor(
        private apollo: Apollo,
        private httpClient: HttpClient
    ) {}

    getShowcasesOfCreativeset(creativesetId: string): Observable<ICreativesetShowcasesResponse> {
        return this.apollo
            .watchQuery<{ showcasesByCreativesetId: ICreativesetShowcasesResponse }>({
                query: GET_SHOWCASES_OF_CREATIVESET,
                variables: {
                    creativesetId: creativesetId
                }
            })
            .valueChanges.pipe(
                map(({ data }) => ({
                    ...data.showcasesByCreativesetId,
                    __typename: undefined,
                    showcases: data.showcasesByCreativesetId.showcases.map(s => ({
                        ...s,
                        __typename: undefined
                    }))
                }))
            );
    }

    createCreativesetShowcase(
        showcase: ICreativesetShowcaseCreateData
    ): Observable<ICreativesetShowcase> {
        return this.apollo
            .mutate<{ createCreativesetShowcase: ICreativesetShowcase }>({
                mutation: CREATE_CREATIVESET_SHOWCASE,
                variables: {
                    creativesetShowcase: showcase
                }
            })
            .pipe(
                map(({ data }) => ({
                    ...data!.createCreativesetShowcase,
                    __typename: undefined
                }))
            );
    }

    updateCreativesetShowcase(showcase: ICreativesetShowcase): Observable<ICreativesetShowcase> {
        return this.apollo
            .mutate<{ updateCreativesetShowcase: ICreativesetShowcase }>({
                mutation: UPDATE_CREATIVESET_SHOWCASE,
                variables: {
                    creativesetShowcase: showcase
                }
            })
            .pipe(
                map(({ data }) => ({
                    ...data!.updateCreativesetShowcase,
                    __typename: undefined
                }))
            );
    }

    deleteCreativesetShowcase(showcaseKey: string): Observable<boolean> {
        return this.apollo
            .mutate<{ deleteCreativesetShowcase: boolean }>({
                mutation: DELETE_CREATIVESET_SHOWCASE,
                variables: {
                    showcaseKey
                }
            })
            .pipe(map(({ data }) => data!.deleteCreativesetShowcase));
    }

    getShowcaseCreativeset(key: string): Observable<IShowcaseCreativesetResponse> {
        return this.httpClient
            .get<CreativeSetShowcaseDtoV2>(`${this.API_ROUTE}/${key}`)
            .pipe(map(this.mapToShowcaseCreativesetResponse));
    }

    private mapToShowcaseCreativesetResponse(
        response: CreativeSetShowcaseDtoV2
    ): IShowcaseCreativesetResponse {
        const { allowedOperations, creativeSet, brand, showcaseToken, fonts } = response;

        return {
            allowedOperations,
            creativeset: creativeSet,
            showcaseToken,
            brand: convertBrandDto(brand),
            fonts: deserializeFontFamilies(fonts)
        };
    }

    completeShowcaseCreativesetTranslation(
        creativesetId: string,
        showcaseKey: string
    ): Observable<IShowcaseCreativesetUpdateResponse> {
        return this.apollo
            .mutate<{ updateCreativesetShowcase: IShowcaseCreativesetUpdateResponse }>({
                mutation: COMPLETE_CREATIVESET_SHOWCASE_TRANSLATION,
                variables: {
                    creativesetShowcase: {
                        disabled: true,
                        notifyCreator: true,
                        showcaseKey: showcaseKey,
                        creativesetId: creativesetId
                    }
                }
            })
            .pipe(map(({ data }) => data!.updateCreativesetShowcase));
    }
}
