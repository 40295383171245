import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { sortFontFamilies } from '@creative/font-families.utils';
import { deserializeFontFamilies } from '@data/deserialization/font-families';
import { IFontFamily, IFontFamilyDto } from '@domain/font-families';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

const API_ROUTE = `${environment.origins.fontManagerApi}/api`;

@Injectable({
    providedIn: 'root'
})
export class FontFamiliesDataService {
    private _creativesetFontFamilies: IFontFamily[];
    get creativesetFontFamilies(): IFontFamily[] {
        return this._creativesetFontFamilies;
    }

    constructor(private httpClient: HttpClient) {}

    getFontFamiliesByBrandId(brandId: string): Observable<IFontFamily[]> {
        const url = `${API_ROUTE}/font/brand/${brandId}?includeDeleted=false&includeGlyphs=false`;

        return this.httpClient
            .get<IFontFamilyDto[]>(url)
            .pipe(map(fontFamiliesDto => this.processFontFamilies(fontFamiliesDto)));
    }

    getFontFamiliesByStyleIds(
        fontStyleIds: string[],
        includeGlyphs = false
    ): Observable<IFontFamily[]> {
        const url = `${API_ROUTE}/font?fontStyleIds=${fontStyleIds.join(
            '&fontStyleIds='
        )}&includeGlyphs=${includeGlyphs}`;
        return this.httpClient.get<IFontFamilyDto[]>(url).pipe(
            map(fontFamiliesDto => this.processFontFamilies(fontFamiliesDto)),
            tap(fontFamilies => (this._creativesetFontFamilies = fontFamilies))
        );
    }

    private processFontFamilies(fontFamiliesDto: IFontFamilyDto[]): IFontFamily[] {
        const deserializedFontFamilies = deserializeFontFamilies(fontFamiliesDto);
        return sortFontFamilies(deserializedFontFamilies);
    }
}
