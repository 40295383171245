import { ImageLibraryAsset } from '@domain/creativeset/image-asset';
import { OneOfLibraryAssets } from '@domain/creativeset/library-asset';
import { VideoLibraryAsset } from '@domain/creativeset/video-asset';

export function isImageLibraryAsset(asset: unknown): asset is ImageLibraryAsset {
    return !!asset && typeof asset === 'object' && 'original' in asset;
}

export function isVideoLibraryAsset(asset: OneOfLibraryAssets | undefined): asset is VideoLibraryAsset {
    return !!asset && 'durationInMilliseconds' in asset;
}
