import { inject, Injectable } from '@angular/core';
import { CreativesetDataService } from '../../creativeset/creativeset.data.service';
import { ErrorsRedirectionService } from '../../services/errors-redirection.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { BrandDataService } from '../brand.data.service';
import * as BrandActions from './brand.actions';

@Injectable()
export class BrandEffects {
    private actions$ = inject(Actions);
    private brandDataService = inject(BrandDataService);
    private creativesetDataService = inject(CreativesetDataService);
    private errorsRedirectionService = inject(ErrorsRedirectionService);

    loadBrand$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(BrandActions.loadBrand),
            switchMap(action =>
                this.brandDataService.getBrand(action.brandId).pipe(
                    map(brand => {
                        brand.localizations.slice().sort((a, b) => {
                            const na = a.name.toLowerCase();
                            const nb = b.name.toLowerCase();

                            return na.localeCompare(nb);
                        });
                        return brand;
                    }),
                    map(brand => BrandActions.loadBrandSuccess({ brand })),
                    tap(({ brand }) => (this.creativesetDataService.brand = brand)),
                    catchError(error => of(BrandActions.loadBrandFailure({ error })))
                )
            )
        );
    });

    loadBrandFailure$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(BrandActions.loadBrandFailure),
                tap(({ error }) => this.errorsRedirectionService.handleErrorRedirection(error))
            );
        },
        { dispatch: false }
    );
}
