import { Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { ICreativeset } from '@domain/creativeset';
import { ICreative } from '@domain/creativeset/creative';
import { IVersion } from '@domain/creativeset/version';
import { map } from 'rxjs/operators';
import { EditCreativeService } from '../../pages/manage-view/services/edit-creative.service';
import { TileSelectService } from '../../pages/manage-view/services/tile-select.service';
import { getDesignViewTitle } from '../../pages/page-title-util';
import { DisplayCampaignService } from '../display-campaign/state/display-campaign.service';
import { CreativesetDataService } from '../creativeset/creativeset.data.service';
import { ObserveVisibilityDirective } from '../directives/observe-visibility.directive';
import { FiltersService } from '../filters/filters.service';
import { LastLocation } from '../user-settings/state/user-settings.model';
import { UserSettingsService } from '../user-settings/state/user-settings.service';
import { UserService } from '../user/state/user.service';
import { VersionsService } from '../versions/state/versions.service';
import { EnvironmentService } from './environment.service';

@Injectable({
    providedIn: 'root'
})
export class StudioRoutingService {
    private displayCampaignService = inject(DisplayCampaignService);

    latestScrollTop = 0;

    get lastScrollPosition(): number {
        // maybe instead of session storage create a new shared service to store it
        // sessions storage is persisted through page reloads, which might be a feature not an issue
        return +(sessionStorage.getItem('lastScrollPosition') || 0);
    }

    set lastScrollPosition(positionY: number) {
        if (positionY === 0) {
            sessionStorage.removeItem('lastScrollPosition');
            return;
        }
        sessionStorage.setItem('lastScrollPosition', positionY.toString());
    }

    private lastLocation: LastLocation;
    private selectedVersion: IVersion;
    private queryParams: string;

    constructor(
        private router: Router,
        private userService: UserService,
        private creativesetDataService: CreativesetDataService,
        private editCreativeService: EditCreativeService,
        private versionsService: VersionsService,
        private environmentService: EnvironmentService,
        private tileSelectService: TileSelectService,
        private activatedRoute: ActivatedRoute,
        private filtersService: FiltersService,
        private userSettingsService: UserSettingsService
    ) {
        this.userSettingsService.sharedSettings$.subscribe(settings => {
            this.lastLocation = settings.lastLocation;
        });

        this.versionsService.selectedVersion$.subscribe(selectedVersion => {
            this.selectedVersion = selectedVersion;
        });

        this.tileSelectService.creativeListScrolled$
            .pipe(map(event => (event.target as HTMLElement)?.scrollTop))
            .subscribe(scrollTop => {
                this.latestScrollTop = scrollTop;
            });

        this.activatedRoute.queryParams.pipe(takeUntilDestroyed()).subscribe(queryParams => {
            this.queryParams = new URLSearchParams(queryParams).toString();
        });
    }

    navigateToTP(): void {
        const creativeset = this.creativesetDataService.creativeset;
        // activatedRoute is empty in MV because its kinda the root entry component.
        // Therefore, cant use relative routing here
        this.router.navigate(
            ['/brand', creativeset.brandId, 'creativeset', creativeset.id, 'translate'],
            {
                queryParamsHandling: 'merge'
            }
        );
    }

    navigateToCreative(creative: ICreative | undefined, event?: MouseEvent): void {
        if (!creative || this.preventNavigateToCreative(event)) {
            return;
        }

        const openInNewTab = event?.ctrlKey || event?.metaKey;
        const { creativeset } = this.creativesetDataService;
        const fullUrl = this.getUrl(creative, creativeset);

        if (openInNewTab) {
            window.open(
                `${fullUrl}&title=${encodeURIComponent(getDesignViewTitle(creative.size.width, creative.size.height, creativeset.name))}`,
                '_blank'
            );
            return;
        }

        this.storeLastScrollPosition();
        /**
         * If intersection observer kicks in, the change detection can
         * run and kill component destruction causing a massive subscription leak
         * so we need to clear statuses and disconnect all directives ASAP.
         */
        this.editCreativeService.clearCreativeVisiblityStatus();
        ObserveVisibilityDirective.disconnectAll$.next();
        this.displayCampaignService.cancelPublish();

        const routeParams = ['editor', creative.id, creative.size.id];
        if (creative.design) {
            routeParams.push(creative.design.id);
        }

        if (this.selectedVersion?.id !== creative.version.id) {
            this.filtersService.selectVersionById(creative.version.id, true);
        } else {
            this.filtersService.updatePreviousVersionSelection();
        }

        // Previous method with 'relativeTo: currentRoute' was causing issues when navigating
        // from the new TranslationPage (/translate) to DesignView.
        // fullUrl gets the same url from getUrl() all the time no matter what the currentRoute is.
        this.router.navigateByUrl(fullUrl);
    }

    storeLastScrollPosition(): void {
        this.lastScrollPosition = this.latestScrollTop;
    }

    private preventNavigateToCreative(event?: MouseEvent): boolean {
        return (
            !this.userService.canChangeDesign ||
            this.environmentService.inShowcaseMode ||
            !!event?.shiftKey
        );
    }

    private getUrl(creative: ICreative, creativeset: ICreativeset): string {
        if (creative.design) {
            return `brand/${creativeset.brandId}/creativeset/${creativeset.id}/editor/${creative.id}/${creative.size.id}/${creative.design.id}?${this.queryParams}`;
        } else {
            return `brand/${creativeset.brandId}/creativeset/${creativeset.id}/editor/${creative.id}/${creative.size.id}?${this.queryParams}`;
        }
    }

    getDVExitUrl(): string {
        const { creativeset, brand } = this.creativesetDataService;
        const manageViewURL = `/brand/${brand.id}/creativeset/${creativeset.id}`;
        const translationPageURL = `${manageViewURL}/translate`;

        return this.lastLocation === 'TranslationPage' ? translationPageURL : manageViewURL;
    }
}
