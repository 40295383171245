// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// https://stackoverflow.com/a/9851769/5161346

const userAgent = (window.navigator?.userAgent || '').toLowerCase();

// Opera 8.0+
export const isOpera =
    (!!window.opr && !!opr.addons) || !!window.opera || userAgent.indexOf(' opr/') >= 0;

// Firefox 1.0+
export const isFirefox = typeof InstallTrigger !== 'undefined';

// Any apple browser including safari, wkWebview, uiWebView.
export const isAppleBrowser = window.navigator?.vendor?.indexOf('Apple') > -1;

// Safari
export const isSafari =
    isAppleBrowser && navigator.userAgent.indexOf('crios') === -1 && userAgent.indexOf('fxios') === -1;

// Internet Explorer 6-11
export const isIE = /* @cc_on!@ */ false || !!document.documentMode;

// Edge 15-18
export const isEdge = !isIE && !!window.StyleMedia;

// Chrome 1 - 79
export const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

// Edge (based on chromium) detection
// eslint-disable-next-line eqeqeq
export const isEdgeChromium = isChrome && userAgent.indexOf('edg') != -1;

// Blink engine detection
export const isBlink = (isChrome || isOpera || isEdgeChromium) && !!window.CSS;

export const isIpad =
    userAgent.match(/(iPad)/) /* iOS pre 13 */ ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1); /* iPad OS 13 */

/**
 * Extract iOS version from User agent.
 * Note that this won't find iPads with version 13 and above
 * because Apple are idiots
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const preciseIOSVersion = (function () {
    try {
        const agent = window.navigator.userAgent;
        const start = agent.indexOf('OS ');

        if ((agent.indexOf('iPhone') > -1 || agent.indexOf('iPad') > -1) && start > -1) {
            const simple = Number(agent.substr(start + 3, 4).replace('_', '.'));
            const full = agent
                .substr(start + 3)
                .split(' ')[0]
                .replace(/_/g, '.')
                .split('.')
                .map(v => Number(v));

            return {
                simple,
                full: {
                    major: full[0],
                    minor: full[1],
                    patch: full[2] || 0
                }
            };
        }
    } catch (e) {
        console.warn('Could not get iOS version.');
    }

    return { simple: 0, full: { major: 0, minor: 0, patch: 0 } };
})();

/** Note probably not accurate but need it for version */
export function getBrowserVersion(): number {
    const ua = navigator.userAgent;
    let tem;
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return parseFloat(tem[1]) || -1;
    }
    if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) {
            return parseFloat(tem[2]) || -1;
        }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
        M.splice(1, 1, tem[1]);
    }
    return parseFloat(M[1]) || -1;
}

export function getAndroidMajorVersion(userAgent: string): string | undefined {
    const match = userAgent.match(/Android\s(\d+)/i);
    return match ? match[1] : undefined;
}
