import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { GenAIEffects } from './gen-ai.effects';
import { GenAIService } from './gen-ai.service';
import * as fromGenAI from './gen-ai.reducer';

@NgModule({
    imports: [
        StoreModule.forFeature(fromGenAI.GEN_AI_FEATURE_KEY, fromGenAI.reducer),
        EffectsModule.forFeature([GenAIEffects])
    ],
    providers: [GenAIService]
})
export class GenAIStoreModule {}
