import { mergeFontFamilies } from '@creative/font-families.utils';
import { IFontFamily } from '@domain/font-families';
import { IAsyncState } from '@domain/store/async';
import { Action, createReducer, on } from '@ngrx/store';
import { creativesetShowcaseActions } from '../../creativeset-showcase/state/creativeset-showcase.actions';
import * as FontFamiliesActions from './font-families.actions';

export const FONT_FAMILIES_FEATURE_KEY = 'fontFamilies';

export interface FontFamiliesPartialState {
    readonly [FONT_FAMILIES_FEATURE_KEY]: FontFamiliesState;
}

export interface FontFamiliesState extends IAsyncState {
    fontFamiliesFromBrand: IFontFamily[];
    fontFamiliesFromCreativeSet: IFontFamily[];
}

export const initialState: FontFamiliesState = {
    loaded: false,
    fontFamiliesFromBrand: [],
    fontFamiliesFromCreativeSet: []
};

export const fontFamiliesReducer = createReducer(
    initialState,

    on(
        FontFamiliesActions.loadFontFamiliesOfBrand,
        (state): FontFamiliesState => ({
            ...state,
            loaded: false,
            error: undefined
        })
    ),

    on(
        FontFamiliesActions.loadFontFamiliesOfBrandSuccess,
        (state, { fontFamilies }): FontFamiliesState => ({
            ...state,
            loaded: true,
            error: undefined,
            fontFamiliesFromBrand: fontFamilies
        })
    ),

    on(
        FontFamiliesActions.loadFontFamiliesOfBrandFailure,
        (state, { error }): FontFamiliesState => ({
            ...state,
            loaded: true,
            error
        })
    ),

    on(
        FontFamiliesActions.setFontFamiliesOfCreativeSet,
        (state, { fontFamilies }): FontFamiliesState => ({
            ...state,
            loaded: true,
            error: undefined,
            fontFamiliesFromCreativeSet: fontFamilies
        })
    ),

    on(
        creativesetShowcaseActions.loadShowcaseCreativesetSuccess,
        (state, { fonts }): FontFamiliesState => ({
            ...state,
            loaded: true,
            error: undefined,
            fontFamiliesFromCreativeSet: fonts
        })
    ),

    on(
        FontFamiliesActions.addFontFamilies,
        (state, { fontFamilies }): FontFamiliesState => ({
            ...state,
            fontFamiliesFromCreativeSet: mergeFontFamilies(
                state.fontFamiliesFromCreativeSet,
                fontFamilies
            )
        })
    )
);

export function reducer(state: FontFamiliesState | undefined, action: Action): FontFamiliesState {
    return fontFamiliesReducer(state, action);
}
