import { Icon } from '@bannerflow/ui/components/icon/svg-icon/icons';
import { CreativeSize } from '@domain/creativeset';

export interface ISelectableSize extends CreativeSize {
    id: string;
    thumbSize?: { width: number; height: number; ratio: number };
    selected?: boolean;
    amount: number;
    compatibility?: SizeCompatibility[];
    thumbnail?: Icon;
    sizeFormatId?: string;
}

export type SizeCategoryType = 'custom' | 'brand' | 'social' | 'group';

export interface ISizeList {
    type: SizeCategoryType;
    label: string;
    sizes: ISelectableSize[];
    stickyHeight?: number;
    collapsed?: boolean;
}

export enum SizeCompatibility {
    Facebook = 'facebook',
    Instagram = 'instagram',
    Messenger = 'messenger',
    YouTube = 'youtube',
    Tiktok = 'tiktok',
    Snapchat = 'snapchat',
    Linkedin = 'linkedin',
    Twitter = 'twitter',
    Pinterest = 'pinterest'
}
