import { CommonModule } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { Breakpoint } from '@studio/utils/breakpoints';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EnvironmentService } from '../services/environment.service';
import { AnimationControlService } from './animation-control.service';
import { MediaDirective } from '../directives';

@Component({
    standalone: true,
    imports: [CommonModule, UIModule, MediaDirective],
    selector: 'animation-control',
    templateUrl: './animation-control.component.html',
    styleUrls: ['./animation-control.component.scss'],
    host: {
        '(window:keydown.space)': 'togglePlayShortcut($event)'
    }
})
export class AnimationControlComponent implements OnInit, OnDestroy {
    disabled = false;
    isMobileShowcase: boolean;
    preloadActive$: Observable<boolean>;
    isPlaying$: Observable<boolean>;
    isInactive$: Observable<boolean>;
    Breakpoint = Breakpoint;

    @HostListener('window:blur')
    documentBlurred(): void {
        if (this.shouldAutoPause) {
            this.animationControlService.stopAnimations();
        }
    }

    private shouldAutoPause = false;
    private unsubscribe$ = new Subject<void>();

    constructor(
        public animationControlService: AnimationControlService,
        private environmentService: EnvironmentService
    ) {
        this.animationControlService.shouldAutoPause$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(shouldAutoPause => {
                this.shouldAutoPause = shouldAutoPause;
            });

        this.isMobileShowcase = this.environmentService.isMobileShowcase;
        this.preloadActive$ = this.animationControlService.showPreloadImage$;
        this.isPlaying$ = this.animationControlService.isPlaying$;
        this.isInactive$ = this.animationControlService.isInactive$;
    }

    ngOnInit(): void {
        this.animationControlService.initDisabledState();
        this.animationControlService.isDisabled$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(disabled => {
                this.disabled = disabled;
            });

        if (this.environmentService.inShowcaseMode) {
            this.animationControlService.initShowcaseState();
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.animationControlService.stopAnimations();
    }

    togglePlay(): void {
        if (!this.disabled) {
            this.animationControlService.toggleAnimations();
        }
    }

    stepToPreloadImage(): void {
        if (!this.disabled) {
            this.animationControlService.stepToPreloadImage();
        }
    }

    stepForward(): void {
        if (!this.disabled) {
            this.animationControlService.stepForward();
        }
    }

    stepBack(): void {
        if (!this.disabled) {
            this.animationControlService.stepBack();
        }
    }

    stepToStart(): void {
        if (!this.disabled) {
            this.animationControlService.stepToStart();
        }
    }

    togglePlayShortcut(event: KeyboardEvent): void {
        if (!this.disabled) {
            if (
                event.target instanceof HTMLInputElement ||
                event.target instanceof HTMLTextAreaElement
            ) {
                return;
            }
            event.preventDefault();
            this.togglePlay();
        }
    }
}
