import {
    BTreeFilter,
    IBTreeDistanceItem,
    IBTreeItem,
    IItemValue,
    ItemValueId
} from '@domain/utils/btree';
import { TextWriter } from './text-writer';

export class BTreeIndex<T> {
    private root?: BTreeNode<T>;
    private maxLength: number;
    length = 0;

    constructor(private minDegrees: number = 2) {
        this.maxLength = 2 * this.minDegrees - 1;
    }

    toString(): string {
        if (!this.root) {
            return '[]';
        }
        return this.root.toString();
    }

    findKey(key: number): IBTreeItem<T> | undefined {
        return this.root && (this.root.find(key) as IBTreeItem<T>);
    }

    find(key: number, id: number): IItemValue<T> | undefined {
        return this.root && (this.root.find(key, id) as IItemValue<T>);
    }

    insert(key: number, value: T, id: ItemValueId): void {
        if (!this.root) {
            this.root = new BTreeNode<T>(this.minDegrees, true);
            this.root.items[0] = {
                key,
                values: [{ id, value }]
            };
            this.root.length = 1;
            this.length++;
            return;
        }
        if (this.root.length === this.maxLength) {
            let k = 0;
            // Find the child which is going to have the new key
            while (k < this.root.length && this.root.items[k].key < key) {
                k++;
            }
            if (k < this.root.length && this.root.items[k].key === key) {
                this.root.insertIfNotFull(key, value, id);
                this.length--;
                return;
            }
            const newRoot = new BTreeNode<T>(this.minDegrees, false);
            newRoot.children[0] = this.root;
            newRoot.splitNodeIfFull(0, this.root);
            let i = 0;
            if (newRoot.items[0].key < key) {
                i++;
            }
            newRoot.children[i].insertIfNotFull(key, value, id);
            this.root = newRoot;
            this.length++;
            return;
        }

        this.root.insertIfNotFull(key, value, id);
        this.length++;
    }

    delete(key: number, id: ItemValueId): void {
        if (!this.root) {
            return;
        }
        if (this.root.find(key, id)) {
            this.root.delete(key, id);
        }

        // If the root node has 0 keys, make its first child as the new root
        // if it has a child, otherwise set root as NULL
        if (this.root.length === 0) {
            if (this.root.isLeaf) {
                this.root = undefined;
            } else {
                this.root = this.root.children[0];
            }
        }
    }

    inRange(start: number, end: number): IItemValue<T>[] {
        if (start > end) {
            throw new Error('Start cannot be bigger than end.');
        }
        if (!this.root) {
            return [];
        }
        return this.root.inRange(start, end);
    }

    /**
     * Get the closest key
     */
    closest(target: number): IBTreeDistanceItem<T> | undefined;
    closest(target: number, maxTolerance: number): IBTreeDistanceItem<T> | undefined;
    closest(
        target: number,
        maxTolerance: number,
        filter: BTreeFilter<T> | undefined
    ): IBTreeDistanceItem<T> | undefined;
    closest(
        target: number,
        maxTolerance: number = Infinity,
        filter?: BTreeFilter<T>
    ): IBTreeDistanceItem<T> | undefined {
        const items = this.inItemRange(target - maxTolerance, target + maxTolerance);
        let closestDistance = Infinity;
        let closestOffset: number | undefined;
        let closestItem: IBTreeDistanceItem<T> | undefined;
        for (const item of items) {
            const offset = target - item.key;
            const distance = Math.abs(offset);
            if (distance < closestDistance && (!filter || filter(item))) {
                closestDistance = distance;
                closestItem = item as IBTreeDistanceItem<T>;
                closestOffset = offset;
            }
        }
        if (closestItem) {
            closestItem.distance = closestDistance;
            closestItem.offset = closestOffset!;
        }
        return closestItem;
    }

    inItemRange(start: number, end: number): IBTreeItem<T>[] {
        if (start > end) {
            throw new Error('Start cannot be bigger than end.');
        }
        if (!this.root) {
            return [];
        }
        return this.root.inItemRange(start, end);
    }

    clear(): void {
        if (this.root) {
            this.root.clear();
            this.length = 0;
            this.root.isLeaf = true;
        }
    }
}

class BTreeNode<T> {
    items: IBTreeItem<T>[] = [];
    children: BTreeNode<T>[] = [];
    length = 0;
    private maxLength: number;

    constructor(
        private minDegrees: number,
        public isLeaf: boolean
    ) {
        this.maxLength = 2 * this.minDegrees - 1;
    }

    clear(): void {
        for (const child of this.children) {
            child.clear();
        }
        this.items = [];
        this.children = [];
        this.length = 0;
    }

    toString(tw?: TextWriter): string {
        tw = tw || new TextWriter();
        tw.write('[');
        tw.writeLine();
        tw.indent();
        let i: number;
        for (i = 0; i < this.length; i++) {
            if (!this.isLeaf) {
                this.children[i].toString(tw);
                tw.writeLine(',');
            }
            tw.write(`${this.items[i].key}`);
            for (const value of this.items[i].values) {
                tw.write(` { value: ${(value.value as any).value}, id: ${value.id} }`);
            }
            tw.save();
            tw.writeLine(',');
        }
        tw.restore();
        if (!this.isLeaf) {
            tw.writeLine(',');
            this.children[i].toString(tw);
        }
        tw.writeLine();
        tw.unindent();
        tw.write(']');
        return tw.toString();
    }

    find(key: number, id?: ItemValueId): IItemValue<T> | IBTreeItem<T> | undefined {
        // Find the first key greater than or equal to k
        let i = 0;
        while (i < this.length && this.items[i].key < key) {
            i++;
        }

        // If the found key is equal to k, return this node
        if (i < this.length && this.items[i].key === key) {
            if (typeof id !== 'undefined') {
                return this.items[i].values.find(v => v.id === id);
            }
            return this.items[i];
        }

        // If key is not found here and this is a leaf node
        if (this.isLeaf) {
            return undefined;
        }

        return this.children[i].find(key, id);
    }

    valueInRange(start: number, end: number): number[] {
        // Find the first key greater than or equal to k
        let i = 0;
        while (i < this.length && this.items[i].key < start) {
            i++;
        }
        const values: number[] = [];
        let lastValue = 0;
        while (i < this.length && (lastValue = this.items[i].key) <= end) {
            if (this.children[i]) {
                values.push(...this.children[i].valueInRange(start, lastValue));
            }
            values.push(this.items[i].key);
            lastValue = this.items[i].key;
            i++;
        }

        // If the found key is equal to k, return this node
        if (lastValue === end) {
            return values;
        }

        // If key is not found here and this is a leaf node
        if (this.isLeaf) {
            return values;
        }

        return values.concat(this.children[i].valueInRange(lastValue + 1, end));
    }

    inRange(start: number, end: number): IItemValue<T>[] {
        if (this.length === 0) {
            return [];
        }

        // Find the first key greater than or equal to k
        let i = 0;
        let lastValue = this.items[i].key;
        while (i < this.length && this.items[i].key < start) {
            i++;
        }
        const values: IItemValue<T>[] = [];
        while (i < this.length) {
            if (!this.isLeaf) {
                values.push(...this.children[i].inRange(start, end));
            }
            lastValue = this.items[i].key;
            if (lastValue <= end) {
                for (const value of this.items[i].values) {
                    values.push(value);
                }
                i++;
            } else {
                i++;
                break;
            }
        }

        // If key is not found here and this is a leaf node
        if (this.isLeaf) {
            return values;
        }

        return values.concat(this.children[i].inRange(start, end));
    }

    inItemRange(start: number, end: number): IBTreeItem<T>[] {
        // Find the first key greater than or equal to k
        let i = 0;
        let lastValue = this.items[i].key;
        while (i < this.length && this.items[i].key < start) {
            i++;
        }
        const values: IBTreeItem<T>[] = [];
        while (i < this.length) {
            if (!this.isLeaf) {
                values.push(...this.children[i].inItemRange(start, end));
            }
            lastValue = this.items[i].key;
            if (lastValue <= end) {
                values.push(this.items[i]);
                i++;
            } else {
                i++;
                break;
            }
        }

        // If key is not found here and this is a leaf node
        if (this.isLeaf) {
            return values;
        }

        return values.concat(this.children[i].inItemRange(start, end));
    }

    insertIfNotFull(key: number, value: T, id: ItemValueId): void {
        let i = this.length - 1;

        if (this.isLeaf) {
            let y = 0;
            while (y < this.length && this.items[y].key < key) {
                y++;
            }
            if (y < this.length && this.items[y].key === key) {
                this.items[y].values.push({ id, value });
                return;
            }
            // The following loop does two things
            // a) Finds the location of new key to be inserted
            // b) Moves all greater keys one place ahead
            while (i >= 0 && this.items[i].key > key) {
                this.items[i + 1] = this.items[i];
                i--;
            }
            this.items[i + 1] = { key, values: [{ id, value }] };
            this.length++;
        } else {
            // Find the child which is going to have the new key
            while (i >= 0 && this.items[i].key > key) {
                i--;
            }
            const item = this.items[i];
            if (item && item.key === key) {
                item.values.push({ id, value });
                return;
            }

            // See if the found child is full
            const childIndex = i + 1;
            const child = this.children[childIndex];
            if (child.length === this.maxLength) {
                let y = 0;
                while (y < child.length && child.items[y].key < key) {
                    y++;
                }
                if (y < child.length && child.items[y].key === key) {
                    child.items[y].values.push({ id, value });
                    return;
                }
                this.splitNodeIfFull(childIndex, child);

                // After split, the middle value of 'this.children[i]' goes up and
                // 'this.children[i]' is splitted into two. See which of the two
                // is going to have the new key
                if (this.items[childIndex].key < key) {
                    this.children[childIndex + 1].insertIfNotFull(key, value, id);
                    return;
                }
            }
            this.children[childIndex].insertIfNotFull(key, value, id);
        }
    }

    splitNodeIfFull(middleIndex: number, node: BTreeNode<T>): void {
        const child = new BTreeNode<T>(node.minDegrees, node.isLeaf);

        // Copy the last (minDegrees-1) values of old node to new node
        child.items = node.items.splice(this.minDegrees, Infinity);
        child.length = this.minDegrees - 1;

        // Copy the last t children of old node to new node
        if (!node.isLeaf) {
            child.children = node.children.splice(this.minDegrees, Infinity);
        }

        node.length = this.minDegrees - 1;

        // Since this node is going to have a new child,
        // create space of new child
        for (let i = this.length; i >= middleIndex + 1; i--) {
            this.children[i + 1] = this.children[i];
        }

        // Link the new child to this node
        this.children[middleIndex + 1] = child;

        // A key of y will move to this node. Find location of
        // new key and move all greater keys one space ahead
        for (let i = this.length - 1; i >= middleIndex; i--) {
            this.items[i + 1] = this.items[i];
        }

        // Copy the middle key of y to this node
        this.items[middleIndex] = node.items.splice(this.minDegrees - 1, 1)[0];
        this.length++;
    }

    delete(key: number, id?: ItemValueId): void {
        let index = 0;
        while (index < this.length && this.items[index].key < key) {
            index++;
        }

        if (index < this.length && this.items[index].key === key) {
            if (this.isLeaf) {
                this.removeFromLeaf(index, id);
            } else {
                this.removeFromNonLeaf(index, id!);
            }
        } else {
            if (this.isLeaf) {
                return;
            }
            const isLastChild = index === this.length;
            if (this.children[index].length < this.minDegrees) {
                this.fill(index);
            }
            if (isLastChild && index > this.length) {
                this.children[index - 1].delete(key, id);
            } else {
                this.children[index].delete(key, id);
            }
        }
    }

    private fill(index: number): void {
        // If the previous child(children[index - 1]) has more than minDegrees-1 values, borrow a value
        // from that child
        if (index !== 0 && this.children[index - 1].length >= this.minDegrees) {
            this.borrowFromPrevious(index);
        }

        // If the next child(children[index + 1]) has more than minDegrees-1 keys, borrow a value
        // from that child
        else if (index !== this.length && this.children[index + 1].length >= this.minDegrees) {
            this.borrowFromNext(index);
        }

        // Merge children[index] with its sibling
        // If children[index] is the last child, merge it with with its previous sibling
        // Otherwise merge it with its next sibling
        else {
            if (index !== this.length) {
                this.merge(index);
            } else {
                this.merge(index - 1);
            }
        }
    }

    private merge(index: number): void {
        const child = this.children[index];
        const sibling = this.children[index + 1];

        // Pulling a key from the current node and inserting it into (minDegrees-1)th
        // position of children[index]
        child.items[this.minDegrees - 1] = this.items[index];

        // Copying the keys from children[index + 1] to C[idx] at the end
        for (let i = 0; i < sibling.length; i++) {
            child.items[i + this.minDegrees] = sibling.items[i];
        }

        // Copying the child pointers from children[index + 1] to children[index]
        if (!child.isLeaf) {
            for (let i = 0; i <= sibling.length; i++) {
                child.children[i + this.minDegrees] = sibling.children[i];
            }
        }

        // Moving all keys after idx in the current node one step before -
        // to fill the gap created by moving values[index] to children[index]
        for (let i = index + 1; i < this.length; i++) {
            this.items[i - 1] = this.items[i];
        }

        // Moving the child pointers after (index + 1) in the current node one
        // step before
        for (let i = index + 2; i <= this.length; i++) {
            this.children[i - 1] = this.children[i];
        }

        // Updating the key count of child and the current node
        child.length += sibling.length + 1;
        this.length--;
    }

    private borrowFromNext(index: number): void {
        const child = this.children[index];
        const nextSibling = this.children[index + 1];

        // keys[idx] is inserted as the last key in C[idx]
        child.items[child.length] = this.items[index];

        // Sibling's first child is inserted as the last child
        // into C[idx]
        if (!child.isLeaf) {
            child.children[child.length + 1] = nextSibling.children[0];
        }

        this.items[index] = nextSibling.items[0];

        // Moving all keys in sibling one step behind
        for (let i = 1; i < nextSibling.length; i++) {
            nextSibling.items[i - 1] = nextSibling.items[i];
        }

        // Moving the child pointers one step behind
        if (!nextSibling.isLeaf) {
            for (let i = 1; i <= nextSibling.length; i++) {
                nextSibling.children[i - 1] = nextSibling.children[i];
            }
        }

        // Increasing and decreasing the key count of C[idx] and C[idx+1]
        // respectively
        child.length += 1;
        nextSibling.length -= 1;
    }

    private borrowFromPrevious(index: number): void {
        const child = this.children[index];
        const previousSibling = this.children[index - 1];

        // The last key from children[index - 1] goes up to the parent and values[index - 1]
        // from parent is inserted as the first key in children[index]. Thus, the  loses
        // sibling one key and child gains one key

        // Moving all key in children[index] one step ahead
        for (let i = child.length - 1; i >= 0; --i) {
            child.items[i + 1] = child.items[i];
        }

        // If children[index] is not a leaf, move all its child pointers one step ahead
        if (!child.isLeaf) {
            for (let i = child.length; i >= 0; i--) {
                child.children[i + 1] = child.children[i];
            }
        }

        // Setting child's first key equal to values[index - 1] from the current node
        child.items[0] = this.items[index - 1];

        // Moving sibling's last child as children[index]'s first child
        if (!child.isLeaf) {
            child.children[0] = previousSibling.children[previousSibling.length];
        }

        // Moving the key from the sibling to the parent
        // This reduces the number of keys in the sibling
        this.items[index - 1] = previousSibling.items[previousSibling.length - 1];

        child.length += 1;
        previousSibling.length -= 1;
    }

    private removeFromLeaf(index: number, id?: ItemValueId): void {
        const item = this.items[index];
        if (typeof id !== 'undefined') {
            item.values = item.values.filter(v => v.id !== id);
            if (item.values.length === 0) {
                this.items.splice(index, 1);
                this.length--;
            }
        } else {
            this.items.splice(index, 1);
            this.length--;
        }
    }

    private removeFromNonLeaf(index: number, id: ItemValueId): void {
        const value = this.items[index];
        value.values = value.values.filter(v => v.id !== id);
        if (value.values.length !== 0) {
            return;
        }

        // If the child that precedes value (children[index]) has at least
        // minDegrees keys, find the predecessor 'pred' of k in the subtree
        // rooted at children[index]. Replace k by pred. Recursively delete pred
        // in children[index]
        if (this.children[index].length >= this.minDegrees) {
            const predecessor = this.getPredecessor(index);
            this.items[index] = predecessor;
            this.children[index].delete(predecessor.key);
        }

        // If the child children[index] has less than minDegrees keys, examine children[index + 1].
        // If children[index + 1] has at least min degrees keys, find the successor of value in the
        // subtree rooted at children[index + 1].
        // Replace value by successor.
        // Recursively delete successor in children[index + 1].
        else if (this.children[index + 1].length >= this.minDegrees) {
            const successor = this.getSuccessor(index);
            this.items[index] = successor;
            this.children[index + 1].delete(successor.key);
        }

        // If both children[index] and children[index + 1] has less than minDegrees keys, merge
        // value and all of children[index + 1] into children[index]. Now children[index]
        // contains 2 * minDegrees - 1 keys. Free children[index + 1] and recursively delete
        // value from children[index].
        else {
            this.merge(index);
            this.children[index].delete(value.key);
        }
    }

    private getSuccessor(index: number): IBTreeItem<T> {
        let current = this.children[index + 1];
        while (!current.isLeaf) {
            current = current.children[0];
        }
        return current.items[0];
    }

    private getPredecessor(index: number): IBTreeItem<T> {
        // Keep moving to the right most node until we reach a leaf
        let current = this.children[index];
        while (!current.isLeaf) {
            current = current.children[current.length];
        }
        return current.items[current.length - 1];
    }
}
