import { CommonModule } from '@angular/common';
import { Component, Input, ViewChild, computed, effect, inject, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { ColorPickerComponent, IColorPalette } from '@app/shared/components';
import { UserService } from '@app/shared/user/state/user.service';
import { TranslationPageState } from '@app/translation-page/state/translation-page.reducer';
import { UIModule, UIPopoverDirective } from '@bannerflow/ui';
import { Color } from '@creative/color';
import { tryGetFontStyleById } from '@creative/font-families.utils';
import { isTextDataElement } from '@creative/nodes';
import { IColor } from '@domain/color';
import { IElement } from '@domain/creativeset';
import { ICreative } from '@domain/creativeset/creative';
import { ITextSpan, IVersion, IVersionProperty, IVersionedText } from '@domain/creativeset/version';
import { ISelectedFont } from '@domain/font';
import { ITextDataNode, OneOfDataNodes } from '@domain/nodes';
import { ICharacterProperties } from '@domain/text';
import {
    CreativeToDirtyCharacterStyling,
    DirtyCharacterStylingChanges,
    DirtyVersionPropertiesChanges,
    GroupedElements,
    StyleIdsModification,
    VersionToDirtyProperties
} from '@studio/domain/components/translation-page';
import { cloneDeep } from '@studio/utils/clone';
import { injectFontFace } from '@studio/utils/dom-utils';
import { uuidv4 } from '@studio/utils/id';
import { deepEqual, toFixedDecimal } from '@studio/utils/utils';
import { delay, take } from 'rxjs';
import { BrandService } from '../../../../../shared/brand/state/brand.service';
import { ButtonToggleComponent } from '../../../../../shared/components/button-toggle/button-toggle.component';
import { ColorPaletteComponent } from '../../../../../shared/components/color-picker/color-palette.component';
import { ColorButtonComponent } from '../../../../../shared/components/color/color-button/color-button.component';
import { FontPickerComponent } from '../../../../../shared/components/font-picker/font-picker.component';
import { CreativesService } from '../../../../../shared/creatives/state/creatives.service';
import { FontFamiliesService } from '../../../../../shared/font-families/state/font-families.service';
import { ParseColorPipe } from '../../../../../shared/pipes/parse-color.pipe';
import { EnvironmentService } from '../../../../../shared/services/environment.service';
import { VersionsService } from '../../../../../shared/versions/state/versions.service';
import { textPropertiesInputValidation } from '../../../../design-view/properties-panel/text/text-properties.component.constants';
import { TranslationPageService } from '../../../state/translation-page.service';
import { applySelectionToSpans, getSpansInSelection } from '../../../utils/span.utils';
import {
    clearSelection,
    getVersionPropertyAndDirtyProperty,
    mergeCreativesWithDirtyCharacterStyles,
    shouldInvertColor
} from '../../../utils/tp.utils';

type Preset = {
    name: string;
    styleId: string;
    elementIds: string[]; // just to see if all elements contain that preset
    documentIds: string[]; // just to see if all documents contain that prest
    cssStyles: string;
    fontSize: string;
    disabled: boolean;
};
type SelectedPreset = Preset | 'mixed' | 'default';

type FontIdValue = string | 'mixed' | undefined;
type FontSizeValue = number | 'mixed' | undefined;
type LineHeightValue = number | 'mixed' | undefined;
type DecoractionValue = boolean | 'mixed' | undefined;
type ColorValue = IColor | 'mixed' | undefined;
type CharacterSpacingValue = number | 'mixed' | undefined;

interface SelectedStyles {
    fontStyleId: FontIdValue;
    fontFamilyId: FontIdValue;
    fontSize: FontSizeValue;
    textColor: ColorValue;
    uppercase: DecoractionValue;
    underline: DecoractionValue;
    strikethrough: DecoractionValue;
    characterSpacing: CharacterSpacingValue;
    lineHeight: LineHeightValue;
}

const DEFAULT_SELECTED_STYLES: SelectedStyles = {
    fontFamilyId: undefined,
    fontStyleId: undefined,
    fontSize: undefined,
    textColor: undefined,
    uppercase: undefined,
    underline: undefined,
    strikethrough: undefined,
    characterSpacing: undefined,
    lineHeight: undefined
};

@Component({
    standalone: true,
    imports: [
        CommonModule,
        UIModule,
        ColorButtonComponent,
        ColorPaletteComponent,
        ButtonToggleComponent,
        FontPickerComponent,
        ParseColorPipe,
        ColorPickerComponent
    ],
    templateUrl: './style-popover.component.html',
    styleUrls: ['./style-popover.component.scss'],
    selector: 'style-popover'
})
export class StylePopoverComponent {
    private brandService = inject(BrandService);
    private creativesService = inject(CreativesService);
    private environmentService = inject(EnvironmentService);
    private fontFamiliesService = inject(FontFamiliesService);
    private translationPageService = inject(TranslationPageService);
    private userService = inject(UserService);
    private versionsService = inject(VersionsService);

    @Input() group: GroupedElements;
    @Input() version: IVersion;

    @ViewChild('stylingPopover', { static: true }) stylingPopover: UIPopoverDirective | undefined;

    presets: Preset[] = [];
    selectedPreset: SelectedPreset;
    currentPreset: string | undefined;
    hasStyles = false;

    private _usedColors = toSignal(this.translationPageService.usedColors$, { initialValue: [] });
    usedColorsPalette = computed<IColorPalette[]>(() => {
        const usedColors = this._usedColors();
        return [
            {
                name: 'In use colors',
                swatch: usedColors
            }
        ];
    });

    showColorPicker = signal(false);

    brandPalettes = this.brandService.brandPalettes;

    inputValidation = textPropertiesInputValidation;
    inShowcaseMode = toSignal(this.environmentService.inShowcaseMode$, { initialValue: true });

    private selectedText = toSignal(this.translationPageService.selectedText$);
    elementName = computed<string>(() => {
        const selectedText = this.selectedText();
        return this.group?.elements.find(({ id }) => id === selectedText?.elementId)?.name ?? '';
    });
    selectedStylesSignal = computed(() => {
        const selectedText = this.selectedText();
        this.handleSelectedTextChange(selectedText);

        return this.selectedStyles;
    });

    private allowedOperations = toSignal(this.userService.allowedOperations$);
    allowStyling = computed(() => {
        const inShowcaseMode = this.inShowcaseMode();
        if (!inShowcaseMode) {
            return true;
        }
        const allowedOperations = !!this.allowedOperations()?.includes('styleTranslations');
        return allowedOperations;
    });

    private _colorPickerValue = signal<IColor | undefined>(undefined);
    colorPickerValue = computed(() => {
        const colorPickerValue = this._colorPickerValue();
        const selectedStyles = this.selectedStylesSignal();

        if (colorPickerValue) {
            return colorPickerValue;
        }

        return selectedStyles?.textColor !== 'mixed' ? selectedStyles?.textColor : undefined;
    });

    private defaultVersion = toSignal(this.versionsService.defaultVersion$, {
        initialValue: {} as IVersion
    });
    private dirtyCharacterStyling = toSignal(this.translationPageService.dirtyCharacterStyling$, {
        initialValue: {} as CreativeToDirtyCharacterStyling
    });
    // delay(0) because Angular...
    private dirtyProperties = toSignal(this.translationPageService.dirtyProperties$.pipe(delay(0)), {
        initialValue: {} as VersionToDirtyProperties
    });
    private expanded = computed(() => Boolean(this.selectedText()?.expanded));
    private fontFamilies = toSignal(this.fontFamiliesService.fontFamilies$, { initialValue: [] });
    private notifyNextColorPickerChange: boolean;
    private originalCreatives: ICreative[] = []; // Creatives without dirtyChanges
    private selectedCreatives: ICreative[] = [];
    private selectedSpans: ITextSpan[] = [];
    private selectedStyles: SelectedStyles = cloneDeep(DEFAULT_SELECTED_STYLES);

    constructor() {
        this.creativesService.filteredCreatives$
            .pipe(takeUntilDestroyed())
            .subscribe(selectedCreatives => {
                this.originalCreatives = selectedCreatives;
                this.selectedCreatives = mergeCreativesWithDirtyCharacterStyles(
                    selectedCreatives,
                    this.dirtyCharacterStyling()
                );
            });

        effect(
            () => {
                const selectedText = this.selectedText();
                this.creativesService.focusElement(
                    selectedText?.elementId ?? this.group.elements[0].id,
                    this.version.id
                );
            },
            { allowSignalWrites: true }
        );
    }

    toggleColorPicker(): void {
        this.showColorPicker.update(showColorPicker => !showColorPicker);
    }

    onRemoveStylesClicked(): void {
        const selectedText = this.selectedText();
        if (!selectedText) {
            return;
        }
        const changes: DirtyVersionPropertiesChanges = [];
        const elements = this.expanded()
            ? this.group.elements.filter(({ id }) => id === selectedText.elementId)
            : this.group.elements;
        // for selected elements
        for (const element of elements) {
            const elementVersionProperty = element.properties.find(
                ({ versionPropertyId }) => !!versionPropertyId
            );
            if (!elementVersionProperty?.versionPropertyId) {
                continue;
            }
            const { versionProperty, dirtyVersionProperty } = getVersionPropertyAndDirtyProperty(
                elementVersionProperty.versionPropertyId,
                this.version,
                this.defaultVersion(),
                this.dirtyProperties()
            );
            const versionedText = (dirtyVersionProperty ?? versionProperty).value;

            const spanStylesModification: StyleIdsModification = {
                type: 'remove',
                modification: {}
            };
            const selectedSpans = getSpansInSelection(selectedText.selection, versionedText.styles);
            const selectedCreativesWithElement = this.selectedCreatives
                .filter(({ design }) => !!design?.document.elements.some(({ id }) => element.id === id))
                .map(({ design }) => design?.document.id)
                .filter(Boolean);
            for (const span of selectedSpans) {
                for (const [documentId, styleId] of Object.entries(span.styleIds)) {
                    if (selectedCreativesWithElement.includes(documentId)) {
                        spanStylesModification.modification[documentId] = styleId;
                    }
                }
            }
            const newStyles = applySelectionToSpans(
                selectedText.selection,
                versionedText.styles,
                spanStylesModification
            );
            const newVersionProperty = {
                ...versionProperty,
                value: {
                    ...versionedText,
                    styles: newStyles
                }
            };
            changes.push({
                action: 'upsert',
                versionProperty: newVersionProperty,
                versionId: this.group.version.id
            });
        }
        this.translationPageService.modifyDirtyVersionProperties(changes);
    }

    presetSelected(selectedPreset: SelectedPreset, event: MouseEvent): void {
        if (typeof selectedPreset !== 'string' && selectedPreset.disabled) {
            event.preventDefault();
            event.stopImmediatePropagation();
            return;
        }
        const selectedText = this.selectedText();
        if (!selectedText || selectedPreset === 'mixed' || selectedPreset === 'default') {
            return;
        }
        const changes: DirtyVersionPropertiesChanges = [];
        const elements = this.expanded()
            ? this.group.elements.filter(({ id }) => id === selectedText.elementId)
            : this.group.elements;
        for (const element of elements) {
            const elementVersionProperty = element.properties.find(
                ({ versionPropertyId }) => !!versionPropertyId
            );
            if (!elementVersionProperty?.versionPropertyId) {
                continue;
            }
            const { versionProperty, dirtyVersionProperty } = getVersionPropertyAndDirtyProperty(
                elementVersionProperty.versionPropertyId,
                this.version,
                this.defaultVersion(),
                this.dirtyProperties()
            );
            const versionedText = (dirtyVersionProperty ?? versionProperty).value;

            const spanStylesModification: StyleIdsModification = {
                type: 'merge',
                modification: {}
            };
            for (const documentId of selectedPreset.documentIds) {
                spanStylesModification.modification[documentId] = selectedPreset.styleId;
            }
            const newStyles = applySelectionToSpans(
                selectedText.selection,
                versionedText.styles,
                spanStylesModification
            );
            const newVersionProperty = {
                ...versionProperty,
                value: {
                    ...versionedText,
                    styles: newStyles
                }
            };
            changes.push({
                action: 'upsert',
                versionProperty: newVersionProperty,
                versionId: this.version.id
            });
        }
        this.translationPageService.modifyDirtyVersionProperties(changes);

        this.close();
    }

    async selectedFontChange(selectedFont: ISelectedFont): Promise<void> {
        const font = await injectFontFace(selectedFont.fontStyle);
        if (!document.fonts.has(font)) {
            document.fonts.add(font);
        }
        const newFontProperty: ICharacterProperties = {
            font: {
                id: selectedFont.fontStyle.id,
                src: selectedFont.fontStyle.fontUrl,
                style: selectedFont.fontStyle.italic ? 'italic' : 'normal',
                weight: selectedFont.fontStyle.weight,
                fontFamilyId: selectedFont.fontFamily.id
            },
            __fontFamilyId: selectedFont.fontFamily.id
        };
        this.updateCharacterProperties(newFontProperty);
    }

    onStrikethroughToggled(active: boolean): void {
        const newCharacterProperty: ICharacterProperties = {
            strikethrough: active
        };
        this.updateCharacterProperties(newCharacterProperty);
    }

    onUppercaseToggled(active: boolean): void {
        const newCharacterProperty: ICharacterProperties = {
            uppercase: active
        };
        this.updateCharacterProperties(newCharacterProperty);
    }

    onUnderlineToggled(active: boolean): void {
        const newCharacterProperty: ICharacterProperties = {
            underline: active
        };
        this.updateCharacterProperties(newCharacterProperty);
    }

    onColorSelected(newColor: Color): void {
        const newCharacterProperty: ICharacterProperties = {
            textColor: newColor
        };
        this.updateCharacterProperties(newCharacterProperty);
    }

    onColorPickerValueChanged(newColor: Color): void {
        const previousValue = this._colorPickerValue();
        if (previousValue?.toString() !== newColor.toString()) {
            this._colorPickerValue.set(newColor);
        }

        if (this.notifyNextColorPickerChange) {
            this.onColorSelected(newColor);
        }
        this.notifyNextColorPickerChange = false;
    }

    onColorPickerWillChange(): void {
        this.notifyNextColorPickerChange = true;
    }
    onColorPickerEditEnded(isDifferentColor: boolean): void {
        if (!isDifferentColor) {
            return;
        }
        const colorPickerValue = this.colorPickerValue();
        if (colorPickerValue) {
            this.onColorSelected(colorPickerValue);
        }
    }

    onCharacterSpacingChange(event: number): void {
        const newCharacterProperty: ICharacterProperties = {
            characterSpacing: event
        };
        this.updateCharacterProperties(newCharacterProperty);
    }

    onLineHeightUpdated(newLineHeight: number): void {
        const newCharacterProperty: ICharacterProperties = {
            lineHeight: newLineHeight
        };
        this.updateCharacterProperties(newCharacterProperty);
    }

    close(): void {
        this.stylingPopover?.close();
    }

    private updateCharacterProperties(newCharacterProperties: ICharacterProperties): void {
        const selectedText = this.selectedText();
        if (!selectedText) {
            return;
        }
        const elements = this.expanded()
            ? this.group.elements.filter(({ id }) => id === selectedText.elementId)
            : this.group.elements;
        const changes: DirtyVersionPropertiesChanges = [];
        const characterStylesChanges: DirtyCharacterStylingChanges = [];

        for (const element of elements) {
            const elementVersionProperty = element.properties.find(
                ({ versionPropertyId }) => !!versionPropertyId
            );
            if (!elementVersionProperty?.versionPropertyId) {
                continue;
            }
            const { versionProperty, dirtyVersionProperty } = getVersionPropertyAndDirtyProperty(
                elementVersionProperty.versionPropertyId,
                this.version,
                this.defaultVersion(),
                this.dirtyProperties()
            );
            if (!versionProperty) {
                return;
            }
            const versionPropertyValue = (dirtyVersionProperty ?? versionProperty).value;
            const spans = applySelectionToSpans(
                selectedText.selection,
                cloneDeep(versionPropertyValue.styles)
            );
            this.updateCharacterPropertiesInSpans(
                spans,
                newCharacterProperties,
                element,
                dirtyVersionProperty ?? versionProperty,
                characterStylesChanges,
                changes
            );
        }
        if (changes.length) {
            // TODO: refactor this into one action
            this.translationPageService.modifyDirtyVersionProperties(changes);
            this.translationPageService.modifyDirtyCharacterStyles(characterStylesChanges);
        }
    }

    private updateCharacterPropertiesInSpans(
        spans: ITextSpan[],
        newCharacterProperties: ICharacterProperties,
        element: IElement,
        versionProperty: IVersionProperty<IVersionedText>,
        characterStylesChanges: DirtyCharacterStylingChanges,
        versionPropertiesChanges: DirtyVersionPropertiesChanges
    ): void {
        const selectedText = this.selectedText();
        if (!selectedText?.selection) {
            return;
        }
        const { selection } = selectedText;
        let changed = false;
        for (const span of spans) {
            if (span.position >= selection.end || span.position + span.length <= selection.start) {
                continue;
            }
            for (const creative of this.selectedCreatives) {
                const updated = this.updateCharacterPropertiesInSpan(
                    creative,
                    span,
                    element,
                    newCharacterProperties,
                    characterStylesChanges
                );
                changed ||= updated;
            }
        }
        if (changed) {
            versionPropertiesChanges.push({
                versionId: this.version.id,
                action: 'upsert',
                versionProperty: {
                    ...versionProperty,
                    value: {
                        ...versionProperty.value,
                        styles: spans
                    }
                }
            });
        }
    }

    private updateCharacterPropertiesInSpan(
        creative: ICreative,
        span: ITextSpan,
        element: IElement,
        newCharacterProperties: ICharacterProperties,
        characterStylesChanges: DirtyCharacterStylingChanges
    ): boolean {
        if (!creative?.design) {
            return false;
        }
        const documentElement = creative.design.document.elements.find(({ id }) => id === element.id);
        if (!documentElement || !isTextDataElement(documentElement)) {
            return false;
        }

        const oldStyleId = span.styleIds[creative.design.document.id];
        const newStyleId = uuidv4();

        const oldCharacterStyle = documentElement.characterStyles.get(oldStyleId) ?? {};

        const newCharacterStyle = {
            ...oldCharacterStyle,
            ...newCharacterProperties
        };

        span.styleIds = {
            ...span.styleIds,
            [creative.design.document.id]: newStyleId
        };

        const changedCreative = characterStylesChanges.find(
            ({ creativeId }) => creativeId === creative.id
        );
        if (!changedCreative) {
            characterStylesChanges.push({
                action: 'upsert',
                creativeId: creative.id,
                changes: {
                    [element.id]: { [newStyleId]: newCharacterStyle }
                }
            });
            return true;
        }
        changedCreative.changes[element.id] ??= {};
        changedCreative.changes[element.id][newStyleId] = newCharacterStyle;
        return true;
    }

    private isDirtyStyle(creativeId: string, elementId: string, styleId: string): boolean {
        return !!this.dirtyCharacterStyling()[creativeId]?.[elementId]?.[styleId];
    }

    private getPresetsForElmenet(
        creative: ICreative,
        element: OneOfDataNodes,
        selectedElementIds: string[],
        presets: Preset[]
    ): void {
        if (!selectedElementIds.includes(element.id) || !isTextDataElement(element)) {
            return;
        }
        const documentId = creative.design!.document.id;
        for (const [styleId, _] of element.characterStyles.entries()) {
            if (this.isDirtyStyle(creative.id, element.id, styleId)) {
                continue;
            }
            const preset = presets.find(({ styleId: presetStyleId }) => presetStyleId === styleId);
            if (preset) {
                if (!preset.elementIds.includes(element.id)) {
                    preset.elementIds.push(element.id);
                }
                if (!preset.documentIds.includes(documentId)) {
                    preset.documentIds.push(documentId);
                }
                continue;
            }
            const { stylesForPreset, fontSize } = this.getStylesForPreset(
                documentId,
                element.id,
                styleId
            );
            presets.push({
                name: '',
                elementIds: [element.id],
                styleId,
                documentIds: [documentId],
                fontSize,
                disabled: false,
                cssStyles: stylesForPreset ?? ''
            });
        }
    }

    private updatePresets(): void {
        const selectedText = this.selectedText();
        if (!selectedText) {
            return;
        }
        const elements = this.expanded()
            ? this.group.elements.filter(({ id }) => id === selectedText.elementId)
            : this.group.elements;

        const selectedElementIds = elements.map(({ id }) => id);
        const presets: Preset[] = [];
        for (const creative of this.originalCreatives) {
            for (const element of creative.design?.document.elements ?? []) {
                this.getPresetsForElmenet(creative, element, selectedElementIds, presets);
            }
        }
        // for selected elements
        const numberOfSelectedDocuments = new Set(
            this.originalCreatives
                .map(({ design }) =>
                    design?.document.elements.some(({ id }) => selectedElementIds.includes(id))
                        ? design.document.id
                        : undefined
                )
                .filter(Boolean)
        ).size;

        this.presets = presets.map((preset, index) => {
            const disabled = !(
                preset.elementIds.length === elements.length &&
                preset.documentIds.length === numberOfSelectedDocuments
            );
            const cssStyles = preset.cssStyles + (disabled ? 'cursor:not-allowed;' : '');
            return {
                ...preset,
                name: `Character style ${index + 1}`,
                disabled,
                cssStyles
            };
        });
    }

    private getStylesForPreset(
        documentId: string,
        elementId: string,
        styleId: string
    ): { stylesForPreset: Preset['cssStyles']; fontSize: string } {
        const document = this.selectedCreatives.find(
            ({ design }) => design?.document?.id === documentId
        )?.design?.document;
        if (!document) {
            return { stylesForPreset: '', fontSize: '' };
        }
        const elementInDocument = document.elements.find(({ id }) => id === elementId);
        if (!elementInDocument || !isTextDataElement(elementInDocument)) {
            return { stylesForPreset: '', fontSize: '' };
        }
        const styleFromElement = elementInDocument.characterStyles.get(styleId);
        if (!styleFromElement) {
            return { stylesForPreset: '', fontSize: '' };
        }

        const underline = styleFromElement.underline ? 'underline' : '';
        const strikethrough = styleFromElement.strikethrough ? 'line-through' : '';
        const textDecoration =
            styleFromElement.underline || styleFromElement.strikethrough
                ? `text-decoration: ${underline} ${strikethrough};`
                : '';
        const textTransform = styleFromElement.uppercase ? 'text-transform: uppercase;' : '';

        const textColor = new Color(styleFromElement.textColor);
        const invertedColor = shouldInvertColor(textColor.red, textColor.green, textColor.blue)
            ? 'var(--default-color-black-off)'
            : 'var(--default-color-white-off)';
        const color = styleFromElement.textColor
            ? `color: ${textColor.toString()};`
            : `color: #000000;`;
        const backgroundColor = styleFromElement.textColor ? `background-color: ${invertedColor};` : '';

        const font = styleFromElement.font ? `font-family: f-${styleFromElement.font.id};` : '';

        const fontSize = styleFromElement.fontSize
            ? ` (${toFixedDecimal(styleFromElement.fontSize * 100, 0)}%)`
            : '';

        return {
            stylesForPreset: `${textDecoration}${textTransform}${color}${backgroundColor}${font}`,
            fontSize
        };
    }

    private updateStylesFromElement(
        element: IElement,
        selectedPreset: SelectedPreset,
        defaultStyles: SelectedStyles,
        selectedStyles: SelectedStyles
    ): {
        selectedPreset: SelectedPreset;
        defaultStyles: SelectedStyles;
        selectedStyles: SelectedStyles;
    } {
        const selectedText = this.selectedText();
        if (!selectedText) {
            return { selectedStyles, defaultStyles, selectedPreset };
        }
        const elementVersionProperty = element.properties.find(
            ({ versionPropertyId }) => !!versionPropertyId
        );
        if (!elementVersionProperty?.versionPropertyId) {
            return { selectedStyles, defaultStyles, selectedPreset };
        }
        const { versionProperty, dirtyVersionProperty } = getVersionPropertyAndDirtyProperty(
            elementVersionProperty.versionPropertyId,
            this.version,
            this.defaultVersion(),
            this.dirtyProperties()
        );
        const versionedText = (dirtyVersionProperty ?? versionProperty).value;
        const spans = versionedText.styles;

        this.selectedSpans = getSpansInSelection(selectedText.selection, spans);

        if (selectedPreset !== 'mixed') {
            const selectedPresetForElement = this.getSelectedPreset(this.presets, this.selectedSpans);
            if (
                selectedPresetForElement !== selectedPreset &&
                !this.areStyleIdsSame(this.selectedSpans)
            ) {
                selectedPreset = 'mixed';
            } else if (selectedPreset === 'default') {
                selectedPreset = selectedPresetForElement;
            } else {
                selectedPreset = selectedPresetForElement;
            }
        }

        for (const creative of this.selectedCreatives) {
            const textNode = creative.design?.document.elements.find(({ id }) => id === element.id);
            if (!isTextDataElement(textNode)) {
                continue;
            }
            defaultStyles = this.updateDefaultStyles(textNode, defaultStyles);
            for (const selectedSpan of this.selectedSpans) {
                for (const [_, styleId] of Object.entries(selectedSpan.styleIds)) {
                    selectedStyles = this.updateSelectedStyles(textNode, styleId, selectedStyles);
                }
            }
        }
        return { selectedStyles, defaultStyles, selectedPreset };
    }

    private updateStyles(): void {
        const selectedText = this.selectedText();
        if (!selectedText) {
            return;
        }
        const elements = this.expanded()
            ? this.group.elements.filter(({ id }) => id === selectedText?.elementId)
            : this.group.elements;

        let defaultStyles = cloneDeep(DEFAULT_SELECTED_STYLES);
        let selectedStyles = cloneDeep(DEFAULT_SELECTED_STYLES);

        let selectedPreset: SelectedPreset = 'default';

        for (const element of elements) {
            const updateStylesFromElementResult = this.updateStylesFromElement(
                element,
                selectedPreset,
                defaultStyles,
                selectedStyles
            );
            selectedPreset = updateStylesFromElementResult.selectedPreset;
            defaultStyles = updateStylesFromElementResult.defaultStyles;
            selectedStyles = updateStylesFromElementResult.selectedStyles;
        }

        const defaultStyleSelected = this.isDefaultStyleSelected();
        this.selectedStyles = this.getSelectedStyles(
            defaultStyles,
            selectedStyles,
            defaultStyleSelected
        );

        if (selectedPreset === 'mixed' || selectedPreset === 'default') {
            this.selectedPreset = selectedPreset;
        } else {
            const selectedPresetStyleId = selectedPreset.styleId;
            this.selectedPreset =
                this.presets.find(({ styleId }) => styleId === selectedPresetStyleId) ?? 'default';
        }
    }

    private isDefaultStyleSelected(): boolean {
        const selectedDocumentIds = this.selectedCreatives
            .map(creative => creative.design?.document.id)
            .filter((documentId): documentId is string => !!documentId);
        for (const span of this.selectedSpans) {
            if (!selectedDocumentIds.every(documentId => !!span.styleIds[documentId])) {
                return true;
            }
        }
        return false;
    }

    private getSelectedStyles(
        defaultStyles: SelectedStyles,
        selectedStyles: SelectedStyles,
        defaultStyleSelected: boolean
    ): SelectedStyles {
        const getStyleValue = (key: string, mixedValue: string | boolean): any => {
            const defaultValue = defaultStyles[key];
            const selectedValue = selectedStyles[key];

            if (selectedValue === '$mixed') {
                return mixedValue;
            }
            if (defaultStyleSelected && defaultValue !== selectedValue && !!selectedValue) {
                return mixedValue;
            }
            return selectedValue ?? defaultValue;
        };
        return {
            fontFamilyId: getStyleValue('fontFamilyId', '$mixed'),
            fontStyleId: getStyleValue('fontStyleId', '$mixed'),
            fontSize: getStyleValue('fontSize', 'mixed'),
            textColor: getStyleValue('textColor', 'mixed'),
            characterSpacing: getStyleValue('characterSpacing', 'mixed'),
            lineHeight: getStyleValue('lineHeight', 'mixed'),
            uppercase: getStyleValue('uppercase', false),
            underline: getStyleValue('underline', false),
            strikethrough: getStyleValue('strikethrough', false)
        };
    }

    private areStyleIdsSame(selectedSpans: ITextSpan[]): boolean {
        if (selectedSpans.length <= 1) {
            return true;
        }

        const firstStyleIds = selectedSpans[0].styleIds;
        for (const span of selectedSpans) {
            if (!deepEqual(firstStyleIds, span.styleIds)) {
                return false;
            }
        }

        return true;
    }

    private getSelectedPreset(presets: Preset[], selectedSpans: ITextSpan[]): SelectedPreset {
        let selectedPreset: SelectedPreset = 'default';
        for (const span of selectedSpans) {
            for (const [_, styleId] of Object.entries(span.styleIds)) {
                const spanPreset = presets.find(preset => preset.styleId === styleId);
                if (!spanPreset) {
                    // default
                    continue;
                }
                if (selectedPreset === 'default') {
                    selectedPreset = spanPreset;
                    continue;
                }
                if (selectedPreset !== spanPreset) {
                    return 'mixed';
                }
            }
        }
        return selectedPreset;
    }

    private updateDefaultStyles(
        textNode: ITextDataNode,
        defaultStyles: SelectedStyles
    ): SelectedStyles {
        // font
        if (textNode.__fontStyleId) {
            const font = tryGetFontStyleById(this.fontFamilies(), textNode.__fontStyleId);
            if (font) {
                defaultStyles.fontStyleId = textNode.__fontStyleId;
                defaultStyles.fontFamilyId = font.fontFamilyId;
            }
        }
        defaultStyles.fontSize = this.updateFontSize(textNode.fontSize, defaultStyles.fontSize);

        defaultStyles.lineHeight = this.updateLineHeight(textNode.lineHeight, defaultStyles.lineHeight);

        defaultStyles.textColor = this.updateTextColor(textNode.textColor, defaultStyles.textColor);

        defaultStyles.uppercase = this.updateDecoration(textNode.uppercase, defaultStyles.uppercase);
        defaultStyles.underline = this.updateDecoration(textNode.underline, defaultStyles.underline);
        defaultStyles.strikethrough = this.updateDecoration(
            textNode.strikethrough,
            defaultStyles.strikethrough
        );

        // Character spacing
        defaultStyles.characterSpacing = this.updateCharacterSpacing(
            textNode.characterSpacing,
            defaultStyles.characterSpacing
        );

        return defaultStyles;
    }

    private updateSelectedStyles(
        textNode: ITextDataNode,
        styleId: string,
        selectedStyles: SelectedStyles
    ): SelectedStyles {
        const styles = textNode.characterStyles.get(styleId);
        if (!styles) {
            return selectedStyles;
        }
        if (styles.font) {
            selectedStyles.fontFamilyId ??= styles.font?.fontFamilyId;
            if (
                !!styles.font?.fontFamilyId &&
                selectedStyles.fontFamilyId !== styles.font.fontFamilyId
            ) {
                selectedStyles.fontFamilyId = '$mixed';
            }
            selectedStyles.fontStyleId ??= styles.font?.id;
            if (!!styles.font?.id && selectedStyles.fontStyleId !== styles.font.id) {
                selectedStyles.fontStyleId = '$mixed';
            }
        }
        selectedStyles.fontSize = this.updateFontSize(styles.fontSize, selectedStyles.fontSize);

        selectedStyles.lineHeight = this.updateLineHeight(styles.lineHeight, selectedStyles.lineHeight);

        selectedStyles.textColor = this.updateTextColor(styles.textColor, selectedStyles.textColor);

        selectedStyles.uppercase = this.updateDecoration(styles.uppercase, selectedStyles.uppercase);
        selectedStyles.underline = this.updateDecoration(styles.underline, selectedStyles.underline);
        selectedStyles.strikethrough = this.updateDecoration(
            styles.strikethrough,
            selectedStyles.strikethrough
        );

        // characterSpacing
        selectedStyles.characterSpacing = this.updateCharacterSpacing(
            styles.characterSpacing,
            selectedStyles.characterSpacing
        );
        return selectedStyles;
    }

    private updateCharacterSpacing(
        newCharacterSpacing: CharacterSpacingValue,
        oldCharacterSpacing: CharacterSpacingValue
    ): CharacterSpacingValue {
        if (typeof oldCharacterSpacing === 'undefined') {
            return newCharacterSpacing;
        }
        if (typeof newCharacterSpacing === 'undefined' || oldCharacterSpacing === 'mixed') {
            return oldCharacterSpacing;
        }
        return oldCharacterSpacing !== newCharacterSpacing ? 'mixed' : oldCharacterSpacing;
    }

    private updateLineHeight(
        newLineHeight: LineHeightValue,
        oldLineHeight: LineHeightValue
    ): LineHeightValue {
        if (typeof oldLineHeight === 'undefined') {
            return newLineHeight;
        }
        if (typeof newLineHeight === 'undefined' || oldLineHeight === 'mixed') {
            return oldLineHeight;
        }
        return oldLineHeight !== newLineHeight ? 'mixed' : oldLineHeight;
    }

    private updateFontSize(newFontSize: FontSizeValue, oldFontSize: FontSizeValue): FontSizeValue {
        if (typeof oldFontSize === 'undefined') {
            return newFontSize;
        }
        if (typeof newFontSize === 'undefined' || oldFontSize === 'mixed') {
            return oldFontSize;
        }
        return oldFontSize !== newFontSize ? 'mixed' : oldFontSize;
    }

    private updateDecoration(
        newDecoration: DecoractionValue,
        oldDecoration: DecoractionValue
    ): DecoractionValue {
        if (typeof oldDecoration === 'undefined') {
            return newDecoration;
        }
        if (typeof newDecoration === 'undefined' || oldDecoration === 'mixed') {
            return oldDecoration;
        }
        return oldDecoration !== newDecoration ? 'mixed' : oldDecoration;
    }

    private updateTextColor(newColor: ColorValue, oldColor: ColorValue): ColorValue {
        if (!oldColor) {
            return newColor instanceof Color ? new Color(newColor) : newColor;
        }
        if (!newColor || oldColor === 'mixed') {
            return oldColor instanceof Color ? new Color(oldColor) : oldColor;
        }
        return newColor.toString() !== oldColor.toString() ? 'mixed' : new Color(oldColor);
    }

    private openStylingPopover(selectedText: TranslationPageState['selectedText']): void {
        if (
            this.stylingPopover?.isPopoverOpen ||
            !selectedText ||
            !selectedText.selection.text.length
        ) {
            return;
        }
        const popoverRef = this.stylingPopover?.open(selectedText.popoverTarget);
        popoverRef
            ?.afterClosed()
            .pipe(take(1))
            .subscribe(() => {
                clearSelection();
                this.showColorPicker.set(false);
                this.creativesService.blurElement();
                this.translationPageService.setSelectedText(undefined);
                this._colorPickerValue.set(undefined);
            });
    }

    private handleSelectedTextChange(selectedText: TranslationPageState['selectedText']): void {
        this.resetState();
        const isSameGroup = selectedText?.groupId === this.group?.id;
        if (!selectedText || !isSameGroup) {
            this.close();
            return;
        }
        this.updatePresets();
        this.updateStyles();
        this.openStylingPopover(selectedText);
    }

    private resetState(): void {
        this.selectedStyles = cloneDeep(DEFAULT_SELECTED_STYLES);
    }
}
