import { IAd } from '@domain/ad/ad';
import { IBannerflowWindow } from '@domain/ad/bannerflow-window';
import { TCData } from '@domain/ad/tcf';

export const BANNERFLOW_TCF_VENDOR_ID = 273;
const TCF_VERSION = 2;
export const MAX_TCF_STRING_LENGTH = 10000;

// eslint-disable-next-line @typescript-eslint/naming-convention
export function initTCF_m(ad: IAd, window: IBannerflowWindow): void {
    let frame = window;
    let cmpFrame: Window | undefined;

    while (frame) {
        try {
            /**
             * This throws error if trying to do a cross origin parent access.
             */
            if (frame.frames['__tcfapiLocator']) {
                cmpFrame = frame;
                break;
            }
        } catch (_) {
            // eslint-disable-next-line no-empty
        }

        if (frame === window.top) {
            break;
        }

        frame = frame.parent as IBannerflowWindow;
    }

    /**
     * https://github.com/InteractiveAdvertisingBureau/GDPR-Transparency-and-Consent-Framework/blob/640e0f7d58cb0c2dbb351d5396ae4bb421c33ebb/TCFv2/IAB%20Tech%20Lab%20-%20CMP%20API%20v2.md#is-there-a-sample-iframe-script-call-to-the-cmp-api
     *
     * __tcfapiLocator is supposed to exist if an ancestor frame has CMP as they
     * are required to create a __tcfapiLocator frame inside the child frame
     * (as far as we can traverse) to signal that this is the case.
     */
    try {
        const cmpCallbacks = {};

        /**
         * Set up a __tcfapi proxy method to do the postMessage and map the callback
         * if it doens't exist already.
         * This is so that we know that we get a response back (postMessage) from the
         * correct source (to avoid malicious responses)
         */

        if (!window.__tcfapi && window.top !== window) {
            window.__tcfapi = function (cmd, version, callback, arg): void {
                if (!cmpFrame) {
                    callback({ msg: 'CMP not found' }, false);
                } else {
                    const callId = `${Math.random()}`;
                    const msg = {
                        __tcfapiCall: {
                            command: cmd,
                            parameter: arg,
                            version: version,
                            callId: callId
                        }
                    };

                    /**
                     * map the callback for lookup on response
                     */
                    cmpCallbacks[callId] = callback;
                    cmpFrame.postMessage(msg, '*');
                }
            };
        }

        const postMessageHandler = (event: MessageEvent): void => {
            let json: {
                __tcfapiReturn?: {
                    callId: string;
                    returnValue: object;
                    success: boolean;
                };
            } = {};

            try {
                json = typeof event.data === 'string' ? JSON.parse(event.data) : event.data;
                // eslint-disable-next-line no-empty
            } catch (_) {}

            const payload = json.__tcfapiReturn;

            // Call the stored callback and clean it up
            if (payload && typeof cmpCallbacks[payload.callId] === 'function') {
                cmpCallbacks[payload.callId](payload.returnValue, payload.success);
                cmpCallbacks[payload.callId] = undefined;
            }
        };

        window.addEventListener('message', postMessageHandler, false);

        /**
         * Request the TC Data
         * This will either go through the proxy or the global method
         * that already exists in the iframe scope
         */
        if (window.__tcfapi) {
            window.__tcfapi(
                'getTCData',
                TCF_VERSION,
                (tcData: TCData, success: boolean) => {
                    if (success) {
                        removeSuperfluousTCData(tcData);
                        ad.tcDataSubject.next_m(tcData);
                    }
                    ad.tcDataSubject.complete_m();
                },
                [BANNERFLOW_TCF_VENDOR_ID]
            );
            return;
        }
    } catch (e) {
        // Couldn't fetch any TC Data
    }

    ad.tcDataSubject.complete_m();
}

export function removeSuperfluousTCData(tcData: TCData): void {
    delete tcData.addtlConsent;
    delete tcData.customPurposeConsents;
    delete tcData.customVendorConsents;
    delete tcData.googleVendorConsents;

    if (tcData.publisher) {
        delete tcData.publisher.restrictions;
    }
}

export function replaceTcDataIfOverMaximumAllowedLength(tcData: string | undefined): string {
    if (!tcData) {
        return '';
    }

    if (tcData.length > MAX_TCF_STRING_LENGTH) {
        return `{"stringRemovedLength":${tcData.length}}`;
    }

    return tcData;
}

export function hasPermission(tcData: TCData | undefined): boolean {
    if (tcData) {
        return checkTcDataForConsent(tcData);
    }

    // no tcData and override tcf check false, DONT SEND TO TRACKER
    return false;
}

function hasVendorConsent(tcData: TCData): boolean | undefined {
    return tcData.vendor?.consents?.[BANNERFLOW_TCF_VENDOR_ID];
}

function hasPurposeConsent(tcData: TCData): boolean | undefined {
    return tcData.purpose?.consents?.['1'];
}

function gdprApplies(tcData: TCData): boolean {
    // if the gdprApplies value is missing we treat it as if gdpr applies
    return tcData.gdprApplies === undefined || tcData.gdprApplies;
}

function checkTcDataForConsent(tcData: TCData): boolean {
    // gdpr does not apply, we have permission
    if (!gdprApplies(tcData)) {
        return true;
    }
    // gdpr applies, respect the tcfData consent properties
    if (hasPurposeConsent(tcData) && hasVendorConsent(tcData)) {
        return true;
    }

    // we default to no permission
    return false;
}
