import { deserializeWidgetProperty } from '@creative/serialization/widget-serializer';
import {
    IBrandLibraryElement,
    IElement,
    IElementProperty,
    INewBrandLibraryElement
} from '@domain/creativeset';
import { AssetReference } from '@domain/element-asset';
import { IFontStyle } from '@domain/font';
import {
    IWidgetCustomProperty,
    IWidgetElementProperty,
    IWidgetImage,
    OneOfCustomPropertyValue,
    WIDGET_PROPERTY_PREFIX
} from '@domain/widget';
import { createElementProperty, getWidgetContentUrlOfElement } from '@studio/utils/element.utils';
import { b64DecodeUnicode, b64EncodeUnicode } from '@studio/utils/encoding';
import { uuidv4 } from '@studio/utils/id';
import { PromiseResolver } from '@studio/utils/promises';
import { resolveNamespacedCharacters } from '@studio/utils/utils';
import { IWidgetInstance, WidgetCode, WidgetResourceCache } from './widget-renderer.header';

export const codePropertyRegex = /^(html|css|ts|js)$/;
export const designWidgetElementCodePropertyRegex = /^(html|css|js)$/;

export function stripCustomPropertyPrefix(propertyName: string): string {
    return propertyName.replace(WIDGET_PROPERTY_PREFIX, '');
}

export function applyCustomPropertyPrefix(propertyName: string): string {
    if (propertyName.indexOf(WIDGET_PROPERTY_PREFIX) > -1) {
        return propertyName;
    }

    return `${WIDGET_PROPERTY_PREFIX}${propertyName}`;
}

export function hasWidgetReference(property: IElementProperty): boolean {
    return property.name === AssetReference.Widget;
}

export function hasWidgetContentBlobReference(property: IElementProperty): boolean {
    return property.name === AssetReference.WidgetContentUrl;
}

export function isCustomProperty(property: IElementProperty): property is IWidgetElementProperty {
    return property.name.indexOf(WIDGET_PROPERTY_PREFIX) > -1;
}

export function isWidgetCodeProperty(property: Partial<IElementProperty>): boolean {
    return property.name !== undefined && property.name.match(codePropertyRegex) !== null;
}

export function getIframeIdFromEvent(mouseEvent: MouseEvent): string | undefined {
    const currentTarget = mouseEvent.currentTarget;
    if (!currentTarget) {
        return;
    }
    if ((currentTarget as Document).defaultView) {
        return (currentTarget as Document).defaultView?.frameElement?.id;
    }
    return (currentTarget as HTMLElement)?.ownerDocument?.defaultView?.frameElement?.id;
}

export function translateOpenEventPosition(
    mouseEvent: MouseEvent,
    widgetInstance: IWidgetInstance | undefined
): { x: number; y: number } {
    if (!widgetInstance?.instance) {
        return {
            x: mouseEvent.clientX,
            y: mouseEvent.clientY
        };
    }

    const { x: widgetX, y: widgetY } = widgetInstance.instance;
    // mouseEvent.clientX and clientY values are relative to the iframe
    return {
        x: mouseEvent.clientX + widgetX,
        y: mouseEvent.clientY + widgetY
    };
}

export function isValidWidgetResourceData(data: unknown): data is WidgetCode {
    return !!data && typeof data === 'object' && 'html' in data && 'css' in data && 'js' in data;
}

const widgetResourceCache = new Map<string, WidgetResourceCache>();

export async function fetchWidgetFromResource(resourceUrl: string): Promise<WidgetCode> {
    const existingResource = widgetResourceCache.get(resourceUrl);
    if (existingResource) {
        await existingResource.promise;
        if (existingResource.code) {
            return existingResource.code;
        }
    }

    const loadWidgetResource = new PromiseResolver();
    const promiseObj = { promise: loadWidgetResource.promise };

    let response: unknown;
    widgetResourceCache.set(resourceUrl, promiseObj);

    try {
        response = await (await fetch(resourceUrl)).json();
    } catch (e) {
        loadWidgetResource.reject();
        throw new Error('Could not fetch widget resource.');
    }

    if (!isValidWidgetResourceData(response)) {
        throw new Error('Invalid widget resource data.');
    }

    widgetResourceCache.set(resourceUrl, { ...promiseObj, code: response });

    loadWidgetResource.resolve();

    return response;
}

export function encodeWidgetCode(widgetCode: WidgetCode): WidgetCode {
    return {
        html: b64EncodeUnicode(widgetCode.html),
        css: b64EncodeUnicode(widgetCode.css),
        js: b64EncodeUnicode(widgetCode.js),
        ts: b64EncodeUnicode(widgetCode.ts)
    };
}

export const DEFAULT_WIDGET_JS = `Widget.on(WidgetEvent.PropertyChanged, () => {
    console.log(Widget.properties);
})`;
export const DEFAULT_WIDGET_HTML = `<div>Hello!</div>\n`;
export const DEFAULT_WIDGET_CSS = `html, body {
    padding: 0;
    margin: 0;
}`;

export async function getWidgetCodeOfElement(
    element: IElement | IBrandLibraryElement | INewBrandLibraryElement,
    decoded = false
): Promise<WidgetCode> {
    const resourceUrl = getWidgetContentUrlOfElement(element);

    if (resourceUrl) {
        const widgetCode = await fetchWidgetFromResource(resourceUrl);
        return decoded ? widgetCode : encodeWidgetCode(widgetCode);
    }

    const isElementCreated = Boolean(element.id);
    // Code properties should always be encoded
    const html = isElementCreated
        ? element.properties.find(({ name }) => name === 'html')?.value
        : b64EncodeUnicode(DEFAULT_WIDGET_HTML);
    const css = isElementCreated
        ? element.properties.find(({ name }) => name === 'css')?.value
        : b64EncodeUnicode(DEFAULT_WIDGET_CSS);
    const js = isElementCreated
        ? element.properties.find(({ name }) => name === 'js')?.value
        : b64EncodeUnicode(DEFAULT_WIDGET_JS);
    const ts = isElementCreated
        ? element.properties.find(({ name }) => name === 'ts')?.value
        : b64EncodeUnicode(DEFAULT_WIDGET_JS);

    return {
        html: decoded ? b64DecodeUnicode(html) : html,
        css: decoded ? b64DecodeUnicode(css) : css,
        js: decoded ? b64DecodeUnicode(js) : js,
        ts: decoded ? b64DecodeUnicode(ts) : ts
    };
}

export async function updateOrCreateWidgetElementCodeProperties(element: IElement): Promise<void> {
    const resourceUrl = getWidgetContentUrlOfElement(element);
    if (!resourceUrl) {
        // No widget resource URL, code properties are already present
        return;
    }

    const { html, js, css } = await getWidgetCodeOfElement(element);

    const htmlProperty = element.properties.find(({ name }) => name === 'html');
    const cssProperty = element.properties.find(({ name }) => name === 'css');
    const jsProperty = element.properties.find(({ name }) => name === 'js');

    if (htmlProperty) {
        htmlProperty.value = html;
    } else {
        element.properties.push(
            createElementProperty({
                id: uuidv4(),
                name: 'html',
                value: html,
                unit: 'string'
            })
        );
    }

    if (cssProperty) {
        cssProperty.value = css;
    } else {
        element.properties.push(
            createElementProperty({
                id: uuidv4(),
                name: 'css',
                value: css,
                unit: 'string'
            })
        );
    }

    if (jsProperty) {
        jsProperty.value = js;
    } else {
        element.properties.push(
            createElementProperty({
                id: uuidv4(),
                name: 'js',
                value: js,
                unit: 'string'
            })
        );
    }
}

export function mapElementToCustomWidgetProperties(
    element: IElement | IBrandLibraryElement | INewBrandLibraryElement
): IWidgetCustomProperty[] {
    return (element.properties as IWidgetElementProperty[])
        .filter(isCustomProperty)
        .map((property): IWidgetCustomProperty => {
            const value = deserializeWidgetProperty(property);

            return {
                name: stripCustomPropertyPrefix(property.name),
                label: resolveNamespacedCharacters(property.label),
                unit: property.unit,
                value: value,
                versionPropertyId: property.versionPropertyId
            };
        });
}

export function isWidgetImage(value?: OneOfCustomPropertyValue): value is IWidgetImage {
    return (
        typeof value === 'object' &&
        'id' in value &&
        'src' in value &&
        typeof value.id === 'string' &&
        typeof value.src === 'string'
    );
}

export function isWidgetFont(value?: OneOfCustomPropertyValue): value is IFontStyle {
    return (
        typeof value === 'object' &&
        'id' in value &&
        'src' in value &&
        'style' in value &&
        typeof value.id === 'string' &&
        typeof value.src === 'string'
    );
}
