import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { CreativesEffects } from './creatives.effects';
import { CreativesService } from './creatives.service';
import { StoreModule } from '@ngrx/store';
import * as fromCreatives from './creatives.reducer';

@NgModule({
    imports: [
        StoreModule.forFeature(fromCreatives.CREATIVES_FEATURE_KEY, fromCreatives.reducer),
        EffectsModule.forFeature([CreativesEffects])
    ],
    providers: [CreativesService]
})
export class CreativesStoreModule {}
