import { Color } from '@creative/color';
import { IBounds } from '@domain/dimension';
import { IFontFamilyStyle } from '@domain/font-families';
import { DEFAULT_IMAGE_SETTINGS } from '@domain/image';
import {
    IButtonElementDataNode,
    IElementDataNode,
    IEllipseElementDataNode,
    IImageElementDataNode,
    IRectangleElementDataNode,
    ITextElementDataNode,
    IVideoElementDataNode,
    OneOfElementDataNodes,
    OneOfTextDataNodes
} from '@domain/nodes';
import { OneOfElementPropertyKeys } from '@domain/property';
import { IBorder, RadiusType } from '@domain/style';
import { ICharacterProperties } from '@domain/text';
import { cloneDeep } from '@studio/utils/clone';
import { fromRGBAstring, parseColor } from './color.utils';
import { createRichTextFromString } from './elements/rich-text/text-nodes';
import { DEFAULT_VIDEO_SETTINGS } from './elements/video/video';
import { ElementKind } from '@domain/elements';

export const BASE_ELEMENT_DEFAULTS: Omit<IElementDataNode, 'name' | 'kind'> = {
    id: '',
    locked: false,
    hidden: false,
    mirrorX: false,
    mirrorY: false,
    rotationX: 0,
    rotationY: 0,
    rotationZ: 0,
    originX: 0.5,
    originY: 0.5,
    scaleX: 1,
    scaleY: 1,
    radius: {
        type: RadiusType.Joint,
        topLeft: 0,
        topRight: 0,
        bottomRight: 0,
        bottomLeft: 0
    },
    opacity: 1,
    time: 0,
    duration: 4,
    states: [],
    animations: [],
    actions: [],
    filters: {},
    width: 100,
    height: 100,
    x: 0,
    y: 0
};

export function createBaseElement<
    Kind extends ElementKind,
    Element = Extract<OneOfElementDataNodes, { kind: Kind }>,
    Overrides = IBounds & Partial<Element>
    // The Elements in the return function should really be partial, but all other types are wrong so
    // I'm leaving it without that for now. (adding the partial later you should also be able to remove
    // the any cast).
    // : Partial<Element> & Overrides & typeof BASE_ELEMENT_DEFAULTS ...
>(kind: Kind, overrides: Overrides): Element {
    return {
        ...cloneDeep(BASE_ELEMENT_DEFAULTS),
        kind: kind,
        ...overrides
    } as Element;
}

export function createRectangleElementTemplate(
    overrides: Partial<IElementDataNode>
): IRectangleElementDataNode {
    const template = createBaseElement(ElementKind.Rectangle, overrides);
    template.fill = parseColor('#CCCCCC');
    return template;
}

/**
 * Notice, only 'fill', 'textShadows', 'shadows' and 'border' are not set as default property values.
 * And this function is needed when we promote first span styles to set default styles on spans that
 * are not promoted.
 */
export function getDefaultTextValue<P extends OneOfElementPropertyKeys>(
    property: P
): Color | [] | IBorder | undefined {
    switch (property) {
        case 'textColor':
        case 'fill':
            return parseColor('rgba(0, 0, 0, 0)');
        case 'textShadows':
            return [];
        case 'shadows':
            return [];
        case 'border':
            return { color: parseColor('rgba(0, 0, 0, 0)'), thickness: 0, style: 'solid' } as IBorder;
    }
}

export function createEllipseElementTemplate(
    overrides: Partial<IElementDataNode>
): IEllipseElementDataNode {
    const template = createBaseElement(ElementKind.Ellipse, overrides);
    template.fill = parseColor('#CCCCCC');
    return template;
}

export function createTextElementTemplate(
    overrides: Partial<IElementDataNode>,
    text = 'Type something',
    fontStyle?: IFontFamilyStyle
): ITextElementDataNode {
    const template = createBaseTextElement(
        ElementKind.Text,
        overrides,
        fontStyle
    ) as ITextElementDataNode;
    template.content = createRichTextFromString(text);
    template.fontSize = 40;
    template.padding = { top: 0, left: 0, right: 0, bottom: 0 };
    template.textColor = fromRGBAstring('rgba(0, 0, 0, 1)');
    template.maxRows = 0;
    template.padding = { top: 0, left: 0, right: 0, bottom: 0 };

    Object.assign(template, overrides);

    return template;
}

export function createButtonElementTemplate(
    overrides: Partial<IElementDataNode>,
    fontStyle?: IFontFamilyStyle
): IButtonElementDataNode {
    const template = createBaseTextElement(
        ElementKind.Button,
        overrides,
        fontStyle
    ) as IButtonElementDataNode;
    template.content = createRichTextFromString('Click here');
    template.maxRows = 1;
    template.padding = { top: 5, left: 5, right: 5, bottom: 5 };
    template.fill = parseColor('#808080');
    template.radius = {
        type: RadiusType.Joint,
        topLeft: 3,
        topRight: 3,
        bottomRight: 3,
        bottomLeft: 3
    };
    template.textColor = parseColor('#ffffff');
    template.fontSize = 20;

    Object.assign(template, overrides);

    return template;
}

function createBaseTextElement<
    Kind extends OneOfTextDataNodes['kind'],
    TextElement = Extract<OneOfTextDataNodes, { kind: Kind }>
>(
    kind: Kind,
    defaultOverrides: Partial<TextElement>,
    fontStyle?: IFontFamilyStyle
): OneOfTextDataNodes {
    const template: Partial<OneOfTextDataNodes> = createBaseElement(
        kind,
        defaultOverrides
    ) as ITextElementDataNode;
    template.characterSpacing = 0;
    template.lineHeight = 1.3;
    template.horizontalAlignment = 'center';
    template.verticalAlignment = 'middle';
    template.textOverflow = 'shrink';
    template.underline = false;
    template.strikethrough = false;
    template.uppercase = false;

    if (!template.font && fontStyle) {
        template.font = {
            id: fontStyle.id,
            src: fontStyle.fontUrl,
            weight: fontStyle.weight,
            style: fontStyle.italic ? 'italic' : 'normal',
            fontFamilyId: fontStyle.fontFamilyId!
        };
    }

    template.characterStyles = new Map<string, Partial<ICharacterProperties>>();
    template.__styleHashMap = new Map<string, string>();
    template.__deletedStyleHashMap = new Map<string, string>();

    return template as ITextElementDataNode;
}

export function createImageElementTemplate(
    overrides: Partial<IElementDataNode>
): IImageElementDataNode {
    const template = createBaseElement(ElementKind.Image, overrides);
    template.imageSettings = cloneDeep(DEFAULT_IMAGE_SETTINGS);
    return template;
}

export function createVideoElementTemplate(
    overrides: Partial<IElementDataNode>
): IVideoElementDataNode {
    const template = createBaseElement(ElementKind.Video, overrides);
    template.videoSettings = cloneDeep(DEFAULT_VIDEO_SETTINGS);
    return template;
}
