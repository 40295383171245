import { StreamingHttpClient } from '../streaming-http-client';
import { parseManifest } from './manifest-parser';
import { StreamingManifest, StreamingRepresentation } from './manifest.model';

export class ManifestManager {
    private _manifest: StreamingManifest;
    private _currentRepresentationIndex = 0;

    constructor(private _httpClient: StreamingHttpClient) {}

    async init(manifestUrl: string): Promise<void> {
        try {
            const manifestResponse = await this._httpClient.fetchText(manifestUrl);
            this._manifest = parseManifest(manifestResponse);
            console.debug('[ManifestManager] manifest parsed');
        } catch (error) {
            console.error('Error fetching and parsing manifest', error);
        }
    }

    getTotalDuration(): number {
        return this._manifest.totalDuration;
    }

    getTotalChunks(): number {
        return this._manifest.totalChunks;
    }

    getManifest(): StreamingManifest {
        return this._manifest;
    }

    getCurrentRepresentation(): StreamingRepresentation {
        const manifest = this.getManifest();
        return manifest.videoRepresentations[this._currentRepresentationIndex];
    }

    verifyCurrentRepresentation(bps: number): void {
        const previousIndex = this._currentRepresentationIndex;
        this._currentRepresentationIndex = 0; // reset to the lowest bitrate
        // find a representation that matches our current bandwidth, starting at the best one
        const videoRepresentations = this.getManifest().videoRepresentations;
        for (let i = videoRepresentations.length - 1; i > 0; i--) {
            if (bps > videoRepresentations[i].bandwidth) {
                this._currentRepresentationIndex = i;
                break;
            }
        }

        if (previousIndex !== this._currentRepresentationIndex) {
            console.log(`Switching to representation[${this.getCurrentRepresentation().name}`);
        }
    }
}
