import { isGroupDataNode } from '@creative/nodes/helpers';
import { OneOfDataNodes } from '@domain/nodes';
import { OneOfElementPropertyKeys } from '@domain/property';
import { cloneDeep } from '@studio/utils/clone';
import { deepEqual } from '@studio/utils/utils';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

export type KeyOfPropertyElementsType = {
    [key in OneOfElementPropertyKeys]: any;
};

export enum ElementChangeType {
    /** Adds to snapshot history */
    Instant = 0,
    /** Skips adding to snapshot history */
    Skip = 1,
    /** Always add to snapshot history, even if element has not changed */
    Force = 2,
    /** Debounce value change */
    Burst = 3
}

export interface IElementChange {
    element: OneOfDataNodes | undefined;
    changes: KeyOfPropertyElementsType;
}

interface IElementsChange {
    elements: ReadonlyArray<Omit<IElementChange, 'type'>>;
    type: ElementChangeType;
}

export class ElementChanges {
    private _elementChange$ = new Subject<IElementChange>();
    change$ = this._elementChange$.asObservable();
    private _immediateElementChange$ = new Subject<IElementChange>();
    immediateChange$ = this._immediateElementChange$.asObservable();
    private _elementsChange$ = new Subject<IElementsChange>();
    changes$ = this._elementsChange$.asObservable();

    private _valuesChange$ = new Subject<{
        element: OneOfDataNodes;
        values: Partial<OneOfDataNodes>;
        type: ElementChangeType;
    }>();
    private dataElementMap = new Map<string, OneOfDataNodes>();
    private rafId: number;
    private elementChangesMap = new Map<string, IElementChange>();
    private suspended: boolean;
    private bufferTimeout = 10;

    constructor() {
        this._valuesChange$
            .pipe(filter(() => !this.suspended))
            .subscribe(({ element, values, type }) => {
                const keys = Object.keys(values);
                let elementCached = true;

                if (!keys.length && type !== ElementChangeType.Force) {
                    return;
                }

                if (!this.dataElementMap.has(element.id)) {
                    this.dataElementMap.set(element.id, cloneDeep(element));
                    elementCached = false;
                }

                const dataElement = this.dataElementMap.get(element.id)!;

                const changedKeys = keys.filter(key => !deepEqual(dataElement[key], values[key]));

                if (!elementCached || changedKeys.length || type === ElementChangeType.Force) {
                    if (!this.elementChangesMap.has(element.id)) {
                        this.elementChangesMap.set(element.id, {
                            element,
                            changes: {
                                ...values
                            } as KeyOfPropertyElementsType
                        });
                    }
                    const changedElement = this.elementChangesMap.get(element.id)!;

                    changedKeys.forEach(
                        key => (changedElement.changes[key] = dataElement[key] = cloneDeep(values[key]))
                    );

                    this._immediateElementChange$.next({ element, changes: changedElement.changes });
                    this.skipToNextBuffer();
                    this.buffer(() => {
                        const aggregatedElements: Omit<IElementChange, 'type'>[] = [];
                        this.elementChangesMap.forEach(value => {
                            aggregatedElements.push(value);
                            this._elementChange$.next({
                                element: value.element,
                                changes: value.changes
                            });
                        });

                        this._elementsChange$.next({ elements: aggregatedElements, type });

                        this.elementChangesMap.forEach(value => {
                            value.changes = {} as KeyOfPropertyElementsType;
                        });
                    });
                }
            });
    }

    private buffer(callback: () => void): void {
        let start: number;
        const framebuffer = (timestamp: number): void => {
            if (!start && timestamp) {
                start = timestamp;
            }

            if (timestamp - start > this.bufferTimeout) {
                callback();
                this.elementChangesMap.clear();
                cancelAnimationFrame(this.rafId);
            } else {
                this.rafId = requestAnimationFrame(framebuffer);
            }
        };
        this.rafId = requestAnimationFrame(framebuffer);
    }

    private skipToNextBuffer(): void {
        if (this.rafId) {
            cancelAnimationFrame(this.rafId);
        }
    }

    change<Element extends OneOfDataNodes>(
        element: OneOfDataNodes,
        values: Partial<Element>,
        type: ElementChangeType = ElementChangeType.Instant
    ): void {
        this._valuesChange$.next({ element, values: values || {}, type });
    }

    clear(): void {
        this.dataElementMap.clear();
    }

    /** Suspend the service until restored */
    suspend(): void {
        this.suspended = true;
    }

    /** Restores the service from suspension */
    restore(): void {
        this.suspended = false;
    }
}

interface IFilterOptions {
    /**
     * Speficic element to filter for. Any other elements
     * will stop emission.
     */
    explicitElement: OneOfDataNodes;

    /**
     * Specific properties to filter for. Any other properties
     * will stop emission.
     */
    explicitProperties: OneOfElementPropertyKeys[];
}

function explicitAnyChangesFilter(
    changes: KeyOfPropertyElementsType,
    properties: OneOfElementPropertyKeys[]
): boolean {
    for (const property of properties) {
        if (property in changes) {
            return true;
        }
    }
    return false;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function changeFilter(options?: Partial<IFilterOptions>) {
    const { explicitElement, explicitProperties } = options || {};
    const isSameNodeOrDescendant = (
        node: OneOfDataNodes,
        _explicitElement: OneOfDataNodes
    ): boolean => {
        if (node.id === _explicitElement.id) {
            return true;
        }

        if (isGroupDataNode(_explicitElement)) {
            // Find closest descendant
            return !!_explicitElement.findNodeById_m(node.id);
        }

        return false;
    };

    return function (source: Observable<IElementChange>): Observable<IElementChange> {
        return source.pipe(
            filter(({ element }) =>
                explicitElement && element ? isSameNodeOrDescendant(element, explicitElement) : true
            ),
            filter(({ changes }) =>
                explicitProperties ? explicitAnyChangesFilter(changes, explicitProperties) : true
            )
        );
    };
}

export function isElementChange(value: unknown): value is IElementChange {
    if (value && typeof value === 'object') {
        return 'element' in value && 'changes' in value;
    }

    return false;
}
