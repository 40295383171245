import { ManifestManager } from './manifest/manifest-manager';
import { StreamingHttpClient } from './streaming-http-client';

const MIME_CODEC = 'video/mp4; codecs="avc1.64001e"';

export class VideoStreamer {
    private readonly _httpClient: StreamingHttpClient;
    private readonly _videoElement: HTMLVideoElement;
    private readonly _manifestUrl: string;
    private _manifestManager: ManifestManager;
    private _mediaSource: MediaSource;
    private _sourceBuffer: SourceBuffer;
    private _videoSrc: string;

    constructor(videoElement: HTMLVideoElement, manifestUrl: string) {
        this._videoElement = videoElement;
        this._manifestUrl = manifestUrl;
        this._httpClient = new StreamingHttpClient();
        this._manifestManager = new ManifestManager(this._httpClient);

        this._validateStreamingCapabilities();
    }

    /**
     * Initializes the media source and returns the new video source.
     * Starts the stream when the source is opened.
     */
    async init(): Promise<string> {
        console.debug('[VideoStreamer] init');
        await this._manifestManager.init(this._manifestUrl);
        this._mediaSource = new MediaSource();

        this._mediaSource.addEventListener('error', error => {
            console.error('MediaSource error:', error);
        });

        this._mediaSource.addEventListener('sourceopen', async () => {
            this._mediaSource.duration = this._manifestManager.getTotalDuration();
            console.debug('[VideoStreamer] MediaSource open, duration: ', this._mediaSource.duration);

            // todo verify what codec we need
            this._sourceBuffer = this._mediaSource.addSourceBuffer(MIME_CODEC);
            try {
                await this.startStream();
            } catch (err: unknown) {
                console.error('Error starting video stream', err);
            }
        });

        this._videoSrc = URL.createObjectURL(this._mediaSource);
        return this._videoSrc;
    }

    /**
     * Starts the chunk fetching and buffer process.
     * @private
     */
    private async startStream(): Promise<void> {
        const baseUrl = this._getBaseUrl();
        await this._loadInitialChunk(baseUrl);
        await this._loadChunks(baseUrl);
    }

    /**
     * Loads the initialization chunk and appends it to the source buffer.
     * The initialization chunk contains metadata required to initialize the video decoder.
     * @param baseUrl
     * @private
     */
    private async _loadInitialChunk(baseUrl: string): Promise<void> {
        const initializationUrl = this._getInitUrl(baseUrl);
        console.debug('[VideoStreamer] loading initial chunk');
        await this._httpClient.fetchChunkAndAppend(initializationUrl, this._sourceBuffer);
    }

    private async _loadChunks(baseUrl: string): Promise<void> {
        const totalChunks = this._manifestManager.getTotalChunks();
        let chunkIndex = 0;
        console.debug(`[VideoStreamer] loading ${totalChunks} chunk(s)`);

        while (chunkIndex < totalChunks) {
            const chunkUrl = this._getChunkUrl(baseUrl, chunkIndex);
            console.debug(`[VideoStreamer] Next chunk: ${chunkIndex + 1}/${totalChunks}`);
            try {
                if (this._sourceBuffer.updating) {
                    console.error('sourceBuffer should already been updated.');
                }
                if (this._videoElement.error) {
                    console.error(this._videoElement.error);
                }

                await this._httpClient.fetchChunkAndAppend(chunkUrl, this._sourceBuffer);
                this._manifestManager.verifyCurrentRepresentation(this._httpClient.bps);
                chunkIndex++;
            } catch (err: unknown) {
                console.error('Error fetching and appending chunk', err);
                break;
            }
        }
        console.debug(`[VideoStreamer] Fetched all chunks: ${totalChunks}`);
        this._mediaSource.endOfStream();
    }

    /**
     * Returns the base URL inferred from the manifest's location.
     * http://blob.storage.net/123/guid.mpd -> http://blob.storage.net/123
     * @private
     */
    private _getBaseUrl(): string {
        return this._manifestUrl.substring(0, this._manifestUrl.lastIndexOf('/'));
    }

    private _getChunkUrl(baseUrl: string, chunkIndex: number): string {
        const { chunkUrls } = this._manifestManager.getCurrentRepresentation();
        const chunkUrl = chunkUrls[chunkIndex];
        return `${baseUrl}/${chunkUrl}`;
    }

    private _getInitUrl(baseUrl: string): string {
        const { initUrl } = this._manifestManager.getCurrentRepresentation();

        return `${baseUrl}/${initUrl}`;
    }

    private _validateStreamingCapabilities(): void {
        const isStreamingSupported = this._isSupported(MIME_CODEC);
        if (!isStreamingSupported) {
            throw new Error(`Streaming '${MIME_CODEC}' is not supported`);
        }

        const isManifestUrlValid = this._manifestUrl.endsWith('.mpd');
        if (!isManifestUrlValid) {
            throw new Error(`Invalid DASH manifest URL: ${this._manifestUrl}`);
        }
    }

    private _isSupported(mimeCodec: string): boolean {
        if ('MediaSource' in window && MediaSource.isTypeSupported(mimeCodec)) {
            return true;
        }
        return false;
    }
}
