import {
    INetwork,
    IParameter,
    IRedirect,
    OverrideParameterStrategy,
    ParameterValue
} from '@domain/ad/redirect';
import { sanitizeUrl } from '@studio/utils/sanitizer';
import { getDomain, isEncodedUrl } from '@studio/utils/url';

const TARGET_URL_MACRO = '[target_url]';

const defaultNetwork: INetwork = {
    match: undefined,
    separator: '?',
    delimiter: '&',
    assigner: '=',
    fragment: '#',
    deeplinkParam: undefined,
    deeplinkEncode: true
};

// TODO Only provide overrides from default, remove name
const networks: INetwork[] = [
    // Adform preview
    applyDefaults({
        match: 'adform.com/Banner/BannerClickTest.aspx',
        delimiter: ';',
        deeplinkParam: 're'
    }),
    // Adform
    applyDefaults({ match: '.adform.net/C/', delimiter: ';', deeplinkParam: 'cpdir' }),
    // Adition
    applyDefaults({ match: '.adition.com/redi', deeplinkParam: 'clickurl', allowEmptyDeeplink: true }),
    // Advalidation
    applyDefaults({
        match: 'advalidation.net/e/creatives/test_creative_click',
        separator: '',
        delimiter: '',
        assigner: '',
        deeplinkSplit: '/?',
        fixDeeplinkEncoding: true
    }),
    // Agkn
    applyDefaults({ match: '.agkn.com', deeplinkParam: 'l1', deeplinkLastParam: true }),
    // Appnexus 1
    applyDefaults({
        match: '.adnxs',
        separator: '%3F',
        delimiter: '/',
        assigner: '%3D',
        deeplinkParam: 'clickenc',
        deeplinkLastParam: true
    }),
    // Appnexus 2
    applyDefaults({
        match: '.adnxs',
        delimiter: '/',
        deeplinkParam: 'clickenc',
        deeplinkLastParam: true,
        skipUndefinedParameterValues: true
    }),
    // Bidtheatre
    applyDefaults({ match: 'adsby.bidtheatre', deeplinkParam: 'url', allowEmptyDeeplink: true }),
    // Data XU
    applyDefaults({ match: 'w55c.net', deeplinkParam: 'rurl' }),
    // DCM preview
    applyDefaults({ match: 'adspreview.googleusercontent.com', deeplinkParam: '_dc_redir' }),
    // DCM
    applyDefaults({ match: '.doubleclick.net', deeplinkParam: 'adurl', fixDeeplinkEncoding: true }),

    // Verizon Media DSP (They have two redirect for its macro)
    applyDefaults({ match: '.onemobile.yahoo.com', deeplinkParam: 'rd' }),
    applyDefaults({ match: '.ybp.yahoo.com', deeplinkSplit: '/', assigner: '' }),

    // Verizon Media Ad Server
    applyDefaults({ match: '.atwola.com/adlink', delimiter: ';' }),

    // DCM bidmanager
    applyDefaults({ match: 'bid.g.doubleclick.net', deeplinkParam: 'r1', fixDeeplinkEncoding: true }),
    // Demdex
    applyDefaults({ match: '.demdex.net', deeplinkParam: 'd_rd' }),
    // Google Ads (Maybe not needed?)
    // applyDefaults({ match: 'googleads.g.doubleclick.net', separator: '%3F', delimiter: '%26', assigner: '%3D', deeplinkParam: 'adurl', deeplinkLastParam: true, fixDeeplinkEncoding: true }),
    // Google Ads 2
    applyDefaults({ match: '.googleadservices.com', deeplinkParam: 'adurl', deeplinkLastParam: true }),
    // Assumed to be Google Ad Manager (there is a encoding problem)
    applyDefaults({
        match: '.googlesyndication.com/pcs/click',
        separator: '?',
        delimiter: '&',
        assigner: '=',
        deeplinkParam: 'adurl',
        deeplinkLastParam: true,
        fixDeeplinkEncoding: true,
        allowEmptyDeeplink: false
    }),
    // Tune / HasOffers
    applyDefaults({ match: 'go2cloud.org', deeplinkParam: 'url' }),
    // Platform161
    applyDefaults({
        match: ['.creative-serving.org', '.creative-serving.com'],
        deeplinkSplit: '/',
        deeplinkParam: '1',
        assigner: '',
        separator: '?',
        delimiter: '/',
        allowEmptyDeeplink: true
    }),
    // Krux/Salesforce
    applyDefaults({ match: '.krxd.net', deeplinkParam: 'clk' }),
    // Atlas
    applyDefaults({ match: 'ad.atdmt.com', deeplinkParam: 'h', delimiter: ';' }),
    // Adnuntius
    applyDefaults({ match: 'delivery.adnuntius.com', deeplinkParam: 'r' }),
    // Adroll
    applyDefaults({ match: 'd.adroll.com', deeplinkParam: 'clickurl' }),
    // Exactag
    applyDefaults({ match: 'm.exactag.com', deeplinkParam: 'url' }),
    // Readpeak
    applyDefaults({ match: 'app.readpeak.com', deeplinkParam: 'tu' }),
    // Admoove
    applyDefaults({ match: 're.admoove.se', deeplinkParam: 'link' }),
    // Pubmatic
    applyDefaults({
        match: '.pubmatic.com',
        delimiter: '_',
        deeplinkParam: 'url',
        deeplinkLastParam: true
    }),
    // Delta
    applyDefaults({
        match: ['de17a.com/api/click', 'de17a.com/api/dev-click'],
        separator: ';',
        delimiter: ';',
        deeplinkParam: 'ec'
    }),
    // Delta DSP
    applyDefaults({ match: 'de17a.com/click', deeplinkParam: 'url' }),
    // Tradedesk
    applyDefaults({ match: '.adsrvr.org', deeplinkParam: 'r' }),
    // Rubiconproject
    applyDefaults({
        match: '.rubiconproject.com',
        deeplinkParam: '/',
        deeplinkSplit: '/',
        assigner: '',
        deeplinkEncode: false
    }),
    applyDefaults({ match: '.rubiconproject.com', deeplinkParam: 'url' }),
    // RTB House
    applyDefaults({ match: '.creativecdn.com/clicks', deeplinkParam: 'url' }),
    // Adobe
    applyDefaults({ match: '.everesttech.net', deeplinkParam: 'redir' }),
    // Quantcast
    applyDefaults({ match: '.quantserve.com', deeplinkParam: 'redirecturl2' }),
    applyDefaults({ match: '.quantserve.com', deeplinkParam: 'redirecturl3' }),
    // Rocketfuel / Zeta platform sample: https://a.rfihub.com/acs/b/c3Q9xOC43MS4x/n/
    applyDefaults({
        match: '.rfihub.com',
        deeplinkSplit: '/',
        deeplinkParam: 'n',
        assigner: '',
        separator: 'acs/',
        delimiter: '/',
        deeplinkLastParam: true,
        fixDeeplinkEncoding: true
    }),
    // Ecom Access / Income Access
    applyDefaults({ match: '.adsrv.eacdn.com', deeplinkParam: 'asclurl' }),
    // Adman
    applyDefaults({
        match: 'adman.gr/fwd/',
        deeplinkParam: '/',
        deeplinkSplit: '/',
        assigner: '',
        delimiter: '/'
    }),
    // Sage + Archer
    applyDefaults({ match: '.mobpro.com/t/', deeplinkParam: 'mptu', deeplinkEncode: false }),
    // Adhese
    applyDefaults({
        match: 'mediafin.adhese',
        deeplinkParam: '/https',
        deeplinkSplit: '/',
        assigner: '',
        delimiter: '/',
        deeplinkEncode: false
    }),
    applyDefaults({
        match: 'ads-mediafin.adhese',
        deeplinkParam: '',
        separator: 'ads-mediafin.adhese.com',
        delimiter: '',
        deeplinkLastParam: true,
        assigner: '',
        deeplinkEncode: false,
        deeplinkSplit: '/UR'
    }),
    // SUP-5096
    applyDefaults({
        match: 'ads-igmn.adhese.com',
        deeplinkParam: '',
        separator: 'ads-igmn.adhese.com',
        delimiter: '',
        deeplinkLastParam: true,
        assigner: '',
        deeplinkEncode: false,
        deeplinkSplit: '/UR'
    }),
    // Tradedoubler
    applyDefaults({ match: 'clk.tradedoubler.com', deeplinkParam: 'url' }),
    // Sportradar
    applyDefaults({
        match: 'sportradarserving.com/click',
        deeplinkLastParam: true,
        deeplinkParam: '=http',
        deeplinkSplit: '=',
        delimiter: '',
        assigner: ''
    }),
    // Pulsepoint
    applyDefaults({
        match: '.contextweb.com',
        delimiter: '~',
        deeplinkParam: 'u',
        allowEmptyDeeplink: false
    })
];

const uriParameterPrefixes: string[] = [
    'https://',
    'http://',
    'https%3A%2F%2F',
    'http%3A%2F%2F',
    '//',
    '%2F%2F'
];

export class Redirect implements IRedirect {
    prevent = false;
    private _redirectUrl: string;
    private _network: INetwork;
    private _parameters: IParameter[] = [];

    /**
     * List of domains that could be replaced by a new deeplink if they are the deeplink
     */
    private _deeplinkOverrideWhitelist = ['*.bannerflow.*'];

    private _fragmentString: string;

    constructor(
        redirectUrl: string,
        public parent?: Redirect
    ) {
        if (!parent) {
            redirectUrl = this._fixUrlEncoding(redirectUrl);
        }
        this.parse(redirectUrl);
    }

    /**
     * Add domain that could be replaced by a new deeplink if they are the deeplink url.
     * Pass in a pattern like *.bannerflow.* or a url like http://bannerflow.com/whatever to whitelist all overrides on that domain/subdomain.
     * You can also pass an array of urls or patters
     */
    whitelistDomains(urls?: string | string[]): string[] {
        if (urls) {
            // Turn into array if not already an array
            if (!Array.isArray(urls)) {
                urls = [urls];
            }

            // NOSONAR
            for (let i = 0; i < urls.length; i++) {
                const pattern = urls[i].indexOf('*') > -1 ? urls[i] : getWildcardDomain(urls[i]);
                if (pattern && this._deeplinkOverrideWhitelist.indexOf(pattern) === -1) {
                    this._deeplinkOverrideWhitelist.push(pattern);
                }
            }
        }

        return this._deeplinkOverrideWhitelist;
    }

    preventRedirect(): void {
        this.prevent = true;
    }

    /**
     * Fixes the encoding of the Redirect URL
     */
    private _fixUrlEncoding(url: string): string {
        const decodeUrl = (str: string): string => {
            let decodedString = decodeURIComponent(str);
            while (decodedString !== str) {
                str = decodedString;
                try {
                    decodedString = decodeURIComponent(str);
                } catch {
                    break;
                }
            }
            return str;
        };

        const redirects: string[] = [];
        const decodedUrl = decodeUrl(url);

        const networksToFix = networks.filter(network => network.fixDeeplinkEncoding);

        const shouldFixEncoding = networksToFix.some(
            network => this._indexOfNetworkMatch(network, decodedUrl) > -1
        );

        if (!shouldFixEncoding) {
            return url;
        }

        const parseDecodeURL = (): void => {
            const tempUrl = redirects.length
                ? decodedUrl.split(redirects[redirects.length - 1])[1] // Split in last redirect
                : decodedUrl;

            const network = this._getNetworkForUrl(tempUrl, true);

            // when network assigner is falsy we cannot identify the correct deeplink parameter value ends
            // in that case we parse the next url that starts with https://
            const deeplinkParam = network?.assigner ? network?.deeplinkParam || '' : '';
            const deeplinkAssigner = deeplinkParam ? network?.assigner || '' : '';
            const regex = new RegExp(`${deeplinkParam}${deeplinkAssigner}https?://`);
            const nextRedirectIndex = tempUrl.slice(1).search(regex); // /https?:\/\//);
            if (nextRedirectIndex === -1) {
                redirects.push(tempUrl);
                return;
            }
            const urlPart = tempUrl.substring(
                0,
                nextRedirectIndex + 1 + deeplinkParam.length + deeplinkAssigner.length
            );
            redirects.push(urlPart);
            parseDecodeURL();
        };
        parseDecodeURL();

        const fixedUrl = redirects[0];
        let subUrl = '';
        for (let i = redirects.length - 1; i > 0; i--) {
            subUrl = encodeURIComponent(redirects[i] + subUrl);
        }

        return fixedUrl + subUrl;
    }

    /**
     * Create a new RedirectUrl chain based on a url (encoded or not)
     * @param inputUrl
     */
    parse(inputUrl = ''): Redirect {
        this._redirectUrl = sanitizeUrl(this._decodeEncodedUrl(inputUrl));
        this._parameters = [];

        // Detect network
        this._network = this._detectNetwork(this._redirectUrl);
        const deeplinkSplit = this._network.deeplinkSplit;

        // Repair url if needed
        // Sample: http://adclick.g.doubleclick.net/pcs/click%3Fxai%3DAKAOjstH...
        if (this._network.fixDeeplinkEncoding && this._hasEncodedSeparatorInPath(this._redirectUrl)) {
            this._redirectUrl = decodeURIComponent(this._redirectUrl);
        }

        // If last character is '=' AND the network is unknown apply our macro. Open trailing "[deeplinkname]=" is a standard for deeplinks
        if (
            (inputUrl.charAt(inputUrl.length - 1) === '=' ||
                inputUrl.indexOf('%3D') + 3 === inputUrl.length) &&
            !this.containsTargetUrlMacro_m(inputUrl) &&
            !this._network.deeplinkParam
        ) {
            this._redirectUrl += TARGET_URL_MACRO;
        }

        // Parse parameters
        if (deeplinkSplit && this._redirectUrl.split(deeplinkSplit).length) {
            const lastIndex = this._redirectUrl.lastIndexOf(deeplinkSplit);
            const redirectUrl = this._redirectUrl.substring(0, lastIndex);
            const value = this._redirectUrl.substring(lastIndex + deeplinkSplit.length);
            this._redirectUrl = redirectUrl;

            this._parameters.push({
                name: deeplinkSplit,
                value: new Redirect(value, this)
            });
        } else if (this._redirectUrl.indexOf(this._network.separator) > -1) {
            // Handle fragment/hashtag references in URL
            if (this._network.fragment && this._redirectUrl.indexOf(this._network.fragment) > -1) {
                const fragmentPair = this._redirectUrl.split(this._network.fragment);
                this._redirectUrl = fragmentPair[0];
                this._fragmentString = fragmentPair[1];
            }

            // Handle query parameters
            const separatorPairs = this._redirectUrl.split(this._network.separator);
            this._redirectUrl = separatorPairs[0];

            let strParameters: string | undefined;

            if (separatorPairs.length === 1) {
                // No parameters
                strParameters = undefined;
            } else if (separatorPairs.length === 2) {
                // Has parameters, properly formated
                strParameters = separatorPairs[1];
            } else if (separatorPairs.length > 2) {
                // Has too many separators, probably faulty encoding used on deeplinking parameter
                strParameters = separatorPairs.slice(1).join(this._network.separator);
            }

            let keyValues: string[];
            if (strParameters !== undefined) {
                if (this._network.deeplinkLastParam && !this._network.fixDeeplinkEncoding) {
                    // If deeplink param is always placed last, parse parameters a bit differently in order to support incorrectly encoded deeplink values (would otherwise affect parameter parsing)
                    const param =
                        this._network.delimiter + this._network.deeplinkParam + this._network.assigner;

                    const strParameterSplit = strParameters.split(param);
                    const key = strParameterSplit.shift()!;
                    let value = strParameterSplit.join(param);
                    keyValues = key.split(this._network.delimiter);

                    if (key) {
                        if (value) {
                            // Fix encoding if it needs fixing
                            value = this._fixRedirectParameterEncoding(value);
                        }
                        // Apply target url macro
                        else if (
                            strParameters.indexOf(param) ===
                            strParameters.length - param.length - 1
                        ) {
                            value = TARGET_URL_MACRO;
                        }

                        // Only add if value is defined to avoid adding "empty" deeplink parameter
                        if (value) {
                            keyValues.push(
                                this._network.deeplinkParam + this._network.assigner + value
                            );
                        }
                    }
                } else {
                    // Network's deeplink can be placed in any order, parse parameters without consideration of incorrectly encoded deeplinks
                    keyValues = strParameters.split(this._network.delimiter);
                }

                // NOSONAR
                for (let i = 0; i < keyValues.length; i++) {
                    const keyValue = keyValues[i];
                    if (keyValue) {
                        const name = keyValue.split(this._network.assigner)[0];
                        const value =
                            keyValue.indexOf(this._network.assigner) > -1
                                ? keyValue
                                      .split(this._network.assigner)
                                      .slice(1)
                                      .join(this._network.assigner)
                                : undefined;
                        let parameterValue: string | undefined | Redirect = value;
                        // The value is or should be a URL
                        if (
                            name === this._network.deeplinkParam ||
                            (this._network.deeplinkParam === undefined &&
                                this._isUriParameterValue(value)) ||
                            this._isTargetUrlMacro(value)
                        ) {
                            parameterValue = new Redirect(value!, this);
                            parameterValue.whitelistDomains(this._deeplinkOverrideWhitelist);
                        }

                        this._parameters.push({
                            name: name,
                            value: parameterValue
                        });
                    }
                }
            }
        }

        return this;
    }

    /**
     * Turn the RedirectUrl chain into a string that could be used as click link
     */
    toString(): string {
        let url = this._replaceTargetUrlMacro(this._redirectUrl);
        if (this._network.deeplinkSplit) {
            return url + this._getParametersAsString();
        }
        if (this._parameters.length > 0) {
            url += this._network.separator + this._getParametersAsString();
        }

        if (this._fragmentString) {
            url += this._network.fragment + this._fragmentString;
        }

        return url;
    }

    /**
     * Set a parameter value (most often query string) on this RedirectUrl
     * @param name
     * @param value
     * @param encodeValue Should be used when value is not already encoded (Adscripts)
     */
    setParameter(name: string, value: ParameterValue, encodeValue = false): IParameter {
        // Encode value
        if (typeof value === 'string' && encodeValue) {
            value = encodeURIComponent(value);
        }
        const existingParams = this._parameters.filter(param => param.name === name);
        if (existingParams.length > 0) {
            existingParams[0].value = value;
            return existingParams[0];
        } else {
            this._parameters.push({
                name,
                value
            });
        }

        return this._parameters[this._parameters.length - 1];
    }

    /**
     * Set the parameter only if it's missing
     */
    setParameterIfMissing(
        name: string,
        value: ParameterValue,
        encodeValue = false
    ): undefined | IParameter {
        if (!this.hasParameter(name) && value !== undefined) {
            return this.setParameter(name, value, encodeValue);
        }
    }

    /**
     * Get a parameter based on name
     * @param name
     */
    getParameter(name: ParameterValue): ParameterValue {
        return this._parameters.find(param => param.name === name)?.value;
    }

    /**
     * Clear all parameters but ignore any RedirectUrls to keep chain intact
     */
    private _clearParameters(): IParameter[] {
        this._parameters = this._parameters.filter(param => !this._isRedirect(param.value));
        return this._parameters;
    }

    /**
     * Remove a parameter with a certain name
     * @param name
     */
    removeParameter(name: string): void {
        const existingParams = this._parameters.filter(param => param.name === name);
        while (existingParams.length > 0) {
            const param = existingParams.pop()!;
            const index = this._parameters.indexOf(param);
            if (index > -1) {
                this._parameters.splice(index, 1);
            }
        }
    }

    /**
     * Check if parameter exists
     * @param name
     */
    hasParameter(name: string): boolean {
        return this._parameters.some(param => param.name === name);
    }

    /**
     * Convert all parameters to string. Syntax differs between networks
     */
    private _getParametersAsString(): string {
        if (this._parameters.length > 0) {
            let query = '';
            for (let i = 0; i < this._parameters.length; i++) {
                const param = this._parameters[i];
                const value = param.value;
                query +=
                    value === undefined && this._network.skipUndefinedParameterValues
                        ? param.name
                        : (i > 0 ? this._network.delimiter : '') + param.name + this._network.assigner;

                if (this._isRedirect(value)) {
                    const encode = this._network.deeplinkEncode !== false;
                    query += encode ? encodeURIComponent(value.toString()) : value.toString();
                } else {
                    query += value !== undefined ? this._replaceTargetUrlMacro(value) : '';
                }
            }
            return query;
        }
        return '';
    }

    /**
     * Get next RedirectUrl
     */
    private _getInnerRedirect(): Redirect | undefined {
        const deeplinkParam = this._parameters.filter(param => this._isRedirect(param.value)).pop();
        if (deeplinkParam) {
            return deeplinkParam.value as Redirect;
        }
        return undefined;
    }

    /**
     * Get last RedirectURL in chain.
     */
    getFinalRedirect(): Redirect {
        let redirect: Redirect = this;
        let r: Redirect | undefined;
        while ((r = redirect._getInnerRedirect())) {
            redirect = r;
        }
        return redirect;
    }

    /**
     * Set deeplink.
     * @param targetUrl
     * @param allowedOverrides
     */
    setDeeplink(
        targetUrl?: string,
        whitelist?: string | string[],
        overrideParameterStrategy?: OverrideParameterStrategy
    ): Redirect | undefined {
        if (targetUrl) {
            const final = this.getFinalRedirect();
            const target = new Redirect(targetUrl, this);
            const redirectUrl = final._redirectUrl;
            const deeplinkParam = final._network.deeplinkParam;

            // Make sure domains are whitelisted
            this.whitelistDomains(whitelist);
            final.whitelistDomains(whitelist);
            target.whitelistDomains(whitelist);

            // This network supports deeplinks but doesn't have one applied
            if (deeplinkParam) {
                if (this.setParameterIfMissing(deeplinkParam, target, false)) {
                    return target; // Deeplink was set;
                }
            }

            // If this redirect is empty, a macro or is from a whitelisted domain
            if (this._isOverrideAllowed(redirectUrl, this._deeplinkOverrideWhitelist)) {
                // Add all parameters to target
                this._mergeParameters(final, target, overrideParameterStrategy);

                // Override final step
                return final.parse(target.toString());
            }

            // Contains a macro that couldn't get parsed to a redirect
            else if (this.containsTargetUrlMacro_m(redirectUrl)) {
                final._redirectUrl = this._replaceTargetUrlMacro(
                    redirectUrl,
                    encodeURIComponent(targetUrl)
                );
                return final;
            } else {
                console.warn(
                    `Could not override deeplink, add the domain of "${redirectUrl}" to whitelisted domain overrides.`
                );
            }
        }
    }

    /**
     * In some cases we need to force use of deeplinks to prevent users getting stuck in the redirect
     * @returns
     */
    deeplinkRequired_m(): boolean {
        const final = this.getFinalRedirect();
        const allowEmpty = final.parent?._network.allowEmptyDeeplink;

        // Empty deeplinks must be specifically allowed to be empty
        return final.containsTargetUrlMacro_m() || (final.isEmpty_m() && !allowEmpty);
    }

    isEmpty_m(): boolean {
        return !this._redirectUrl || this._isTargetUrlMacro();
    }

    /**
     * Test if this network is uknown or not.
     * Default networks get default behaviour while should have specific rules
     */
    // isDefaultNetwork(): boolean {
    //     return this.network.match === undefined;
    // }

    /**
     * Check if url IS the bannerflow macro. I.E. it starts with the macro
     * @param url
     */
    private _isTargetUrlMacro(url: string = this._redirectUrl): boolean {
        return (
            !!url &&
            (url.indexOf(TARGET_URL_MACRO) === 0 ||
                url.indexOf(encodeURIComponent(TARGET_URL_MACRO)) === 0)
        );
    }

    /**
     * Check if url constains the bannerflow macro.
     * @param url
     */
    containsTargetUrlMacro_m(url: string = this._redirectUrl): boolean {
        return (
            !!url &&
            (url.indexOf(TARGET_URL_MACRO) > -1 ||
                url.indexOf(encodeURIComponent(TARGET_URL_MACRO)) > -1)
        );
    }

    /**
     * Replace macro with a url
     * @param url
     * @param replaceWith
     */
    private _replaceTargetUrlMacro(url: string, replaceWith = ''): string {
        if (this.containsTargetUrlMacro_m(url)) {
            return url.replace(TARGET_URL_MACRO, replaceWith);
        }
        return url;
    }

    /**
     * To prevent us from overwriting deeplinks reffering to trackers,
     * and thus messing up tracking, we need to know that this url is safe to override.
     * For example, dont override tracker.dcm.com but override *.bannerflow.com
     * @param url The url we want to test if we can override
     * @param allowedOverrides An array of allowed domain, typically of the clients own domain / landing pages
     */
    private _isOverrideAllowed(redirectUrl: string, allowedOverrides: string[] = []): boolean {
        if (!redirectUrl || this._isTargetUrlMacro(redirectUrl)) {
            return true;
        }
        if (typeof URL !== 'undefined' && allowedOverrides.length) {
            let domain: string;
            try {
                domain = new URL(redirectUrl).hostname;
            } catch {
                const a = document.createElement('a');
                a.href = redirectUrl;
                domain = a.hostname;
            }

            // Find at least one matching url pattern
            return allowedOverrides.some(override => getUrlRegex(override).test(domain));
        }
        return true;
    }

    /**
     * Detect which network a url goes through
     * @param url
     */
    private _detectNetwork(url: string): INetwork {
        // Make url to lowercase and cut query parameters
        url = url.toLowerCase();
        const urlWithoutParams = url.replace(/\?.+$/i, '');
        let selectedNetwork = defaultNetwork;
        let selectedIndex = Number.MAX_VALUE;

        networks.forEach(network => {
            if (network.match !== undefined) {
                const indexMatch = this._indexOfNetworkMatch(network, urlWithoutParams);
                const delimiterMatch = url.indexOf(network.delimiter);
                const separatorMatch = url.indexOf(network.separator);
                const redirectParamMatch =
                    network.deeplinkParam && url.indexOf(network.deeplinkParam) !== -1;

                /**
                 * If there's multiple networks with the same domain,
                 * make the matching more strict
                 * to be able to differentiate them
                 * */
                const strictlyMatching =
                    indexMatch > -1 &&
                    indexMatch <= selectedIndex &&
                    selectedNetwork !== defaultNetwork &&
                    delimiterMatch > -1 &&
                    separatorMatch > -1;

                if (
                    (strictlyMatching && redirectParamMatch) ||
                    (indexMatch > -1 && indexMatch < selectedIndex) // If a match is found and it's is earlier in the string than previous match
                ) {
                    selectedNetwork = network;
                    selectedIndex = indexMatch;
                }
            }
        });

        if (selectedNetwork.match === undefined && !!url.match(/([?&])url=/)) {
            selectedNetwork = this._parseUrlAsKnownNetwork(url);
        }

        // Return a clone...
        return { ...selectedNetwork };
    }

    private _getNetworkForUrl(url: string, checkDeeplinkParam = false): INetwork | undefined {
        let result: INetwork | undefined;
        let position: number = url.length;
        for (const network of networks) {
            const netPos = this._indexOfNetworkMatch(network, url);
            if (checkDeeplinkParam && network.deeplinkParam && !url.includes(network.deeplinkParam)) {
                continue;
            }
            if (netPos === -1) {
                continue;
            }
            if (position > netPos) {
                position = netPos;
                result = network;
            }
        }
        return result;
    }

    private _indexOfNetworkMatch(network: INetwork, url: string): number {
        if (network.match) {
            const matches = Array.isArray(network.match) ? network.match : [network.match];
            for (const match of matches) {
                const urlIndex = url.toLowerCase().indexOf(match.toLowerCase());
                if (urlIndex !== -1) {
                    return urlIndex;
                }
            }
        }

        return -1;
    }

    private _parseUrlAsKnownNetwork(url: string): INetwork {
        return applyDefaults({
            match: getDomain(url),
            deeplinkParam: 'url'
        });
    }

    private _fixRedirectParameterEncoding(value: string): string {
        if (value && this._network.fixDeeplinkEncoding && !isEncodedUrl(value)) {
            const q = encodeURIComponent(this._network.separator);
            const nextQuestionMark = value.indexOf(q);
            const nextProtocol = this._nextProtocolInUrl(value);

            // Make sure we're not including trailing urls in the encoding
            if (nextProtocol === undefined || nextQuestionMark < nextProtocol) {
                const parts = value.split(q);
                // First part is url, encode that.
                const escapedUrl = encodeURIComponent(parts.shift() || '');
                // Merge back the url
                value = [escapedUrl, ...parts].join(q);
            }
        }
        return value;
    }

    /**
     * Get index of next procol in url.
     * Use this when you need to detect
     * if a deeplink is provided somewhere
     * @param url
     */
    private _nextProtocolInUrl(url: string): undefined | number {
        if (!url || url.indexOf('http') !== 0) {
            return;
        }
        const protocols = ['http://', 'https://', 'http%3A%2F%2F', 'https%3A%2F%2F'];
        // NOSONAR
        for (let i = 0; i < protocols.length; i++) {
            const index = url.indexOf(protocols[i], 5);
            if (index > -1) {
                return index;
            }
        }
    }

    /**
     * Some urls might be malformed with an encoded "?" as separator.
     * Detect if a url is like that. Sample: http://adclick.g.doubleclick.net/pcs/click%3Fxai%...
     * @param url
     */
    private _hasEncodedSeparatorInPath(url: string): boolean {
        if (url && this._network.separator) {
            const path = url.replace(/\?.+$/i, '');
            const encodedSeparator = encodeURIComponent(this._network.separator);
            return path.indexOf(encodedSeparator) > -1;
        }
        return false;
    }

    /**
     * If not decoded already, decode url.
     * @param url
     */
    private _decodeEncodedUrl(url: string): string {
        return isEncodedUrl(url) ? decodeURIComponent(url) : url;
    }

    /**
     * Test if a string is a url or not.
     * @param val
     */
    private _isUriParameterValue(val?: string): boolean {
        if (val) {
            for (let i = 0; i < uriParameterPrefixes.length; i++) {
                if (val.indexOf(uriParameterPrefixes[i]) === 0) {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * Test if a value is a RedirectUrl
     * @param value
     */
    private _isRedirect(value: ParameterValue): value is IRedirect {
        return !!value && value instanceof Redirect === true;
    }

    private _mergeParameters(
        redirect: Redirect,
        target: Redirect,
        strategy: OverrideParameterStrategy = 'mergetargetprimary'
    ): void {
        if (redirect._parameters?.length) {
            switch (strategy) {
                case 'fromredirect':
                    target._clearParameters();
                    redirect._parameters.forEach(param => {
                        target.setParameter(param.name, param.value);
                    });
                    break;
                case 'mergeredirectprimary':
                    redirect._parameters.forEach(param => {
                        target.setParameter(param.name, param.value); // Overwrite
                    });
                    break;
                case 'mergetargetprimary':
                    redirect._parameters.forEach(param => {
                        target.setParameterIfMissing(param.name, param.value); // Don't overwrite
                    });
                    break;
            }
        }
    }
}

/**
 * Turn a url to a wildcard domain patter.
 * https://bannerflow.com -> *.bannerflow.*
 * @param targetUrl
 */
function getWildcardDomain(targetUrl: string): string | undefined {
    if (targetUrl) {
        const domain = getDomain(targetUrl);
        if (domain) {
            const parts = domain.split('.');
            if (parts.length > 1) {
                const main = parts[parts.length - 2];
                return `*.${main}.*`;
            }
        }
    }
}

/**
 * Get regex based on domain pattern.
 * Sample inputs "*.domain.top", "domain.*" or "domain.top"
 */
function getUrlRegex(url = ''): RegExp {
    const parts = url.split('.');

    if (parts.length === 1) {
        parts.unshift('*');
        parts.push('*');
    }

    if (parts.length < 2 || parts.length > 3) {
        throw new Error(
            `Can not parse: "${url}"., url needs to contain 1 or 2 dots. Sample "*.domain.top" or "domain.*"`
        );
    }

    let regexStr = '^';
    parts.map((part, index) => {
        const trailingDot = index !== parts.length - 1 ? '\\.' : '';
        if (part === '*') {
            regexStr += `(\\.?[\\w-]+${trailingDot})?`;
        } else {
            regexStr += `(${part})${trailingDot}`;
        }
    });
    regexStr += '$';

    return new RegExp(regexStr);
}

function applyDefaults(network: Partial<INetwork>): INetwork {
    // Don't wanna polyfill object.assign
    for (const prop in defaultNetwork) {
        if (network[prop] === undefined && defaultNetwork[prop] !== undefined) {
            network[prop] = defaultNetwork[prop];
        }
    }
    return network as INetwork;
}
