import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CreativesetShowcaseEffects } from './creativeset-showcase.effects';
import * as fromShowcase from './creativeset-showcase.reducer';
import { CreativesetShowcaseService } from './creativeset-showcase.service';

@NgModule({
    imports: [
        StoreModule.forFeature(fromShowcase.CREATIVESET_SHOWCASE_FEATURE_KEY, fromShowcase.reducer),
        EffectsModule.forFeature([CreativesetShowcaseEffects])
    ],
    providers: [CreativesetShowcaseService]
})
export class CreativesetShowcaseStoreModule {}
