import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DuplicateCreativeEffects } from './duplicate-creative.effects';
import * as duplicateCreatives from './duplicate-creative.reducer';
import { DuplicateCreativesService } from './duplicate-creatives.service';
@NgModule({
    imports: [
        StoreModule.forFeature(
            duplicateCreatives.DUPLICATE_CREATIVES_FEATURE_KEY,
            duplicateCreatives.reducer
        ),
        EffectsModule.forFeature([DuplicateCreativeEffects])
    ],
    providers: [DuplicateCreativesService]
})
export class DuplicateCreativesStoreModule {}
