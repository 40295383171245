import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromCampaignManager from './display-campaign.reducer';
import { DisplayCampaignEffects } from './display-campaign.effects';

@NgModule({
    imports: [
        StoreModule.forFeature(
            fromCampaignManager.DISPLAY_CAMPAIGN_FEATURE_KEY,
            fromCampaignManager.reducer
        ),
        EffectsModule.forFeature([DisplayCampaignEffects])
    ]
})
export class DisplayCampaignStoreModule {}
