import { createReducer, on } from '@ngrx/store';
import * as AnimationControlActions from './animation-control.actions';

export const ANIMATION_CONTROL_FEATURE_KEY = 'animationControl';

export interface AnimationControlPartialState {
    readonly [ANIMATION_CONTROL_FEATURE_KEY]: AnimationControlState;
}

export interface AnimationControlState {
    isPlaying: boolean;
    isDisabled: boolean;
    showPreloadImage: boolean;
    shouldAutoPause: boolean;
    shownElementId?: string;
}

export const initialState: AnimationControlState = {
    isPlaying: false,
    isDisabled: false,
    showPreloadImage: false,
    shouldAutoPause: true
};

export const reducer = createReducer(
    initialState,
    on(
        AnimationControlActions.toggleAnimations,
        (state): AnimationControlState => ({
            ...state,
            isPlaying: !state.isPlaying,
            showPreloadImage: false
        })
    ),
    on(
        AnimationControlActions.pauseAnimations,
        (state): AnimationControlState => ({
            ...state,
            isPlaying: false,
            showPreloadImage: false
        })
    ),
    on(
        AnimationControlActions.playAnimations,
        (state): AnimationControlState => ({
            ...state,
            isPlaying: true,
            showPreloadImage: false
        })
    ),
    on(
        AnimationControlActions.stepToStart,
        (state): AnimationControlState => ({
            ...state,
            showPreloadImage: false
        })
    ),
    on(
        AnimationControlActions.stepForward,
        (state): AnimationControlState => ({
            ...state,
            showPreloadImage: false
        })
    ),
    on(
        AnimationControlActions.stepBack,
        (state): AnimationControlState => ({
            ...state,
            showPreloadImage: false
        })
    ),
    on(
        AnimationControlActions.stepToPreload,
        (state): AnimationControlState => ({
            ...state,
            isPlaying: false,
            showPreloadImage: true
        })
    ),
    on(
        AnimationControlActions.initShowcaseState,
        (state): AnimationControlState => ({
            ...state,
            isPlaying: true,
            shouldAutoPause: false
        })
    ),
    on(
        AnimationControlActions.disableAnimations,
        (state, { isDisabled }): AnimationControlState => ({
            ...state,
            isDisabled,
            isPlaying: isDisabled ? false : state.isPlaying
        })
    ),
    on(
        AnimationControlActions.showElement,
        (state, { elementId }): AnimationControlState => ({
            ...state,
            shownElementId: elementId,
            isPlaying: false
        })
    )
);
