import { IFontFamily } from '@domain/font-families';
import { createAction, props } from '@ngrx/store';

export const loadFontFamiliesOfBrand = createAction('[FontFamilies] Load FontFamiliesOfBrand');

export const loadFontFamiliesOfBrandSuccess = createAction(
    '[FontFamilies] Load FontFamiliesOfBrand Success',
    props<{ fontFamilies: IFontFamily[] }>()
);

export const loadFontFamiliesOfBrandFailure = createAction(
    '[FontFamilies] Load FontFamiliesOfBrand Failure',
    props<{ error: any }>()
);

export const setFontFamiliesOfCreativeSet = createAction(
    '[FontFamilies] Set FontFamiliesOfCreativeSet',
    props<{ fontFamilies: IFontFamily[] }>()
);

export const addFontFamilies = createAction(
    '[FontFamilies] Add font families',
    props<{ fontFamilies: IFontFamily[] }>()
);
