import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { T } from '@creative/creative.container';
import { initValidator } from '@creative/serialization/document-validator';
import { ActivityLoggerService } from '@studio/monitoring/activity-logger.service';
import { Container } from '@studio/utils/di';
import { getPrimaryError } from '@studio/utils/errors/apps-errors';
import { getCookie, setCookie } from '@studio/utils/utils';
import { Subject, merge } from 'rxjs';
import { filter, map, skipWhile, takeUntil } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { BrandService } from './shared/brand/state/brand.service';
import { FontFamiliesService } from './shared/font-families/state/font-families.service';
import { EnvironmentService } from './shared/services/environment.service';
import { HotkeyBetterService } from './shared/services/hotkeys/hotkey.better.service';
import { UserService } from './shared/user/state/user.service';

const BRANCH_COOKIE_NAME = 'x-ms-routing-name';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    loaded = false;

    private unsubscribe$ = new Subject<void>();

    constructor(
        private hotkeyService: HotkeyBetterService,
        private activityLoggerService: ActivityLoggerService,
        private container: Container<T>,
        private brandService: BrandService,
        private fontFamiliesService: FontFamiliesService,
        private userService: UserService,
        private activatedRoute: ActivatedRoute,
        private environmentService: EnvironmentService,
        protected router: Router
    ) {
        this.container.register_m(T.HOTKEY_SERVICE, this.hotkeyService);
        this.container.register_m(T.ACTIVITY_LOGGER_SERVICE, this.activityLoggerService);

        merge(this.brandService.error$, this.fontFamiliesService.error$, this.userService.error$)
            .pipe(
                skipWhile(error => !error),
                map(getPrimaryError),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(error => {
                throw error;
            });

        initValidator();
    }

    ngOnInit(): void {
        this.environmentService.setGlobals();

        const branchCookie = getCookie(BRANCH_COOKIE_NAME);

        this.activatedRoute.queryParams
            .pipe(
                takeUntil(this.unsubscribe$),
                filter(params => Object.keys(params).length > 0)
            )
            .subscribe(params => {
                // On sandbox PR branches can be browsed by setting the 'x-ms-routing-name' cookie
                if (environment.stage === 'sandbox') {
                    const branch: string | undefined = params?.branch;
                    if ((branch !== branchCookie && branch) || (!branch && branchCookie !== '')) {
                        setCookie(BRANCH_COOKIE_NAME, branch);
                        window.location.reload();
                    }
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
