import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCreatives from './creatives.reducer';

export const selectCreativesetState = createFeatureSelector<fromCreatives.CreativesState>(
    fromCreatives.CREATIVES_FEATURE_KEY
);

export const getFocusedElementId = createSelector(
    selectCreativesetState,
    ({ focusedElementId }) => focusedElementId
);

export const getFocusedElement = createSelector(
    selectCreativesetState,
    ({ focusedElementId, focusedVersionId }) => ({ focusedVersionId, focusedElementId })
);
