import { Color, TextStyleRun } from 'ag-psd/dist/psd';
import { IPosition, ISize } from './dimension';

export enum PSDElementType {
    Unknown = 'unknown',
    Root = 'root',
    Layer = 'layer',
    Group = 'group',
    Text = 'text',
    Vector = 'vector'
}

export interface PSDElementTextData {
    text: string;
    color?: Color;
    font?: string;
    fontSize?: number;
    justification?: 'right' | 'left' | 'center';
    styleRuns?: TextStyleRun[];
    strikethrough?: boolean;
    underline?: boolean;
    uppercase?: boolean;
}

export enum TextFontCaps {
    None = 0,
    SmallCaps = 1,
    AllCaps = 2
}

export type PSDStyleRun = TextStyleRun;

export interface PSDElementVectorData {
    color?: Color;
}
export interface PSDElementLayerData {
    url: string; // base64 encoded
}

export interface PSDElementGroupData {
    opened: boolean;
    children?: PSDElement[];
}

export interface PSDElementRootData {
    children?: PSDElement[]; // tree structure of all layers
    flatChildren: PSDElement[]; // flat list of all layers
}

export interface PSDElementUnknownData {}

export type PSDElementData =
    | PSDElementLayerData
    | PSDElementTextData
    | PSDElementVectorData
    | PSDElementGroupData
    | PSDElementRootData
    | PSDElementUnknownData;

export interface IPSDConversionError extends Error {
    type: PSDErrorType;
    isCritical: boolean;
}

export interface IPSDElement {
    id: string;
    type: PSDElementType;
    name: string;
    position: IPosition;
    size: ISize;
    thumbnail: string;
    hidden: boolean;
    opacity: number;
    error?: IPSDConversionError;
}

export type PSDRootElement = IPSDElement & { type: PSDElementType.Root; data: PSDElementRootData };
export type PSDGroupElement = IPSDElement & { type: PSDElementType.Group; data: PSDElementGroupData };
export type PSDUnknownElement = IPSDElement & {
    type: PSDElementType.Unknown;
    data: PSDElementUnknownData;
};
export type PSDLayerElement = IPSDElement & { type: PSDElementType.Layer; data: PSDElementLayerData };
export type PSDTextElement = IPSDElement & { type: PSDElementType.Text; data: PSDElementTextData };
export type PSDVectorElement = IPSDElement & {
    type: PSDElementType.Vector;
    data: PSDElementVectorData;
};
export type PSDElement =
    | PSDRootElement
    | PSDUnknownElement
    | PSDLayerElement
    | PSDTextElement
    | PSDVectorElement
    | PSDGroupElement;

export type LayerItem = PSDElement & { selected: boolean; collapsed: boolean };

export enum PSDErrorType {
    FileRead = 'FileRead',
    CreativeConversion = 'CreativeConversion',
    CannotReadElements = 'CannotReadElements',
    OriginalFontNotFound = 'OriginalFontNotFound'
}

export function getPSDErrorMessage(errorType: PSDErrorType, ...args: string[]): string {
    switch (errorType) {
        case PSDErrorType.FileRead:
            return 'There was an error reading the PSD file. Please contact our support team to learn what PSD formats are supported.';
        case PSDErrorType.CreativeConversion:
            return 'There was an error converting the PSD file to a creative. This could be due to an unsupported file format, an issue with the file itself, or an error on our end.';
        case PSDErrorType.CannotReadElements:
            return 'Sorry but we were not able to import this file. Try again or contact our support.';
        case PSDErrorType.OriginalFontNotFound:
            const [originalFontFamilyName, fontFamilyName] = args;
            return `"${originalFontFamilyName}" font was not available. "${fontFamilyName}" will be applied instead.`;
        default:
            return '';
    }
}
