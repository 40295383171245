<div
    *ngIf="!(layers$ | async)?.length; else psdList"
    class="psd-content">
    Nothing is selected for import <br />
    Please select a file first
</div>

<ng-template #psdList>
    <size-add-overview
        label="Select layers to import"
        [currentSelected]="selectedPsdLayers"
        (toggleAll)="toggleAllSelection()">
    </size-add-overview>

    <div
        *ngFor="let layer of layers$ | async"
        class="layer ui-scrollbar"
        [ngClass]="{ 'layer--group': layer.type === 'group' }"
        data-test-id="psd-layer">
        <div
            class="group-layer"
            *ngIf="layer.type === 'group'; else anyLayer">
            <div
                class="layer__left"
                (click)="toggleGroupCollapse(layer)">
                <div class="layer__icon">
                    <ui-svg-icon
                        [ngClass]="{ collapsed: layer.collapsed }"
                        icon="arrow-up"></ui-svg-icon>
                </div>
                <div class="layer__name layer__name--group">
                    <span class="title bold">{{ layer.name }} </span>
                    <span>{{ getSelectedChildrenCount(layer) }} / {{ getChildrenCount(layer) }}</span>
                </div>
            </div>

            <div class="layer__right">
                <div
                    *ngIf="!layer.error"
                    class="layer__toggle layer__toggle--visibility"
                    (click)="toggleGroupVisibility(layer)">
                    <ui-svg-icon
                        class="layer__icon"
                        [class.layer__icon--hidden]="layer.hidden"
                        data-test-id="psd-visibility-icon"
                        [icon]="layer.hidden ? 'visibility-hidden' : 'visibility-visible'">
                    </ui-svg-icon>
                </div>
                <div
                    #toggleAll
                    *ngIf="!layer.error?.isCritical"
                    class="layer__toggle layer__toggle--selection"
                    (click)="toggleGroupSelection(layer)">
                    <ui-checkbox
                        [indeterminate]="selectionState(layer) === LayerSelectedState.SomeSelected"
                        [selected]="!layer.error && layer.selected">
                    </ui-checkbox>
                </div>
            </div>
        </div>

        <ng-template #anyLayer>
            <div
                class="any-layer"
                *ngIf="!layer.collapsed"
                (click)="toggleSelection(layer)">
                <div class="layer__left">
                    <div class="layer__thumbnail">
                        <img
                            *ngIf="layer.thumbnail"
                            [src]="layer.thumbnail"
                            data-test-id="psd-thumbnail"
                            alt="Layer thumbnail" />
                    </div>
                    <div
                        class="layer__name"
                        [class.warning]="!!layer.error">
                        <span class="title">{{ layer.name }}</span>
                    </div>
                </div>

                <div class="layer__right">
                    <div class="layer__toggle layer__toggle--warning">
                        <ui-svg-icon
                            *ngIf="layer.error"
                            [uiTooltip]="layer.error.message"
                            [uiTooltipDisabled]="!layer.error"
                            class="layer__icon layer__icon--warning"
                            data-test-id="psd-warning-icon"
                            icon="warning">
                        </ui-svg-icon>
                    </div>
                    <div
                        class="layer__toggle layer__toggle--visibility"
                        (click)="toggleVisibility(layer)"
                        *ngIf="!layer.error?.isCritical">
                        <ui-svg-icon
                            class="layer__icon"
                            [class.layer__icon--hidden]="layer.hidden"
                            data-test-id="psd-visibility-icon"
                            [icon]="layer.hidden ? 'visibility-hidden' : 'visibility-visible'">
                        </ui-svg-icon>
                    </div>
                    <div
                        class="layer__toggle layer__toggle--selection"
                        (click)="toggleSelection(layer)"
                        *ngIf="!layer.error?.isCritical">
                        <ui-checkbox [selected]="layer.selected"></ui-checkbox>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</ng-template>
