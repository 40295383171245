import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, switchMap } from 'rxjs/operators';
import * as AnimationControlActions from './animation-control.actions';
import { VersionsService } from '../../versions/state/versions.service';
import { EnvironmentService } from '../../services/environment.service';
import { merge, of } from 'rxjs';
import { EditCreativeService } from '../../../pages/manage-view/services/edit-creative.service';
import { CreativesetDataService } from '../../creativeset/creativeset.data.service';

@Injectable()
export class AnimationControlsEffects {
    pauseAnimations$ = createEffect(() => {
        return this.versionsService.selectedVersions$.pipe(
            filter(() => !this.environmentService.inShowcaseMode),
            switchMap(() => of(AnimationControlActions.pauseAnimations()))
        );
    });

    disableAnimations$ = createEffect(() => {
        return merge(
            this.actions$.pipe(ofType(AnimationControlActions.initDisabledState)),
            this.editCreativeService.activation.change$,
            this.editCreativeService.deactivation.change$,
            this.editCreativeService.deletion.change$
        ).pipe(
            map(() => this.areAllCreativesWithoutDesign()),
            switchMap(isDisabled => of(AnimationControlActions.disableAnimations({ isDisabled })))
        );
    });

    constructor(
        private actions$: Actions,
        private versionsService: VersionsService,
        private environmentService: EnvironmentService,
        private editCreativeService: EditCreativeService,
        private creativesetDataService: CreativesetDataService
    ) {}

    private areAllCreativesWithoutDesign(): boolean {
        return [...this.creativesetDataService.creativeset.creatives].every(
            creative => !creative.design
        );
    }
}
