<div
    class="library-element"
    [ngClass]="{ 'library-element--preview': viewMode === LibraryViewMode.Preview }"
    [uiTooltip]="(element | brandLibraryElementTooltip | async)!"
    uiTooltipTrigger="hover"
    [uiTooltipClass]="isVideo ? 'ml-tooltip-video' : 'ml-tooltip'"
    [uiTooltipPosition]="isVideo ? 'right' : 'top'"
    (click)="onElementClick()"
    (mouseleave)="toggleAnimatedThumbnail(false)"
    (mouseover)="toggleAnimatedThumbnail(true)"
    uiTooltipMaxWidth="300px">
    @if (!isEditingName) {
        <div class="library-element__header library-element-row">
            <ui-svg-icon
                class="library-element-row__icon"
                [icon]="elementIcon">
            </ui-svg-icon>
            <div
                class="library-element-row__name"
                (click)="editElementName($event)">
                {{ element.name }}
                @if (isVideo && videoDuration && viewMode === LibraryViewMode.List) {
                    <span class="library-element-video-duration-list">
                        {{ videoDuration | date: 'mm:ss' }}
                    </span>
                }
            </div>

            @if (isGenAi && viewMode === LibraryViewMode.List) {
                <ui-svg-icon
                    icon="ai_stars"
                    uiTooltip="AI generated image"
                    uiTooltipPosition="bottom" />
            }

            @if (!mediaLibrary.inDialog && isKebabMenuVisible) {
                <ui-svg-icon
                    class="library-element-row__control"
                    icon="kebab"
                    [uiDropdownTarget]="elementMenu"></ui-svg-icon>
            }
        </div>
    } @else if (isSelected) {
        <ui-input
            #nameInput
            class="library-element__input"
            [minlength]="1"
            (click)="stopPropagation($event)"
            (dblclick)="stopPropagation($event)"
            (mousedown)="stopPropagation($event)"
            (blur)="updateName(nameInput)"
            [selectTextOnFocus]="true"
            (keyup.enter)="nameInput.blurInput()"
            [value]="element.name"
            autocomplete="off"></ui-input>
    }

    <div
        class="library-element__body"
        (click)="clickToContainer()"
        [class.hidden]="viewMode === LibraryViewMode.List">
        <div class="library-element-image">
            @if (isEffect && !isImportedEffect) {
                <div class="library-element__import-overlay">
                    @if (isImportingEffect) {
                        <ui-loader></ui-loader>
                    } @else {
                        Import<br />
                        to library
                    }
                </div>
            }

            @if (!isEffect && isBannerFlowLibraryWidget) {
                <div class="library-element__badge">
                    <ui-logo [small]="true"></ui-logo>
                </div>
            }

            @if (isWidget && !thumbnailUrl) {
                <div class="library-element-widget-placeholder">
                    <ui-svg-icon
                        icon="widget"
                        class="library-element-widget-placeholder__icon"></ui-svg-icon>
                </div>
            }

            @if (isElement || isStyledElement(element)) {
                <div #elementContainer></div>
            }

            @if (isWidget && isBannerFlowLibraryWidget && isNonExportableWidget) {
                <div class="library-element__badge non-exportable always-visible">
                    <ui-svg-icon icon="html-crossed" />
                </div>
            }

            @if (!isElement && !isStyledElement(element) && !isVideo && thumbnailUrl) {
                <div
                    class="library-element-image__preview"
                    [style.backgroundImage]="'url(' + thumbnailUrl + ')'">
                    @if (isGenAi) {
                        <div
                            class="library-element__badge always-visible"
                            uiTooltip="AI generated image">
                            <ui-svg-icon icon="ai_stars" />
                        </div>
                    }
                </div>
            }
            @if (isVideo && !isStyledElement(element)) {
                <div
                    class="library-element-image__preview"
                    [style.backgroundImage]="'url(' + thumbnailUrl + ')'"></div>
            }
            @if (videoDuration) {
                <div class="library-element-video-duration-preview">
                    {{ videoDuration | date: 'mm:ss' }}
                </div>
            }
        </div>
    </div>
</div>

<ui-dropdown
    class="menu"
    #elementMenu>
    @if (isWidget) {
        <ui-dropdown-item
            class="update-widget-option"
            svgIcon="sync-small"
            (click)="updateWidgetInCreativeSet()"
            data-test-id="update-widget">
            Update this {{ isEffect ? 'effect' : 'widget' }}
        </ui-dropdown-item>
    }

    @if (isEditable) {
        <ui-dropdown-item
            svgIcon="settings"
            (click)="emitEditElement()"
            data-test-id="edit">
            Edit
        </ui-dropdown-item>
    }

    <ng-container *permissions="'StudioGenAiImage'">
        @if (isImage) {
            @let _aiStudioState = aiStudioState();

            @let supported = _aiStudioState.supported;
            @let disabledReason = _aiStudioState.message;
            @let tooltipDisabled = supported || !disabledReason;

            <ui-dropdown-item
                id="interaction-open-ai-studio-bl"
                class="custom-dropdown"
                svgIcon="ai_stars"
                [disabled]="!supported"
                [uiTooltip]="disabledReason"
                [uiTooltipDisabled]="tooltipDisabled"
                [uiTooltipPosition]="'right'"
                (click)="openInAIStudio()">
                Open in AI Studio
            </ui-dropdown-item>
        }
    </ng-container>

    @if (!isWidgetOrMedia) {
        <ui-dropdown-item
            svgIcon="copy-s"
            (click)="duplicate()"
            >Duplicate</ui-dropdown-item
        >
    }

    <ui-dropdown-item
        svgIcon="plus-small"
        (click)="addElementToCanvas()"
        data-test-id="add-to-canvas"
        >Add to canvas</ui-dropdown-item
    >

    @if (isBannerFlowLibraryWidget) {
        <ui-dropdown-item
            svgIcon="question-mark-s"
            (click)="openWidgetInfo()"
            >How it works</ui-dropdown-item
        >
    }

    @if (!isEffect) {
        <ui-dropdown-divider></ui-dropdown-divider>
        <ui-dropdown-item
            svgIcon="delete"
            data-test-id="delete"
            (click)="deleteAsset()"
            >{{ isBannerFlowLibraryWidget ? 'Remove from library' : 'Delete' }}</ui-dropdown-item
        >
    }
    @if (!isWidgetOrMedia) {
        <ui-dropdown-item
            svgIcon="edit"
            (click)="focusEditName($event)"
            >Rename</ui-dropdown-item
        >
    }

    @if (isImage || isVideo) {
        <ui-dropdown-item
            svgIcon="download"
            (click)="downloadAsset()"
            >Download {{ element.type }}</ui-dropdown-item
        >
        <ui-dropdown-item
            svgIcon="copy-s"
            (click)="copyAssetUrl()"
            >Copy {{ element.type }} URL</ui-dropdown-item
        >
    }
</ui-dropdown>
