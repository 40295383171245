import { Component, OnDestroy } from '@angular/core';
import { Logger } from '@bannerflow/sentinel-logger';
import { Subject } from 'rxjs';
import { AppComponent } from '../../app.component';

@Component({
    selector: 'creativeset-component',
    templateUrl: './creativeset.component.html'
})
export class CreativesetComponent implements OnDestroy {
    private logger = new Logger('CreativesetComponent');
    private unsubscribe$ = new Subject<void>();

    constructor(private app: AppComponent) {
        this.app.loaded = true;
    }

    ngOnDestroy(): void {
        this.logger.verbose('ngOnDestroy');
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
