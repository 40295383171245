<div class="wrapper">
    @if (isBrandLibraryElement()) {
        <ui-button
            text="Save and replace"
            type="primary"
            [disabled]="disabled() || !canSave()"
            [uiTooltip]="'The image will be saved and replaced in the brand library'"
            [loading]="isRequestingBrandLibrarySave()"
            (click)="onSaveAndReplaceButtonClicked()" />
        <ui-button
            text="Save as duplicate"
            type="default"
            [disabled]="disabled() || !canSave()"
            [uiTooltip]="
                'The image, along with all AI changes made, will be saved as a copy to the brand library'
            "
            [loading]="isRequestingBrandLibraryDuplicate()"
            (click)="onSaveAsDuplicateButtonClicked()" />
    } @else {
        <ui-button
            text="Save and replace"
            type="primary"
            [uiTooltip]="
                'You will choose to save and replace this image in one or all sizes in the creative set'
            "
            [disabled]="disabled() || !canSave()"
            [loading]="isRequestingOnCanvasSaveAndReplace()"
            (click)="onSaveAndReplaceToCanvasButtonClicked()" />
        <ui-button
            text="Save as duplicate"
            type="default"
            [uiTooltip]="'The image, along with all Al changes, will be saved as a copy on the canvas'"
            [disabled]="disabled() || !canSave()"
            [loading]="isRequestingOnCanvasSaveAsDuplicate()"
            (click)="onSaveAsDuplicateToCanvasButtonClicked()" />
    }
    <ui-button
        [border]="false"
        [disabled]="disabled()"
        (click)="onCancelButtonClicked()"
        text="Cancel" />
</div>
