import { Injectable } from '@angular/core';
import { IFontFamily } from '@domain/font-families';
import { Store } from '@ngrx/store';
import * as FontFamiliesActions from './font-families.actions';
import * as FontFamiliesSelectors from './font-families.selectors';

@Injectable({
    providedIn: 'root'
})
export class FontFamiliesService {
    fontFamilies$ = this.store.pipe(FontFamiliesSelectors.getFontFamiliesWhenLoaded);
    creativeSetFontFamilies$ = this.store.pipe(FontFamiliesSelectors.getCreativesetFontFamilies);
    visibleFontFamilies$ = this.store.pipe(FontFamiliesSelectors.getVisibleBrandFontFamilies);
    nonDeletedBrandFontFamilies$ = this.store.pipe(
        FontFamiliesSelectors.getNonDeletedBrandFontFamilies
    );

    loaded$ = this.store.select(FontFamiliesSelectors.getFontFamiliesLoaded);
    error$ = this.store.select(FontFamiliesSelectors.getFontFamiliesError);

    constructor(private readonly store: Store) {}

    loadFontFamilies(): void {
        this.store.dispatch(FontFamiliesActions.loadFontFamiliesOfBrand());
    }

    setFontFamiliesOfCreativeSet(fontFamilies: IFontFamily[]): void {
        this.store.dispatch(FontFamiliesActions.setFontFamiliesOfCreativeSet({ fontFamilies }));
    }

    addFontFamilies(fontFamilies: IFontFamily[]): void {
        this.store.dispatch(FontFamiliesActions.addFontFamilies({ fontFamilies }));
    }
}
