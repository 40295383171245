import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UIModule } from '@bannerflow/ui';
import { GlobalErrorHandler } from './global-error-handler.service';
import { TokenInterceptor } from './http.interceptor';
import { IntercomService } from './plugins/intercom.service';
import { GoogleTagManagerService } from './plugins/gtm.service';

@NgModule({
    imports: [CommonModule, RouterModule, UIModule],
    exports: [],
    declarations: [],
    providers: [
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        TokenInterceptor
    ]
})
export class CoreModule {
    constructor(
        @SkipSelf() @Optional() core: CoreModule,
        // Injected to avoid tree-shaking
        private _intercom: IntercomService,
        private _gtm: GoogleTagManagerService
    ) {
        if (core) {
            throw new Error('CoreModule should only be instantiated once!');
        }
    }
}
