import { createReducer, on } from '@ngrx/store';
import * as CreativesActions from './creatives.actions';

export const CREATIVES_FEATURE_KEY = 'creatives';
export interface CreativesPartialState {
    readonly [CREATIVES_FEATURE_KEY]: CreativesState;
}
export interface CreativesState {
    focusedVersionId: string | undefined;
    focusedElementId: string | undefined;
}

export const initialState: CreativesState = {
    focusedVersionId: undefined,
    focusedElementId: undefined
};

export const reducer = createReducer(
    initialState,
    on(
        CreativesActions.focusElement,
        (state, { elementId, versionId }): CreativesState => ({
            ...state,
            focusedElementId: elementId,
            focusedVersionId: versionId
        })
    ),
    on(
        CreativesActions.blurElement,
        (state): CreativesState => ({
            ...state,
            focusedElementId: undefined,
            focusedVersionId: undefined
        })
    )
);
