import { CommonModule } from '@angular/common';
import { Component, Input, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UIConfirmDialogService, UIDialogService, UIDropdownComponent, UIModule } from '@bannerflow/ui';
import { Observable, firstValueFrom } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AnimationControlService } from '../../../shared/animation-control/animation-control.service';
import { FontManagerComponent } from '../../../shared/components/fonts/font-manager.component';
import { ManageWarningsDropdownComponent } from '../../../shared/components/manage-warnings-dropdown/manage-warnings-dropdown.component';
import { VersionDialogService } from '../../../shared/components/version-dialog';
import { CreativesetShowcaseService } from '../../../shared/creativeset-showcase/state/creativeset-showcase.service';
import { CreativesetDataService } from '../../../shared/creativeset/creativeset.data.service';
import { PermissionsDirective } from '../../../shared/directives/permissions.directive';
import { DisplayCampaignService } from '../../../shared/display-campaign/state/display-campaign.service';
import { NavigationService } from '../../../shared/services/navigation.service';
import { UserService } from '../../../shared/user/state/user.service';
import { CampaignsDropdownComponent } from '../campaigns-dropdown/campaigns-dropdown.component';
import { DuplicateCreativeService } from '../duplicate-creative-dialog/duplicate-creative.service';
import { ExportDropdownComponent } from '../export-creative';
import { EditCreativeService } from '../services/edit-creative.service';
import { TileSelectService } from '../services/tile-select.service';
import { ShowcaseDialogComponent } from '../showcase/showcase-dialog.component';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        UIModule,
        PermissionsDirective,
        ExportDropdownComponent,
        CampaignsDropdownComponent,
        ManageWarningsDropdownComponent
    ],
    selector: 'manage-menu',
    templateUrl: './manage-menu.component.html',
    styleUrls: ['./manage-menu.component.scss']
})
export class ManageMenuComponent {
    private navigationService = inject(NavigationService);
    private displayCampaignService = inject(DisplayCampaignService);

    hasConnectedCampaigns$ = this.displayCampaignService.hasConnectedCampaigns$;

    @Input() hasDesigns: boolean;
    @ViewChild('dropdown', { static: true }) dropdown: UIDropdownComponent;

    selectedCreatives: number;
    isAnySelected = false;
    countText: string;
    selectedText: string;
    addToCampaignDisabled: boolean;
    addToCampaignText: string;
    isEmployee$: Observable<boolean>;

    constructor(
        private versionDialogService: VersionDialogService,
        private uiConfirmDialogService: UIConfirmDialogService,
        private creativesetDataService: CreativesetDataService,
        private tileSelectService: TileSelectService,
        private editCreativeService: EditCreativeService,
        private uiDialogService: UIDialogService,
        private animationControlService: AnimationControlService,
        private duplicationService: DuplicateCreativeService,
        private creativesetShowcaseService: CreativesetShowcaseService,
        public userService: UserService
    ) {
        this.tileSelectService.selection$.pipe(takeUntilDestroyed()).subscribe(creatives => {
            this.selectedCreatives = creatives.filter(c => c.design).length;
            const count = creatives.length;
            this.isAnySelected = creatives.length > 0;
            this.countText = count >= 1 ? `(${count})` : '';
            this.selectedText = this.selectedCreatives >= 1 ? `(${this.selectedCreatives})` : '';
        });

        this.isEmployee$ = this.userService.isEmployee$;
    }

    duplicateSize(duplicateToNew: boolean): void {
        this.duplicationService.openDialog(duplicateToNew);
    }

    manageVersions = (): void => this.versionDialogService.openVersionDialog();

    fontManagerClosed(): void {
        this.creativesetDataService.syncFonts();
    }

    manageFonts(): void {
        const dialog = this.uiDialogService.openComponent(FontManagerComponent, {
            padding: 0,
            headerText: 'Manage brand fonts',
            width: '100%',
            maxWidth: '100%',
            height: '100%',
            panelClass: ['inlined-iframe', 'fullscreen'],
            data: {
                brandId: this.creativesetDataService.brand.id
            }
        });
        firstValueFrom(dialog.beforeClose()).then(() => this.fontManagerClosed());
    }

    async regenerateSet(): Promise<void> {
        this.dropdown.closePopover();

        const res = await this.uiConfirmDialogService.confirm({
            headerText: 'FORCE UPDATE',
            text: 'This action will force update checksums on all the creatives, making it possible to publish without any changes',
            cancelText: 'CANCEL',
            confirmText: 'FORCE UPDATE'
        });

        if (res === 'confirm') {
            await this.editCreativeService.forceUpdateChecksums();
        }
    }

    navitageToAnalytics(): void {
        const url = `${
            environment.origins.analyticsFrontendUrl
        }/${this.creativesetDataService.getAccountSlug()}/${this.creativesetDataService.getBrandSlug()}/analytics/stbannerset/${
            this.creativesetDataService.creativeset.id
        }/dashboard`;
        window.open(url, '_blank');
    }

    async exit(): Promise<void> {
        const brandUrl = `${this.creativesetDataService.getAccountSlug()}/${this.creativesetDataService.getBrandSlug()}`;
        const creativesetId = this.creativesetDataService.creativeset.id;
        const url = `${environment.origins.bfc}/${brandUrl}/creative-sets?redirect=${creativesetId}`;

        window.location.href = url;
    }

    sharePublicShowcaseDialog(): void {
        // stop animations when showing shared links
        this.animationControlService.stopAnimations();

        this.uiDialogService.openComponent(ShowcaseDialogComponent, {
            headerText: 'Share Creative set',
            width: '100%',
            height: '100%',
            maxWidth: '100%',
            padding: 0,
            panelClass: ['no-padding', 'fullscreen']
        });
    }

    showMobilePreviewModal(): void {
        this.creativesetShowcaseService.openQRLinkModal();
    }

    redirectToCreateCampaign(): void {
        this.navigationService.openCreateCampaign();
    }
}
