import {
    Component,
    ElementRef,
    forwardRef,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IUIPopoverConfig, UISelectComponent } from '@bannerflow/ui';
import { Color } from '@creative/color';
import { parseColor } from '@creative/color.utils';
import {
    hasSameStyleProperty,
    isContentSpan,
    isVariable
} from '@creative/elements/rich-text/text-nodes';
import { isElementDataNode, isTextDataElement } from '@creative/nodes/helpers';
import { IFontStyle } from '@domain/font';
import { IFontFamily, IFontFamilyStyle } from '@domain/font-families';
import { ITextElementDataNode, OneOfTextDataNodes, OneOfTextViewElements } from '@domain/nodes';
import { characterPropertyToUnitMap } from '@domain/property';
import { IRichText, RichTextEvents } from '@domain/rich-text/rich-text.header';
import { IState } from '@domain/state';
import { ITextShadow } from '@domain/style';
import {
    HorizontalAlignment,
    IMixedCharacterProperties,
    ITextVariable,
    TextOverflow,
    VerticalAlignment
} from '@domain/text';
import { cloneDeep } from '@studio/utils/clone';
import { deepEqual, isNumber } from '@studio/utils/utils';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { IButtonToggleOption } from '../../../../shared/components';
import { FeedSettingService } from '../../../../shared/components/feeds';
import { CreativesetDataService } from '../../../../shared/creativeset/creativeset.data.service';
import { FontFamiliesService } from '../../../../shared/font-families/state/font-families.service';
import { SortArrayPipe } from '../../../../shared/pipes';
import { ColorService } from '../../../../shared/services/color.service';
import {
    FontValidationService,
    ValidateFontParams
} from '../../../../shared/services/font-validation.service';
import { HotkeyBetterService } from '../../../../shared/services/hotkeys/hotkey.better.service';
import { DesignViewComponent } from '../../design-view.component';
import { EditorEventService } from '../../services';
import { ElementChangeType } from '../../services/editor-event';
import { HistoryService } from '../../services/history.service';
import { MutatorService } from '../../services/mutator.service';
import { GizmoDrawSchedulerService } from '../../workspace/gizmo-draw-scheduler';
import { createMixedProperty } from '../mixed-properties';
import { PropertiesService } from '../properties.service';
import { textPropertiesInputValidation } from './text-properties.component.constants';

interface IMixedTextShadow extends ITextShadow {
    colorMixed: boolean;
}

@Component({
    selector: 'text-properties',
    styleUrls: ['../common.scss', './text-properties.component.scss'],
    templateUrl: './text-properties.component.html'
})
export class TextPropertiesComponent implements OnInit, OnDestroy {
    @Input() elements$: Observable<OneOfTextDataNodes[]>;

    @ViewChild('fontFamilySelect') fontFamilySelect: UISelectComponent;
    @ViewChild('fontStyleSelect') fontStyleSelect: UISelectComponent;

    color = createMixedProperty<Color>(new Color());
    textShadows: IMixedTextShadow[] = [];
    textShadowsDifferentLength = false;
    horizontalAlignment: HorizontalAlignment | '';
    verticalAlignment?: VerticalAlignment | '';
    underline = false;
    strikethrough = false;
    uppercase = false;
    lineHeight = createMixedProperty<number>();
    fontSize = createMixedProperty<number>();
    maxRows = createMixedProperty<number>();
    characterSpacing = createMixedProperty<number>();
    textOverflow = createMixedProperty<TextOverflow | 'Mixed'>();
    paddingTop = createMixedProperty<number>();
    fontFamilyId?: string;
    defaultFontFamilyId?: string;
    fontStyleId?: string;
    defaultFontStyleId?: string;
    inputValidation = textPropertiesInputValidation;
    ElementChangeType = ElementChangeType;
    hideProperties = false;
    stateData?: IState | ITextElementDataNode;

    horizontalAlignmentOptions: IButtonToggleOption[] = [
        { id: 'align-horizontal-left-button', icon: 'text-align-h-left', value: 'left' },
        { id: 'align-horizontal-center-button', icon: 'text-align-h-center', value: 'center' },
        { id: 'align-horizontal-right-button', icon: 'text-align-h-right', value: 'right' }
    ];
    verticalAlignmentOptions: IButtonToggleOption[] = [
        { id: 'align-vertical-top-button', icon: 'text-align-v-top', value: 'top' },
        { id: 'align-vertical-middle-button', icon: 'text-align-v-middle', value: 'middle' },
        { id: 'align-vertical-bottom-button', icon: 'text-align-v-bottom', value: 'bottom' }
    ];
    textOverflowOptions: { id: string; value: string; name: string }[] = [
        { id: 'interaction-text-overflow-shrink', value: 'shrink', name: 'Shrink to fit' },
        { id: 'interaction-text-overflow-truncate', value: 'truncate', name: 'Truncate overflow' }
    ];

    private elements: OneOfTextDataNodes[] = [];
    private viewElements: Array<OneOfTextViewElements | undefined>;
    private isPreviewEnabled = false;
    private colorPreviewCache: (Color | undefined)[] = [];
    private shadowColorPreviewCache: { index: number; colors: (Color | undefined)[] };
    private fontFamilies: IFontFamily[] = [];
    private font?: IFontStyle | '$mixed';
    private pendingFontChange: boolean;
    private isPreviewFont: boolean;
    private italic: boolean;
    private weight: number;
    private _lastElement: OneOfTextViewElements;
    private sortPipe = new SortArrayPipe();

    private closeFeed$ = new Subject<void>();
    private unsubscribe$ = new Subject<void>();

    get textDecoration(): string | undefined {
        const decorations: string[] = [];
        if (this.underline) {
            decorations.push('underline');
        }
        if (this.strikethrough) {
            decorations.push('strikethrough');
        }
        return decorations.join(' ') || undefined;
    }

    constructor(
        @Inject(forwardRef(() => DesignViewComponent))
        private editor: DesignViewComponent,
        private editorEventService: EditorEventService,
        private gizmoDrawScheduler: GizmoDrawSchedulerService,
        public creativesetDataService: CreativesetDataService,
        private historyService: HistoryService,
        private hotkeyService: HotkeyBetterService,
        private feedSettingsService: FeedSettingService,
        public propertiesService: PropertiesService,
        public colorService: ColorService,
        private mutatorService: MutatorService,
        private fontValidationService: FontValidationService,
        private fontFamiliesService: FontFamiliesService
    ) {
        this.editorEventService.text.textSelectionChange$.pipe(takeUntilDestroyed()).subscribe(() => {
            this.updateProperties();
        });

        this.fontFamiliesService.fontFamilies$.pipe(takeUntilDestroyed()).subscribe(fontFamilies => {
            this.fontFamiliesChanged(fontFamilies);
        });

        this.historyService.onChange$.pipe(takeUntilDestroyed()).subscribe(() => {
            this.updateProperties();
        });
    }

    toggleProperties(toggle: boolean): void {
        this.hideProperties = toggle;
    }

    ngOnInit(): void {
        this.gizmoDrawScheduler.gizmoDrawer = this.editor.workspace.gizmoDrawer;
        this.startHotkeyListeners();

        this.elements$.pipe(takeUntil(this.unsubscribe$)).subscribe(elements => {
            this.stopLastRichTextRender();
            this.elements = elements;
            this.viewElements = this.elements
                .filter(element => !element.hidden)
                .filter(Boolean)
                .map(element => this.mutatorService.renderer.getViewElementById(element.id));

            if (elements.length === 1) {
                this.startActiveRichTextRender();
            }

            this.updateProperties();
        });

        this.propertiesService
            .observeDataElementOrStateChange<ITextElementDataNode>()
            .pipe(
                takeUntil(this.unsubscribe$),
                filter(() => !this.isPreviewFont)
            )
            .subscribe(({ element, state }) => {
                this.stateData = state || element;
                if (isElementDataNode(element) && !isTextDataElement(element)) {
                    return;
                }
                this.setDefaultFontIds();
                this.updateProperties();
            });
    }

    toggleItalicTextStyle = (): void => {
        this.toggleFontStyleProperty('italic');
    };

    toggleBoldTextStyle = (): void => {
        this.toggleFontStyleProperty('bold');
    };

    toggleUnderlineTextStyle = (): void => {
        this.toggleFontStyleProperty('underline');
    };

    private toggleFontStyleProperty(property: 'bold' | 'italic' | 'underline'): void {
        if (property === 'underline') {
            this.underline = !this.underline;
            this.setUnderline();
            return;
        }
        if (property === 'italic') {
            this.italic = !this.italic;
        }

        const currentFontFamily = this.fontFamilies.find(
            fontFamily => fontFamily.id === this.fontFamilyId
        );
        if (!currentFontFamily) {
            if (this.fontFamilies.length === 0) {
                // If there is no fontfamilies, then do nothing.
                return;
            }
            throw new Error('Current font family is not set');
        }
        const italicFilteredFontStyles = this.sortPipe.transform<IFontFamilyStyle>(
            currentFontFamily.fontStyles.filter(fontStyle => fontStyle.italic === this.italic),
            { weight: 'asc' }
        );

        if (property === 'bold') {
            const currentFontStyle =
                italicFilteredFontStyles.length > 0
                    ? italicFilteredFontStyles
                    : currentFontFamily.fontStyles;

            const boldFilteredFontStyles = this.sortPipe.transform<IFontFamilyStyle>(
                currentFontStyle.filter(fontStyle => !fontStyle.deletedAt),
                { weight: 'asc' }
            );

            if (boldFilteredFontStyles.length === 0) {
                return;
            }

            const foundFontStyle = boldFilteredFontStyles.some(fontStyle => {
                if (fontStyle.weight > this.weight) {
                    this.updateFontStyle(fontStyle, false, true);
                    return true;
                }
            });

            if (!foundFontStyle) {
                this.updateFontStyle(boldFilteredFontStyles[0], false, true);
            }
        } else if (italicFilteredFontStyles[0]) {
            const currentStyleAsItalic = italicFilteredFontStyles.find(
                ({ weight }) => weight === this.weight
            );

            if (currentStyleAsItalic) {
                this.updateFontStyle(currentStyleAsItalic, false, true);
            }
        }
    }

    private fontFamiliesChanged(fontFamilies: IFontFamily[]): void {
        this.fontFamilies = fontFamilies;

        if (!this.fontFamilyId && this.fontFamilies?.length) {
            const fontFamily = this.fontFamilies[0];
            this.fontFamilyId = fontFamily.id;
            this.fontStyleId = fontFamily.fontStyles[0].id ?? undefined;
        }
    }

    suspendNextBlur(): void {
        for (const element of this.viewElements) {
            element?.__richTextRenderer?.editor_m?.suspendNextBlur();
        }
    }

    updateColor(newColor: Color, eventType?: ElementChangeType): void {
        this.color.value = newColor;
        this.isPreviewEnabled = false;

        const color = this.isPreviewEnabled ? this.colorPreviewCache.shift() : this.color.value.copy();

        this.setColor(color, eventType);
    }

    private setColor(color: Color | undefined, eventType?: ElementChangeType): void {
        for (const element of this.elements) {
            this.mutatorService.setElementPropertyValue(element, 'textColor', color, eventType);
        }

        this.isPreviewEnabled = false;
    }

    setAlpha(alpha?: number, eventType?: ElementChangeType): void {
        if (alpha) {
            const newColor = new Color(this.color.value);
            newColor.alpha = alpha;
            this.color.value = newColor;
        }
        this.setColor(this.color.value, eventType);
    }

    addStateColor = (): void => {
        const state = this.propertiesService.getCalculatedStateAtCurrentTime();

        if (!state?.textColor) {
            // when in stateView we should only have one element
            this.color.value = this.elements[0].textColor.copy() || parseColor('#FF0000');
            this.hideProperties = false;
            this.setColor(this.color.value);
        }
    };

    clearStateColor(): void {
        // when in stateView we should only have one element
        this.color.value = this.elements[0].textColor || new Color();
        this.setColor(undefined);
        if (this.propertiesService.stateData) {
            delete this.propertiesService.stateData.textColor;
        }
        this.editor.rerenderNode(this.elements[0]);
        this.updateProperties();
    }

    previewColor(color: Color): void {
        if (!this.isPreviewEnabled) {
            this.isPreviewEnabled = true;
            this.colorPreviewCache = this.elements.map(
                element => element.__dirtyContent?.style.textColor || element.textColor
            );
        }
        this.setColor(color, ElementChangeType.Skip);
    }

    previewColorStopped(color: Color): void {
        if (this.elements.length === 1) {
            this.updateColor(color, ElementChangeType.Skip);
            return;
        }
        for (const element of this.elements) {
            const prevColor = this.colorPreviewCache.shift() ?? color;
            this.mutatorService.setElementPropertyValue(
                element,
                'textColor',
                prevColor,
                ElementChangeType.Skip
            );
        }

        this.isPreviewEnabled = false;
    }

    setUppercase(): void {
        for (const element of this.elements) {
            this.mutatorService.setElementPropertyValue(element, 'uppercase', this.uppercase);
        }
    }

    setUnderline(): void {
        for (const element of this.elements) {
            this.mutatorService.setElementPropertyValue(element, 'underline', this.underline);
        }
    }

    setStrikethrough(): void {
        for (const element of this.elements) {
            this.mutatorService.setElementPropertyValue(element, 'strikethrough', this.strikethrough);
        }
    }

    setHorizontalAlignment(horizontalAlignment: HorizontalAlignment): void {
        this.horizontalAlignment = horizontalAlignment;
        for (const element of this.elements) {
            this.mutatorService.setElementPropertyValue(
                element,
                'horizontalAlignment',
                horizontalAlignment
            );
        }
    }

    setVerticalAlignment(verticalAlignment: VerticalAlignment): void {
        this.verticalAlignment = verticalAlignment;
        for (const element of this.elements) {
            this.mutatorService.setElementPropertyValue(
                element,
                'verticalAlignment',
                verticalAlignment
            );
        }
    }

    setTextShadow(color: Color, index: number, eventType?: ElementChangeType): void {
        this.isPreviewEnabled = false;
        if (this.textShadows?.length) {
            const shadow = this.textShadows[index];
            shadow.color = color;
            shadow.colorMixed = false;
            this.setTextShadows(eventType);
        }
    }

    setTextShadows(eventType: ElementChangeType = ElementChangeType.Force): void {
        const textShadows =
            this.textShadows.map(
                (shadow): IMixedTextShadow => ({
                    offsetX: shadow.offsetX,
                    offsetY: shadow.offsetY,
                    blur: shadow.blur,
                    color: shadow.color,
                    colorMixed: shadow.colorMixed
                })
            ) || [];

        for (const element of this.elements) {
            if (!(this.elements.length > 1 && textShadows.length && element.textShadows)) {
                this.mutatorService.setElementPropertyValue(
                    element,
                    'textShadows',
                    textShadows,
                    eventType
                );
                continue;
            }

            const fixedTextShadows: ITextShadow[] = [];

            for (let i = 0; i < textShadows.length; i++) {
                let color: Color | undefined = textShadows[i].colorMixed
                    ? element.textShadows[i]?.color
                    : textShadows[i].color;

                if (this.isPreviewEnabled && i === this.shadowColorPreviewCache.index) {
                    color = this.shadowColorPreviewCache.colors.shift();
                }

                fixedTextShadows.push({
                    offsetX: textShadows[i].offsetX ?? element.textShadows[i].offsetX,
                    offsetY: textShadows[i].offsetY ?? element.textShadows[i].offsetY,
                    blur: textShadows[i].blur ?? element.textShadows[i].blur,
                    color: color || new Color()
                });
            }
            this.mutatorService.setElementPropertyValue(
                element,
                'textShadows',
                fixedTextShadows.length > 0 ? fixedTextShadows : textShadows,
                eventType
            );
        }
        this.isPreviewEnabled = false;
    }

    previewTextShadows(shadowIndex: number, color: Color): void {
        if (!this.isPreviewEnabled) {
            this.isPreviewEnabled = true;
            this.shadowColorPreviewCache = {
                index: shadowIndex,
                colors: this.elements.map(
                    element =>
                        element.__dirtyContent?.style.textShadows?.[shadowIndex].color ||
                        element.textShadows?.[shadowIndex].color
                )
            };
        }

        for (const element of this.elements) {
            let textShadows = element.__dirtyContent?.style.textShadows ||
                element.textShadows || [
                    {
                        color,
                        blur: 5,
                        offsetX: 0,
                        offsetY: 2
                    }
                ];
            textShadows = textShadows?.map(
                (shadow, i): ITextShadow => ({
                    ...shadow,
                    color: shadowIndex === i ? color : shadow.color
                })
            );

            this.mutatorService.setElementPropertyValue(
                element,
                'textShadows',
                textShadows,
                ElementChangeType.Skip
            );
        }
    }

    addTextShadows = (): void => {
        this.isPreviewEnabled = false;
        if (!this.textShadows) {
            this.textShadows = [];
        }

        this.textShadows.push({
            offsetX: 0,
            offsetY: 2,
            blur: 5,
            color: parseColor('rgba(0,0,0,0.25)'),
            colorMixed: false
        });

        this.setTextShadows();
    };

    clearTextShadow(shadow: IMixedTextShadow): void {
        if (!this.textShadows) {
            throw new Error('Cannot remove Text Shadow style, because it is not set.');
        }

        this.textShadows.splice(this.textShadows.indexOf(shadow), 1);
        if (this.textShadows.length === 0) {
            this.textShadows = [];
        }
        this.setTextShadows();
    }

    setCharacterSpacing(): void {
        if (!isNumber(this.characterSpacing.value)) {
            return;
        }

        for (const element of this.elements) {
            this.mutatorService.setElementPropertyValue(
                element,
                'characterSpacing',
                this.characterSpacing.value,
                ElementChangeType.Burst
            );
        }
    }

    setLineHeight(): void {
        if (!isNumber(this.lineHeight.value)) {
            return;
        }

        for (const element of this.elements) {
            this.mutatorService.setElementPropertyValue(
                element,
                'lineHeight',
                this.lineHeight.value,
                ElementChangeType.Burst
            );
        }
    }

    refocusTextElement(): void {
        if (this.viewElements.length === 1) {
            const richTextRenderer = this.viewElements[0]?.__richTextRenderer;
            richTextRenderer?.editor_m?.focus();
        }
    }

    onPreviewStop(): void {
        for (const element of this.elements) {
            const fonstStyle = element.font;
            this.mutatorService.setElementPropertyValue(
                element,
                'font',
                fonstStyle,
                ElementChangeType.Skip
            );
        }
    }

    setFontSize(): void {
        for (const element of this.elements) {
            this.mutatorService.setElementPropertyValue(
                element,
                'fontSize',
                this.fontSize.value,
                ElementChangeType.Burst
            );
        }
    }

    setMaxRows(): void {
        for (const element of this.elements) {
            this.mutatorService.setElementPropertyValue(
                element,
                'maxRows',
                Math.round(this.maxRows.value || 0),
                ElementChangeType.Burst
            );
        }
    }

    setPadding(): void {
        const padding = {
            top: this.paddingTop.value,
            left: this.paddingTop.value,
            right: this.paddingTop.value,
            bottom: this.paddingTop.value
        };
        for (const element of this.elements) {
            this.mutatorService.setElementPropertyValue(
                element,
                'padding',
                padding,
                ElementChangeType.Burst
            );
        }
    }

    setTextOverflow(textOverflow: TextOverflow): void {
        for (const element of this.elements) {
            this.mutatorService.setElementPropertyValue(element, 'textOverflow', textOverflow);
        }
    }

    async updateFontStyle(
        fontStyle: IFontFamilyStyle | undefined,
        preview?: boolean,
        isSelection?: boolean
    ): Promise<void> {
        if (!fontStyle) {
            return;
        }
        this.weight = fontStyle.weight;
        this.italic = !!fontStyle.italic;
        if (preview && !this.pendingFontChange) {
            this.pendingFontChange = true;
        }

        await this.mutatorService.renderer.injectFontFace(fontStyle);

        const newFontStyle: IFontStyle = fontStyle && {
            id: fontStyle.id,
            src: fontStyle.fontUrl,
            weight: fontStyle.weight,
            style: fontStyle.italic ? 'italic' : 'normal',
            fontFamilyId: fontStyle.fontFamilyId ?? ''
        };

        const changeType = preview ? ElementChangeType.Skip : ElementChangeType.Force;
        for (const element of this.elements) {
            this.mutatorService.setElementPropertyValue(element, 'font', newFontStyle, changeType);
        }

        if (isSelection) {
            this.historyService.checkDirtiness();
        }

        if (preview) {
            this.isPreviewFont = true;
        } else {
            this.isPreviewFont = false;
            this.fontFamilyId = fontStyle.fontFamilyId;
            this.fontStyleId = fontStyle.id;
            this.updateProperties();
            this.pendingFontChange = false;
            this.validateFont(fontStyle);
        }
    }

    emitUndo(): void {
        this.historyService.undo$.next();
    }

    emitRedo(): void {
        this.historyService.redo$.next();
    }

    private updateProperties = (): void => {
        if (this.elements.length === 0) {
            return;
        }

        const element = this.elements[0];

        const currentColor = element.__dirtyContent?.style.textColor || element.textColor;
        this.color.isMixed = this.elements.some(element => {
            const elementColor = element.__dirtyContent?.style.textColor ?? element.textColor;
            return !deepEqual(elementColor, currentColor);
        });

        const elementsShadows = this.elements.map(
            element => element.__dirtyContent?.style.textShadows || element.textShadows || []
        );
        this.textShadowsDifferentLength = elementsShadows.some(
            ({ length }) => length !== elementsShadows[0].length
        );

        for (let i = 0; i < this.elements.length; i++) {
            const multipleElements = this.elements.length > 1;
            const viewElement = this.viewElements.find(element => element?.id === this.elements[i].id);

            const richTextRenderer = viewElement?.__richTextRenderer;
            if (!richTextRenderer) {
                return;
            }

            const stateData =
                this.propertiesService.inStateView && !multipleElements ? this.stateData : {};
            const currentStyle = { ...richTextRenderer.editor_m?.getTextProperties(), ...stateData };

            this.textOverflow.isMixed =
                multipleElements && this.textOverflow.value !== currentStyle.textOverflow;
            this.textOverflow.value =
                this.textOverflow.isMixed && i !== 0 ? 'Mixed' : currentStyle.textOverflow;

            // Max rows
            this.maxRows.isMixed = multipleElements && this.maxRows.value !== currentStyle.maxRows;
            this.maxRows.value = this.maxRows.isMixed && i !== 0 ? undefined : currentStyle.maxRows;

            // Line Height
            this.lineHeight.isMixed =
                multipleElements && this.lineHeight.value !== currentStyle.lineHeight;
            this.lineHeight.value =
                this.lineHeight.isMixed && i !== 0 ? undefined : currentStyle.lineHeight;

            // Character spacing
            this.characterSpacing.isMixed =
                multipleElements && this.characterSpacing.value !== currentStyle.characterSpacing;
            this.characterSpacing.value =
                this.characterSpacing.isMixed && i !== 0 ? undefined : currentStyle.characterSpacing;

            // Padding
            this.paddingTop.isMixed =
                multipleElements && this.paddingTop.value !== currentStyle.padding?.top;
            this.paddingTop.value =
                this.paddingTop.isMixed && i !== 0 ? undefined : currentStyle.padding?.top;

            // Text decorations
            this.strikethrough =
                multipleElements && this.strikethrough !== currentStyle.strikethrough && i !== 0
                    ? false
                    : !!currentStyle.strikethrough;
            this.underline =
                multipleElements && this.underline !== currentStyle.underline && i !== 0
                    ? false
                    : !!currentStyle.underline;
            this.uppercase =
                multipleElements && this.uppercase !== currentStyle.uppercase && i !== 0
                    ? false
                    : !!currentStyle.uppercase;

            // Alignments
            this.horizontalAlignment =
                multipleElements &&
                this.horizontalAlignment !== currentStyle.horizontalAlignment &&
                i !== 0
                    ? ''
                    : currentStyle.horizontalAlignment || 'center';
            this.verticalAlignment =
                multipleElements && this.verticalAlignment !== currentStyle.verticalAlignment && i !== 0
                    ? ''
                    : currentStyle.verticalAlignment || 'middle';

            // Text shadows
            if (this.textShadowsDifferentLength) {
                this.textShadows = [];
            } else if (!this.textShadowsDifferentLength && i !== 0 && this.textShadows.length !== 0) {
                const textShadowPlaceHolder: IMixedTextShadow[] = [];
                const currentTextShadows = currentStyle.textShadows || [];

                for (let index = 0; index < currentTextShadows.length; index++) {
                    const textShadow = this.textShadows[index];
                    const currentTextShadow = currentTextShadows[index];
                    const isTextShadowColorMixed = !deepEqual(
                        textShadow.color,
                        currentTextShadow.color
                    );
                    const isOffsetXEqual = textShadow.offsetX === currentTextShadow.offsetX;
                    const isOffsetYEqual = textShadow.offsetY === currentTextShadow.offsetY;
                    const isBlurEqual = textShadow.blur === currentTextShadow.blur;

                    textShadowPlaceHolder.push({
                        colorMixed: isTextShadowColorMixed,
                        color: isTextShadowColorMixed ? new Color() : currentTextShadow.color,
                        offsetX: isOffsetXEqual ? currentTextShadow.offsetX : 0,
                        offsetY: isOffsetYEqual ? currentTextShadow.offsetY : 0,
                        blur: isBlurEqual ? currentTextShadow.blur : 0
                    });
                }
                if (!this.isPreviewEnabled) {
                    this.textShadows = textShadowPlaceHolder;
                }
            } else if (!this.isPreviewEnabled) {
                this.textShadows =
                    currentStyle.textShadows?.map(shadow => ({ ...shadow, colorMixed: false })) ?? [];
            }

            // Fonts
            this.fontSize.isMixed = multipleElements && this.fontSize.value !== currentStyle.fontSize;
            this.fontSize.value = this.fontSize.isMixed && i !== 0 ? undefined : currentStyle.fontSize;

            this.font = currentStyle.font;
            const currentFont = currentStyle.font as IFontStyle;
            this.fontFamilyId =
                multipleElements && this.fontFamilyId !== currentFont?.fontFamilyId && i !== 0
                    ? '$mixed'
                    : currentFont?.fontFamilyId;
            this.fontStyleId =
                multipleElements && this.fontStyleId !== currentFont?.id && i !== 0
                    ? '$mixed'
                    : currentFont?.id;

            if (!multipleElements && this.font === '$mixed') {
                this.fontFamilyId = currentStyle.__fontFamilyId;
            }

            if (this.font) {
                if (this.font === '$mixed') {
                    this.fontStyleId = '$mixed';
                    this.italic = false;
                    this.weight = 0;
                } else {
                    this.fontStyleId = this.fontStyleId !== '$mixed' ? this.font.id : '$mixed';
                    this.italic = this.font.style === 'italic';
                    this.weight = this.font.weight;
                }
            } else {
                this.fontStyleId = undefined;
                this.italic = false;
                this.weight = 0;
            }

            if (
                viewElement.__richTextRenderer?.editor_m?.inEditMode ||
                this.propertiesService.inStateView
            ) {
                if (!this.colorPreviewCache?.length) {
                    this.color.value = currentStyle.textColor?.copy() || new Color();
                }
            } else if (!this.colorPreviewCache?.length) {
                if (this.elements.length === 1) {
                    const mixedCharacterStyles = this.getMixedCharacterStyles(element);
                    this.color.isMixed = mixedCharacterStyles.has('textColor');
                }

                this.color.value =
                    this.elements[i].__dirtyContent?.style.textColor ||
                    this.elements[i]?.textColor ||
                    new Color();
            }

            if (this.propertiesService.inStateView && !this.stateData?.textColor) {
                this.hideProperties = true;
            } else {
                this.hideProperties = false;
            }
        }
    };

    private openFeedPopover = async ({
        node: element,
        variable: feed,
        richTextRenderer
    }: RichTextEvents['variableselected']): Promise<void> => {
        this.closeFeedPopover();
        const elementRef = new ElementRef(element);
        const config: IUIPopoverConfig = {
            position: 'top',
            arrowPosition: 'bottom'
        };

        if (!this.mutatorService.renderer.feedStore) {
            throw new Error('FeedStore is not provided');
        }

        await this.feedSettingsService.open(
            elementRef,
            element.id,
            feed,
            feed.step,
            this.mutatorService.renderer.feedStore,
            {},
            config
        );
        this.feedSettingsService.feedValueChanged$
            .pipe(takeUntil(this.closeFeed$))
            .subscribe(({ newFeed }) => {
                if (richTextRenderer.element_m && isVariable(newFeed, richTextRenderer.element_m)) {
                    this.feedValueChanged(newFeed, element, richTextRenderer);
                }
            });

        this.feedSettingsService.close$
            .pipe(
                takeUntil(this.closeFeed$),
                tap(() => {
                    this.historyService.saveLastSnapshotCandidate();
                    this.historyService.checkDirtiness();
                })
            )
            .subscribe(this.closeFeedPopover);
    };

    private feedValueChanged(
        feed: ITextVariable,
        element: HTMLSpanElement,
        richTextRenderer: IRichText
    ): void {
        if (!this.mutatorService.renderer.feedStore) {
            throw new Error('FeedStore is not provided');
        }

        const newFeed = cloneDeep(feed);

        richTextRenderer.feedStore_m.addFeedElement(element.id, newFeed, this.elements[0], true);

        const elements = Array.from(richTextRenderer.feedStore_m.elements.entries());

        for (const [id, el] of elements) {
            if (el.feed.id === newFeed.id && el.feed.path === newFeed.path && element.id === id) {
                richTextRenderer.editor_m?.updateVariableLabels(el, newFeed, richTextRenderer);
            }
        }

        this.editorEventService.elements.change<OneOfTextDataNodes>(this.elements[0], {
            content: this.elements[0].__dirtyContent
        });
    }

    closeFeedPopover = (): void => {
        this.closeFeed$.next();
    };

    hideFeedPopover = (): void => {
        this.feedSettingsService.close();
    };

    private stopLastRichTextRender(): void {
        if (this._lastElement && this._lastElement.__richTextRenderer) {
            this.stopListenToRichTextRender(this._lastElement.__richTextRenderer);
        }
    }

    private startActiveRichTextRender(): void {
        if (!this.viewElements[0]) {
            return;
        }

        const richText = this.viewElements[0].__richTextRenderer;
        if (richText) {
            this.startListenToRichTextRender(richText);
        }

        this._lastElement = this.viewElements[0];
    }

    private stopActiveRichTextRender(): void {
        if (this.viewElements.length === 0 || !this.viewElements[0]) {
            return;
        }
        const richText = this.viewElements[0].__richTextRenderer;
        if (richText) {
            this.stopListenToRichTextRender(richText);
        }
    }

    private startListenToRichTextRender(richTextRenderer: IRichText): void {
        richTextRenderer.on('interactionsEnded', this.updateProperties);
        richTextRenderer.on('change', this.updateProperties);
        richTextRenderer.on('selectionchange', this.updateProperties);
        richTextRenderer.on('variableselected', this.openFeedPopover);
        richTextRenderer.on('hidevariablesettings', this.hideFeedPopover);
        richTextRenderer.on('blur', this.onRichTextBlur);
    }

    private stopListenToRichTextRender(richTextRenderer: IRichText): void {
        richTextRenderer.off('interactionsEnded', this.updateProperties);
        richTextRenderer.off('change', this.updateProperties);
        richTextRenderer.off('selectionchange', this.updateProperties);
        richTextRenderer.off('variableselected', this.openFeedPopover);
        richTextRenderer.off('hidevariablesettings', this.hideFeedPopover);
        richTextRenderer.off('blur', this.onRichTextBlur);
    }

    private startHotkeyListeners(): void {
        this.hotkeyService.on('ToggleItalicTextStyle', this.toggleItalicTextStyle);
        this.hotkeyService.on('ToggleBoldTextStyle', this.toggleBoldTextStyle);
        this.hotkeyService.on('ToggleUnderlineTextStyle', this.toggleUnderlineTextStyle);
    }

    private stopHotkeyListeners(): void {
        this.hotkeyService.off('ToggleItalicTextStyle', this.toggleItalicTextStyle);
        this.hotkeyService.off('ToggleBoldTextStyle', this.toggleBoldTextStyle);
        this.hotkeyService.off('ToggleUnderlineTextStyle', this.toggleUnderlineTextStyle);
    }

    private setDefaultFontIds(): void {
        const content = this.propertiesService.getPlaceholderValue('content');

        // Might get undefined while destroying the component (only when inDebugMode maybe?)
        if (content) {
            this.defaultFontFamilyId = content.fontFamilyId;
            this.defaultFontStyleId = content.id;
        }
    }

    trackByIndex = (index: number): number => index; //  index not optimal, implement id. but since removing a shadow closes the picker, it does not show a problem

    private onRichTextBlur = (): void => {
        const fonts: ValidateFontParams[] = [];
        for (const element of this.elements) {
            const viewElement = this.viewElements.find(
                vElement => vElement && vElement.id === element.id
            );

            if (!element || !viewElement) {
                continue;
            }

            const selectedCharacters = viewElement.__richTextRenderer?.selectedCharacters;
            if (selectedCharacters) {
                fonts.push({
                    elementId: element.id,
                    elementName: element.name,
                    fontStyle: this.elements[0].font,
                    text: selectedCharacters
                });
                continue;
            }

            const textFontMap = this.fontValidationService.spansToMap(
                element.__dirtyContent?.spans || element.content.spans,
                element.font!
            );

            for (const textFontValue of Object.values(textFontMap)) {
                const { font, text } = textFontValue;
                fonts.push({
                    elementId: element.id,
                    elementName: element.name,
                    fontStyle: font,
                    text
                });
            }
        }
        this.fontValidationService.validateFonts(fonts);
    };

    private validateFont(fontStyle?: IFontFamilyStyle | IFontStyle): void {
        this.fontValidationService.validateElements(
            this.elements,
            this.feedSettingsService.feedStore,
            fontStyle as IFontStyle
        );
    }

    private getMixedCharacterStyles(element: OneOfTextDataNodes): Set<keyof IMixedCharacterProperties> {
        const spans = (element.__dirtyContent?.spans ?? element.content.spans).filter(isContentSpan);

        const mixedStyles = new Set<keyof IMixedCharacterProperties>();
        const properties = Object.keys(
            characterPropertyToUnitMap
        ) as (keyof IMixedCharacterProperties)[];

        for (const property of properties) {
            for (const spanA of spans) {
                for (const spanB of spans.slice(1)) {
                    if (!hasSameStyleProperty(property, spanA.style, spanB.style)) {
                        mixedStyles.add(property);
                    }
                }
            }
        }

        return mixedStyles;
    }

    ngOnDestroy(): void {
        this.stopLastRichTextRender();
        this.stopActiveRichTextRender();
        this.stopHotkeyListeners();

        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.closeFeed$.next();
        this.closeFeed$.complete();
    }
}
