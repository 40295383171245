import { Injectable, OnDestroy } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Logger } from '@bannerflow/sentinel-logger';
import { UINotificationService, UINotificationType } from '@bannerflow/ui';
import { CreativeSize } from '@domain/creativeset';
import { ICreative } from '@domain/creativeset/creative/creative';
import { LayerItem } from '@domain/psd';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { CreativesetDataService } from '../../../../shared/creativeset/creativeset.data.service';
import { EditCreativeService } from '../../services/edit-creative.service';
import { SizeAddService } from '../../size-add-dialog/size-add.service';
import { hasCriticalError } from '../conversion-errors';
import { CreativeConverterUploadService } from '../creative-converter-upload.service';
import { CreativeConverterService } from '../creative-converter.service';
import { isPSDGroupElement } from './psd-reader';

@Injectable()
export class PsdImportService implements OnDestroy {
    private _creative: BehaviorSubject<ICreative> = new BehaviorSubject(
        undefined as unknown as ICreative
    );
    private _layers: BehaviorSubject<LayerItem[]> = new BehaviorSubject([] as LayerItem[]);
    private _isSaving: BehaviorSubject<boolean> = new BehaviorSubject(false);

    creative$: Observable<ICreative> = this._creative.asObservable();
    layers$: Observable<LayerItem[]> = this._layers.asObservable();
    isSaving$: Observable<boolean> = this._isSaving.asObservable();
    selectedPsdLayers$: Observable<LayerItem[]>;

    private logger = new Logger('PsdImportService');

    constructor(
        private creativeConverterService: CreativeConverterService,
        private creativeConverterUploadService: CreativeConverterUploadService,
        private creativesetDataService: CreativesetDataService,
        private uiNotificationService: UINotificationService,
        private editCreativeService: EditCreativeService,
        private sizeAddService: SizeAddService
    ) {
        this.creativeConverterService.convertedCreative$
            .pipe(takeUntilDestroyed())
            .subscribe(({ creative, blueprint }) => {
                this._creative.next(creative);
                this._layers.next(
                    blueprint.data.flatChildren.map(layer => ({
                        ...layer,
                        selected: !layer.hidden,
                        collapsed: false
                    }))
                );
            });

        this.creativeConverterUploadService.assetUploadComplete$
            .pipe(takeUntilDestroyed())
            .subscribe(design => {
                this.logger.verbose('Asset upload complete');

                if (this.creativeConverterUploadService.imageUploadFailed()) {
                    const failedImages = this.creativeConverterUploadService.getFailedImages();

                    failedImages.forEach(nodeId => {
                        design.elements = design.elements.filter(element => element.id !== nodeId);
                        design.document.removeNodeById_m(nodeId);
                    });
                }

                this._creative.next({ ...this._creative.value, design });
                this.storeCreative();
            });

        this.selectedPsdLayers$ = this.layers$.pipe(
            map(layers =>
                layers.filter(
                    layer => layer.selected && !hasCriticalError(layer) && layer.type !== 'group'
                )
            )
        );
    }

    ngOnDestroy(): void {
        this.creativeConverterUploadService.uploadSubscription.unsubscribe();
    }

    toggleAllSelection(): void {
        const isSelected = !this._layers.value.some(
            layer => layer.selected && !hasCriticalError(layer) && !isPSDGroupElement(layer)
        );

        this._layers.value.forEach(layer => {
            this.toggleSelection(layer, isSelected);
        });
    }

    toggleGroupVisibility(groupLayer: LayerItem, hidden?: boolean): void {
        const childLayers = this.getChildLayers(groupLayer);
        if (!childLayers || childLayers.length === 0) {
            return;
        }
        const isHidden =
            hidden ??
            !childLayers.some(
                layer => layer.hidden && !hasCriticalError(layer) && !isPSDGroupElement(layer)
            );

        childLayers.forEach(layer => {
            if (isPSDGroupElement(layer)) {
                this.toggleGroupVisibility(layer, isHidden);
            } else {
                this.toggleVisibility(layer, isHidden);
            }
        });

        this.toggleVisibility(groupLayer, isHidden);
    }

    toggleVisibility(layer: LayerItem, hidden?: boolean): void {
        if (hasCriticalError(layer)) {
            return;
        }

        const updatedLayers = this._layers.value.map(l =>
            l.id === layer.id ? { ...l, hidden: hidden ?? !l.hidden } : l
        );
        this._layers.next(updatedLayers);

        const document = this._creative.value.design!.document;
        for (const node of document.nodeIterator_m()) {
            if (node.id === layer.id) {
                node.hidden = !layer.hidden;
                break;
            }
        }
    }

    toggleGroupSelection(groupLayer: LayerItem, selected?: boolean): void {
        const childLayers = this.getChildLayers(groupLayer);
        if (!childLayers || childLayers.length === 0) {
            return;
        }
        const isSelected =
            selected ??
            !childLayers.some(
                layer => layer.selected && !hasCriticalError(layer) && !isPSDGroupElement(layer)
            );

        childLayers.forEach(layer => {
            if (isPSDGroupElement(layer)) {
                this.toggleGroupSelection(layer, isSelected);
            } else {
                this.toggleSelection(layer, isSelected);
            }
        });

        this.toggleSelection(groupLayer, isSelected);
    }

    toggleSelection(layer: LayerItem, selected?: boolean): void {
        if (hasCriticalError(layer)) {
            return;
        }

        const updatedLayers = this._layers.value.map(l =>
            l.id === layer.id ? { ...l, selected: selected ?? !l.selected } : l
        );
        this._layers.next(updatedLayers);
    }

    toggleGroupCollapse(groupLayer: LayerItem, collapsed?: boolean): void {
        const childLayers = this.getChildLayers(groupLayer);
        if (!childLayers || childLayers.length === 0) {
            return;
        }

        const isCollapsed =
            collapsed ?? !childLayers.some(layer => layer.collapsed && !isPSDGroupElement(layer));

        childLayers.forEach(layer => {
            if (isPSDGroupElement(layer)) {
                this.toggleGroupCollapse(layer, isCollapsed);
            } else {
                this.toggleCollapse(layer, isCollapsed);
            }
        });

        this.toggleCollapse(groupLayer, isCollapsed);
    }

    toggleCollapse(layer: LayerItem, collapsed?: boolean): void {
        const updatedLayers = this._layers.value.map(l =>
            l.id === layer.id ? { ...l, collapsed: collapsed ?? !l.collapsed } : l
        );
        this._layers.next(updatedLayers);
    }

    getChildLayers(groupLayer: LayerItem): LayerItem[] {
        const children: LayerItem[] = [];

        const traverseChildren = (layer: LayerItem): void => {
            if (isPSDGroupElement(layer) && layer.data.children) {
                layer.data.children.forEach(child => {
                    const childLayer = this._layers.value.find(l => l.id === child.id);
                    if (childLayer) {
                        children.push(childLayer);
                        traverseChildren(childLayer);
                    }
                });
            }
        };

        traverseChildren(groupLayer);

        return children;
    }

    resetPsd(): void {
        this._layers.next([]);
        this._creative.next(undefined as any);
    }

    async saveCreative(): Promise<void> {
        this._isSaving.next(true);
        this.cleanDesign();
        const design = this._creative.value.design!;

        if (this.creativeConverterUploadService.hasAssetsToUpload(design)) {
            // upload all assets and replace base64 with URLs
            await this.creativeConverterUploadService.uploadCreativeAssets(design);
        } else {
            // if nothing needs to be uploaded, save right away
            await this.storeCreative();
        }
    }

    private async storeCreative(): Promise<void> {
        await this.createSize();
        await this.createDesign();
        this.showNotification('Creative saved successfully', 'info');
        this._isSaving.next(false);
    }

    private async createSize(): Promise<void> {
        try {
            this.logger.verbose('Creating new size');
            const newSizes = await this.addCreativeSize();
            // find the created size again. Should be done via Ids but right now we can only track sizes by unique names
            const size: CreativeSize | undefined = newSizes.find(
                newSize => newSize.name === this._creative.value.size.name
            );
            if (!size) {
                throw new Error('Size was not created or found');
            }
            // set the newly created size on the psd creative
            this._creative.next({ ...this._creative.value, size });
        } catch (e) {
            this.showNotification(
                `There was an error when creating the creative size. Please try again. If the problem persists, please contact our support team for assistance. We apologize for any inconvenience.`,
                'error'
            );
        }
    }

    private async createDesign(): Promise<void> {
        try {
            this.logger.verbose('Activating new creative design');
            await this.editCreativeService.saveNewCreative(this._creative.value);
        } catch (e) {
            this.showNotification(
                `There was an error when creating the creative design. Please try again. If the problem persists, please contact our support team for assistance. We apologize for any inconvenience.`,
                'error'
            );
        }
    }
    private async addCreativeSize(): Promise<CreativeSize[]> {
        return this.sizeAddService.addSizes(this.creativesetDataService.creativeset.id, [
            this._creative.value.size
        ]);
    }

    private cleanDesign(): void {
        const design = this._creative.value.design!;
        const document = design.document;

        // remove not selected elements from design
        for (const layer of this._layers.value) {
            if (!layer.selected) {
                document.removeNodeById_m(layer.id);
                design.elements = design.elements.filter(element => element.id !== layer.id);
            }
        }

        // remove empty groups
        document.clearEmptyChildren();
    }

    private showNotification(text: string, type: UINotificationType): void {
        this.uiNotificationService.open(text, {
            type,
            placement: 'top',
            autoCloseDelay: 5000
        });
    }
}
