import {
    IAnimation,
    IAnimationKeyframe,
    IAnimationSetting,
    IAnimationSettings
} from '@domain/animation';
import { AnimationDto, KeyframeDto, SettingDto } from '@domain/api/generated/sapi';
import { TimingFunctionKey, TimingFunctionKeyMacro } from '@domain/timing-functions';
import { uuidv4 } from '@studio/utils/id';
import { decimal, omit } from '@studio/utils/utils';

export function convertAnimationToDto(animation: IAnimation): AnimationDto {
    const keyframes = animation.keyframes.map(keyframe => convertKeyframeToDto(keyframe));
    const settings: SettingDto[] = convertAnimationSettingsToDto(animation.settings);

    return {
        keyframes,
        id: animation.id,
        name: animation.name,
        type: animation.type,
        timingFunction: animation.timingFunction,
        templateId: animation.templateId,
        settings,
        hidden: animation.hidden || false
    };
}

export function convertAnimationSettingsToDto(settings?: IAnimationSettings): SettingDto[] {
    const settingsDto: SettingDto[] = [];
    if (settings) {
        for (const key in settings) {
            const setting = settings[key as keyof IAnimationSettings]!;
            settingsDto.push({
                key,
                name: setting.name,
                value: setting.value
            });
        }
    }
    return settingsDto;
}

export function deserializeAnimation(animationDto: AnimationDto): IAnimation {
    const keyframes = animationDto.keyframes.map(keyframe => deserializeKeyframe(keyframe));

    const settings = deserializeAnimationSettings(animationDto.settings);

    return {
        keyframes,
        id: animationDto.id,
        name: animationDto.name,
        type: animationDto.type,
        timingFunction: animationDto.timingFunction as TimingFunctionKey,
        templateId: animationDto.templateId,
        settings,
        hidden: animationDto.hidden || false
    };
}

export function deserializeAnimationSettings(settings?: SettingDto[]): IAnimationSettings | undefined {
    if (settings?.length) {
        return settings.reduce((acc, curr) => {
            acc[curr['key'] as keyof IAnimationSettings] = omit(curr, 'key') as IAnimationSetting;
            return acc;
        }, {} as IAnimationSettings);
    }
    return undefined;
}

function deserializeKeyframe(keyframe: KeyframeDto): IAnimationKeyframe {
    const { id, stateId, time, timingFunction } = keyframe;
    const duration = keyframe.duration || 0;

    return {
        id: id || uuidv4(true)!,
        stateId,
        time,
        timingFunction: timingFunction as TimingFunctionKeyMacro | TimingFunctionKey,
        duration
    };
}

function convertKeyframeToDto(keyframe: IAnimationKeyframe): KeyframeDto {
    return {
        id: keyframe.id,
        stateId: keyframe.stateId,
        timingFunction: keyframe.timingFunction,
        time: decimal(keyframe.time),
        duration: decimal(keyframe.duration)
    };
}
