import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { VersionsEffects } from './versions.effects';
import * as fromVersions from './versions.reducer';
import { VersionsService } from './versions.service';

@NgModule({
    imports: [
        StoreModule.forFeature(fromVersions.VERSIONS_FEATURE_KEY, fromVersions.reducer),
        EffectsModule.forFeature([VersionsEffects])
    ],
    providers: [VersionsService]
})
export class VersionsStoreModule {}
