import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AnimationControlsEffects } from './animation-control.effects';
import * as animationControl from './animation-control.reducer';

@NgModule({
    imports: [
        EffectsModule.forFeature([AnimationControlsEffects]),
        StoreModule.forFeature(animationControl.ANIMATION_CONTROL_FEATURE_KEY, animationControl.reducer)
    ]
})
export class AnimationControlStoreModule {}
