import { CommonModule } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild,
    inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Title } from '@angular/platform-browser';
import { RouterLink } from '@angular/router';
import { Logger } from '@bannerflow/sentinel-logger';
import {
    UIConfirmDialogService,
    UIDialogService,
    UIModule,
    UINotificationService
} from '@bannerflow/ui';
import { ICampaignStatus } from '@domain/campaign';
import { ICreative } from '@domain/creativeset/creative/creative';
import { ICreativeset } from '@domain/creativeset/creativeset';
import { CreativeSize } from '@domain/creativeset/size';
import { IVersion } from '@domain/creativeset/version';
import { IHotkeyContext } from '@domain/hotkeys/hotkeys.types';
import { BrowserDefaultHotkeys } from '@studio/hotkeys';
import { Breakpoint } from '@studio/utils/breakpoints';
import { hasDesign } from '@studio/utils/design.utils';
import {
    isChildOfSelector,
    isElementDescendantOfElement,
    isElementDescendantOfElementWithClass
} from '@studio/utils/dom-utils';
import { SimpleCache } from '@studio/utils/simple-cache';
import { getParser } from 'bowser';
import { BehaviorSubject, Observable, Subject, merge } from 'rxjs';
import { filter, map, mergeMap, switchMap, take, takeUntil } from 'rxjs/operators';
import { DevtoolsComponent } from '../../core/devtools/devtools.component';
import { NavigationGuard } from '../../routes/navigation.guard';
import { AnimationControlComponent } from '../../shared/animation-control/animation-control.component';
import { AnimationControlService } from '../../shared/animation-control/animation-control.service';
import { CommentsOverviewComponent } from '../../shared/components/comments-overview/comments-overview.component';
import { CurrentUsersComponent } from '../../shared/components/current-users/current-users.component';
import { FeatureToggleComponent } from '../../shared/components/feature-toggle/feature-toggle.component';
import { HelpMenuComponent } from '../../shared/components/help-menu/help-menu.component';
import { MarkDoneButtonComponent } from '../../shared/components/mark-done-button/mark-done-button.component';
import { ToggleEnvironmentComponent } from '../../shared/components/toggle-environment/toggle-environment.component';
import { TopbarContextMenuComponent } from '../../shared/components/topbar-context-menu/topbar-context-menu.component';
import { VersionPickerComponent } from '../../shared/components/version-picker/version-picker.component';
import { CreativeListComponent } from '../../shared/creative-list/creative-list.component';
import { CreativesetDataService } from '../../shared/creativeset/creativeset.data.service';
import { MediaDirective } from '../../shared/directives/media.directive';
import { PermissionsDirective } from '../../shared/directives/permissions.directive';
import { TruncateSpanComponent } from '../../shared/directives/truncate-span.directive';
import { DisplayCampaignService } from '../../shared/display-campaign/state/display-campaign.service';
import { isCreativeInCampaign } from '../../shared/display-campaign/state/display-campaign.utils';
import { FiltersService } from '../../shared/filters/filters.service';
import { EnvironmentService } from '../../shared/services/environment.service';
import { HotkeyBetterService } from '../../shared/services/hotkeys/hotkey.better.service';
import { PointerStatesService } from '../../shared/services/pointer-state.service';
import { SessionStorageService } from '../../shared/services/session-storage.service';
import { StudioRoutingService } from '../../shared/services/studio-routing.service';
import {
    IVideoValidationState,
    VideoValidationService
} from '../../shared/services/video-validation.service';
import { StudioTopbarComponent } from '../../shared/studio-topbar/studio-topbar.component';
import { UserSettingsService } from '../../shared/user-settings/state/user-settings.service';
import { UserService } from '../../shared/user/state/user.service';
import { VersionsService } from '../../shared/versions/state/versions.service';
import { getManageViewTitle } from '../page-title-util';
import { SizePickerComponent } from '../translation-page/translation-topbar/size-picker/size-picker.component';
import { CampaignActionsComponent } from './campaign-actions/campaign-actions.component';
import { MVContextMenuComponent } from './context-menu/context-menu.component';
import { ManageViewContextMenuService } from './context-menu/manage-view-context-menu.service';
import { CreativeListItemMenuComponent } from './creative-list-item-menu/creative-list-item-menu.component';
import { CreativeListItemPreviewComponent } from './creative-list-item-preview/creative-list-item-preview.component';
import { CreativeListItemSelectComponent } from './creative-list-item-select/creative-list-item-select.component';
import { CreativeListItemTitleComponent } from './creative-list-item-title/creative-list-item-title.component';
import { CreativeListItemWeightComponent } from './creative-list-item-weight/creative-list-item-weight.component';
import { ExportCreativeDataService } from './export-creative';
import { FilterListComponent } from './filter-list/filter-list.component';
import { HeavyVideoIndicatorComponent } from './heavy-video-indicator/heavy-video-indicator.component';
import { ManageMenuComponent } from './manage-menu/manage-menu.component';
import { EditCreativeService } from './services/edit-creative.service';
import { TileSelectService } from './services/tile-select.service';
import { SizeAddDialogComponent } from './size-add-dialog/size-add-dialog.component';
import { SizeListHorizontalComponent } from './size-list-horizontal/size-list-horizontal.component';
import { SizeSelectorComponent } from './size-selector/size-selector.component';
import { TranslationPanelComponent } from './translation-panel/translation-panel.component';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        UIModule,
        RouterLink,
        PermissionsDirective,
        TranslationPanelComponent,
        MediaDirective,
        TruncateSpanComponent,
        StudioTopbarComponent,
        FeatureToggleComponent,
        ToggleEnvironmentComponent,
        CurrentUsersComponent,
        CommentsOverviewComponent,
        CampaignActionsComponent,
        MarkDoneButtonComponent,
        SizeListHorizontalComponent,
        ManageMenuComponent,
        TopbarContextMenuComponent,
        VersionPickerComponent,
        CreativeListItemPreviewComponent,
        CreativeListItemWeightComponent,
        HeavyVideoIndicatorComponent,
        CreativeListComponent,
        CreativeListItemSelectComponent,
        CreativeListItemTitleComponent,
        CreativeListItemMenuComponent,
        MVContextMenuComponent,
        AnimationControlComponent,
        SizeSelectorComponent,
        FilterListComponent,
        SizePickerComponent,
        DevtoolsComponent,
        HelpMenuComponent
    ],
    templateUrl: './manage-view.component.html',
    styleUrls: ['./manage-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManageViewComponent implements OnInit, OnDestroy, AfterViewInit {
    private displayCampaignService = inject(DisplayCampaignService);

    @ViewChild('scrollView') scrollView: ElementRef;
    @ViewChild('filterList', { static: true }) filterList: FilterListComponent;

    @HostListener('window:mousedown', ['$event']) w = (event: MouseEvent): void => {
        if (event.button !== 2) {
            if (!isElementDescendantOfElementWithClass(event.target, 'ui-dropdown-list')) {
                this.manageViewContextMenuService.close();
            }
        }
    };

    toggleOtherSizes = (): void => this.applyToggleOtherSizesShortcut();
    deselectAllShortcut = (): void => this.selectShortcut(false);
    selectAllShortcut = (): void => this.selectShortcut(true);
    deleteSelectedShortcut = (): void => this.deleteShortcut();
    copySelected = (): void => this.editCreativeService.copyDesign();
    pasteSelected = (): Promise<void> => this.editCreativeService.pasteDesign();
    navigateToTPShortcut = (): void => this.studioRoutingService.navigateToTP();

    hideTranslationPanel = false;

    private logger = new Logger('ManageView', true);

    private unsubscribe$ = new Subject<void>();

    campaigns: ICampaignStatus[] = [];
    sizes$ = new BehaviorSubject<CreativeSize[]>([]);
    selectedSize: CreativeSize;
    isTopBarStatic = false;

    isMobile$: Observable<boolean>;
    isMobileShowcase$: Observable<boolean>;

    creativeset: ICreativeset;

    private get creatives(): ICreative[] {
        return this.creativesetDataService.creativeset.creatives.map(val => val);
    }

    private inShowcaseMode: boolean;
    private downloadIsPending = false;

    showButtons = false;

    selectedVersion$: Observable<IVersion>;
    hasActiveFilters$: Observable<boolean>;
    isShowingMultipleVersions$: Observable<boolean>;
    isShowingAllVersions$: Observable<boolean>;
    inShowcaseMode$: Observable<boolean>;
    isEmployee$ = this.userService.isEmployee$;

    Breakpoint = Breakpoint;

    constructor(
        private navigationGuard: NavigationGuard,
        private uiConfirmDialogService: UIConfirmDialogService,
        private hotkeyBetterService: HotkeyBetterService,
        private pointerStatesService: PointerStatesService,
        private userService: UserService,
        private sessionStorageService: SessionStorageService,
        private videoValidationService: VideoValidationService,
        private exportCreativeDataService: ExportCreativeDataService,
        private titleService: Title,
        private editCreativeService: EditCreativeService,
        private manageViewContextMenuService: ManageViewContextMenuService,
        private environmentService: EnvironmentService,
        private uiNotificationService: UINotificationService,
        private uiDialogService: UIDialogService,
        private animationControlService: AnimationControlService,
        private filtersService: FiltersService,
        private versionsService: VersionsService,
        private creativesetDataService: CreativesetDataService,
        private tileSelectService: TileSelectService,
        private studioRoutingService: StudioRoutingService,
        private userSettingsService: UserSettingsService
    ) {
        this.environmentService.setPage('MV');

        this.environmentService.inShowcaseMode$.pipe(take(1)).subscribe(inShowcaseMode => {
            this.inShowcaseMode = inShowcaseMode;
            this.environmentService.inShowcaseMode = inShowcaseMode;
            this.hideTranslationPanel =
                inShowcaseMode && !this.userService.operationsAllowed(['updateVersions']);
            this.isTopBarStatic =
                inShowcaseMode && this.userService.operationsAllowed(['setApprovalStatusOnCreatives']);
        });

        this.creativesetDataService.creativeset$
            .pipe(takeUntilDestroyed())
            .subscribe(creativeset => (this.creativeset = creativeset));

        this.isShowingMultipleVersions$ = this.filtersService.isShowingMultipleVersions$;
        this.isShowingAllVersions$ = this.filtersService.isShowingAllVersions$;
        this.selectedVersion$ = this.versionsService.selectedVersion$;
        this.hasActiveFilters$ = this.filtersService.hasActiveFilters$;
        this.inShowcaseMode$ = this.environmentService.inShowcaseMode$;
        this.isMobile$ = this.environmentService.isMobile$;
        this.isMobileShowcase$ = this.environmentService.isMobileShowcase$;

        this.filtersService.initFilters(this.creativeset.creatives.map(({ size: { id } }) => id));

        this.setupHotkeyListeners();
    }

    ngOnInit(): void {
        this.userSettingsService.sharedSettings$.pipe(take(1)).subscribe(settings => {
            if (settings.lastLocation !== 'TranslationPage') {
                this.filtersService.selectPreviousVersionSelection();
            }
            this.userSettingsService.setSharedSetting('lastLocation', 'ManageView');
        });

        this.resize();

        this.editCreativeService.init();
        this.navigationGuard.addPristineUnloadCheck(this.isPristine);
        this.navigationGuard.addPristineCheck(this.checkPristineState);

        this.tileSelectService.deselectAllCreatives();

        this.userService.isEmployee$.pipe(take(1), filter(Boolean)).subscribe(() => {
            window.managePage = this;
        });

        this.environmentService.inShowcaseMode$.pipe(take(1)).subscribe(inShowcaseMode => {
            this.inShowcaseMode = inShowcaseMode;
            this.environmentService.inShowcaseMode = inShowcaseMode;
            this.hideTranslationPanel =
                inShowcaseMode && !this.userService.operationsAllowed(['updateVersions']);
        });

        this.setManageViewTitle();

        /* combineLatest needs atleast a startvalue from each before it fires for the first time */
        merge(this.editCreativeService.updateView$, this.versionsService.selectedVersions$)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.updateView();
            });

        this.exportCreativeDataService.exportVideoResult$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(videoExportResult => {
                this.uiNotificationService.open(videoExportResult.message, {
                    type: videoExportResult.type,
                    autoCloseDelay: 5000,
                    placement: 'top'
                });
            });

        this.versionsService.selectedVersions$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.tileSelectService.deselectAllCreatives();
        });

        this.videoValidationService.videoValidationChange$
            .pipe(
                takeUntil(this.unsubscribe$),
                map(videoValidationResults =>
                    videoValidationResults.filter(
                        result => result.id === this.creativeset.id && result.hasHeavyVideo
                    )
                ),
                mergeMap(videoValidationResults =>
                    this.videoValidationService.filterIgnoredResults(videoValidationResults)
                ),
                filter(videoValidationResults => !!videoValidationResults.length)
            )
            .subscribe(videoValidationResults => {
                const visitedCreativesetsWithHeavyVideos =
                    this.sessionStorageService.getItem(`visitedCreativesetsWithHeavyVideos`) || [];
                const isCreativesetSavedInSessionStorage =
                    visitedCreativesetsWithHeavyVideos.indexOf(this.creativeset.id)! > -1;
                if (!isCreativesetSavedInSessionStorage) {
                    this.processHeavyVideoResult(videoValidationResults);
                    this.sessionStorageService.setSessionStorageItem(
                        'visitedCreativesetsWithHeavyVideos',
                        JSON.stringify([...visitedCreativesetsWithHeavyVideos, this.creativeset.id])
                    );
                }
            });

        if (!this.isSupportBrowser()) {
            this.uiNotificationService.open(
                `You will get the best experience with the Bannerflow
                     showcase on the latest versions of Safari and Google Chrome browsers. We cannot
                     guarantee it on your current browser.`,
                {
                    placement: 'bottom',
                    type: 'warning'
                }
            );
        }
    }

    ngAfterViewInit(): void {
        if (!this.creativeset.creatives.length) {
            this.openAddSizeDialog();
        }

        if (this.applyLastScrollPosition()) {
            this.scrollView.nativeElement.scrollTo(0, this.studioRoutingService.lastScrollPosition);
        }

        this.validateVideos();
    }

    private applyLastScrollPosition(): boolean {
        if (this.studioRoutingService.lastScrollPosition === 0 || !this.creatives.length) {
            return false;
        }

        return true;
    }

    private validateVideos(): void {
        this.videoValidationService.validateElements(this.creativeset);
    }

    private processHeavyVideoResult(videoValidationResults: IVideoValidationState[]): void {
        const message = `One or multiple sizes in this creative set contain a heavy video (over 2MB). Possibility to use these sizes in display campaigns will be limited. <a target='_blank' href="http://support.bannerflow.com/en/articles/6401112-working-with-heavy-videos">Read more here</a>.`;
        this.uiNotificationService.open(
            message,
            { type: 'warning', placement: 'top' },
            'Don’t show this again',
            true
        );
        this.uiNotificationService.notificationRef.instance.onCloseCheckBox
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(isCheckboxChecked => {
                if (isCheckboxChecked) {
                    this.videoValidationService.updateIgnoredResults(videoValidationResults);
                }
            });
    }

    async setupHotkeyListeners(): Promise<void> {
        this.logger.verbose('Setting up hotkeys');
        const hotkeyContext: IHotkeyContext = {
            name: 'ManagePage',
            input: window,
            keyDefaultBehaviourExclusions: Object.values(BrowserDefaultHotkeys)
        };

        if (this.inShowcaseMode) {
            hotkeyContext.name = 'Showcase';
        }

        this.hotkeyBetterService.pushContext(hotkeyContext);

        this.hotkeyBetterService.on('ToggleOtherSizes', this.toggleOtherSizes);
        this.hotkeyBetterService.on('Deselect', this.deselectAllShortcut);
        this.hotkeyBetterService.on('SelectAll', this.selectAllShortcut);

        if (this.userService.canChangeDesign) {
            this.hotkeyBetterService.on('DeleteSize', this.deleteSelectedShortcut);
        }

        if (await this.userService.hasPermission('StudioTranslationPage')) {
            this.hotkeyBetterService.on('NavigateToTP', this.navigateToTPShortcut);
        }

        this.hotkeyBetterService.on('Copy', this.copySelected);
        this.hotkeyBetterService.on('Paste', this.pasteSelected);
    }

    removeHotkeyListeners(): void {
        this.logger.verbose('Removing hotkeys');
        this.hotkeyBetterService.off('ToggleOtherSizes', this.toggleOtherSizes);
        this.hotkeyBetterService.off('Deselect', this.deselectAllShortcut);
        this.hotkeyBetterService.off('SelectAll', this.selectAllShortcut);
        this.hotkeyBetterService.off('DeleteSize', this.deleteSelectedShortcut);
        this.hotkeyBetterService.off('Copy', this.copySelected);
        this.hotkeyBetterService.off('Paste', this.pasteSelected);
        this.hotkeyBetterService.off('NavigateToTP', this.navigateToTPShortcut);
        this.hotkeyBetterService.popContext();
    }

    setManageViewTitle(): void {
        this.titleService.setTitle(getManageViewTitle(this.creativeset.name));
    }

    scrollViewMouseEnter = (): void => this.pointerStatesService.managePageScrollViewHovered.next(true);
    scrollViewMouseLeave = (): void =>
        this.pointerStatesService.managePageScrollViewHovered.next(false);

    private checkPristineState = async (): Promise<boolean> => {
        if (!this.isPristine()) {
            const result = await this.uiConfirmDialogService.confirm({
                headerText: 'You have unsaved changes',
                text: 'Are you sure you want discard unsaved changes?',
                confirmText: 'Yes'
            });
            return result !== 'cancel';
        }
        return true;
    };

    isPristine = (): boolean => !this.downloadIsPending;

    selectShortcut(select: boolean): void {
        if (select) {
            this.tileSelectService.selectAllCreatives();
        } else {
            this.tileSelectService.deselectAllCreatives();
        }
    }

    private applyToggleOtherSizesShortcut = (): void => {
        this.filtersService.toggleSelectedSizes();
    };

    private deleteShortcut(): void {
        const selectedCreatives = this.tileSelectService.getSelected();
        this.displayCampaignService.campaigns$.pipe(take(1)).subscribe(campaigns => {
            const canDelete = !selectedCreatives.some(creative =>
                isCreativeInCampaign(creative, campaigns)
            );
            if (canDelete) {
                this.editCreativeService.deleteSizes();
            }
        });
    }

    isViewEmpty(): boolean {
        if (this.creativesetDataService.creativeset.creatives && this.creatives) {
            return this.creatives.length === 0;
        }
        return false;
    }

    private updateView(): void {
        SimpleCache.clear();
        /* refactoring of propertiespanel was outside the scope of this ticket. More explanation inside serivce */
    }

    ngOnDestroy(): void {
        this.tileSelectService.deselectAllCreatives();
        this.editCreativeService.clearCreativeVisiblityStatus();
        this.navigationGuard.removePristineCheck(this.checkPristineState);
        this.removeHotkeyListeners();

        SimpleCache.clear();
        this.editCreativeService.destroy();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    hasDesigns(): boolean {
        return [...this.creativeset.creatives].some(creative => hasDesign(creative));
    }

    openManageView(): void {
        window.location.href = `/brand/${this.creativeset.brandId}/creativeset/${this.creativeset.id}`;
    }

    deselect(event: MouseEvent): void {
        const isCreativeListClick = isElementDescendantOfElement(
            this.scrollView.nativeElement,
            event.target
        );
        const isMenuClick = isChildOfSelector(event.target as HTMLElement, 'creative-list-groupheader');
        if (isCreativeListClick && !isMenuClick) {
            this.tileSelectService.deselectAllCreatives();
        }
    }

    isSupportBrowser(): boolean {
        const browserInformation = getParser(window.navigator.userAgent);
        const device = browserInformation.getPlatform().type;
        const version = +browserInformation.getBrowserVersion().split('.')[0];
        const browser = browserInformation.getBrowserName(true);

        const isMobileDevice = device === 'mobile' || device === 'tablet';
        const isChrome = browser === 'chrome' && version >= 107;
        const isSafari = browser === 'safari' && version >= 16;
        const isDesktop = device === 'desktop';
        if ((isMobileDevice && (isChrome || isSafari)) || isDesktop) {
            return true;
        }
        return false;
    }

    async openAddSizeDialog(): Promise<void> {
        // stop animations when opening the dialog
        this.animationControlService.stopAnimations();

        const dialog = this.uiDialogService.openComponent(SizeAddDialogComponent, {
            headerText: 'Add creative sizes',
            panelClass: ['no-padding', 'fullscreen'],
            maxWidth: '100%',
            width: '100%',
            height: '100%',
            escKeyClose: false,
            theme: 'default'
        });

        dialog
            .afterClose()
            .pipe(
                take(1),
                switchMap(() => this.creativesetDataService.creativeset$)
            )
            .subscribe(() => {
                this.filtersService.setAllSizes();
                this.tileSelectService.deselectAllCreatives();
                /* we update more than just this view */
                this.editCreativeService.updateView();
            });
    }

    @HostListener('window:resize') resize(): void {
        const documentElement = document.documentElement;
        documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
    }

    creativeListScroll($event: Event): void {
        this.tileSelectService.creativeListScrolled($event);
    }
}
