import { Injectable } from '@angular/core';
import {
    CREATE_BRAND_LIBRARY_FOLDER,
    DELETE_BRAND_LIBRARY_FOLDER,
    MOVE_BRAND_LIBRARY_FOLDER,
    UPDATE_FOLDER_NAME
} from '@data/graphql/brand-library.queries';
import { Folder, IBrandLibrary } from '@domain/creativeset/brand-library';
import { Apollo } from 'apollo-angular';
import { firstValueFrom, Subject } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { BrandLibraryDataService } from '@app/shared/media-library/brand-library.data.service';

@Injectable({ providedIn: 'root' })
export class BrandLibraryFolderService {
    private _selectedFolder = new Subject<Folder | undefined>(); // undefined is root folder
    selectedFolder$ = this._selectedFolder.asObservable();
    brandLibrary: Readonly<IBrandLibrary>;

    constructor(
        private brandLibraryDataService: BrandLibraryDataService,
        private apolloClient: Apollo
    ) {
        this.brandLibraryDataService.brandLibrary$
            .pipe(takeUntilDestroyed())
            .subscribe(brandLibrary => {
                this.brandLibrary = brandLibrary;
            });
    }

    selectFolder(folderId?: string): void {
        const selectedFolder = this.brandLibrary.folders.find(f => f.id === folderId);
        if (selectedFolder) {
            this._selectedFolder.next(selectedFolder);
        } else {
            this.selectRootFolder();
        }
    }

    selectRootFolder(): void {
        this._selectedFolder.next(undefined);
    }

    async addFolder(name: string, parentFolderId?: string): Promise<void> {
        const brandLibraryId = this.brandLibrary.id;

        const mutationResult = await firstValueFrom(
            this.apolloClient.mutate({
                mutation: CREATE_BRAND_LIBRARY_FOLDER,
                variables: {
                    name: name,
                    brandLibraryId: brandLibraryId,
                    parentFolderId: parentFolderId
                }
            })
        );

        const folders = [...mutationResult.data!.createBrandLibraryFolder.folders];
        const newFolders = folders.filter(
            folder => !this.brandLibrary.folders.find(blFolder => blFolder.id === folder.id)
        );
        this.brandLibraryDataService.mutateData('folders', [
            ...this.brandLibrary.folders,
            ...newFolders
        ]);
    }

    async deleteFolder(folderId: string): Promise<void> {
        const brandLibraryId = this.brandLibrary.id;

        const mutationResult = await firstValueFrom(
            this.apolloClient.mutate({
                mutation: DELETE_BRAND_LIBRARY_FOLDER,
                variables: {
                    contentIds: [folderId],
                    brandLibraryId: brandLibraryId
                }
            })
        );
        if (!mutationResult.data?.deleteBrandLibraryContents) {
            throw new Error('Could not deserialize DeleteBrandLibraryContents response');
        }

        const folders = mutationResult.data.deleteBrandLibraryContents.folders;
        const elements = mutationResult.data.deleteBrandLibraryContents.elements;

        this.brandLibraryDataService.mutateData('folders', folders);
        this.brandLibraryDataService.mutateData('elements', elements);
    }

    async moveElementsToFolder(elementIds: string[], folderId: string): Promise<void> {
        const brandLibraryId = this.brandLibrary.id;

        const mutationResult = await firstValueFrom(
            this.apolloClient.mutate({
                mutation: MOVE_BRAND_LIBRARY_FOLDER,
                variables: {
                    contentIds: elementIds,
                    brandLibraryId: brandLibraryId,
                    parentFolderId: folderId
                }
            })
        );

        this.brandLibrary.elements.forEach(e => {
            const changedFolderIdElement = mutationResult.data?.moveBrandLibraryContents.elements.find(
                element => element.parentFolderId !== e.parentFolderId && element.id === e.id
            );

            if (changedFolderIdElement) {
                e.parentFolderId = changedFolderIdElement.parentFolderId;
            }
        });
    }

    async updateFolderName(folderId: string, folderName: string): Promise<void> {
        const brandLibraryId = this.brandLibrary.id;

        await firstValueFrom(
            this.apolloClient.mutate({
                mutation: UPDATE_FOLDER_NAME,
                variables: {
                    name: folderName,
                    brandLibraryId: brandLibraryId,
                    id: folderId
                }
            })
        );
    }

    isEmptyFolder(folderId: string): boolean {
        return !this.brandLibrary.elements.find(e => e.parentFolderId === folderId);
    }
}
